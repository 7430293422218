import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Avatar from '../shared/members/Avatar';
import ForecastIcon from './ForecastIcon';
import moment from 'moment';
import { UserDto } from 'utils/wemble-api.generated';

const useStyles = makeStyles((theme) => ({
  overview__header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  header__title: {
    fontSize: 26,
    fontWeight: 600,
  },
  header__week: {
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: theme.palette.gray.g7,
    color: theme.palette.gray.g10,
    padding: '4px 12px',
    borderRadius: 6,
  },
  overview__table: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& tr': {
        display: 'block',
      },
    },
  },
  table__header: {
    fontSize: 12,
    color: theme.palette.gray.g8,
    textTransform: 'uppercase',
    '& th': {
      fontWeight: 500,
      paddingBlock: 12,
    },
  },
  table__row: {
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.gray.g7,
    '& td': {
      paddingBlock: 18,
    },
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  },
  table__name: {
    width: '40%',
  },
  table__forecast: {
    width: '10%',
    textAlign: 'right',
  },
}));

function dayOfWeek(dayNumber: number, currentDay: number | null) {
  if (dayNumber === currentDay) {
    return 'TODAY';
  }
  switch (dayNumber) {
    case 1:
      return 'MONDAY';
    case 2:
      return 'TUESDAY';
    case 3:
      return 'WEDNESDAY';
    case 4:
      return 'THURSDAY';
    case 5:
      return 'FRIDAY';
    default:
      return '';
  }
}

const WorkplaceOverview = ({
  users,
  currentUser,
}: {
  users: UserDto[] | undefined;
  currentUser: UserDto | null | undefined;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentDayNbr = moment().isoWeekday();
  const isCurrentDayWeekend = currentDayNbr > 5;
  const nextFiveDays = [...Array(5)].map((_, i) => {
    const isSunday = currentDayNbr === 7;
    const isWeekend = isCurrentDayWeekend || currentDayNbr + i > 5;
    const newDate = moment().add(i + (isWeekend ? (isSunday ? 1 : 2) : 0), 'days');
    return {
      dayNbr: newDate.isoWeekday(),
      date: newDate.format('YYYY/MM/DD'),
    };
  });

  return (
    <article>
      <div className={classes.overview__header}>
        <h1 className={classes.header__title}>Workplace overview</h1>
        <div>
          {
            //TODO Add search for user
          }
          <div className={classes.header__week}>
            {nextFiveDays[0].date} - {nextFiveDays[4].date}
          </div>
        </div>
      </div>
      <table className={classes.overview__table}>
        {!mobile && (
          <tr className={classes.table__header}>
            <th className={classes.table__name}>Name</th>
            {nextFiveDays.map((day) => (
              <th className={classes.table__forecast} key={`workplace-overview-${day.date}`}>
                {dayOfWeek(day.dayNbr, isCurrentDayWeekend ? null : currentDayNbr)}
              </th>
            ))}
          </tr>
        )}
        {users
          ?.filter((user) => user.completed && !user.admin)
          .sort((a) => (a._id === currentUser?._id ? -1 : 1))
          .map((user) => (
            <>
              {mobile ? (
                <>
                  <tr className={classes.table__row} key={user._id}>
                    <td className={classes.table__name}>
                      <Avatar
                        image={user.profilePicture === undefined ? '' : user.profilePicture}
                        name={user.name}
                        email={user.email}
                      />
                    </td>
                  </tr>
                  <tr>
                    {[0, 1, 2, 3, 4].map((index) => (
                      <td className={classes.table__forecast} key={`workplace-overview-mobile-forecast-${index}`}>
                        <ForecastIcon user={user} forecast={(user.workplaceForecast && user.workplaceForecast[index]) ?? null} />
                      </td>
                    ))}
                  </tr>
                </>
              ) : (
                <tr className={classes.table__row} key={user._id}>
                  <td className={classes.table__name}>
                    <Avatar
                      image={user.profilePicture === undefined ? '' : user.profilePicture}
                      name={user.name}
                      email={user.email}
                    />
                  </td>
                  {[0, 1, 2, 3, 4].map((index) => (
                    <td className={classes.table__forecast} key={`workplace-overview-desktop-forecast-${index}`}>
                      <ForecastIcon user={user} forecast={(user.workplaceForecast && user.workplaceForecast[index]) ?? null} />
                    </td>
                  ))}
                </tr>
              )}
            </>
          ))}
      </table>
    </article>
  );
};

export default WorkplaceOverview;
