import colors from '../colors';

const sortStyle = {
    container: (provided) => ({
        ...provided,
        fontSize: 16,
        fontWeight: 500,
        fontFamily: 'Roboto',
    }),
    control: (provided) => ({
        ...provided,
        border: 'none',
        borderRadius: 4,
        boxShadow: 'none',
        padding: '6px 16px',
        backgroundColor: colors.gray.g3,
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
    }),
    singleValue: (provided) => ({
        ...provided,
        position: 'relative',
        transform: 'none',
        maxWidth: 'none',
    }),
    multiValue: (provided) => ({
        ...provided,
        padding: 2,
        borderRadius: 6,
        marginRight: 4,
        backgroundColor: colors.blue.b3,
        color: 'white',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'white',
        fontSize: 14,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        transition: '0.1s',
        transform: 'scale(1.1) translateY(-0.5px)',
        '&:hover': {
            backgroundColor: 'transparent',
            color: colors.gray.g1,
            opacity: 0.5,
        },
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
    }),
    indicatorSeparator: () => ({}),
    indicatorContainer: (provided) => ({
        ...provided,
        paddingRight: 0,
    }),
    menu: (provided) => ({
        ...provided,
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
    }),
    groupHeading: (provided) => ({
        ...provided,
        paddingBottom: 6,
        marginBottom: 0,
        borderBottom: 'solid 1px',
        borderBottomColor: colors.gray.g6,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected && colors.blueGray.bg1,
        '&:hover': {
            backgroundColor: !state.isSelected && colors.gray.g3,
            //opacity: 0.7,
        },
        '&:nth-child(1)': {
            borderRadius: '4px 4px 0 0',
        },
        '&:nth-last-child(1)': {
            borderRadius: '0 0 4px 4px',
        },
    }),
};
export default sortStyle;

/*
clearIndicator
dropdownIndicator
group
input
loadingIndicator
loadingMessage
menuPortal
noOptionsMessage
placeholder
 */
