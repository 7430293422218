import { makeStyles } from '@material-ui/core';
import { UserDto } from 'utils/wemble-api.generated';
import classNames from 'classnames';
import UserMessageInput from './UserMessageInput';
import SingleBoxButton from './SingleBoxButton';
import absent from 'assets/icons/absent.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    transition: '0.2s',
    position: 'absolute',
    right: 0,
    top: -65,
    height: 65,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
      height: 65,
      top: -65,
    },
  },
  rootAdmin: {
    minHeight: 72,
    '& > div:first-child': {
      borderRadius: 24,
      paddingBottom: 6,
      minHeight: 72,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 62,
      '& > div:first-child': {
        minHeight: 62,
      },
    },
  },
  profileContainer: {
    height: '100%',
    padding: '8px 8px 0 8px',
    backgroundColor: 'white',
    borderRadius: '26px 26px 0 0',
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      padding: '6px 6px 0 6px',
      borderRadius: '24px 24px 0 0',
    },
  },
  profile__img: {
    borderRadius: '50%',
    backgroundColor: theme.palette.gray.g6,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    height: 48,
    width: 48,
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 40,
    },

    '& img': {
      width: '100%',
    },
  },
  profile__letter: {
    color: theme.palette.gray.g1,
    fontWeight: 600,
    fontSize: 14,
  },
  text: {
    padding: '0 14px 0 4px',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    fontSize: 13,
    height: '48px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '115%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      height: '40px',
      padding: '0 5px 0 3px',
      whiteSpace: 'normal',

    },
  },
  text__sub: {
    fontSize: 10,
    color: '#828282',
    marginTop: -2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
      marginTop: -4,
    },
  },
  adminAction: {
    marginLeft: 20,
    '& > div': {
      backgroundColor: 'white',
      height: 72,
      '& > div': {
        fontSize: 12,
      },
      [theme.breakpoints.down('sm')]: {
        height: 62,
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
}));

const Profile = ({
  user,
  openVacationPlanner,
  disableComments,
}: {
  user: UserDto | null | undefined;
  openVacationPlanner?: (() => void) | false;
  disableComments?: boolean | true
}) => {
  const classes = useStyles();

  if (!user) return null;

  const group = typeof user.group !== 'string' ? user.group?.name : '';
  const office = typeof user.office !== 'string' ? user.office?.name : '';

  return (
    <div className={classNames(classes.root, { [classes.rootAdmin]: user.admin })}>
      <div className={classes.profileContainer}>
        <div>
          <div className={classes.profile__img}>

            {user.profilePicture ? (
              <img
                alt={`${user.name}-image`}
                src={(user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture}
              />
            ) : (
              <div className={classes.profile__letter}>{user.name?.charAt(0)}</div>
            )}
          </div>
        </div>

        <div className={classes.text}>
          <div>{user.name}</div>
          <div className={classes.text__sub}>
            {group} - {office}
          </div>
        </div>
        {openVacationPlanner && (
          <SingleBoxButton sm xs icon={absent} text={'Forecasts'} event={openVacationPlanner} />
        )}

        {!user.admin && !disableComments && (<UserMessageInput sm />)}


      </div>
    </div>
  );
};

export default Profile;
