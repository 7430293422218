import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  settings: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 16,
    fontFamily: 'Roboto',
  },
  settings__title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 16px',
    marginBottom: 30,
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.gray.g6,
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
    },
  },
  settings_items: {
    display: 'flex',
    flexDirection: 'column',
  },
  settings__buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 32,
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.gray.g6,
    fontFamily: 'Roboto',
  },
  buttons__discard: {
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    marginRight: 20,
    opacity: 1,
    transition: 'opacity 0.3s'
  },
  buttons__save: {
    backgroundColor: theme.palette.blue.b3,
    color: 'white',
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    opacity: 1,
    transition: 'opacity 0.3s'
  },
  disabled: {
    opacity: 0.8,
    cursor: 'auto'
  },
  created: {
    opacity: 0.5,
    fontSize: 16,
    flexGrow: 1
  }
}));

export const SettingsButtons = ({
  discard,
  discardCustom,
  save,
  saveCustom,
  changePassword,
  customPadding,
  created,
  modified = true
}: {
  discard?: () => void;
  discardCustom?: string;
  save?: () => void;
  saveCustom?: string;
  changePassword?: () => void;
  customPadding?: number | string;
  created?: string;
  modified?: boolean
}) => {
  const classes = useStyles();

  return (
    <div className={classes.settings__buttons} style={customPadding ? { paddingTop: customPadding } : undefined}>
      {created && (<span className={classes.created}>Created {moment(created).format("YYYY-MM-DD")}</span>)}

      {changePassword && (
        <div className={classes.buttons__discard} onClick={changePassword}>
          Change password
        </div>
      )}
      {discard && (<div className={classes.buttons__discard + (!modified ? (' ' + classes.disabled) : '')} onClick={discard}>
        {discardCustom ? discardCustom : 'Discard'}
      </div>)}
      <div className={classes.buttons__save + (!modified ? (' ' + classes.disabled) : '')} onClick={save}>
        {saveCustom ? saveCustom : 'Save changes'}
      </div>
    </div>
  );
};

const Settings = ({
  title,
  noPadding = false,
  children,
}: {
  title?: string;
  noPadding?: boolean;
  children?: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <article className={classes.settings} style={noPadding ? { padding: 0 } : undefined}>
      {title && <h2 className={classes.settings__title}>{title}</h2>}
      <div className={classes.settings_items}>{children}</div>
    </article>
  );
};

export default Settings;
