import { useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Avatar from './../Avatar';
import Workload from '../project/Workload';
import CompetenceAverageSkillLevel from '../../../competences/CompetenceAverageSkillLevel';
import UserCard from '../../UserCard';
import Modal from '../../Modal';
import { useGetCurrentUserQuery, useDeleteSkillMutation, usePrefetch } from 'utils/wemble-api';
import { CompetenceDto, CompetenceUserDto } from 'utils/wemble-api.generated';
import organization from 'utils/organization';
import { compareNumbers, compareString } from 'helpers/compareFunctions';
import { useGetNextForecastForUser } from 'components/Home/Forecast/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Roboto',
  },
  members__title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 16px',
    marginBottom: 16,
  },
  members__content: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  members__table: {
    '& .MuiTableCell-root': {
      borderBottomColor: theme.palette.gray.g6,
      padding: '16px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 8px',
      },
    },
    '& .MuiTableCell-head': {
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.gray.g10,
      padding: '0 0 8px',
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
  },
  details: {
    backgroundColor: theme.palette.gray.g3,
    display: 'inline-block',
    height: 40,
    width: 40,
    borderRadius: 8,
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  },
}));

const getSortFunction = () => {

  const getNextForecastForUser = useGetNextForecastForUser();

  return (sortMethod) => (memberA: CompetenceUserDto, memberB: CompetenceUserDto) => {

    const getForecastedWorkload = (userId) => {
      const forecast = getNextForecastForUser(userId)
      // If no forecast date is set, return 5 to ensure it's sorted last
      if (!forecast?.status) return 5;
      if (forecast?.status == 'away') return 4;
      return parseInt(forecast?.status);
    };

    switch (sortMethod) {
      default:
      case 'name':
        return compareString(memberA.name, memberB.name);
      case 'skill-level':
        return (memberB.skills[0]?.level?.level || 0) - (memberA.skills[0]?.level?.level || 0);
      case 'current-workload':
        return compareNumbers(memberA.currentWorkload, memberB.currentWorkload);
      case 'workload-forecast':
        return compareNumbers(getForecastedWorkload(memberA._id), getForecastedWorkload(memberB._id));
    }
  }
};

const CompetenceMembers = ({
  reload,
  competence,
}: {
  reload: () => void;
  competence: CompetenceDto | null | undefined;
}) => {
  const members = competence?.users;
  const { data: currentUser } = useGetCurrentUserQuery();
  const prefetchUser = usePrefetch('getUserById');
  const organizationConfig = organization(currentUser?.company);

  const [sortMethod, setSortMethod] = useState<string>('name');

  const [removeSkill] = useDeleteSkillMutation();
  const classes = useStyles();
  const [clickedUserId, setClickedUserId] = useState<string | null | undefined>();

  const sortFunction = getSortFunction();

  function getSkillID(clickedUser: CompetenceUserDto) {
    return competence?.users?.find((user) => user._id === clickedUser._id)?.skills[0]._id;
  }

  async function removeUser(user: CompetenceUserDto) {
    if (!user.skills) return;
    for (const skill of user.skills) {
      if (skill.competence === competence?._id && skill._id) {
        await removeSkill({ id: skill._id });
      }
    }
    reload();
  }

  return (
    <article className={classes.root}>
      <h2 className={classes.members__title}>Members ({members?.length})</h2>
      <div className={classes.members__content}>
        <TableContainer>
          <Table className={classes.members__table}>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => setSortMethod('name')} style={{ color: sortMethod == 'name' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}><span >Name</span></TableCell>
                <TableCell onClick={() => setSortMethod('skill-level')} style={{ color: sortMethod == 'skill-level' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>Skill level</TableCell>
                <TableCell onClick={() => setSortMethod('current-workload')} style={{ color: sortMethod == 'current-workload' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>Workload</TableCell>
                <TableCell onClick={() => setSortMethod('workload-forecast')} style={{ color: sortMethod == 'workload-forecast' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>Forecast</TableCell>

                {/* <TableCell align={'right'}>Details</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {members && [...members]?.sort(sortFunction(sortMethod)).map((user) => (
                <TableRow
                  onClick={() => {
                    console.log(user);
                  }}
                  key={user._id}
                >
                  <TableCell onMouseEnter={() => prefetchUser({ id: user._id || '' }, { ifOlderThan: 2 })}>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setClickedUserId(user._id);
                      }}
                    >
                      <Avatar image={user.profilePicture} name={user.name} email={user.title} />
                    </div>
                  </TableCell>
                  <TableCell>
                    {currentUser?.admin || currentUser?.companyAdmin || currentUser?.administratorPriviliges || (!organizationConfig.disableCompetencesEditSelf && currentUser?._id === user._id) ? (
                      <CompetenceAverageSkillLevel
                        skill={user?.skills[0]?.level?.level}
                        changeable
                        userID={user._id}
                        skillID={getSkillID(user)}
                      />
                    ) : (
                      <CompetenceAverageSkillLevel skill={user?.skills[0]?.level?.level} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Workload userId={user._id} />
                  </TableCell>
                  <TableCell><Workload userId={user._id} forecast /></TableCell>

                  {(!organizationConfig.disableCompetencesPriviligies || (currentUser?.admin || currentUser?.companyAdmin || currentUser?.administratorPriviliges || (!organizationConfig.disableCompetencesEditSelf && currentUser?._id === user._id))) && (
                    <TableCell align={'right'}>
                      <div className={classes.details}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            removeUser(user);
                          }}
                        >
                          <span
                            style={{
                              fontSize: 25,
                            }}
                          >
                            &times;
                          </span>
                        </div>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal visible={typeof clickedUserId == 'string'} close={() => setClickedUserId(undefined)} customWidth={900}>
        {clickedUserId && <UserCard userId={clickedUserId} />}
      </Modal>
    </article>
  );
};

export default CompetenceMembers;
