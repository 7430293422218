import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useGetCurrentUserQuery, useUpdateWorkloadMessageMutation } from 'utils/wemble-api';
import { useDebounce, usePrevious } from 'hooks';
import organization from 'utils/organization';

const useStyles = (sm, commentLength) => makeStyles((theme) => ({
  message: {
    backgroundColor: theme.palette.gray.g7,
    width: sm ? (commentLength ? (commentLength > 50 ? '230px' : '180px') : '145px') : '100%',
    height: sm ? (commentLength ? '100%' : '90%') : 100,
    borderRadius: sm ? '8px 16px 8px 8px' : 20,
    position: 'relative',
    transition: '0.1s',
    border: 'solid 1px transparent',
  },
  message__input: {
    backgroundColor: 'transparent',
    color: theme.palette.gray.g1,
    borderRadius: 20,
    padding: sm ? (commentLength ? (commentLength > 60 ? '8px 6px' : 8) : 12) : 18,
    width: '100%',
    transition: '0.1s',
    height: '100%',
    border: 'none',
    position: 'relative',
    resize: 'none',
    lineHeight: 1.2,
    fontWeight: 600,
    '&:focus': {
      outline: 'none',
    },
    '&::-webkit-scrollbar': {
      width: 2,
    },
    display: '-webkit-box',
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    fontSize: sm ? ((commentLength && commentLength < 75) ? 12 : 11) : 14,

  },
  warning__text: {
    position: 'absolute',
    ...(sm ? {
      bottom: -70,
      right: 2,
      borderRadius: '8px 0px 8px 8px',
    } : {
      top: 90,
      left: 12,
      borderRadius: '0px 0px 8px 8px',
    }),
    width: '175px',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.gray.g1,
    opacity: 1,
    userSelect: 'none',
    backgroundColor: 'white',
    padding: '3px 8px',
    pointerEvents: 'none',
    transition: 'opacity 0.2s',
    boxShadow: '0px 0px 22px rgba(0,0,0,0.16)',
    zIndex: 4
  },
  warning__text__top: {
    position: 'absolute',
    width: '27px',
    height: '24px',
    backgroundColor: 'white',
    borderRadius: '12px 12px 0px 0px',
    ...(sm ? {
      right: 0
    } : {
      left: 0,
    }),
    top: -24,
    zIndex: 5


  },
  message__item: {
    position: 'absolute',
    transition: 'opacity 0.2s',
    bottom: 10,
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.gray.g1,
    userSelect: 'none',
    borderRadius: 8,
    padding: '0px 3px',

  },
  message__counter: {
    ...(sm ? {
      right: 0,
      bottom: -22,
      fontSize: 10,
      padding: '1px 4px'
    } : {
      right: 16
    }),
    backgroundColor: theme.palette.gray.g7,

  },
  message__warning: {

    opacity: 0,
    zIndex: 10,
    ...(sm ? {
      right: 0,
      bottom: -22,
      fontSize: 10,
      padding: '1px 4px'
    } : {
      left: 16,
    }),

  }
}));

const UserMessageInput = ({ disabled = false, sm = false }: { disabled?: boolean, sm?: boolean }) => {

  const { data: user } = useGetCurrentUserQuery(undefined, { refetchOnMountOrArgChange: 5, refetchOnReconnect: true });

  const [value, setValue] = useState<string | undefined>();
  const [updateWorkloadMessage] = useUpdateWorkloadMessageMutation();
  const [debouncedValue] = useDebounce(value, 150);
  const prevDebouncedValue = usePrevious(debouncedValue);
  const [highlighted, setHighlighted] = useState(false);
  const [warning, setWarningVisible] = useState(false);

  const classes = useStyles(sm, value?.length ?? 0)();

  useEffect(() => {
    if (value === undefined && user) {
      setValue(user.message?.message ?? '');
    }
  }, [value, user]);

  useEffect(() => {
    if (debouncedValue !== undefined && prevDebouncedValue !== undefined) {
      updateWorkloadMessage({
        workloadMessageCreationParams: {
          message: debouncedValue,
        },
      });
    }
  }, [debouncedValue]);

  if (value === undefined) return null;


  return (
    <div className={classNames(classes.message)}>

      <>
        <textarea
          maxLength={organization(user?.company).expandComment ? 100 : 50}
          placeholder={"Let your team know what you're up to"}
          onFocus={() => setHighlighted(true)}
          onBlur={() => setHighlighted(false)}
          className={classNames(classes.message__input)}
          defaultValue={value}
          disabled={disabled}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              //Prevent line breaks
              event.preventDefault();
            }
          }}
        />
        <div onMouseOver={() => (!value?.length && highlighted) && setWarningVisible(true)} onMouseOut={() => setWarningVisible(false)} style={{ opacity: (!value?.length && highlighted) ? '1' : '0' }} className={classes.message__item + " " + classes.message__warning}>
          <svg
            style={{
              marginRight: '5px',
              marginTop: '-3px',
            }}
            xmlns="http://www.w3.org/2000/svg"
            height="11"
            viewBox="0 0 357.17 304.53"
          >
            <g id="Group_1" data-name="Group 1" transform="translate(-1625.352 -3437.678)">
              <path
                id="Path_2"
                data-name="Path 2"
                d="M1345.3,3421.5l-143.2,234.53L1489.27,3654Z"
                transform="translate(458.252 51.177)"
                fill="#ffae05"
                stroke="#ffae05"
                strokeLinejoin="round"
                strokeWidth="70"
              />
              <rect
                id="Rectangle_10"
                data-name="Rectangle 10"
                width="42"
                height="138"
                rx="13"
                transform="translate(1783 3505)"
                fill="#fff"
              />
              <ellipse
                id="Ellipse_5"
                data-name="Ellipse 5"
                cx="21"
                cy="21.5"
                rx="21"
                ry="21.5"
                transform="translate(1783 3664)"
                fill="#fff"
              />
            </g>
          </svg>
        </div>

        <div className={classes.message__item + " " + classes.message__counter} style={{ opacity: (highlighted && value) || !sm ? '1' : '0' }}>{value?.length ?? 0}/{organization(user?.company).expandComment ? 100 : 50}</div>
      </>
      <div className={classes.warning__text} style={{ opacity: warning ? '1' : '0' }}><div className={classes.warning__text__top} />Do not share any sensitive information</div>

    </div>
  );
};

export default UserMessageInput;
