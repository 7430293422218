import { Checkbox } from '@material-ui/core';
import Input from 'components/shared/Input';
import { Formik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { CompanyDto, useUpdateCompanySmtpConfigMutation, useTestCompanySmtpConfigMutation } from 'utils/wemble-api.generated';


const SmtpModal = ({
  isOpen,
  onClose,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  data?: CompanyDto | undefined;
}) => {
  const handleClose = () => {
    onClose();
  };

  const [updateCompanySmtpConfigMutation] = useUpdateCompanySmtpConfigMutation();
  const [testCompanySmtpConfigMutation] = useTestCompanySmtpConfigMutation();

  


  // smtpHost: string;
  // smtpPort: string;
  // smtpSender: string;
  // smtpSecure: boolean;
  // smtpUser: string;
  // smtpPassword: string;


  data?.smtpSecure
  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
            <Formik initialValues={data ? {smtpHost: data.smtpHost,
smtpPort: data.smtpPort,
smtpSender: data.smtpSender,
smtpSecure: data.smtpSecure,
smtpUser: data.smtpUser,
smtpPassword: data.smtpPassword} : {}} onSubmit={ (values, submitProps)=> updateCompanySmtpConfigMutation({companySmtpConfigParams: values}).then((_)=>submitProps.resetForm({values}))}
        
>
        {({ handleSubmit, values, setFieldValue, dirty }) => (
          <div>
        <ModalHeader toggle={handleClose}>{'SMTP config'}</ModalHeader>
        <ModalBody>
        <p style={{fontSize: 14}}>By default, invites, password resets etc. are sent from email servers managed by Wemble. By inputing your own configuration, all emails to users within you organization will instead be sent by the server specified below.</p>

          <table className={'table'}>
            <tbody>
              <tr>
                <th scope="row" className={'control-label'}>
                  Sender
                </th>
                <td>
                <Input placeholder={'Eg. Wemble <noreply@wemble.app>'} name={'smtpSender'} type="text" /> 
                </td>
              </tr>
              <tr>
                <th scope="row" className={'control-label'}>
                  Host
                </th>
                <td>
                <Input placeholder={'Eg. smtp.domain.com'} name={'smtpHost'} type="text" /> 
                </td>
              </tr>
              <tr>
                <th scope="row" className={'control-label'}>
                  Port
                </th>
                <td>
                <Input placeholder={'Eg. 587'} name={'smtpPort'} type="text" /> 
                </td>
              </tr>
              <tr>
                <th scope="row" className={'control-label'}>
                  Secure
                </th>
                <td>
                <Checkbox
                color="primary"
                checked={values.smtpSecure}
                onChange={(event) => {
                setFieldValue('smtpSecure', event.target.checked)          
                }}
                />
              </td>
              </tr>
              <tr>
                <th scope="row" className={'control-label'}>
                  Username
                </th>
                <td>
                <Input placeholder={''} name={'smtpUser'} type="text" /> 
                </td>
              </tr>
              <tr>
                <th scope="row" className={'control-label'}>
                  Password
                </th>
                <td>
                <Input placeholder={''} name={'smtpPassword'} type="password" /> 
                </td>
              </tr>





              </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
        <Button color="primary" type="submit" disabled={!dirty || !(values.smtpHost && values.smtpPort && values.smtpSender)} onClick={handleSubmit}>
            {'Update'}
          </Button>

          <Button color="danger" disabled={!(values.smtpHost && values.smtpPort && values.smtpSender )} onClick={() => updateCompanySmtpConfigMutation({companySmtpConfigParams: {smtpHost: undefined,
smtpPort: undefined,
smtpSender: undefined,
smtpSecure: undefined,
smtpUser: undefined,
smtpPassword: undefined}}).then(_ => {alert("Custom SMTP config removed, using Wemble servers instead"); onClose();})}>
            Reset
          </Button>

          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>


          <Button color="primary" type="submit" disabled={dirty} onClick={async () => {
          const res = await testCompanySmtpConfigMutation();
          try{
            (res as {data: string}).data && alert((res as {data: string}).data)
          }
          catch{}
          try{
            (res as {error: any}).error.data && alert((res as {error: any}).error.data)

          }
          catch{}
          
           }}>
            {'Send test email'}
          </Button>

        </ModalFooter>


              </div>
        )}
        </Formik>

    </Modal>
  );
};

export default SmtpModal;
