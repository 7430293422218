import { useState, useEffect } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Modal, ModalBody } from 'reactstrap';
import '../../assets/datePickerCustomStyling.css';
import DatePicker from 'react-datepicker';

import { CalendarItemType } from 'components/vacationPlanner/Calendar';
import classNames from 'classnames';
import { useGetCurrentUserQuery } from 'utils/wemble-api';
import organization from 'utils/organization';
import { convertLocalToUTCDate, convertUTCToLocalDate } from 'components/Home/Forecast/utils';

const useStyles = makeStyles(() => ({
  datePicker: {
    '& .react-datepicker__close-icon::after': {
      right: -12,
      position: 'relative',
    },
  },
}));

const ItemModal = ({
  isOpen,
  item,
  close,
  disabled,
  handleAddVacation,
  handleEditVacation,
  handleRemoveVacation,
}: {
  isOpen: boolean;
  item: CalendarItemType | false;
  handleAddVacation: (title: string, startDate: Date | null, endDate: Date | null) => Promise<void>;
  handleEditVacation: (
    title: string,
    startDate: Date | null,
    endDate: Date | null,
    vacationId: string | null | undefined,
  ) => Promise<void>;
  handleRemoveVacation: (vacationId: string | null | undefined) => Promise<void>;
  close: () => void;
  disabled: boolean;
}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [title, setTitle] = useState<string>('Away');
  const { data: user } = useGetCurrentUserQuery();
  const organizationConfig = organization(user?.company);


  useEffect(() => {
    setStartDate(item ? item.startDate.toDate() : null);
    setEndDate(item && item.endDate ? item.endDate.toDate() : null);
    setTitle(item ? item.title ?? 'Away' : 'Away');
  }, [item]);

  const handleClose = () => {
    if (disabled) return;
    setStartDate(null);
    setEndDate(null);
    setTitle('Away');
    close();
  };

  const handleSubmit = async () => {
    if (item) {
      await handleEditVacation(title, startDate, endDate, item._id);
    } else {
      await handleAddVacation(title, startDate, endDate);
    }
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} size="md" toggle={handleClose}>
      <ModalBody>
        <h5>
          {item ? 'Update ' : 'Add '}
          Item
        </h5>
        <input
          className={'input100' + (title ? ' has-val' : '')}
          type="text"
          name="title"
          placeholder="Enter title"
          disabled={organizationConfig.name === "MAQS"}
          onChange={(ev) => setTitle(ev.target.value)}
          value={title}
          style={{
            textAlign: 'right',
            padding: '0 12px',
            fontSize: 16,
            color: 'black',
          }}
        />
        <DatePicker
          wrapperClassName={classNames('datePickerCustomStyling', classes.datePicker)}
          selected={convertUTCToLocalDate(startDate)}
          maxDate={convertUTCToLocalDate(endDate)}
          onChange={(date) => setStartDate((convertLocalToUTCDate(date)))}
          placeholderText="Start date"
          calendarStartDay={1}
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        />
        <DatePicker
          wrapperClassName={classNames('datePickerCustomStyling', classes.datePicker)}
          selected={convertUTCToLocalDate(endDate)}
          minDate={convertUTCToLocalDate(startDate)}
          onChange={(date) => setEndDate((convertLocalToUTCDate(date)))}
          placeholderText="End date"
          calendarStartDay={1}
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
          time
        />


        <Button variant="outlined" color="primary" disabled={!startDate || !title || disabled} onClick={handleSubmit}>
          {item ? 'Update' : 'Add'}
        </Button>
        &nbsp;&nbsp;&nbsp;{' '}
        {item && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleRemoveVacation(item._id)}
            disabled={disabled}
          >
            Delete
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ItemModal;
