import { makeStyles, TableBody, TableCell, Table, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import { useState } from 'react';
import { ProjectTimeReportDto, ProjectWithUsersDto } from 'utils/wemble-api.generated';
import Modal from '../../Modal';
import dots from './../../../../assets/icons/dots.svg';
import HoursReportModal from './HoursReportModal';


const useStyles = makeStyles((theme) => ({
  select: {
    fontFamily: 'Roboto',
  },
  select__title: {
    color: theme.palette.gray.g1,
    '& h1': {
      fontSize: 28,
      fontWeight: 600,
    },
    '& p': {
      color: theme.palette.gray.g8,
    },
  },
  hours__table: {
    width: 100 + '%',
    '& .MuiTableCell-root': {
      borderBottomColor: theme.palette.gray.g6,
      padding: '16px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 8px',
      },
    },
    '& .MuiTableCell-head': {
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.gray.g10,
      padding: '0 0 8px',
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
  },
  details: {
    backgroundColor: theme.palette.gray.g3,
    display: 'inline-block',
    height: 40,
    width: 40,
    borderRadius: 8,
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  },
  details__add: {
    backgroundColor: theme.palette.blue.b2,
    color: 'white'
  }
}));

const ProjectHoursModal = ({
  project,
  userId,
  visible,
  onClose,
}: {
  project?: ProjectWithUsersDto | null
  userId?: string | null | undefined;
  visible: boolean;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const user = project?.users?.find(user => user._id == userId)
  const [clickedReport, setClickedReport] = useState<ProjectTimeReportDto | string | undefined>(undefined);
  return (
    <div>
    <Modal visible={visible} close={onClose} customWidth={600}>
      <div className={classes.select}>
        <div className={classes.select__title}>
          <h1>Hours</h1>
          <p>Summary of hours reported for {user?.name} in {project?.name}</p>
          <Table className={classes.hours__table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Date
                </TableCell>
                <TableCell>
                  Hours
                </TableCell>
                <TableCell align={'right'}>
                <div className={classes.details + ' ' + classes.details__add}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setClickedReport(user?._id || undefined);
                        }}
                      >
                <span
                          style={{
                            fontSize: 25,
                          }}
                        >
                          +
                        </span>
                        </div>
                        </div>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

             {user?.projectTimeReports?.map(report => (
              <TableRow key={report._id}>
                <TableCell>
                  {moment(report.date).format("DD/MM/YY") }
                </TableCell>
                <TableCell>
                  {report.hours}
                </TableCell>
                <TableCell align={'right'}>
                    <div className={classes.details}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setClickedReport(report);
                        }}
                      >
                       <img alt={'...'} src={dots} />
                      </div>
                    </div>
                  </TableCell>

              </TableRow>
            ))}
            </TableBody>
          </Table>
        </div>
        </div>
    </Modal>
    <HoursReportModal visible={typeof clickedReport !== 'undefined'} onClose={()=>{setClickedReport(undefined);}} projectId={project?._id} report={typeof clickedReport != 'string' ? clickedReport : null} userId={typeof clickedReport == 'string' ? clickedReport : null} />
    </div>
  );
};

export default ProjectHoursModal;
