import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    button: {
        padding: '10px 20px',
        fontWeight: 500,
        borderRadius: 8,
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    button__icon: {
        maxWidth: 13,
        '& > img': {
            width: '100%',
        },
    },
}));

const Button = ({ onClick, text, icon, background, color }) => {
    const classes = useStyles();

    return (
        <div
            onClick={onClick}
            className={classes.button}
            style={{ backgroundColor: background, color: color }}
        >
            {icon && <div className={classes.button__icon}>{icon}</div>}
            {text && <div style={icon ? { marginLeft: 10 } : null}>{text}</div>}
        </div>
    );
};

Button.defaultProps = {
    background: '#fff',
    color: '#333',
};
Button.propTypes = {
    onClick: PropTypes.any,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.element,
    background: PropTypes.string,
    color: PropTypes.string,
};

export default Button;
