import React, { useState } from 'react';
import Modal from '../shared/Modal';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, makeStyles, Radio } from '@material-ui/core';
import OrderSelect from '../shared/OrderSelect';
import {
  compareUsersByExperience,
  compareUsersByName,
  compareUsersByNegativeWorkload,
  compareUsersByUpdated,
  compareUsersByWorkload,
} from 'helpers/compareFunctions';
import Avatar from '../shared/members/Avatar';
import { SettingsButtons } from '../shared/Settings';
import ReactLoading from 'react-loading';
import UniversalFilterSelect from '../shared/UniversalFilterSelect';
import { useGetFilteredUserIdsMutation, useGetGroupsQuery, useGetOfficesQuery, useGetUsersQuery } from 'utils/wemble-api';
import { UserDto, UserFilterParams } from 'utils/wemble-api.generated';
import { isArray } from 'utils/helpers';

const orderOptions = [
  {
    label: 'Name',
    value: 'name',
  },
  // {
  //     label: 'Number of users',
  //     value: 'users-number',
  // },
];

const useStyles = makeStyles((theme) => ({
  select: {
    fontFamily: 'Roboto',
  },
  select__title: {
    color: theme.palette.gray.g1,
    '& h1': {
      fontSize: 28,
      fontWeight: 600,
    },
    '& p': {
      color: theme.palette.gray.g8,
    },
  },
  select__filters: {
    paddingBlock: 10,
  },
  select__list: {
    maxHeight: 400,
    overflowY: 'scroll',
  },
  listItem: {
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.gray.g7,
    marginBlock: 0,
    userSelect: 'none',
  },
  label: {
    width: '100%',
  },
  checkBox: {
    color: theme.palette.gray.g1,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.gray.g1,
    },
  },
  list__user: {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: 8,
    margin: 0,
  },
  user__project: {
    textAlign: 'right',
    flexGrow: 1,
    '& h2': {
      fontSize: 14,
      fontWeight: 400,
      margin: 0,
      color: theme.palette.gray.g8,
    },
    '& p': {
      fontSize: 15,
      fontWeight: 500,
      margin: 0,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  loader: {
    fill: '#59abcb',
    margin: 'auto',
    marginTop: '50px',
    marginBottom: '50px',
    width: '20%',
  },
}));

const UserPicker = ({
  type,
  description,
  visible,
  onClose,
  onSelect,
  userFilter,
}: {
  type: 'competence' | 'manager' | 'project';
  description?: string;
  visible: boolean;
  onClose: () => void;
  onSelect: (selectedUsers: UserDto[]) => void;
  userFilter?: (user: UserDto) => boolean;
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState(orderOptions[0].value);
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const [users, setUsers] = useState<UserDto[]>([]);
  const [loadStatus, setLoadStatus] = useState('loading');
  const [getFilteredUserIds] = useGetFilteredUserIdsMutation();
  const { data: groups } = useGetGroupsQuery();
  const { data: offices } = useGetOfficesQuery();
  const { data: allUsers } = useGetUsersQuery();

  const [filters, setFilters] = useState<UserFilterParams | undefined>();
  const officeAndGroupSelected =
    filters &&
    isArray(filters.offices) &&
    filters.offices.length > 0 &&
    isArray(filters.groups) &&
    filters.groups.length > 0;
  React.useEffect(() => {
    if (filters && officeAndGroupSelected) {
      setLoadStatus('loading');
      getFilteredUserIds({
        body: {
          filters: {
            ...filters,
          },
        },
      }).then((res) => {
        if ('data' in res) {
          setUsers(allUsers?.filter((user) => user.completed && !user.admin && res.data.includes(user._id || '')) ?? []);
        }
        setLoadStatus('ready');
      });
    }
  }, [filters]);

  const sortUsers = (userA: UserDto, userB: UserDto) => {
    switch (order) {
      default:
      case 'updated':
        return compareUsersByUpdated(userA, userB);
      case 'name':
        return compareUsersByName(userA, userB);
      case 'workload-desc':
        return compareUsersByWorkload(userA, userB);
      case 'workload-asc':
        return compareUsersByNegativeWorkload(userA, userB);
      case 'experience':
        return compareUsersByExperience(userA, userB);
    }
  };

  const handleUserSelect = (selectedUser: UserDto, checked: boolean) => {
    if (type === 'manager') {
      setSelectedUsers([selectedUser]);
    } else {
      const users = selectedUsers.filter((user) => {
        return user._id !== selectedUser._id;
      });
      if (checked) {
        users.push(selectedUser);
      }
      setSelectedUsers(users);
    }
  };

  return (
    <Modal visible={visible} close={onClose} customWidth={600}>
      <div className={classes.select}>
        <div className={classes.select__title}>
          <h1>Search users</h1>
          <p>{description ? description : `Add users to ${type}`}</p>
        </div>
        <Grid container className={classes.select__filters}>
          <Grid item xs={12}>
            <UniversalFilterSelect type='users' setFilters={setFilters} showAllOffices showAllGroups requireOfficeAndGroup={false} itemsFullWidth>
              <OrderSelect
                options={orderOptions}
                onChange={(order) => {
                  setOrder(order.value);
                }}
                defaultValue={orderOptions[0]}
              />
            </UniversalFilterSelect>
          </Grid>
        </Grid>
      </div>

      <div className={classes.select__list}>
        <FormControl component="fieldset" fullWidth={true}>
          <FormGroup aria-label="position">
            {loadStatus === 'ready' &&
              users
                .filter(
                  userFilter ||
                  (() => {
                    return true;
                  }),
                )
                .sort(sortUsers)
                .map((user, key) => {
                  return (
                    <FormControlLabel
                      className={classes.listItem}
                      key={key}
                      value="end"
                      classes={{ label: classes.label }}
                      control={
                        type === 'manager' ? (
                          <Radio
                            color="primary"
                            className={classes.checkBox}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            checked={selectedUsers.some((u) => u._id === user._id)}
                            onChange={(event) => {
                              handleUserSelect(user, event.target.checked);
                              console.log(user);
                            }}
                          />
                        ) : (
                          <Checkbox
                            color="primary"
                            className={classes.checkBox}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            onChange={(event) => {
                              handleUserSelect(user, event.target.checked);
                              console.log(user);
                            }}
                          />
                        )
                      }
                      label={
                        <div className={classes.list__user}>
                          <Avatar
                            image={user.profilePicture === undefined ? '' : user.profilePicture}
                            name={user.name}
                            email={`${(groups?.groups?.find((group) => group._id === user.group) || { name: '' })?.name
                              } - ${(offices?.find((office) => office._id === user.office) || { name: '' })?.name}`}
                          />
                          {!!user?.projects?.length && type === 'project' && (
                            <div className={classes.user__project}>
                              <h2>#Of Projects</h2>
                              <p>{user.projects.length}</p>
                            </div>
                          )}
                        </div>
                      }
                      labelPlacement="end"
                    />
                  );
                })}
            {loadStatus === 'loading' && <ReactLoading type={'spinningBubbles'} className={classes.loader} />}
          </FormGroup>
        </FormControl>
      </div>
      <SettingsButtons
        discardCustom={'Cancel'}
        discard={onClose}
        saveCustom={'Add Selected Users'}
        save={() => {
          onClose();
          onSelect(selectedUsers);
          setSelectedUsers([]);
        }}
        customPadding={15}
      />
    </Modal>
  );
};

export default UserPicker;
