import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0',
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.gray.g6,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item__title: {
    maxWidth: 280,
    width: '100%',
    color: theme.palette.gray.g1,
    fontWeight: 500,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  item__input: {
    //transform: 'translateY(15px)',
    width: '100%',
  },
}));

const Item = ({ title, input, alignRight = false }: { title: string; alignRight?: boolean; input?: ReactNode }) => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <div className={classes.item__title}>{title}</div>
      <div style={alignRight ? { textAlign: 'right' } : undefined} className={classes.item__input}>
        {input}
      </div>
    </div>
  );
};
export default Item;
