import { useCallback, useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import ProjectsList from '../components/projects/ProjectsList';
import Modal from '../components/shared/Modal';
import CreateProject from '../components/projects/CreateProject';
import Button from '../components/shared/Button';
import plus from '../assets/icons/plus.svg';
import download from '../assets/icons/download.svg';
import axios from 'axios';
import { useGetCurrentUserQuery, useGetFilteredProjectsMutation } from 'utils/wemble-api';
import UniversalFilterSelect from 'components/shared/UniversalFilterSelect';
import { ProjectFilterParams, ProjectWithUsersDto, } from 'utils/wemble-api.generated';
import { isArray } from 'utils/helpers';
import OrderSelect from 'components/shared/OrderSelect';
import { compareNumbers, compareString } from 'helpers/compareFunctions';
import moment from 'moment';
import organization from 'utils/organization';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  projects: {
    width: '100%',
    margin: '70px auto 65px',
    fontFamily: 'Roboto',
  },
  projects__title: {
    fontSize: 26,
    fontWeight: 600,
  },
  projects__toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbar__tools: {
    display: 'flex',
    '& > div': {
      marginLeft: 16,
    },
  },
  searchField: {
    '& > div': {
      backgroundColor: 'white !important',
    },
  },

}));

const orderOptions: { label: string; value: string }[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Start date',
    value: 'start-date',
  },
  {
    label: 'End date',
    value: 'end-date',
  },
  {
    label: 'Members',
    value: 'members',
  },
  {
    label: 'Workload',
    value: 'current-workload',
  },
];

const sortFunction = (sortMethod) => (projectA: ProjectWithUsersDto, projectB: ProjectWithUsersDto) => {
  switch (sortMethod) {
    default:
    case 'name':
      return compareString(projectA.name, projectB.name);
    case 'start-date':
      return compareNumbers(projectB.startDate ? moment(projectB.startDate).unix() : -1, projectA.startDate ? moment(projectA.startDate).unix() : -1);
    case 'end-date':
      return compareNumbers(projectB.endDate ? moment(projectB.endDate).unix() : -1, projectA.endDate ? moment(projectA.endDate).unix() : -1);
    case 'members':
      return compareNumbers(projectB.users?.length, projectA.users?.length);
    case 'current-workload':
      return compareNumbers(projectB.averageWorkload, projectA.averageWorkload);
  }
};

const Projects = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const { data: user } = useGetCurrentUserQuery();
  const [filters, setFilters] = useState<ProjectFilterParams | undefined>();
  const [filteredProjects, setFilteredProjects] = useState<ProjectWithUsersDto[] | undefined>();
  const [getFilteredProject] = useGetFilteredProjectsMutation();
  const [order, setOrder] = useState(orderOptions[0].value);

  order && order
  const officeOrGroupSelected =
    filters &&
    ((isArray(filters.offices) && filters.offices.length > 0) ||
      (isArray(filters.groups) && filters.groups.length > 0));

  const fetchProjects = useCallback(
    async () => {
      if (!filters) return;
      if (officeOrGroupSelected) {
        const res = await getFilteredProject({
          body: {
            filters,
          },
        });
        if ('data' in res) {
          setFilteredProjects(res.data);
        }
      } else {
        setFilteredProjects([]);
      }
    },
    [filters, officeOrGroupSelected],
  );

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const downloadXlsx = () => {
    axios({
      url: '/api/projectsSummary/' + encodeURIComponent(JSON.stringify(filteredProjects?.map(p => p._id))) + '/report.xlsx', //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data as BlobPart]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
  const toggleModal = () => {
    setVisible(!visible);
  };

  return (
    <>
      <Container className={classes.container}>
        <div className={classes.projects}>
          <h1 className={classes.projects__title}>Projects</h1>
          <br />
          <UniversalFilterSelect
            type='projects'
            setFilters={setFilters}
            searchFieldClassName={classes.searchField}
            showAllOffices
            showAllGroups
            requireOfficeAndGroup={false}
            hideFilter={organization(user?.company).projectsAllGroups && true}
            manyChildren
          >
            <div className={classes.toolbar__tools}>
              <OrderSelect
                options={orderOptions}
                onChange={(order) => {
                  setOrder(order.value);
                }}
                defaultValue={orderOptions[0]}
              />

              <Button onClick={downloadXlsx} icon={<img alt={'+'} src={download} />} />
              <Button onClick={toggleModal} text={'Add project'} icon={<img alt={'+'} src={plus} />} />
            </div>
          </UniversalFilterSelect>
          <br />

          <ProjectsList
            currentUser={user}
            projects={filteredProjects && [...filteredProjects].sort(sortFunction(order))}
          />
          <Modal visible={visible} close={toggleModal}>
            <CreateProject user={user} close={toggleModal} />
          </Modal>
        </div>
      </Container>
    </>
  );
};

export default Projects;
