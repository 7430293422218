import { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import 'chartjs-adapter-moment';

const useStyles = makeStyles((theme) => ({
  lineChart: {
    backgroundColor: 'white',
    borderRadius: 16,
    minHeight: 300,
    height: '100%',
    padding: '20px 20px 10px',
  },
  lineChart__chart: {
    width: '100%',
    paddingBottom: 10,
  },
  lineChart__text: {
    color: theme.palette.gray.g1,
    fontSize: 14,
    fontWeight: 900,
  },
}));

const LineChart = ({
  data,
  title,
  labels,
  yAxisTickFormatFn = (v) => v,
}: {
  data: number[];
  title: string;
  labels: string[];
  yAxisTickFormatFn?: (value: number | string) => number | string;
}) => {
  const classes = useStyles();
  const [chart, setChart] = useState<Chart | null>(null);
  const lineChart = useRef<HTMLCanvasElement | null>(null);

  const config: ChartConfiguration = useMemo(
    () => ({
      type: 'line',
      data: {
        datasets: [
          {
            data: [],
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            grid: {
              borderColor: 'transparent',
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              maxRotation: 20,
              minRotation: 0,
              color: 'black',
              font: {
                weight: '900',
              },
            },
          },
          y: {
            grid: {
              borderColor: 'transparent',
              display: false,
            },
            labels: {
              show: true,
            },
            ticks: {
              padding: 10,
              maxTicksLimit: 4,
              color: 'black',
              stepSize: 0.05,
              precision: 2,
              font: {
                weight: '900',
              },
              callback: function (value, _index, _values) {
                return yAxisTickFormatFn(value);
              },
            },
          },
        },
      },
    }),
    [],
  );

  useEffect(() => {
    if (lineChart && lineChart.current && !chart) {
      let newChart = new Chart(lineChart.current, config);
      setChart(newChart);
    }
  }, [lineChart, chart, config]);

  useEffect(() => {
    if (chart && data.length > 0) {
      config.data.labels = labels;
      chart.data.datasets[0].data = data;
      chart.update();
    }
  });

  return (
    <div className={classes.lineChart}>
      <div className={classes.lineChart__chart}>
        <canvas ref={lineChart} height={260} />
      </div>
      <div className={classes.lineChart__text}>{title}</div>
    </div>
  );
};
export default LineChart;
