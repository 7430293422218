import { makeStyles, Hidden, Button } from '@material-ui/core';
import { CalendarUserType } from './Calendar';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  user_item: {
    height: '50px',
    paddingTop: '7px',
  },
  user_text_container: {
    width: 110,
    [theme.breakpoints.down('sm')]: {
      width: 80,
    },
  },
  user_letter: {
    color: theme.palette.gray.g1,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  user_extra: {
    color: theme.palette.gray.g2,
    fontWeight: 400,
    fontSize: 10,

    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
  user_image: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    float: 'left',
    marginRight: '12px',
  },
  letter_image: {
    color: theme.palette.gray.g1,
    backgroundColor: theme.palette.gray.g6,
    borderRadius: '50%',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'center',
    paddingTop: '7px',
  },
  user_button: {
    height: 16,
    marginTop: -10,
    padding: '3px 10px',
    fontSize: 8,
  },
}));

const UserItem = ({
  user,
  openAddModal,
  isAdmin,
}: {
  user: CalendarUserType;
  openAddModal: (userId: string) => void;
  isAdmin: boolean;
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" wrap="nowrap" className={classes.user_item}>
      <Hidden smDown>
        {user.profilePicture ? (
          <img
            className={classes.user_image}
            alt={`${user.name}`}
            src={(user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture}
          />
        ) : (
          <div className={classes.user_image + ' ' + classes.letter_image}>{user.name?.charAt(0)}</div>
        )}
      </Hidden>
      <div className={classes.user_text_container}>
        <div className={classes.user_letter}>{user.name}</div>
        <div className={classes.user_extra}>{user.title}</div>
      </div>
      {isAdmin && (
        <svg style={{ cursor: 'pointer' }} onClick={() => user.id && openAddModal(user.id)} width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="24" cy="24" r="24" fill="#DDD" />
          <rect x="8" y="21" width="33" height="6" rx="3" fill="white" />
          <rect x="27" y="7" width="33" height="6" rx="3" transform="rotate(90 27 7)" fill="white" />
        </svg>

      )}
    </Grid>
  );
};

export default UserItem;
