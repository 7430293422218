
import { makeStyles } from '@material-ui/core';
import theme from 'theme/theme';
import { statusColor, formattedDateRange, statusTitle } from '../utils';
import { ForecastDto } from 'utils/wemble-api.generated';

const useStyles = makeStyles(() => ({
  daterange: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.gray.g1
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  status: {
    fontWeight: 600,
    fontSize: '20px',
    textAlign: 'center',
    lineHeight: '120%',
    maxWidth: 100,
    overflowWrap: 'break-word'
  },
  source: {
    fontSize: '10px',
  }

}));

const DefaultView = ({
  data
}: {
  data: { startDate?: string | undefined, endDate?: string | undefined, status?: string | undefined, comment?: string | null | undefined, externalSource?: string | null | undefined };
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.daterange}>{formattedDateRange(data)}</div>
      <div className={classes.statusContainer} style={{ color: statusColor(data.status) }}>
        <div className={classes.status}>{data.comment || statusTitle(data.status)}</div>
        {data.externalSource ? (<div className={classes.source}>From {data.externalSource}</div>) : ''}
      </div>

    </>
  );
};

export default DefaultView;
