import CardItem from '../CardItem';
import ProjectLeader from '../../../projects/ProjectLeader';
import AvatarRow from '../../../AvatarRow';
import moment from 'moment';
import { ProjectWithUsersDto } from 'utils/wemble-api.generated';
import ProjectInfoBox from 'components/projects/ProjectInfoBox';

const formatDate = (date) => {
  let month = moment(date).locale('en').format('MMMM').substr(0, 3);
  let day = moment(date).locale('en').format('DD');
  let year = moment(date).locale('en').format('YYYY');
  return `${month} ${day}, ${year}`;
};

const ProjectCards = ({ data }: { data: ProjectWithUsersDto }) => {
  return (
    <>
      {data.startDate && <CardItem data={formatDate(data.startDate)} description={'Starts'} />}
      {data.endDate && <CardItem data={formatDate(data.endDate)} description={'Ends'} />}

      <ProjectInfoBox score={typeof data.averageWorkload === 'number' ? data.averageWorkload + 1 : undefined} size={'medium'} />

      {data.manager && <ProjectLeader leader={data.manager} size={'medium'} reverse />}

      {data.users && <AvatarRow members={data.users} size={'medium'} />}

      {data.projectCategories?.map(projectCategory => <ProjectInfoBox text={projectCategory.name} size={'small'} />)}

      {data.projectStage && (<ProjectInfoBox color={data.projectStage?.color} text={data.projectStage.name} size={'small'} />)}

    </>
  );
};
export default ProjectCards;
