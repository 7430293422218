import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { UserDto } from 'utils/wemble-api.generated';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  addNewItemBtn: {
    filter: 'brightness(115%)',
    color: 'white',
  },
  list: {
    marginTop: 10,
    marginBottom: 20,
  },
  notCompletedText: {
    float: 'right',
    margin: 0,
    pointerEvents: 'none',
  },
  actions: {
    '& > *:not(:first-child)': {
      marginLeft: 10,
    },
  },
}));

const HighlightedText = ({ text, highlight }: { text: string; highlight: string }) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span key={part + i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
          {part}
        </span>
      ))}{' '}
    </span>
  );
};

const UsersList = ({
  title,
  search,
  handleItemClick,
  handleAddNewItem,
  handleAddMultipleNewItems,
  users,
}: {
  title: string;
  search?: string;
  handleItemClick?: (value: UserDto) => void;
  handleAddNewItem?: () => void;
  handleAddMultipleNewItems?: () => void;
  users: UserDto[];
}) => {
  const classes = useStyles();
  const filteredUsers = users.filter((user) =>
    (user.name || user.email || '')?.toLowerCase().includes((search ?? '').toLowerCase()),
  );

  return (
    <div>
      <Grid container justify="space-between" alignItems="center">
        <h4>{title}</h4>
        <Grid className={classes.actions}>
          {handleAddMultipleNewItems && (
            <Button
              className={classes.addNewItemBtn}
              color={'primary'}
              size="sm"
              onClick={() => handleAddMultipleNewItems()}
            >
              Invite multiple
            </Button>
          )}
          {handleAddNewItem && (
            <Button className={classes.addNewItemBtn} color={'primary'} size="sm" onClick={() => handleAddNewItem()}>
              Invite
            </Button>
          )}
        </Grid>
      </Grid>

      <ListGroup className={classes.list}>
        {filteredUsers.map((user) => (
          <ListGroupItem
            key={user._id}
            onClick={() => handleItemClick && handleItemClick(user)}
            tag="a"
            style={{ cursor: handleItemClick ? 'pointer' : 'default' }}
          >
            <HighlightedText text={user.name || user.email || ''} highlight={search ?? ''} />

            {!user.completed && (
              <span className={classNames(classes.notCompletedText, 'text-secondary')}>Not completed</span>
            )}
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export default UsersList;
