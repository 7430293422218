import { createMuiTheme } from '@material-ui/core';
import { palette, typography } from './defaults';
import colors from './colors';
import overrides from './overrides';

export default createMuiTheme({
  palette,
  typography,
  overrides,

  // @ts-ignore Todo: TS type remove this later as it's in palette instead but old components still rely on it.
  colors,
  props: {
    MuiUseMediaQuery: {
      noSsr: true,
    },
  },
});
