import { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import { ChartConfiguration } from 'chart.js';

const useStyles = makeStyles((theme) => ({
  doughnutChart: {
    backgroundColor: 'white',
    borderRadius: 16,
    minHeight: 300,
    height: '100%',
    padding: '20px 20px 10px',
  },
  doughnutChart__chart: {
    width: '100%',
    position: 'relative',
  },
  doughnutChart__text: {
    paddingTop: 10,
    color: theme.palette.gray.g1,
    fontSize: 14,
    fontWeight: 900,
  },
  centerText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 38,
    fontWeight: 700,
  },
}));

const DoughnutChart = ({
  data,
  title,
  hideTooltip = false,
  backgroundColor = ['#75B7D8', '#65C898', '#EEC444', '#FA6B6B'],
  centerText,
  centerTextColor = '#000000',
  onclick,
}: {
  data: number[];
  title: string;
  hideTooltip?: boolean;
  backgroundColor?: string[] | string;
  centerText: string;
  centerTextColor?: string;
  onclick?: (workload: Number) => void;
}) => {
  const classes = useStyles();
  const [chart, setChart] = useState<Chart | null>(null);
  const doughnutChart = useRef<HTMLCanvasElement | null>(null);

  const config: ChartConfiguration = useMemo(
    () => ({
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [],
            backgroundColor,
            borderRadius: 10,
            borderWidth: 8,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: !hideTooltip,
          },
        },
        elements: {
          arc: {
            borderWidth: 10,
          },
        },
        onClick: (_, activeEls) => {
          activeEls.length && onclick && onclick(activeEls[0].index + 1)
        }
      },

    }),
    [backgroundColor],
  );

  useEffect(() => {
    if (doughnutChart && doughnutChart.current && !chart) {
      setChart(new Chart(doughnutChart.current, config));
    }
  }, [doughnutChart, chart, config]);

  useEffect(() => {
    if (chart && data.length > 0) {
      chart.data.datasets[0].data = data;
      chart.update();
    }
  });

  return (
    <div className={classes.doughnutChart}>
      <div className={classes.doughnutChart__chart}>
        <canvas ref={doughnutChart} height={260} />
        {centerText && (
          <div className={classes.centerText} style={{ color: centerTextColor }}>
            {centerText}
          </div>
        )}
      </div>
      <div className={classes.doughnutChart__text}>{title}</div>
    </div>
  );
};
export default DoughnutChart;
