import { makeStyles, Theme, useTheme } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  projectInfoBox: {
    height: 44,
    minWidth: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    fontWeight: 600,
    paddingLeft: 8,
    paddingRight: 8 
  },
}));

function handleColors(theme: Theme, color?: string, score?: number) {
  if (color){
    return {
      backgroundColor: color + "29",
      color: color,
    };
  }
  switch (Math.round(score!)) {
    case 1:
      return {
        backgroundColor: theme.palette.blue.opacity.b16,
        color: theme.palette.blue.b3,
      };
    case 2:
      return {
        backgroundColor: theme.palette.green.opacity.g16,
        color: theme.palette.green.g4,
      };
    case 3:
      return {
        backgroundColor: theme.palette.yellow.opacity.y16,
        color: theme.palette.yellow.y3,
      };
    case 4:
      return {
        backgroundColor: theme.palette.red.opacity.r16,
        color: theme.palette.red.r1,
      };
    default:
      return {
        backgroundColor: theme.palette.blueGray.opacity.bg16,
        color: theme.palette.blueGray.bg1,
      };
  }
}

function handleSizing(size: string, append: object) {
  let sizes;

  if (size === 'mini') {
    sizes = {
      height: 40,
      minWidth: 40,
      fontSize: 16,
      paddingLeft: 4,
      paddingRight: 4 
    };
  }
  else if (size === 'small') {
    sizes = {
      height: 44,
      minWidth: 44,
      fontSize: 18,
    };
  } else {
    sizes = {
      height: 64,
      minWidth: 64,
      fontSize: 24,
      paddingLeft: 16,
      paddingRight: 16 
  
    };
  }
  if (append) {
    return Object.assign(append, sizes);
  } else {
    return sizes;
  }
}
const ProjectInfoBox = ({
  score = undefined,
  color = undefined,
  size = 'small',
  text = undefined
}: {
  score?: number | undefined;
  color?: string;
  text?: string;
  size?: 'mini' | 'small' | 'medium';
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.projectInfoBox} style={handleSizing(size, handleColors(theme, color, score))}>
        {score?.toString().substr(0, 3)}
        {text}
      </div>
    </>
  );
};

export default ProjectInfoBox;
