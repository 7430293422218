import { useState } from 'react';
import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import SingleStat from './SingleStat';
import StatisticOverview from './StatisticOverview';
import Modal from '../shared/Modal';
import LineChart from '../charts/LineChart';
import DoughnutChart from '../charts/DoughnutChart';
import PieChart from '../charts/PieChart';
import BubbleChart from '../charts/BubbleChart';
import BarChart from '../charts/BarChart';
import CompetenceOverview from './CompetenceOverview';
import ReactLoading from 'react-loading';
import { IUsersStatistics } from 'utils/wemble-api.generated';
import { notEmpty } from 'utils/helpers';

const useStyles = makeStyles(() => ({
  statistics: {
    marginTop: 30,
  },
  profile: {
    position: 'relative',
    height: 75,
    width: 75,
    borderRadius: '50%',
    border: 'solid 6px white',
    marginRight: 20,
    backgroundColor: '#efefef',
  },
  profile__img: {
    width: '100%',
    overflow: 'hidden',
    borderRadius: '50%',
    verticalAlign: 'unset',
    fontSize: 12,
  },
  profile__initials: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRadius: '50%',
    fontSize: 22,
    fontWeight: 600,
  },
  loader: {
    fill: '#59ABCB',
    margin: 'auto',
    marginTop: 50,
    width: '12%',
  },
}));

function colorForWorkload(workload, theme) {
  return statusBackgroundColor(theme, Math.floor(Math.round(workload * 10) / 10 + 0.5))
}

function getInitials(name) {
  const nameSplit = name.toUpperCase().split(' ');
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    return [nameSplit[0].substring(0, 1), nameSplit[nameLength - 1].substring(0, 1)];
  } else if (nameLength === 1) {
    return [nameSplit[0].substring(0, 1), ''];
  } else return '';
}

function statusBackgroundColor(theme: Theme, status: number) {
  switch (status) {
    case 0:
      return theme.palette.statusCards.blue;
    case 1:
      return theme.palette.statusCards.green;
    case 2:
      return theme.palette.statusCards.yellow;
    case 3:
      return theme.palette.statusCards.red;
    default:
      return theme.palette.statusCards.gray;
  }
}

function statusColor(theme: Theme, status: number | null) {
  switch (status) {
    case 0:
      return theme.palette.blue.b2;
    case 1:
      return theme.palette.green.g4;
    case 2:
      return theme.palette.yellow.y2;
    case 3:
      return theme.palette.red.r3;
    default:
      return '#666';
  }
}

export type StatisticLayout = 'admin' | 'user';

const StatisticsGraphs = ({
  layout,
  statistics,
  showTeams,
}: {
  layout: StatisticLayout | undefined;
  statistics: IUsersStatistics | null;
  showTeams: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [modalSegment, setModalSegment] = useState<false | Number>(false);

  const toggleModal = () => {
    setModalSegment(false);
    setVisible(!(visible || Boolean(modalSegment)));

  };

  if (!statistics || !layout) {
    return <ReactLoading type={'spinningBubbles'} className={classes.loader} />;
  }

  const seriouslyBusyMoreThan80percentOfTime =
    (100 *
      Object.values(statistics.userWorkloadData).filter((user) => (user.portionOfSeriouslyBusy ?? 0) >= 0.8).length) /
    Object.values(statistics.userWorkloadData).length;
  const availableMoreThan80percentOfTime =
    (100 * Object.values(statistics.userWorkloadData).filter((user) => (user.portionOfAvailable ?? 0) >= 0.8).length) /
    Object.values(statistics.userWorkloadData).length;

  return (
    <div className={classes.statistics}>
      <Grid container>
        {statistics.averageWorkloadOverTime && (
          <Grid
            item
            xs={12}
            md={layout !== 'user' ? 7 : 9}
            style={{
              paddingRight: 20,
            }}
          >
            <LineChart
              key={'average-workload-line-chart'}
              data={statistics.averageWorkloadOverTime.map(([, v]) => v)}
              labels={statistics.averageWorkloadOverTime.map(([date]) => new Date(date).toLocaleDateString())}
              title={'Average workload'}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={3}
          style={{
            paddingRight: 20,
          }}
        >
          <DoughnutChart
            data={Object.values(statistics.workloadDistributions)}
            title={'Workload distribution'}
            hideTooltip
            centerText={statistics.averageWorkload?.toFixed(1)}
            centerTextColor={statusColor(theme, Math.round(statistics.averageWorkload) - 1)}
            onclick={(workload) => setModalSegment(workload)}
          />
        </Grid>

        {layout !== 'user' && (
          <Grid container item xs={12} md={2}>
            <Grid
              item
              style={{
                width: '100%',
              }}
            >
              <SingleStat
                data={`${seriouslyBusyMoreThan80percentOfTime?.toFixed(0)}%`}
                text={`Seriously busy more than 80% of the time`}
                color={theme.palette.red.r1}
                backgroundColor={theme.palette.red.opacity.r16}
              />
            </Grid>
            <Grid
              item
              style={{
                marginTop: 20,
                width: '100%',
              }}
            >
              <SingleStat
                data={`${availableMoreThan80percentOfTime?.toFixed(0)}%`}
                text={`Available more than 80% of the time`}
                color={theme.palette.blue.b2}
                backgroundColor={theme.palette.blue.opacity.b16}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      {layout !== 'user' && (
        <Grid container style={{ marginTop: 20 }}>
          <Grid container item xs={12} md={2}>
            <Grid
              item
              style={{
                width: '100%',
              }}
            >
              <SingleStat
                data={
                  ((100 * (statistics.workloadForecast?.lower ?? 0)) / (statistics?.nbrOfUsers ?? 1))?.toFixed(0) + '%'
                }
                text={`Are forecasting a lower workload`}
                color={theme.palette.blue.b2}
                backgroundColor={theme.palette.blue.opacity.b16}
              />
            </Grid>
            <Grid
              item
              style={{
                marginTop: 20,
                width: '100%',
              }}
            >
              <SingleStat
                data={
                  ((100 * (statistics.workloadForecast?.higher ?? 0)) / (statistics?.nbrOfUsers ?? 1))?.toFixed(0) + '%'
                }
                text={`Are forecasting a higher workload`}
                color={theme.palette.red.r1}
                backgroundColor={theme.palette.red.opacity.r16}
              />
            </Grid>
          </Grid>
          <Grid item md={10} xs={12} style={{ paddingLeft: 20 }}>
            <StatisticOverview title={showTeams ? 'Teams overview' : 'People overview'} showMore={toggleModal}>
              {showTeams
                ? Object.entries(statistics.teamWorkload)
                  .filter((entry) => {
                    const [officeId, groupId] = entry[0].split('_');
                    const office = statistics.data.offices.find((o) => o._id === officeId);
                    const group = statistics.data.groups.find((g) => g._id === groupId);
                    return !(!office || !group);
                  })
                  .splice(0, 8)
                  .map(([id, data]) => {
                    const [officeId, groupId] = id.split('_');
                    const office = statistics.data.offices.find((o) => o._id === officeId);
                    const group = statistics.data.groups.find((g) => g._id === groupId);
                    if (!office || !group) return null;
                    return (
                      <Grid item md={3} xs={6} key={`team-stat-${officeId}-${groupId}`}>
                        <SingleStat
                          backgroundColor={colorForWorkload(data.avgWorkload, theme)}
                          data={
                            typeof data.avgWorkload === 'number' && !isNaN(data.avgWorkload)
                              ? (data.avgWorkload + 1).toFixed(1)
                              : ''
                          }
                          text={`${office?.name} ${group?.name}`}
                        />
                      </Grid>
                    );
                  })
                : Object.entries(statistics.userWorkloadData)
                  .filter((entry) => statistics.data.users.find((x) => x._id === entry[0]))
                  .splice(0, 8)
                  .map(([id, data]) => {
                    const user = statistics.data.users.find((x) => x._id === id);
                    if (!user) return null;
                    return (
                      <Grid item md={3} xs={6} key={`users-stat-${id}`}>
                        <SingleStat
                          backgroundColor={colorForWorkload(data.avgWorkload, theme)}
                          data={
                            typeof data.avgWorkload === 'number' && !isNaN(data.avgWorkload)
                              ? (data.avgWorkload + 1).toFixed(1)
                              : ''
                          }
                          text={user?.name ?? ''}
                          ImageComponent={
                            <div className={classes.profile}>
                              {user?.profilePicture ? (
                                <img
                                  alt=""
                                  src={(user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture}
                                  className={classes.profile__img}
                                />
                              ) : (
                                <div className={classes.profile__initials}>
                                  <span style={{ color: statusColor(theme, null) }}>
                                    {getInitials(user?.name)[0]}
                                  </span>
                                  <span style={{ color: statusColor(theme, null) }}>
                                    {getInitials(user?.name)[1]}
                                  </span>
                                </div>
                              )}
                            </div>
                          }
                        />
                      </Grid>
                    );
                  })}
            </StatisticOverview>
          </Grid>
        </Grid>
      )}

      <Grid container style={{ marginTop: 20 }}>
        {layout !== 'user' ? (
          <>
            <Grid container item xs={12} md={3}>
              <Grid
                item
                style={{
                  width: '100%',
                  paddingRight: 20,
                }}
              >
                <BubbleChart
                  data={statistics.WFHToWorkloadCorrelation.filter(notEmpty)}
                  title={'Workload'}
                  chartText="WFH"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={3}>
              <Grid
                item
                style={{
                  width: '100%',
                  paddingRight: 20,
                }}
              >
                <PieChart
                  data={Object.values(statistics.nbrOfHomeDaysPerWeek)}
                  title={'Home-days per week'}
                  showDataLabels
                  dataLabelColors={['black', 'black', 'black', 'white', 'white', 'white']}
                  backgroundColor={[
                    'rgba(76, 162, 205, 0)',
                    'rgba(76, 162, 205, 0.2)',
                    'rgba(76, 162, 205, 0.4)',
                    'rgba(76, 162, 205, 0.6)',
                    'rgba(76, 162, 205, 0.8)',
                    'rgba(76, 162, 205, 1)',
                  ]}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        {layout === 'user' && (
          <Grid container item xs={12} md={3}>
            <Grid
              item
              style={{
                width: '100%',
                paddingRight: 20,
              }}
            >
              <BarChart
                data={Object.values(statistics.workFromHomePerWeekday ?? {}).map((v) => v * 100)}
                title={'WFH per week-day'}
              />
            </Grid>
          </Grid>
        )}

        <Grid container item xs={12} md={layout !== 'user' ? 6 : 9}>
          <Grid
            item
            style={{
              width: '100%',
            }}
          >
            <LineChart
              key={'work-from-home-line-chart'}
              data={statistics.portionOfUsersWorkingFromHomeOverTime.map((x) => x.portionAtHome)}
              labels={statistics.portionOfUsersWorkingFromHomeOverTime.map((x) =>
                new Date(x.date).toLocaleDateString(),
              )}
              title={'Work from home'}
              yAxisTickFormatFn={(v) => `${v}%`}
            />
          </Grid>
        </Grid>
      </Grid>

      {layout !== 'user' && (
        <Grid container style={{ marginTop: 20 }}>
          <Grid container item xs={12} md={4}>
            <Grid
              item
              style={{
                width: '100%',
                paddingRight: 20,
              }}
            >
              <CompetenceOverview data={statistics.competenceWorkload} competences={statistics.data.competences} />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={5}>
            <Grid container item xs={12} md={6} style={{ paddingRight: 20 }}>
              <Grid
                item
                style={{
                  width: '100%',
                }}
              >
                <SingleStat
                  data={`${(100 * (statistics?.percentOfMoreThan4daysHome ?? 0))?.toFixed(0)}%`}
                  text={`At home more than 4 days a week`}
                  color={theme.palette.blue.b3}
                  backgroundColor={'white'}
                />
              </Grid>
              <Grid
                item
                style={{
                  marginTop: 20,
                  width: '100%',
                }}
              >
                <SingleStat
                  data={`${(100 * (statistics?.percentOfMoreThan4daysAtOffice ?? 0))?.toFixed(0)}%`}
                  text={`At the office more than 4 days a week`}
                  color={theme.palette.blue.b2}
                  backgroundColor={'white'}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} style={{ paddingRight: 20 }}>
              <Grid
                item
                style={{
                  width: '100%',
                }}
              >
                <SingleStat
                  data={`${(100 * (statistics?.peakOfficeOccupancy ?? 0))?.toFixed(0)}%`}
                  text={`Peak office occupancy`}
                  color={theme.palette.yellow.y3}
                  backgroundColor={'white'}
                />
              </Grid>
              <Grid
                item
                style={{
                  marginTop: 20,
                  width: '100%',
                }}
              >
                <SingleStat
                  data={`${(100 * (statistics?.percentOnLeave ?? 0))?.toFixed(0)}%`}
                  text={`Average out of office`}
                  color={theme.palette.gray.g2}
                  backgroundColor={theme.palette.gray.g9}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={3}>
            <Grid
              item
              style={{
                width: '100%',
              }}
            >
              <BarChart
                data={Object.values(statistics.workFromHomePerWeekday ?? {}).map((v) => v * 100)}
                title={'WFH per week-day'}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Modal visible={visible || Boolean(modalSegment)} close={toggleModal} customWidth={1000}>
        <Grid container spacing={2}>
          {!Boolean(modalSegment) && showTeams
            ? Object.entries(statistics.teamWorkload)
              .filter((entry) => {
                const [officeId, groupId] = entry[0].split('_');
                const office = statistics.data.offices.find((o) => o._id === officeId);
                const group = statistics.data.groups.find((g) => g._id === groupId);
                return !(!office || !group);
              })
              .map(([id, data]) => {
                const [officeId, groupId] = id.split('_');
                const office = statistics.data.offices.find((o) => o._id === officeId);
                const group = statistics.data.groups.find((g) => g._id === groupId);
                if (!office || !group) return null;
                return (
                  <Grid item md={3} xs={6} key={`team-stat-${officeId}-${groupId}`}>
                    <SingleStat
                      backgroundColor={colorForWorkload(data.avgWorkload, theme)}
                      data={
                        typeof data.avgWorkload === 'number' && !isNaN(data.avgWorkload)
                          ? (data.avgWorkload + 1).toFixed(1)
                          : ''
                      }
                      text={`${office?.name} ${group?.name}`}
                    />
                  </Grid>
                );
              })
            : Object.entries(statistics.userWorkloadData)
              .filter((entry) => statistics.data.users.find((x) => x._id === entry[0]) && (!modalSegment || (colorForWorkload(entry[1].avgWorkload, theme) == colorForWorkload(Number(modalSegment) - 1, theme))))
              .map(([id, data]) => {
                const user = statistics.data.users.find((x) => x._id === id);
                if (!user) return null;
                return (
                  <Grid item md={3} xs={6} key={`users-stat-${id}`}>
                    <SingleStat
                      backgroundColor={colorForWorkload(data.avgWorkload, theme)}
                      data={
                        typeof data.avgWorkload === 'number' && !isNaN(data.avgWorkload)
                          ? (data.avgWorkload + 1).toFixed(1)
                          : ''
                      }
                      text={user?.name ?? ''}
                      ImageComponent={
                        <div className={classes.profile}>
                          {user?.profilePicture ? (
                            <img
                              alt=""
                              src={(user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture}
                              className={classes.profile__img}
                            />
                          ) : (
                            <div className={classes.profile__initials}>
                              <span style={{ color: statusColor(theme, null) }}>{getInitials(user?.name)[0]}</span>
                              <span style={{ color: statusColor(theme, null) }}>{getInitials(user?.name)[1]}</span>
                            </div>
                          )}
                        </div>
                      }
                    />
                  </Grid>
                );
              })}
        </Grid>
      </Modal>
    </div>
  );
};
export default StatisticsGraphs;
