import { makeStyles } from '@material-ui/core';
import { formattedDateRange, statusBackgroundColor, statusColor, statusTitle, useGetNextForecastForUser, useGetStatusForUser } from 'components/Home/Forecast/utils';
import descriptionForWorkscale from 'workscale/descriptionForWorkscale';

const useStyles = makeStyles((theme) => ({
  workload: {
    width: 130,
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: 4,
  },
  workload__date: {
    fontSize: 12,
    color: theme.palette.gray.g2,
    fontWeight: 500,
  },
}));

const Workload = ({ userId, forecast }: { userId?: string | null, forecast?: boolean }) => {
  const classes = useStyles();
  const status = useGetStatusForUser()(userId)
  const nextForecast = useGetNextForecastForUser()(userId)

  if (forecast && !nextForecast) return null;
  return (
    <div className={classes.workload} style={{ backgroundColor: statusBackgroundColor(forecast ? nextForecast!.status : status) }}>
      <div style={{ color: statusColor(forecast ? nextForecast!.status : status) }}>{forecast ? (nextForecast!.comment ? nextForecast!.comment : statusTitle(nextForecast!.status)) : statusTitle(status)}</div>
      {forecast && <div className={classes.workload__date}>{formattedDateRange(nextForecast)}</div>}
    </div>
  );
};
export default Workload;
