import { useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ProjectInvoiceDto, ProjectWithUsersDto, useGetProjectInvoicesQuery } from 'utils/wemble-api.generated';
import moment from 'moment';
import dots from './../../../../assets/icons/dots.svg';
import Button from 'components/shared/Button';
import ProjectInvoiceModal from './ProjectInvoicesModal';


const useStyles = makeStyles((theme) => ({
  members__title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 16px',
    marginBottom: 16,
    position: 'absolute'
  },
  members__content: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  members__table: {
    '& .MuiTableCell-root': {
      borderBottomColor: theme.palette.gray.g6,
      padding: '16px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 8px',
      },
    },
    '& .MuiTableCell-head': {
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.gray.g10,
      padding: '0 0 8px',
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
  },
  details: {
    backgroundColor: theme.palette.gray.g3,
    display: 'inline-block',
    height: 40,
    width: 40,
    borderRadius: 8,
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  },
  hours: {
    cursor: 'pointer'
  },
  toolbar__tools: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
}));

const ProjectInvoices = ({ project }: { project: ProjectWithUsersDto | null | undefined;  }) => {
  const classes = useStyles();
  const [clickedInvoice, setClickedInvoice] = useState<ProjectInvoiceDto | string | undefined>();
  const { data: invoices } = useGetProjectInvoicesQuery({projectId: project?._id || ''});


  return (
    <article>
      <h2 className={classes.members__title}>Invoices</h2>
      <div className={classes.toolbar__tools}>
      <Button onClick={()=>setClickedInvoice(project?._id || undefined)} text={'Add invoice'}/>
      </div>
      <br/>
      <div className={classes.members__content}>
        <TableContainer>
          <Table className={classes.members__table}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Vat</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Identification number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((invoice) => (
                <TableRow key={invoice._id}>
                  <TableCell>{moment(invoice.date).format("DD/MM/YY")}</TableCell>
                  <TableCell>{invoice.amount} {invoice.currency}</TableCell>
                  <TableCell>{invoice.vat || 0} {invoice.currency}</TableCell>
                  <TableCell>{(invoice.amount || 0) + (invoice.vat || 0)}  {invoice.currency}</TableCell>
                  <TableCell>{invoice.identification} </TableCell>

                  <TableCell align={'right'}>
                    <div className={classes.details}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setClickedInvoice(invoice);
                        }}
                      >
                       <img alt={'...'} src={dots} />

                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ProjectInvoiceModal visible={typeof clickedInvoice !== 'undefined'} data={clickedInvoice} onClose={() => setClickedInvoice(undefined)}/>
    </article>
  );
};

export default ProjectInvoices;
