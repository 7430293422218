import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

export const axiosBaseQuery = (
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '/api/v2' },
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, body, params }) => {
    if ((url == "/users/me" || url == "/account/token/new") && !localStorage.getItem('token')){
      return {error: {status: 401, data: {}}}
    }
    try {
      const result = await axios({ url: baseUrl + url, method, data: body, params });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  }
)();
