
import { makeStyles } from '@material-ui/core';
import theme from 'theme/theme';
import ForecastItem from './ForecastItem';
import { useGetCurrentUserQuery, useGetForecastsQuery } from 'utils/wemble-api';
import { useEffect, useRef, useState } from 'react';
import { getToday } from './utils';

import moment from 'moment-timezone';
moment.tz.setDefault("utc");

const useStyles = (scrollbar) => makeStyles(() => ({
  root: {
    ...(scrollbar ? {
      '&::-webkit-scrollbar': {
        'width': '7px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        backgroundColor: 'rgba(0, 0, 0, .12)',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
      },
      scrollbarWidth: 'auto',
      marginBottom: '-12px',
      paddingBottom: '12px',
      height: 'calc(205px + 12px + 12px + 10px)',
    } : { height: 'calc(215px + 12px + 12px)', }),
    overflowX: 'scroll',
    overflowY: 'visible',
    margin: -12,
    padding: 12,
    zIndex: 100
  },
  forecastsContainer: {
    height: '100%',
    display: 'flex',
    '& > *:not(:first-child)': {
      marginLeft: '12px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '8px'

      }
    },
  },


}));


const Forecasting = ({
}: {
  }) => {

  const { data: user } = useGetCurrentUserQuery();
  const { data: allForecasts } = useGetForecastsQuery(undefined, { refetchOnReconnect: true });

  const forecastsContainerRef = useRef<HTMLHeadingElement>(null);

  const [scrollbar, setScrollbar] = useState<boolean>(false);


  useEffect(() => {
    setScrollbar((forecastsContainerRef.current?.scrollWidth || 0) - (forecastsContainerRef.current?.clientWidth || 0) > 10)
  }, [forecastsContainerRef.current?.scrollWidth]);

  const classes = useStyles(scrollbar)();


  const missingForecast = (lastDate: string | undefined) => {
    const nextDate = moment(lastDate).add(1, 'day');

    return !forecasts.some(f => {
      const startDate = moment(f.startDate)
      const diff = startDate.diff(nextDate, 'days')
      return startDate.isSame(nextDate, 'day') || (startDate.day() == 1 && diff <= 3 && diff >= 0)
    }) ? nextDate : undefined
  }

  const today = getToday()


  const forecasts = allForecasts?.filter((forecast) => forecast.user == user?._id && moment(forecast.endDate) >= moment(today)).sort((a, b) => new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()) || []
  const currentForecast = forecasts.find(forecast => moment(forecast.startDate) <= moment(today))


  return (
    <div className={classes.root} >

      <div className={classes.forecastsContainer} ref={forecastsContainerRef}>
        {!currentForecast && (<>
          <ForecastItem today />
          {missingForecast(today) && (<ForecastItem nextDate={missingForecast(today)} />)}

        </>)}
        {forecasts.map((forecast) => (
          <><ForecastItem data={forecast} key={forecast._id} />
            {missingForecast(forecast.endDate) && (<ForecastItem nextDate={missingForecast(forecast.endDate)} />)}
          </>))}


      </div>



    </div>
  );
};

export default Forecasting;
