const NewForecast = ({ classes }) => {

  return (<>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#F2F2F2" />
      <rect x="8" y="21" width="33" height="6" rx="3" fill="white" />
      <rect x="27" y="7" width="33" height="6" rx="3" transform="rotate(90 27 7)" fill="white" />
    </svg>

    <div className={classes.addForecast}>Add new<br />forecast</div>
  </>)
}

export default NewForecast