import CardHeader from './card/CardHeader';
import { makeStyles } from '@material-ui/core';
import ProjectCards from './card/project/ProjectCards';
import CompetenceCards from './card/competence/CompetenceCards';
import classNames from 'classnames';
import { CompetenceDto, ProjectWithUsersDto } from 'utils/wemble-api.generated';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: '100%',
    margin: '0 auto 30px',
    fontFamily: 'Roboto',
    backgroundColor: 'white',
    padding: '30px 30px 0',
    borderRadius: 16,
  },
  card__info: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      margin: '0 14px 0 0',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        marginBottom: 10,
      },
    },
  },
  card__navigation: {
    position: 'relative',
    display: 'flex',
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.gray.g6,
    marginTop: 30,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        marginBottom: 10,
      },
    },
  },
  navigation__item: {
    fontWeight: 500,
    color: theme.palette.state.disabledText,
    padding: 12,
    marginRight: 12,
    cursor: 'pointer',
    userSelect: 'none',
    transition: '0.1s',
  },
  navigation__itemActive: {
    color: theme.palette.blue.b3,
  },
  card__buttons: {
    position: 'absolute',
    top: 30,
    right: 30,
    display: 'flex',
  },
  card__addButton: {
    backgroundColor: theme.palette.gray.g3,
    color: theme.palette.gray.g10,
    padding: '10px 16px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    marginLeft: '10px',
  },
}));

type BaseProps = {
  tabs: string[];
  currentTab: number;
  setTab: (newTab: number) => void;
  onAddMember?: () => void;
};

type ProjectProps = {
  type: 'project';
  data: ProjectWithUsersDto | undefined;
};
type CompetenceProps = {
  type: 'competence';
  data: CompetenceDto | undefined;
};

type DataProps = CompetenceProps | ProjectProps;

type Props = BaseProps & DataProps;

const Card = ({ data, type, setTab, currentTab, tabs, onAddMember = () => {} }: Props) => {
  const classes = useStyles();

  if (!data) return null;

  const cardsForType = () => {
    switch (type) {
      case 'project':
        return <ProjectCards data={data as ProjectWithUsersDto} />;
      case 'competence':
        return <CompetenceCards data={data as CompetenceDto} />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.card}>
      <CardHeader
        title={data.name ?? ''}
        description={type === 'project' ? (data as ProjectWithUsersDto).description ?? '' : ''}
      />
      <div className={classes.card__info}>{cardsForType()}</div>
      <div className={classes.card__navigation}>
        {tabs.map((tab, index) => (
          <div
            className={classNames(classes.navigation__item, currentTab === index && classes.navigation__itemActive)}
            onClick={() => {
              setTab(index);
            }}
          >
            {tab}
          </div>
        ))}
      </div>
      <div className={classes.card__buttons}>
        <div className={classes.card__addButton} onClick={onAddMember}>
          Add member
        </div>
      </div>
    </div>
  );
};

export default Card;
