import { useState } from 'react';
import StatusCardList from '../StatusCardList';
import Modal from '../shared/Modal';
import UserCard from '../shared/UserCard';
import { UserDto, UserFilterParams } from 'utils/wemble-api.generated';

const Statuses = ({
  users,
  isFetching,
  setFilters,
  officeOrGroupSelected,
}: {
  users: UserDto[] | undefined;
  isFetching: boolean;
  setFilters: (filters: UserFilterParams) => void;
  officeOrGroupSelected: boolean;
}) => {
  const [clickedUserId, setClickedUserId] = useState<string | null | undefined>();

  return (
    <>
      <StatusCardList
        users={users}
        onClick={(user) => {
          setClickedUserId(user._id);
        }}
        isFetching={isFetching}
        setFilters={setFilters}
        officeOrGroupSelected={officeOrGroupSelected}
      />
      <Modal visible={typeof clickedUserId === 'string'} close={() => setClickedUserId(undefined)} closeButton>
        {clickedUserId && <UserCard userId={clickedUserId as string} />}
      </Modal>
    </>
  );
};

export default Statuses;
