import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import shortid from 'shortid';

type MessageType = 'success' | 'error' | 'signup';

type Message = {
  id: string;
  type: MessageType;
  text?: string;
};

interface FlashMessagesState {
  messages: Message[];
}

const initialState: FlashMessagesState = {
  messages: [],
};

const flashMessagesSlice = createSlice({
  name: 'flashMessages',
  initialState,
  reducers: {
    addFlashMessage(state, action: PayloadAction<{ type: MessageType; text?: string }>) {
      state.messages.push({
        id: shortid.generate().code,
        type: action.payload.type,
        text: action.payload.text,
      });
    },
    removeFlashMessage(state, action: PayloadAction<string>) {
      const indexOfMessage = state.messages.findIndex((m) => m.id === action.payload);
      if (indexOfMessage >= 0) {
        state.messages.splice(indexOfMessage, 1);
      }
    },
  },
});

export const { addFlashMessage, removeFlashMessage } = flashMessagesSlice.actions;

export default flashMessagesSlice.reducer;
