import { ReactNode, useEffect } from 'react';
import { Hidden, makeStyles } from '@material-ui/core';
import closeIcon from './../../assets/icons/close.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 2,
    animation: '$fade 0.2s',
    overflowY: 'auto',
  },
  modal__bg: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    position: 'fixed',
    zIndex: 2,
    width: '100vw',
    height: '100vh',
  },
  modal__content: {
    backgroundColor: 'white',
    position: 'relative',
    margin: '60px auto',
    padding: 30,
    borderRadius: 16,
    zIndex: 3,
    animation: '$slideUp 0.2s',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '16px 16px 0 0',
      position: 'absolute',
      bottom: 0,
      marginBottom: 0,
      maxHeight: '70vh',
      overflow: 'auto',
      padding: 15,
    },
  },
  modal__closeIcon: {
    position: 'absolute',
    top: 20,
    right: 30,
    cursor: 'pointer',
  },
  '@keyframes fade': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes slideUp': {
    '0%': {
      transform: 'translateY(200px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
}));

const Modal = ({
  visible,
  children,
  close,
  closeButton,
  customWidth,
}: {
  visible: boolean;
  children?: ReactNode;
  close: () => void;
  closeButton?: boolean;
  customWidth?: number | string;
}) => {
  const classes = useStyles();

  useEffect(() => {
    const element = document.getElementById('root');

    if (visible) {
      document.body.style.overflow = 'hidden';
      if (element) {
        element.style['overflow'] = 'hidden';
      }
    } else {
      document.body.style.overflow = 'unset';
      if (element) {
        element.style['overflow'] = 'unset';
      }
    }
  });

  return (
    <>
      {visible && (
        <div className={classes.modal}>
          <div className={classes.modal__bg} onClick={close} />
          <Hidden mdUp>
            <div className={classes.modal__content} style={{ width: '100%' }}>
              {children}
            </div>
          </Hidden>
          <Hidden smDown>
            <div
              className={classes.modal__content}
              style={{ maxWidth: customWidth ? customWidth : 700, width: '100%' }}
            >
              {children}
              {closeButton && (
                <div className={classes.modal__closeIcon} onClick={close}>
                  <img alt={'X'} src={closeIcon} />
                </div>
              )}
            </div>
          </Hidden>
        </div>
      )}
    </>
  );
};
export default Modal;
