import 'core-js';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles.css';
import './react-tagsinput.css';
// import Sockets from './components/Sockets';
import * as serviceWorker from './serviceWorker';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactGA from 'react-ga';

import Routes from './Routes';
import AxiosAPI from './helpers/axios';
import store from './store';
import App from 'components/App';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'typeface-roboto';

Chart.register(ChartDataLabels);
Chart.defaults.set('plugins.datalabels', {
  display: false,
});


ReactGA.initialize('UA-133311191-1');

//Creates axios interceptors for requests
new AxiosAPI();

if (localStorage.token) {
  try {
    (window as any).webkit?.messageHandlers?.setAuthorizationToken?.postMessage(localStorage.token);
  } catch { }
}

ReactDOM.render(
  <Provider store={store}>
    {/* <Sockets store={store} /> */}
    <Router>
      <App>
        <Routes />
      </App>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
