import { makeStyles, Theme, useTheme } from '@material-ui/core';
import Select from './Select';
import Calendar from '../Home/Calendar';
import moment from 'moment';
import { useGetCurrentUserQuery, useGetForecastsQuery } from 'utils/wemble-api';
import classNames from 'classnames';
import { formattedDateRange, getToday, statusBackgroundColor, statusColor, statusTitle } from 'components/Home/Forecast/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.statusCards.blue,
    height: '100%',
    borderRadius: 24,
    transition: '0.2s',
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  rootDisabled: {
    pointerEvents: 'none',
    userSelect: 'none',
    '&:after': {
      content: '""',
      background: theme.palette.action.disabledBackground,
      borderRadius: 24,
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 2,
    },
  },
  title: {
    fontSize: 26,
    fontWeight: 600,
    transition: '0.2s',
  },
  select__title: {
    color: '#828282',
    fontWeight: 500,
    marginBottom: 6,
    fontSize: 14
  },
  select: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const AvailabilitySelect = ({
  future = false,
  disabled: disabledProp = false,
}: {
  future?: boolean;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { data: user } = useGetCurrentUserQuery();
  const { data: allForecasts } = useGetForecastsQuery(undefined, { refetchOnReconnect: true });

  if (!user) return null;

  const today = getToday()
  const forecasts = allForecasts?.filter(forecast => forecast.user === user._id && moment(forecast.endDate).isAfter(today)).sort((a, b) => moment(a.startDate).diff(moment(b.startDate))) || [];

  const status = user.onLeave ? 'away' : user.currentWorkload?.toString()

  const currentForecast = forecasts.find(forecast => moment(forecast.startDate) <= moment(today))
  const nextWorkloadForecast = forecasts.find(forecast => forecast !== currentForecast && forecast.status !== "away")

  const disabled = disabledProp

  return (
    <>
      <div
        className={classNames(classes.root, { [classes.rootDisabled]: disabled })}
        style={{
          backgroundColor: statusBackgroundColor(future ? nextWorkloadForecast?.status : status),
        }}
      >
        <div
          className={classes.title}
          style={{
            color: statusColor(future ? nextWorkloadForecast?.status : status),
          }}
        >
          {!future
            ? (currentForecast?.comment || statusTitle(status))
            : (nextWorkloadForecast ? nextWorkloadForecast?.comment || statusTitle(nextWorkloadForecast?.status) : 'Forecast')}
        </div>
        <div>
          <div className={classes.select__title}>
            {!future
              ? `Current status: ${currentForecast?.comment || statusTitle(status)} ${currentForecast ? formattedDateRange(currentForecast) : ''}`
              : (user.workloadForecastDate || nextWorkloadForecast) ? `Forecasted from ${moment(user.workloadForecastDate || moment(currentForecast?.endDate).add(1, 'day')).locale('en').format('LL')}` : 'Select date for forecast'}
          </div>
          <div className={classes.select}>
            <Select future={future} disabled={(!future && status == 'away') || (future && !(user.workloadForecastDate || currentForecast?.endDate))} forecastDate={user.workloadForecastDate || moment(currentForecast?.endDate).add(1, 'day').toISOString()} />
            {future && <Calendar user={user} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default AvailabilitySelect;
