import { makeStyles } from '@material-ui/core';
import { useGetGroupsForUserQuery, useGetGroupsQuery, useGetOfficesForUserQuery, useGetOfficesQuery } from 'utils/wemble-api';
import { GroupDto, OfficeDto } from 'utils/wemble-api.generated';
import { useEffect } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    width: 100 + '%',
    marginTop: 15 + 'px',
    marginBottom: -10 + 'px',
    display: 'inline-block',
  },
  dividerText: {
    float: 'left',
    paddingTop: '8px',
    marginLeft: '8px',
    marginRight: '8px',
    fontSize: '16px',
    fontWeight: 500,
  },
}));

const OptionsComponent = ({
  selectedOfficeId,
  selectedGroupId,
  uncompletedUserId,
  setSelectedGroup,
  setSelectedOffice,
  filterOffice,
  filterGroup,
  allowEmptyValue = false,
  secondary = false,
}: {
  selectedOfficeId: string;
  selectedGroupId: string;
  uncompletedUserId?: string;
  setSelectedOffice: (officeId: string) => void;
  setSelectedGroup: (groupId: string) => void;
  filterOffice?: (office: OfficeDto) => boolean;
  filterGroup?: (office: GroupDto) => boolean;
  allowEmptyValue?: boolean;
  secondary?: boolean;
}) => {
  const classes = useStyles();
  const { data: groupsRes } = uncompletedUserId ? useGetGroupsForUserQuery({userId: uncompletedUserId}) : useGetGroupsQuery();
  const { data: offices } = uncompletedUserId ? useGetOfficesForUserQuery({userId: uncompletedUserId}) : useGetOfficesQuery();
  const groups = groupsRes?.groups;

  const selectedOffice = offices?.find((office) => office._id === selectedOfficeId);
  const selectedGroup = groups?.find((group) => group._id === selectedGroupId);

  useEffect(() => {
    let newSelectedGroup = selectedGroupId;
    if (!allowEmptyValue && newSelectedGroup === '' && groups && groups[0]?._id) {
      newSelectedGroup = groups[0]._id;
      setSelectedGroup(newSelectedGroup);
    }
    const groupOffices = offices?.filter((o) =>
      typeof o.group === 'string' ? o.group === selectedGroupId : o.group?._id === selectedGroupId,
    );
    if (!allowEmptyValue && selectedOfficeId === '' && groupOffices && groupOffices[0]?._id) {
      setSelectedOffice(groupOffices[0]._id);
    }
  }, [selectedGroupId, selectedOfficeId, groups, offices]);

  useEffect(() => {
    if (!secondary) return;
    const filteredGroups = groups?.filter((g) => (filterGroup ? filterGroup(g) : true));
    const groupExists = filteredGroups?.map((g) => g._id).includes(selectedGroupId);
    const filteredOffices = offices?.filter((o) => (filterOffice ? filterOffice(o) : true));
    const officeExists = filteredOffices?.map((o) => o._id).includes(selectedOfficeId);
    if ((!officeExists || !groupExists) && filteredGroups && filteredGroups[0]?._id) {
      setSelectedGroup(filteredGroups[0]._id);
      const groupOffices = offices?.filter((o) =>
        typeof o.group === 'string' ? o.group === filteredGroups[0]._id : o.group?._id === filteredGroups[0]._id,
      );
      setSelectedOffice(groupOffices ? groupOffices[0]?._id ?? '' : '');
    }
  }, [filterOffice, filterGroup, selectedGroupId, selectedOfficeId, groups, offices, secondary]);

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'inline-block',
        }}
      >
        <select
          className="groupSelect form-control"
          onChange={(e) => {
            setSelectedGroup(e.target.value);
            setSelectedOffice(
              offices?.find((o) =>
                typeof o.group === 'string' ? o.group === e.target.value : o.group?._id === e.target.value,
              )?._id ?? '',
            );
          }}
          value={selectedGroupId}
          style={{
            marginLeft: '2px',
            paddingLeft: '0',
            width: (selectedGroup?.name?.length ?? 5) * 9 + 30,
          }}
          disabled={ (typeof uncompletedUserId === "string" ) && !groupsRes?.allowGroupSelection}
        >
          {allowEmptyValue && <option value="" />}
          {groups
            ?.filter((g) => (filterGroup ? filterGroup(g) : true))
            .map((group) => (
              <option key={group._id} value={group._id ?? ''}>
                {group.name}
              </option>
            ))}
        </select>
        <p className={classes.dividerText}>in</p>
        <select
          className="officeSelect form-control"
          onChange={(e) => {
            setSelectedOffice(e.target.value);
          }}
          value={selectedOfficeId}
          style={{
            width: (selectedOffice?.name?.length ?? 5) * 9 + 45,
          }}
        >
          {allowEmptyValue && !selectedGroup && <option value="" />}
          {offices
            ?.filter((o) => (filterOffice ? filterOffice(o) : true))
            .filter((o) =>
              typeof o.group === 'string' ? o.group === selectedGroupId : o.group?._id === selectedGroupId,
            )
            .map((office) => (
              <option key={office._id} value={office._id ?? ''}>
                {office.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default OptionsComponent;
