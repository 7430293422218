import { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { isValidEmail } from 'utils/helpers';
import { useCreateMultipleUsersMutation } from 'utils/wemble-api';
import { UserModalType } from 'components/user/UserModal';

const useStyles = makeStyles(() => ({
  formattedUsersTable: {
    overflow: 'auto',
    '& > table': {
      tableLayout: 'fixed',
      '& td': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}));

const schema = yup.object().shape({
  emails: yup.string().required('This field is required.'),
});

const InviteMultipleUsersModal = ({
  isOpen,
  handleClose,
  type,
  office = undefined,
  group = undefined,
  onAfterUserModified,
}: {
  isOpen: boolean;
  handleClose: () => void;
  type: UserModalType | undefined;
  office?: string | undefined | null;
  group?: string | undefined | null;
  onAfterUserModified?: () => Promise<void> | void;
}) => {
  const classes = useStyles();
  const [errorIndexes, setErrorIndexes] = useState<number[]>([]);

  const [createMultipleUsers] = useCreateMultipleUsersMutation();

  const { register, formState, reset, handleSubmit, setError, clearErrors, watch } = useForm({
    defaultValues: {
      names: '',
      emails: '',
    },
    resolver: yupResolver(schema),
  });
  const { errors, isSubmitting } = formState;
  const names = watch('names').split(';');
  const emails = watch('emails');

  useEffect(() => {
    if (isOpen) {
      reset({
        names: '',
        emails: '',
      });
      setErrorIndexes([]);
      clearErrors();
    }
  }, [isOpen]);

  const validateEmails = (emails: string) => {
    const errorIndexes: number[] = [];
    emails.split(';').forEach((email, index) => {
      if (!isValidEmail(email)) {
        errorIndexes.push(index);
      }
    });
    return errorIndexes;
  };

  useEffect(() => {
    setErrorIndexes([]);
    clearErrors();
  }, [emails]);

  const onSubmit = async (data) => {
    clearErrors();
    const validatedEmails = validateEmails(data.emails);
    setErrorIndexes(validatedEmails);
    if (validatedEmails.length > 0) {
      return;
    }
    const res = await createMultipleUsers({
      multipleUserCreationParams: {
        users: data.emails.split(';').map((email, index) => ({
          email: email.trim(),
          name: data.names.split(';')[index]?.trim(),
        })),
        group: group ?? undefined,
        office: office ?? undefined,
        admin: type === 'administrator',
      },
    });
    if ('error' in res) {
      const error = res.error as any;
      if (Array.isArray(error?.data)) {
        const errorIndexes: number[] = [];
        data.emails.split(';').forEach((email, index) => {
          if (error.data.includes(email)) {
            errorIndexes.push(index);
          }
        });
        setErrorIndexes(errorIndexes);
        // @ts-ignore
        setError('server', { message: 'Users with emails marked in red already exists. Remove them and try again.' });
      } else if (typeof error?.data?.message === 'string') {
        // @ts-ignore
        setError('server', { message: error?.data?.message });
      }
      return;
    }
    if (onAfterUserModified) await onAfterUserModified();
    handleClose();
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={handleClose}>Invite new {type}s</ModalHeader>
          <ModalBody>
            Separate the emails and names (optional) by ";"
            <table className={'table'}>
              <tbody>
                <tr>
                  <th
                    scope="row"
                    className={classNames('control-label', {
                      'text-danger': errors.names,
                    })}
                  >
                    Names
                  </th>
                  <td>
                    <input {...register('names')} type="text" className={'form-control'} disabled={isSubmitting} />{' '}
                    {errors.names && <span className="text-danger">{errors.names?.message}</span>}
                  </td>
                </tr>
                <tr>
                  <th
                    scope="row"
                    className={classNames('control-label', {
                      'text-danger': errors.emails,
                    })}
                  >
                    Emails
                  </th>
                  <td>
                    <input
                      {...register('emails')}
                      required
                      type="text"
                      className={'form-control'}
                      disabled={isSubmitting}
                    />{' '}
                    {errors.emails && <span className="text-danger">{errors.emails?.message}</span>}
                  </td>
                </tr>
              </tbody>
            </table>
            {emails.length > 0 && (
              <div className={classes.formattedUsersTable}>
                Formatted users from input:
                <table className={classNames('table')}>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emails
                      .split(';')
                      .filter((email) => email.length > 0)
                      .map((email, index) => (
                        <tr key={email + names[index] + index}>
                          <td className={classNames({ 'text-danger': errorIndexes.includes(index) })}>{email}</td>
                          <td>{names[index] ?? ''}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            {errors['server']?.message ? (
              <span className="text-danger">{errors['server'].message}</span>
            ) : (
              errorIndexes.length > 0 && (
                <span className="text-danger">
                  Make sure all emails entered are valid. Invalid ones are marked as red in the list above.
                </span>
              )
            )}
            <Button color="secondary" onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </Button>
            {
              <Button color="primary" type="submit" disabled={isSubmitting}>
                Invite
              </Button>
            }
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default InviteMultipleUsersModal;
