import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 60,
    },
  },
  header__title: {
    fontSize: 36,
    fontWeight: 600,
  },
  header__description: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.gray.g2,
    maxWidth: 600
  },
}));

const CardHeader = ({ title = 'NaN', description = '' }: { title?: string; description?: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div>
        <h2 className={classes.header__title}>{title}</h2>
        <p className={classes.header__description}>{description}</p>
      </div>
    </div>
  );
};

export default CardHeader;
