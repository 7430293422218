import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
}));

const HalfGrid = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
export default HalfGrid;
