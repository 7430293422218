import { useCallback, useEffect, useState } from "react";
import { useGetDetailedReportMutation } from "utils/wemble-api";
import { AugmentedMatter, DetailedReport, UserDto, UserFilterParams } from "utils/wemble-api.generated";
import DetailedTable from "./DetailedTable";
import ReactLoading from 'react-loading';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  loader: {
    fill: '#59abcb',
    margin: 'auto',
    marginTop: 50,
    marginBottom: 50,
    width: '10%',
  }
}));

const DetailedPage = ({
  filters,
  setLayout,
  filteredUsers
}: {
  filters: UserFilterParams | undefined,
  setLayout: (layout: string) => void;
  filteredUsers?: UserDto[];
}) => {
  const [getDetailedReport] = useGetDetailedReportMutation();
  const [detailedReport, setDetailedReport] = useState<DetailedReport | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const somethingSelected =
    filters && ((filters.groups?.length || 0) + (filters.offices?.length || 0) + (filters.competences?.length || 0) + (filters.projects?.length || 0) + (filters.cvpartnerData?.length || 0) + (filters.term?.length || 0)) > 0


  const fetchUtilizationReport = useCallback(
    async (showLoader = true) => {

      if (!filters) return;

      if (somethingSelected) {
        if (showLoader) setIsLoading(true);
        const res = await getDetailedReport({
          body: {
            filters: { ...filters, term: undefined }
          },
        });
        if ('data' in res) {
          setDetailedReport(res.data)
        }
        if (showLoader) setIsLoading(false);
      } else {
        setDetailedReport(undefined);
      }

    },
    [filters, somethingSelected],
  );

  useEffect(() => {
    fetchUtilizationReport();
  }, [fetchUtilizationReport]);

  const updateMatterManager = useCallback(event => {
    const updatedManager = (matter: AugmentedMatter) => (event.detail.matterNumber === matter.number) ? (event.detail.value ? event.detail.userId : undefined) : matter.manager
    const updatedReport = detailedReport?.map(tk => ({ ...tk, matters: tk.matters?.map(matter => ({ ...matter, manager: updatedManager(matter) })) }))
    setDetailedReport(updatedReport)
  }, [detailedReport])

  useEffect(() => {
    window.addEventListener('updateMatterManager', updateMatterManager)
    return () => {
      window.removeEventListener('updateMatterManager', updateMatterManager)
    }
  }, [updateMatterManager])

  // updateMatterManager: {
  //   async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
  //     try {
  //       await queryFulfilled;
  //       dispatch(api.util.updateQueryData('getMatterCommentsForMe', undefined, (draft) => {
  //         Object.assign(draft.find(m => m.number == body.matterNumber) as MaybeDrafted<AugmentedMatter>, {
  //           manager: body.value ? body.userId : undefined
  //         });
  //       }))
  //       document.dispatchEvent(new CustomEvent("updateMatterManager", { detail: body }))
  //     } catch {
  //     }
  //   }
  // },



  return (
    <>
      {detailedReport && <DetailedTable filteredUsers={filteredUsers} detailedReport={detailedReport} setLayout={setLayout} filterTerm={filters?.term || ''} />}
      {isLoading && <ReactLoading type={'spinningBubbles'} className={classes.loader} />}

    </>
  );
};

export default DetailedPage;
