import { AnyAction, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api as wembleApi } from '../utils/wemble-api';

import auth from './reducers/auth';
import flashMessages from './reducers/flashMessages';

const appReducer = combineReducers({
  flashMessages,
  auth,
  [wembleApi.reducerPath]: wembleApi.reducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state, action: AnyAction) => {
  if (action.type === 'RESET') {
    return appReducer({} as RootState, action);
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV?.trim() === 'development') {
      const filterActionsStartingWith = ['api/', '__rtkq'];
      const logger = createLogger({
        predicate: (_getState, action) => !filterActionsStartingWith.some((f) => action.type.startsWith(f)),
      });
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      })
        .concat(logger)
        .concat(wembleApi.middleware);
    }
    return getDefaultMiddleware().concat(wembleApi.middleware);
  },
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type Store = typeof store;
export default store;
