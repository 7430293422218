import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, useTheme } from '@material-ui/core';
import Avatar from 'components/shared/members/Avatar';
import { compareNumbers, compareString } from 'helpers/compareFunctions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useGetCurrentUserQuery, useGetUsersQuery } from 'utils/wemble-api';
import { UserDto, UserUtilization, UtilizationReport } from 'utils/wemble-api.generated';
import { formattedDateRange, statusColor, statusTitle, useGetNextForecastForUser, useGetStatusForUser } from './Forecast/utils';
import { titleRank, UserStatus } from './DetailedTable';
import VacationPlanner from 'components/vacationPlanner/VacationPlanner';


function colorForWorkload(workload) {
    return statusColor(Math.floor(Math.round(workload * 10) / 10 + 0.5).toString())
}

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Roboto',
    },
    utilization__content: {
        backgroundColor: 'white',
        padding: '20px 16px',
        [theme.breakpoints.down('sm')]: {
            padding: 12
        },
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    table: {

        '& .MuiTableCell-root': {
            borderBottomColor: theme.palette.gray.g6,
            padding: '6px 16px !important',
            [theme.breakpoints.down('lg')]: {
                padding: '6px 10px !important',

            },
            [theme.breakpoints.down('md')]: {
                padding: '6px 6px !important',

            },
            [theme.breakpoints.down('sm')]: {
                padding: '6px 4px !important',

            },
        },
        '& .MuiTableCell-head': {
            [theme.breakpoints.down('md')]: {
                fontSize: 11,

            },
            fontSize: 12,
            textTransform: 'uppercase',
            color: theme.palette.gray.g10,

        },
        '& .MuiTableCell-body': {
            fontWeight: 500,
        },
        '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
            border: 'none',
        },
    },
}));


const getSortFunction = () => {
    const getNextForecastForUser = useGetNextForecastForUser();
    const getStatusForUser = useGetStatusForUser();
    const { data: currentUser } = useGetCurrentUserQuery();

    return (sortMethod) => (tkA: UserUtilization, tkB: UserUtilization) => {


        const getWeekNumber = (method) => {
            if (method.endsWith(' B')) {
                return method.substring(1).replace(" B", "")
            }

            if (method.startsWith('W')) {
                return method.substring(1)
            }
            return null;
        };

        const getForecastedWorkload = (userId) => {
            const forecast = getNextForecastForUser(userId)
            // If no forecast date is set, return 5 to ensure it's sorted last
            if (!forecast?.status) return 5;
            if (forecast?.status == 'away') return 4;
            return parseInt(forecast?.status);
        };

        const getCurrentWorkload = (userId) => {
            const status = getStatusForUser(userId)
            if (status == undefined) return 5;
            if (status == 'away') return 4;
            return parseInt(status);
        };


        switch (sortMethod) {
            case 'NAME':
                return compareString(currentUser?.name == tkA.displayName ? "AAA" : tkA.displayName, currentUser?.name == tkB.displayName ? "AAA" : tkB.displayName);
            case 'TITLE':
                const rankA = titleRank(currentUser?.company, tkA.title)
                const rankB = titleRank(currentUser?.company, tkB.title)
                return compareNumbers(rankB, rankA);
            case 'AVERAGE STATUS':
                return compareNumbers(tkB.avgWorkload, tkA.avgWorkload);
            case 'UTILIZATION':
                return compareNumbers(tkB.utilizationAverage, tkA.utilizationAverage);
            case 'UTILIZATION ' + moment().add(-1, 'month').locale("en").format("MMMM"):
                return compareNumbers(tkB.utilizationAverageLastMonth, tkA.utilizationAverageLastMonth);
            case 'UTILIZATION ' + moment().locale("en").format("MMMM"):
                return compareNumbers(tkB.utilizationAverage, tkA.utilizationAverage);
            case 'CURRENT STATUS':
                return compareNumbers(getCurrentWorkload(tkA.userId), getCurrentWorkload(tkB.userId));
            case 'FORECAST':
                return compareNumbers(getForecastedWorkload(tkA.userId), getForecastedWorkload(tkB.userId));
            default:
                if (getWeekNumber(sortMethod) !== null) {
                    const weekA = tkA.weeklyOverview?.find(week => week.weekNumber == getWeekNumber(sortMethod))
                    const weekB = tkB.weeklyOverview?.find(week => week.weekNumber == getWeekNumber(sortMethod))

                    const weekDataA = sortMethod.includes(" B") ? ((weekA?.totalHours || 0) - (weekA?.billableHours || 0)) : weekA?.billableHours;
                    const weekDataB = sortMethod.includes(" B") ? ((weekB?.totalHours || 0) - (weekB?.billableHours || 0)) : weekB?.billableHours;
                    return compareNumbers(weekDataB || 0, weekDataA || 0);
                }
                return 0;
        }
    }
};

const UtilizationTable = ({ utilizationReport, filteredUsers }: { utilizationReport: UtilizationReport, filteredUsers?: UserDto[]; }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [sortMethod, setSortMethod] = useState<string>(localStorage.getItem("UtilizationPageSortMethod") || 'NAME');

    const { data: users } = useGetUsersQuery();
    const [vacationPlanner, setVacationPlanner] = useState<string | undefined>(undefined);


    useEffect(() => {
        localStorage.setItem("UtilizationPageSortMethod", sortMethod)
    }, [sortMethod])

    const utilizationMonths = typeof utilizationReport[0]?.utilizationAverageLastMonth !== 'undefined' ? 1 : 0;

    const sortFunction = getSortFunction()

    const columns = [
        "CURRENT STATUS",
        "FORECAST",
        ...utilizationReport[0].weeklyOverview?.map(week => `W${week.weekNumber}`) ?? [],
        ...(utilizationMonths ? ["UTILIZATION " + moment().add(-1, 'month').locale("en").format("MMMM"), "UTILIZATION " + moment().locale("en").format("MMMM")] : ['AVERAGE', 'UTILIZATION']),
        'AVERAGE STATUS',
    ];


    return (
        <div className={classes.utilization__content}>
            <TableContainer>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell

                            >
                                <span onClick={() => setSortMethod('NAME')} style={{ color: sortMethod === 'NAME' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>NAME</span>
                                &nbsp;/&nbsp;
                                <span onClick={() => setSortMethod('TITLE')} style={{ color: sortMethod === 'TITLE' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>TITLE</span>
                            </TableCell>
                            {columns.map((column, i) => (
                                <TableCell
                                    align={(columns.length - i > 3) ? 'left' : 'right'}
                                    key={column}
                                    onClick={() => setSortMethod((sortMethod == column && columns.length - i > 3) ? (column + " B") : column)}
                                    style={{ padding: column.includes(" B") ? '0px' : '0 16px', cursor: 'pointer' }}>
                                    <span style={{ color: sortMethod === column ? '#4e505d' : '#7E8299' }} >{column}</span>
                                    {(columns.length - i > 3 && i >= 2) && <span style={{ color: sortMethod === (column + " B") ? '#4e505d' : '#7E8299', float: 'right' }}>NB&nbsp;&nbsp;&nbsp;</span>}
                                </TableCell>
                            ))}
                        </TableRow>                    </TableHead>
                    <TableBody>
                        {utilizationReport && [...utilizationReport]?.sort(sortFunction(sortMethod)).map((timekeeper) => (
                            <TableRow>
                                <TableCell>
                                    <Avatar size={40} image={timekeeper.profilePicture} name={timekeeper.displayName} email={timekeeper.title} />
                                </TableCell>
                                <UserStatus userId={timekeeper.userId} className={''} setVacationPlanner={setVacationPlanner} />
                                {timekeeper.weeklyOverview?.map(week => <>
                                    <TableCell align='left' >{week.billableHours?.toFixed(1)}&nbsp;
                                        <span style={{ color: theme.palette.gray.g10, fontSize: 11 }}>{((week.totalHours || 0) - (week.billableHours || 0)).toFixed(1)}</span>
                                    </TableCell>
                                    {/* {typeof week.billableHours != "undefined" && (<TableCell align='left' className={classes.billable}>{week.billableHours?.toFixed(1)}</TableCell>)} */}
                                </>)}
                                {!utilizationMonths && (<TableCell align='right'>{timekeeper.weeklyAverage?.toFixed(1)}</TableCell>)}
                                {utilizationMonths ? (<TableCell align='right'>{Math.round((timekeeper.utilizationAverageLastMonth || 0) * 100)}%</TableCell>) : ("")}
                                <TableCell align='right'>{Math.round((timekeeper.utilizationAverage || 0) * 100)}%</TableCell>
                                <TableCell align='right'><span style={{ color: colorForWorkload(timekeeper.avgWorkload), fontWeight: 800 }}>{(typeof timekeeper.avgWorkload == 'number') && ((timekeeper.avgWorkload + 1).toFixed(1) /*+ " - " + titleForWorkload(timekeeper.avgWorkload) */)}</span></TableCell>
                                {/* <TableCell align='right' onClick={() => setVacationPlanner(timekeeper.userId)} style={{ cursor: 'pointer' }}>
                                    {getNextForecast(timekeeper.userId) && (<>
                                        <span style={{ color: statusColor(getNextForecast(timekeeper.userId)?.status), fontWeight: 800 }}>{getNextForecast(timekeeper.userId)!.comment ? getNextForecast(timekeeper.userId)!.comment : statusTitle(getNextForecast(timekeeper.userId)!.status)}</span>
                                        <div style={{ opacity: 0.5, fontSize: 10, whiteSpace: 'nowrap' }}>{formattedDateRange(getNextForecast(timekeeper.userId))} </div></>)}
                                </TableCell> */}



                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {users && (<VacationPlanner
                isOpen={Boolean(vacationPlanner)}
                close={() => setVacationPlanner(undefined)}
                selectedUser={vacationPlanner}
                users={filteredUsers} />)}
        </div >
    );
};

export default UtilizationTable;
