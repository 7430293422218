import { ReactNode, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import UsersList from './UsersList';
import UserModal, { UserModalType } from '../user/UserModal';
import ReactLoading from 'react-loading';
import OptionsComponent from './OptionsComponent';
import { makeStyles } from '@material-ui/core';
import { useGetCurrentUserQuery } from 'utils/wemble-api';
import { UserDto } from 'utils/wemble-api.generated';
import classNames from 'classnames';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import InviteMultipleUsersModal from 'components/user/InviteMultipleUsersModal';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  topbar: {
    height: '75px',
    width: 'calc(100% - 75px)',
    marginLeft: '75px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    left: 0,
    backgroundColor: 'white',
    overflowX: 'hidden',
    paddingLeft: '8px',
    paddingRight: '10px',
    paddingTop: '8px',
    borderBottom: '1px solid #FAFBFD',
    textAlign: 'left',
    borderTop: '1px solid #EAEBF1',
  },
  searchInput: {
    marginLeft: 14,
    border: 'none',
    color: '#8E93A2',
    width: 'calc(100% - 44px)',
    height: '60px',
  },
  title: {
    color: '#2B2E39',
    display: 'inline',
    fontWeight: 'bolder',
  },
  loader: {
    fill: '#59abcb',
    margin: 'auto',
    marginTop: 50,
    width: '25%',
  },
  content: {
    marginTop: 100,
  },
}));

const filterGroupAndOfficeUser = (groupId: string | undefined, officeId: string | undefined, user: UserDto) => {
  if (!groupId || !officeId || user.companyAdmin) return false;
  const userGroupId = typeof user.group === 'string' ? user.group : user.group?._id;
  const userOfficeId = typeof user.office === 'string' ? user.office : user.office?._id;
  const userSecondaryGroupId = typeof user.secondaryGroup === 'string' ? user.secondaryGroup : user.secondaryGroup?._id;
  const userSecondaryOfficeId =
    typeof user.secondaryOffice === 'string' ? user.secondaryOffice : user.secondaryOffice?._id;
  return (
    (userGroupId === groupId && userOfficeId === officeId) ||
    (userSecondaryGroupId === groupId && userSecondaryOfficeId === officeId)
  );
};

const UsersPage = ({
  fullWidth = false,
  users,
  loading,
  hideFilters = false,
  handleBack = undefined,
  office = undefined,
  group = undefined,
  onAfterUserModified,
  children,
}: {
  fullWidth?: boolean;
  users: UserDto[] | undefined | null;
  loading: boolean;
  hideFilters?: boolean;
  handleBack?:
    | {
        text: string;
        path: string;
      }
    | undefined;
  office?: string | undefined | null;
  group?: string | undefined | null;
  onAfterUserModified?: () => Promise<void> | void;
  children?: ReactNode;
}) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>();
  const [selectedOfficeId, setSelectedOfficeId] = useState<string | undefined>();

  const [selectedUser, setSelectedUser] = useState<UserDto | undefined>();
  const [userModalType, setUserModalType] = useState<UserModalType | undefined>();
  const [userModalMultiple, setUserModalMultiple] = useState(false);

  const { data: user } = useGetCurrentUserQuery();

  const admins = users?.filter(
    (user) =>
      (filterGroupAndOfficeUser(selectedGroupId, selectedOfficeId, user) || hideFilters) &&
      (user.admin === true || user.administratorPriviliges === true),
  );
  const employees = users?.filter(
    (user) =>
      (filterGroupAndOfficeUser(selectedGroupId, selectedOfficeId, user) || hideFilters) &&
      user.admin === false &&
      user.administratorPriviliges === false,
  );

  const handleOpenUserModal =
    (type: UserModalType, multiple = false) =>
    (user?: UserDto | undefined) => {
      setSelectedUser(user);
      setUserModalType(type);
      setUserModalMultiple(multiple);
    };

  const handleCloseUserModal = () => {
    setSelectedUser(undefined);
    setUserModalType(undefined);
    setUserModalMultiple(false);
  };

  useEffect(() => {
    if (user && !selectedGroupId && !selectedOfficeId && !hideFilters) {
      setSelectedGroupId(typeof user.group === 'string' ? user.group : user.group?._id ?? '');
      setSelectedOfficeId(typeof user.office === 'string' ? user.office : user.office?._id ?? '');
    }
  }, [user, selectedGroupId, selectedOfficeId, hideFilters]);

  // Update user data in modal if users list changes.
  useEffect(() => {
    setSelectedUser((prevSelectedUser) => users?.find((u) => u._id === prevSelectedUser?._id));
  }, [users]);

  return (
    <div>
      <UserModal
        isOpen={userModalType !== undefined && !userModalMultiple}
        handleClose={handleCloseUserModal}
        user={selectedUser}
        type={userModalType}
        office={selectedOfficeId && selectedOfficeId.length > 0 ? selectedOfficeId : office}
        group={selectedGroupId && selectedGroupId.length > 0 ? selectedGroupId : group}
        onAfterUserModified={onAfterUserModified}
      />
      <InviteMultipleUsersModal
        isOpen={userModalMultiple}
        handleClose={handleCloseUserModal}
        type={userModalType}
        office={selectedOfficeId && selectedOfficeId.length > 0 ? selectedOfficeId : office}
        group={selectedGroupId && selectedGroupId.length > 0 ? selectedGroupId : group}
        onAfterUserModified={onAfterUserModified}
      />
      <div className={classNames('topbar', classes.topbar)}>
        <Col
          sm="12"
          md={
            fullWidth && {
              size: 8,
              offset: 2,
            }
          }
        >
          <SearchIcon />
          <input
            className={classNames(classes.searchInput, 'search')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            type="text"
            placeholder={'Search user'}
          />
        </Col>
      </div>
      <Row className={classes.content}>
        <Col
          sm="12"
          md={{
            size: 8,
            offset: 2,
          }}
        >
          {handleBack && (
            <Link className="nav-link back-link" to={handleBack.path} style={{ paddingLeft: 0 + 'px', color: 'grey' }}>
              <svg height="25" width="15" viewBox="0 7 30 20">
                <polyline points="15,0 5,15 15,30" strokeWidth="8" strokeLinecap="round" fill="none" stroke="grey" />
              </svg>
              {handleBack.text}
            </Link>
          )}
          <h1 className={classes.title}>Users</h1>
          {!hideFilters && selectedOfficeId !== undefined && selectedGroupId !== undefined && (
            <OptionsComponent
              selectedOfficeId={selectedOfficeId}
              selectedGroupId={selectedGroupId}
              setSelectedOffice={setSelectedOfficeId}
              setSelectedGroup={setSelectedGroupId}
            />
          )}
          <br />
          <br />
          {loading ? (
            <ReactLoading type={'spinningBubbles'} className={classes.loader} />
          ) : (
            <div>
              {admins && (
                <UsersList
                  title="Administrators"
                  users={admins}
                  handleItemClick={handleOpenUserModal('administrator')}
                  handleAddNewItem={handleOpenUserModal('administrator')}
                  handleAddMultipleNewItems={handleOpenUserModal('administrator', true)}
                  search={searchQuery}
                />
              )}
              {employees && (
                <UsersList
                  title="Users"
                  users={employees}
                  handleItemClick={handleOpenUserModal('employee')}
                  handleAddNewItem={handleOpenUserModal('employee')}
                  handleAddMultipleNewItems={handleOpenUserModal('employee', true)}
                  search={searchQuery}
                />
              )}
            </div>
          )}
          {children}
        </Col>
      </Row>
    </div>
  );
};

export default UsersPage;
