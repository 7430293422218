import { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import StatisticsGraphs, { StatisticLayout } from '../components/Statistics/StatisticsGraphs';
import UniversalFilterSelect from '../components/shared/UniversalFilterSelect';
import { IUsersStatistics, UserFilterParams } from 'utils/wemble-api.generated';
import { useGetCurrentUserQuery, useGetStatisticsMutation } from 'utils/wemble-api';
import { isArray, notEmpty } from 'utils/helpers';
import axios from 'axios';
import download from '../assets/icons/download.svg';
import Button from 'components/shared/Button';
import organization from 'utils/organization';
import { cleanedDateString } from 'components/Home/Forecast/utils';

const useStyles = makeStyles(() => ({
  statistics: {
    width: '100%',
    margin: '100px auto 65px',
    fontFamily: 'Roboto',
  },
  statistics__title: {
    fontSize: 26,
    fontWeight: 600,
  },
  statistics__toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  toolbar__filter: {
    display: 'flex',
    flexGrow: 1,
    '& > div': {
      marginRight: 20,
      width: '100%',
    },
  },
  toolbar__datepicker: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    height: 44,
    paddingInline: 10,
    borderRadius: 12,
    cursor: 'default',
    marginRight: 16
  },
  datepicker__input: {
    backgroundColor: 'white',
    padding: 10,
  },
}));


const Statistics = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [filters, setFilters] = useState<UserFilterParams | undefined>();
  const [layout, setLayout] = useState<StatisticLayout | undefined>();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const { data: user } = useGetCurrentUserQuery();
  const [getStatistics] = useGetStatisticsMutation();

  const downloadXlsx = () => {
    if (!startDate || !user?._id) return;
    axios({
      url: '/api/statistics-raw/',
      method: 'POST',
      responseType: 'blob',
      params: { startDate: cleanedDateString(startDate), endDate: cleanedDateString(endDate) },
      data: layout === 'user' ? { filters: { users: [user._id] } } : { filters }

    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data as BlobPart]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };


  useEffect(() => {
    if (!layout && user) {
      setLayout(user.administratorPriviliges || user.admin ? 'admin' : 'user');
    }
  }, [layout, user]);

  const [statistics, setStatistics] = useState<IUsersStatistics | null>(null);

  // Sets the start date based on user team created date. Can't be greater than 6 months.
  // If the user is a assigned to two teams the created date of the oldest one will be used.
  useEffect(() => {
    if (!startDate && user) {
      const createdDateTeam1 = [
        typeof user.office !== 'string' ? user.office?.created : null,
        typeof user.group !== 'string' ? user.group?.created : null,
      ]
        .filter(notEmpty)
        .reduce((a, b) => (a === '' ? b : a < b ? a : b), '');
      const createdDateTeam2 = [
        typeof user.secondaryOffice !== 'string' ? user.secondaryOffice?.created : null,
        typeof user.secondaryGroup !== 'string' ? user.secondaryGroup?.created : null,
      ]
        .filter(notEmpty)
        .reduce((a, b) => (a === '' ? b : a < b ? a : b), '');

      const defaultStartDate = new Date(
        moment(new Date())
          .subtract(30 * 4, 'd')
          .format('YYYY-MM-DD'),
      );

      if (createdDateTeam1.length > 0 && createdDateTeam2.length > 0) {
        const oldestTeamCreatedDate = createdDateTeam1 < createdDateTeam2 ? createdDateTeam1 : createdDateTeam2;
        setStartDate(
          oldestTeamCreatedDate > defaultStartDate.toISOString() ? new Date(oldestTeamCreatedDate) : defaultStartDate,
        );
      } else if (createdDateTeam1.length > 0 && createdDateTeam1 > defaultStartDate.toISOString()) {
        setStartDate(new Date(createdDateTeam1));
      } else if (createdDateTeam2.length > 0 && createdDateTeam2 > defaultStartDate.toISOString()) {
        setStartDate(new Date(createdDateTeam1));
      } else {
        setStartDate(defaultStartDate);
      }
    }
  }, [startDate, user]);

  const officeAndGroupSelected =
    filters &&
    isArray(filters.offices) &&
    filters.offices.length > 0 &&
    isArray(filters.groups) &&
    filters.groups.length > 0;

  useEffect(() => {
    if (layout === 'admin' && officeAndGroupSelected && startDate) {
      setStatistics(null);
      setErrorMsg(null);
      getStatistics({
        startDate: cleanedDateString(startDate),
        endDate: cleanedDateString(endDate),
        body: {
          filters,
        },
      })
        .unwrap()
        .then((data) => {
          setStatistics(data);
        })
        .catch((err) => {
          setErrorMsg(err?.data?.message);
        });
    } else if (layout === 'user' && user?._id && startDate) {
      setStatistics(null);
      setErrorMsg(null);
      getStatistics({
        startDate: cleanedDateString(startDate),
        endDate: cleanedDateString(endDate),
        body: {
          filters: {
            users: [user._id],
          },
        },
      })
        .unwrap()
        .then((data) => {
          setStatistics(data);
        })
        .catch((err) => {
          setErrorMsg(err?.data?.message);
        });
    }
  }, [startDate, endDate, filters, layout]);

  return (
    <>
      <Container>
        <div className={classes.statistics}>
          <h1 className={classes.statistics__title}>Statistics</h1>
          <div className={classes.statistics__toolbar}>
            <div className={classes.toolbar__filter}>
              {layout === 'admin' && (
                <UniversalFilterSelect
                  type='users'
                  setFilters={setFilters}
                  hideSearch
                  showAllGroups={user?.companyAdmin === true}
                  showAllOffices={(user?.companyAdmin === true) || ((user?.admin === true || user?.administratorPriviliges === true) && organization(user.company).allOfficesDefault) /*Kvale*/}
                />
              )}
            </div>
            <div className={classes.toolbar__datepicker}>
              <DatePicker
                wrapperClassName={'datePickerCustomStyling'}
                selected={startDate}
                maxDate={new Date()}
                onChange={(date) => setStartDate(date)}
                startDate={startDate}
                endDate={endDate}
                placeholderText={'Start date'}
                calendarStartDay={1}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                customInput={
                  <button
                    type="button"
                    className={classes.datepicker__input}
                    style={startDate ? { opacity: 1 } : undefined}
                  >
                    {cleanedDateString(startDate).split('T')[0] ?? ''}
                  </button>
                }
              />
              -
              <DatePicker
                wrapperClassName={'datePickerCustomStyling'}
                selected={endDate}
                maxDate={new Date()}
                onChange={(date) => setEndDate(date)}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText={'End date'}
                calendarStartDay={1}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                customInput={
                  <button
                    type="button"
                    className={classes.datepicker__input}
                    style={endDate ? { opacity: 1 } : undefined}
                  >
                    {endDate?.toISOString().split('T')[0] ?? ''}
                  </button>
                }
              />
            </div>

            <Button onClick={downloadXlsx} icon={<img alt={'+'} src={download} />} />

          </div>
          {errorMsg ? (
            errorMsg
          ) : (
            <StatisticsGraphs
              statistics={layout === 'admin' && !officeAndGroupSelected ? null : statistics}
              layout={layout}
              showTeams={Object.keys(statistics?.teamWorkload ?? {}).length > 1}
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default Statistics;
