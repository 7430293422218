import { useEffect, useState } from 'react';
import CompetenceMembers from '../../shared/members/competence/CompetenceMembers';
import Settings from '../../shared/Settings';
import CompetenceSettings from '../../shared/settings/CompetenceSettings';
import { Container, makeStyles } from '@material-ui/core';
import PageTitle from '../../PageTitle';
import Card from '../../shared/Card';
import UserPickerModal from '../../user/UserPickerModal';
import { useGetCompetenceQuery, useAddCompetenceToUsersMutation, useGetCurrentUserQuery } from 'utils/wemble-api';
import { Switch, Route, useParams, useHistory } from 'react-router-dom';
import { notEmpty } from 'utils/helpers';
import organization from 'utils/organization';

const useStyles = makeStyles(() => ({
  project: {
    marginBottom: 200,
  },
}));

const views = ['members', 'settings'];

const Competence = () => {
  const classes = useStyles();
  const history = useHistory();
  const [userPickerVisible, setUserPickerVisible] = useState(false);
  const { id, view } = useParams<{ id: string; view?: string }>();
  const { data: competence, refetch } = useGetCompetenceQuery({ id });
  const [addCompetenceUsers] = useAddCompetenceToUsersMutation();
  const [activeNavigationTab, setActiveNavigationTab] = useState(view ? views.indexOf(view) : 0);
  const { data: currentUser } = useGetCurrentUserQuery();
  const organizationConfig = organization(currentUser?.company);

  useEffect(() => {
    if (!view) {
      history.replace(`/competences/${id}/members`);
    }
  });

  return (
    <>
      <Container className={classes.project}>
        <PageTitle
          title={!competence?.root ? 'Competence overview' : 'Category overview'}
          breadcrumbs={['Competences', competence?.name]}
          link={'competences'}
        />
        {!competence?.root ? (
          <>
            <Card
              type={'competence'}
              setTab={(newTabVal) => {
                setActiveNavigationTab(newTabVal);
                history.push(`/competences/${id}/${views[newTabVal]}`);
              }}
              currentTab={activeNavigationTab}
              tabs={(!organizationConfig.disableCompetencesPriviligies || currentUser?.admin || currentUser?.administratorPriviliges) ? ['Members', "Settings"] : ['Members']}
              data={competence ?? undefined}
              onAddMember={() => {
                setUserPickerVisible(!userPickerVisible);
              }}
            />
            <Switch>
              <Route
                exact
                path="/competences/:id/members"
                render={() => <CompetenceMembers competence={competence} reload={refetch} />}
              />
              <Route
                exact
                path="/competences/:id/settings"
                render={() => (
                  <Settings title={'Competence settings'}>
                    <CompetenceSettings competence={competence} />
                  </Settings>
                )}
              />
            </Switch>
            <UserPickerModal
              type={'competence'}
              visible={userPickerVisible}
              onClose={() => {
                setUserPickerVisible(false);
              }}
              userFilter={(user) => (!organizationConfig.disableCompetencesPriviligies || currentUser?.admin || currentUser?.administratorPriviliges) ?  !Boolean(competence?.users?.find((u) => u._id === user._id)) : (user._id === currentUser?._id && !Boolean(competence?.users?.find((u) => u._id === currentUser?._id)))}
              onSelect={(selectedUsers) => {
                if (competence?._id) {
                  addCompetenceUsers({
                    id: competence._id,
                    body: {
                      userIds: selectedUsers.map((user) => user._id).filter(notEmpty),
                    },
                  });
                }
              }}
            />
          </>
        ) : (
          <Settings title={'Category settings'}>
            <CompetenceSettings competence={competence} />
          </Settings>
        )}
      </Container>
    </>
  );
};

export default Competence;
