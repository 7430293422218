import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  initialized: boolean;
  websocketConnected: boolean;
  authenticated: boolean;
  token: string | null;
}

const initialState: AuthState = {
  initialized: false,
  websocketConnected: false,
  authenticated: false,
  token: null,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(state, action: PayloadAction<string | false>) {
      state.initialized = true;
      state.authenticated = false;
      if (typeof action.payload === 'string') {
        state.authenticated = true;
        state.token = action.payload;
      }
    },
    clearCredentials(state) {
      state.initialized = true;
      state.websocketConnected = false;
      state.authenticated = false;
      state.token = null;
    },
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;
