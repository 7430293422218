import { makeStyles, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { useUpdateSkillMutation, useGetLevelOfSkillsQuery } from 'utils/wemble-api';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    padding: '4px 0',
  },
  circles: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    height: 16,
    width: 16,
    marginLeft: 6,
    borderRadius: '50%',
    [theme.breakpoints.down('xs')]: {
      height: 16,
      width: 16,
      marginLeft: 4,

    }

  },
  circle_hover: {
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer',
    }
  },
  circle_non_changeable: {
    cursor: 'default'
  },
  tooltip: {
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    visibility: 'hidden',
    opacity: 0,
    backgroundColor: "#222222AA",
    color: 'white',
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    transition: 'opacity 0.3s, visibility 0.3s',
    zIndex: 1,
    pointerEvents: 'none',

  },
  circleContainer: {
    position: 'relative',
    display: 'inline-block',
    '&:hover $tooltip': {
      visibility: 'visible',
      opacity: 1,
    },
  },

}));

const CompetenceAverageSkillLevel = ({
  skill = -1,
  changeable = false,
  userID = null,
  skillID,
}: {
  skill: number | undefined;
  changeable?: boolean;
  userID?: string | null;
  skillID?: string | null;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { data: levelOfSkills } = useGetLevelOfSkillsQuery();
  const [updateSkill] = useUpdateSkillMutation();

  return (
    <div className={classes.root}>
      <div className={classes.circles}>
        {[...(levelOfSkills ?? [])]
          .sort((a, b) => (a.level ?? 0) - (b.level ?? 0))
          .map((levelOfSkill, index) => (
            <div
              className={classNames(classes.circleContainer, classes.circle, changeable ? classes.circle_hover : classes.circle_non_changeable)}
              style={
                Math.round(skill) <= index - 1
                  ? { backgroundColor: theme.palette.gray.g9 }
                  : {
                    backgroundColor: skill === 0 ? theme.palette.green.g4 : theme.palette.blue.b2,
                  }
              }
              key={index}
              onClick={(e) => {
                e.stopPropagation()
                console.log('update skill', changeable, userID, skillID, levelOfSkill._id);
                if (changeable && userID && skillID && levelOfSkill._id) {
                  // TODO Implement redux function for changing skill
                  console.log(levelOfSkill);
                  console.log({
                    _id: skillID,
                    level: levelOfSkill._id,
                  });
                  updateSkill({ id: skillID, skillUpdateParams: { levelOfSkillId: levelOfSkill.level == skill ? undefined : levelOfSkill._id } });
                }
              }}
            >
              <div className={classes.tooltip}>{levelOfSkill.name}</div>

            </div>

          ))}
      </div>
    </div>
  );
};

export default CompetenceAverageSkillLevel;
