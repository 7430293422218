import { AugmentedMatter, api as generatedApi, LevelOfSkillDto, SkillDto, UserDto } from './wemble-api.generated';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { clearCredentials, setCredentials } from 'store/reducers/auth';
import axios from 'axios';
import { WritableDraft } from 'immer/dist/internal';

type TagType =
  | 'Company'
  | 'Competence'
  | 'Group'
  | 'LevelOfSkill'
  | 'ProjectStage'
  | 'ProjectCategory'
  | 'ProjectTimeReport'
  | 'ProjectInvoice'
  | 'NotificationToken'
  | 'Office'
  | 'Project'
  | 'Skill'
  | 'Statistic'
  | 'User'
  | 'Vacation'
  | 'Forecast'
  | 'WorkloadHistory'
  | 'WorkplaceHistory'
  | 'Matter';

function providesList<R extends { _id?: string | null | undefined }[]>(
  resultsWithIds: R | undefined | null,
  tagType: TagType,
  listSuffix?: string,
): { type: TagType; id: string | undefined }[] {
  return resultsWithIds
    ? [
      { type: tagType, id: `LIST${listSuffix ?? ''}` },
      ...resultsWithIds.map(({ _id }) => ({ type: tagType, id: _id ?? undefined })),
    ]
    : [{ type: tagType, id: `LIST${listSuffix ?? ''}` }];
}

const updateUserInList = (data: UserDto) => (draftUsers: MaybeDrafted<UserDto[] | null>) => {
  if (!draftUsers) return;

  const indexOfUser = draftUsers.findIndex((u) => u._id === data._id);
  console.log(indexOfUser)
  console.log(draftUsers[indexOfUser].vacations?.length)
  if (indexOfUser >= 0) {
    draftUsers[indexOfUser] = data;
  }
  console.log(draftUsers[indexOfUser].vacations?.length)

};

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [
    'Company',
    'Competence',
    'Group',
    'LevelOfSkill',
    'ProjectStage',
    'ProjectCategory',
    'ProjectTimeReport',
    'ProjectInvoice',
    'NotificationToken',
    'Office',
    'Project',
    'Skill',
    'Statistic',
    'User',
    'Vacation',
    'Forecast',
    'WorkloadHistory',
    'WorkplaceHistory',
    'Matter'
  ],
  endpoints: {
    refreshToken: {
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          if (!localStorage.getItem('token')) return;
          const { data: token } = await queryFulfilled;
          if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            if (
              (window as any).webkit?.messageHandlers?.setAuthorizationToken?.postMessage &&
              (window as any).webkit?.messageHandlers?.setAuthorizationToken?.postMessage instanceof Function
            ) {
              (window as any).webkit?.messageHandlers?.setAuthorizationToken?.postMessage(token);
            }
            localStorage.setItem('token', token);
            dispatch(setCredentials(token));

          }
        }
        catch { }
      }
    },
    getCompany: {
      providesTags: (result) => (result ? [{ type: 'Company', id: result._id ?? undefined }] : []),
    },
    updateCompany: {
      async onQueryStarted({ }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!data) return;
          dispatch(
            api.util.updateQueryData('getGroups', undefined, (draft) => {
              draft.customHtml = data.customHtml;
              draft.companyName = data.name;
            }),
          );
          dispatch(
            api.util.updateQueryData('getCompany', undefined, (draft) => {
              Object.assign(draft, data);
            }),
          );
        } catch { }
      },
    },
    getCompetences: {
      providesTags: (result) => providesList(result, 'Competence'),
    },
    createCompetence: {
      invalidatesTags: [{ type: 'Competence', id: 'LIST' }],
    },
    getCompetence: {
      providesTags: (result) => (result ? [{ type: 'Competence', id: result._id ?? undefined }] : []),
    },
    updateCompetence: {
      invalidatesTags: (result) => [{ type: 'Competence', id: result?._id ?? undefined }],
    },
    deleteCompetence: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Competence', id: arg.id }],
    },
    addCompetenceToUsers: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Competence', id: arg.id }, ...providesList(arg.body.userIds.map(_id => ({ _id })), 'User')],
    },
    addCompetenceToUser: {
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        console.log(arg)
        try {
          const { data: success } = await queryFulfilled;
          if (success) {
            dispatch(api.util.invalidateTags([{ type: "User", id: arg.userId }]))
            dispatch(api.util.invalidateTags([{ type: "Competence", id: arg.id }]))
          }

        }
        catch (error) {
          console.log(error)
        }
      },
    },

    getGroups: {
      providesTags: (result) => providesList(result?.groups, 'Group'),
    },
    getGroupsForUser: {
      providesTags: (result) => providesList(result?.groups, 'Group')
    },
    createGroup: {
      invalidatesTags: [{ type: 'Group', id: 'LIST' }],
    },
    updateGroup: {
      invalidatesTags: (result) => [{ type: 'Group', id: result?._id ?? undefined }],
    },
    deleteGroup: {
      invalidatesTags: [{ type: 'Group', id: 'LIST' }],
    },

    getLevelOfSkills: {
      providesTags: (result) => providesList(result, 'LevelOfSkill'),
    },
    createLevelOfSkill: {
      invalidatesTags: [{ type: 'LevelOfSkill', id: 'LIST' }],
    },
    updateLevelOfSkill: {
      invalidatesTags: (result) => [{ type: 'LevelOfSkill', id: result?._id ?? undefined }],
    },
    deleteLevelOfSkill: {
      invalidatesTags: [{ type: 'LevelOfSkill', id: 'LIST' }],
    },
    getProjectStages: {
      providesTags: (result) => providesList(result, 'ProjectStage'),
    },
    createProjectStage: {
      invalidatesTags: [{ type: 'ProjectStage', id: 'LIST' }],
    },
    updateProjectStage: {
      invalidatesTags: (result) => [{ type: 'ProjectStage', id: result?._id ?? undefined }],
    },
    deleteProjectStage: {
      invalidatesTags: [{ type: 'ProjectStage', id: 'LIST' }],
    },
    getProjectCategories: {
      providesTags: (result) => providesList(result, 'ProjectCategory'),
    },
    createProjectCategory: {
      invalidatesTags: [{ type: 'ProjectCategory', id: 'LIST' }],
    },
    updateProjectCategory: {
      invalidatesTags: (result) => [{ type: 'ProjectCategory', id: result?._id ?? undefined }],
    },
    deleteProjectCategory: {
      invalidatesTags: [{ type: 'ProjectCategory', id: 'LIST' }],
    },
    getProjectTimeReports: {
      providesTags: (result) => providesList(result, 'ProjectTimeReport'),
    },
    createProjectTimeReport: {
      invalidatesTags: (result) => result ? [{ type: 'ProjectTimeReport', id: result._id || '' }, { type: 'Project', id: result.project || '' }] : [],
    },
    updateProjectTimeReport: {
      invalidatesTags: (result) => result ? [{ type: 'ProjectTimeReport', id: result._id || ' ' }, { type: 'Project', id: result.project || '' }] : [],
    },
    deleteProjectTimeReport: {
      invalidatesTags: (result, _error, arg) => result ? [{ type: 'ProjectTimeReport', id: arg.id }, { type: 'Project', id: arg.projectId }] : [],
    },
    getProjectInvoices: {
      providesTags: (result) => providesList(result, 'ProjectInvoice'),
    },
    createProjectInvoice: {
      invalidatesTags: [{ type: 'ProjectInvoice', id: 'LIST' }],
    },
    updateProjectInvoice: {
      invalidatesTags: (result) => [{ type: 'ProjectInvoice', id: result?._id ?? undefined }],
    },
    deleteProjectInvoice: {
      invalidatesTags: [{ type: 'ProjectInvoice', id: 'LIST' }],
    },
    getOffices: {
      providesTags: (result) => providesList(result, 'Office'),
    },
    getOfficesForUser: {
      providesTags: (result) => providesList(result, 'Office'),
    },
    getMatterCommentsForUser: {
      providesTags: (result) => providesList(result, 'Matter'),
    },
    getMatterCommentsForMe: {
      providesTags: (result) => providesList(result, 'Matter'),
    },
    updateMatterComment: {
      invalidatesTags: (result) => [{ type: 'Matter', id: result?._id ?? undefined }],
    },

    updateMatterManager: {
      async onQueryStarted({ body }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          window.dispatchEvent(new CustomEvent("updateMatterManager", { detail: body }))
          dispatch(api.util.updateQueryData('getMatterCommentsForMe', undefined, (draft) => {
            Object.assign(draft.find(m => m.number == body.matterNumber) as MaybeDrafted<AugmentedMatter>, {
              manager: body.value ? body.userId : undefined
            });
          }))
        } catch {
        }
      }
    },
    createOffice: {
      invalidatesTags: [{ type: 'Office', id: 'LIST' }],
    },
    updateOffice: {
      invalidatesTags: (result) => [{ type: 'Office', id: result?._id ?? undefined }],
    },
    deleteOffice: {
      invalidatesTags: [{ type: 'Office', id: 'LIST' }],
    },

    getProjects: {
      providesTags: (result) => providesList(result, 'Project'),
    },
    getProject: {
      providesTags: (result) => (result ? [{ type: 'Project', id: result._id ?? undefined }] : []),
    },
    createProject: {
      invalidatesTags: [{ type: 'Project', id: 'LIST' }],
    },
    updateProject: {
      invalidatesTags: (result) => [{ type: 'Project', id: result?._id ?? undefined }],
    },
    deleteProject: {
      invalidatesTags: [{ type: 'Project', id: 'LIST' }],
    },
    archiveProject: {
      invalidatesTags: (result) => [{ type: 'Project', id: result?._id ?? undefined }],
    },
    unarchiveProject: {
      invalidatesTags: (result) => [{ type: 'Project', id: result?._id ?? undefined }],
    },
    addProjectToUser: {
      invalidatesTags: (result) => [{ type: 'User', id: result?._id ?? undefined }],
    },
    deleteProjectFromUser: {
      invalidatesTags: (result, _error, arg) =>
        result
          ? [
            { type: 'User', id: arg.userId },
            { type: 'Project', id: arg.id },
          ]
          : [],
    },
    addProjectToUsers: {
      invalidatesTags: (result, _error, arg) =>
        result ? [...arg.body.ids.map((id) => ({ type: 'User' as TagType, id })), { type: 'Project', id: arg.id }] : [],
    },
    updateSkill: {
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const { data: skill } = await queryFulfilled;
          const competenceId = (typeof skill.competence == "string" ? skill.competence : skill.competence?._id) || ""

          dispatch(api.util.updateQueryData('getCompetence', { id: competenceId }, draft => {
            if (draft && draft.users) {
              const userIndex = draft.users.findIndex((u) => u.skills[0]._id == skill._id)
              draft.users[userIndex].skills[0].level = skill.level as LevelOfSkillDto
            }
          }))

          var userId: string | undefined;

          dispatch(api.util.updateQueryData('getUsers', undefined, usersDraft => {
            userId = usersDraft?.find(user => user.skills?.find(s => typeof s == 'string' ? (s == skill._id) : (s._id == skill._id)))?._id || undefined
          }))

          userId && dispatch(api.util.updateQueryData('getUserById', { id: userId }, draft => {

            if (draft && draft.skills) {
              const skillIndex = draft.skills.findIndex(s => (typeof s == 'string' ? s : s._id) == skill._id);
              if (typeof draft.skills[skillIndex] != 'string') {
                (draft.skills[skillIndex] as WritableDraft<SkillDto> | SkillDto).level = skill.level as LevelOfSkillDto
              }

            }
          }))


          dispatch(api.util.invalidateTags([{ type: "Competence", id: competenceId }]))
          setTimeout(() => dispatch(api.util.prefetch('getCompetences', undefined, {})), 450)

        }
        catch (error) {
          console.log(error)
        }
      },
    },

    getCurrentUser: {
      providesTags: [{ type: 'User', id: 'ME' }],
      async onQueryStarted(_, { dispatch, queryFulfilled, getCacheEntry }) {
        try {
          if (!localStorage.getItem('token')) return;
          const firstFetch = !Boolean(getCacheEntry().data);
          const { data } = await queryFulfilled;
          if (firstFetch && data) {
            console.log("User logged in")
          }
        } catch (error) {
          // @ts-ignore
          if (error?.error?.status === 401) {
            delete axios.defaults.headers.common['Authorization'];
            if (
              (window as any).webkit?.messageHandlers?.deleteAuthorizationToken?.postMessage &&
              (window as any).webkit?.messageHandlers?.deleteAuthorizationToken?.postMessage instanceof Function
            ) {
              (window as any).webkit?.messageHandlers?.deleteAuthorizationToken?.postMessage('Token removed');
            }
            localStorage.removeItem('token');
            localStorage.removeItem('OverviewPageSavedFilter')
            dispatch({ type: 'RESET' });
            dispatch(clearCredentials());
            window.location.replace('/');
          }
        }
      },
    },
    getUsers: {
      providesTags: (result) => providesList(result, 'User'),
    },
    getUserById: {
      providesTags: (result) => [{ type: 'User', id: result?._id ?? undefined }],
    },
    createUser: {
      invalidatesTags: [
        { type: 'User', id: 'LISTtrue' },
        { type: 'User', id: 'LISTfalse' },
      ],
    },
    updateUser: {
      async onQueryStarted({ id, userUpdateParams }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
            if (draft?._id === id) {
              Object.assign(draft, userUpdateParams);
            }
          }),
        );
        try {
          const { data } = await queryFulfilled;
          if (!data) return;
          dispatch(api.util.updateQueryData('getUsers', undefined, updateUserInList(data)));
          dispatch(
            api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
              if (draft?._id === id) {
                Object.assign(draft, data);
              }
            }),
          );
        } catch {
          patchResult.undo();
        }
      },
    },
    updateUserProfilePicture: {
      invalidatesTags: (result) => [
        { type: 'User', id: result?._id ?? undefined },
        { type: 'User', id: 'ME' },
      ],
    },
    createMultipleUsers: {
      invalidatesTags: [
        { type: 'User', id: 'LISTtrue' },
        { type: 'User', id: 'LISTfalse' },
      ],
    },
    deleteUser: {
      invalidatesTags: [
        { type: 'User', id: 'LISTtrue' },
        { type: 'User', id: 'LISTfalse' },
      ],
    },
    changeUserTeam: {
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!data) return;
          dispatch(api.util.updateQueryData('getUsers', undefined, updateUserInList(data)));
          dispatch(
            api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
              if (draft?._id === id) {
                Object.assign(draft, data);
              }
            }),
          );
        } catch { }
      },
    },
    updateUserAdminStatus: {
      invalidatesTags: (result) => [{ type: 'User', id: result?._id ?? undefined }],
    },
    getForecasts: {
      providesTags: (result) => providesList(result, 'Forecast'),
    },
    createForecast: {
      invalidatesTags: (result) => [{ type: 'Forecast', id: 'LIST' }, { type: 'User', id: result?.user ?? undefined }, { type: 'User', id: 'ME' },],
    },
    updateForecast: {
      invalidatesTags: (result) => [{ type: 'Forecast', id: result?._id ?? undefined }, { type: 'User', id: result?.user ?? undefined }, { type: 'User', id: 'ME' },],
    },
    deleteForecast: {
      invalidatesTags: (_result, _error, arg) => [{ type: 'Forecast', id: 'LIST' }, { type: 'User', id: arg.userId ?? undefined }, { type: 'User', id: 'ME' },],
    },
    createVacation: {
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!data) return;
          dispatch(api.util.updateQueryData('getUsers', undefined, updateUserInList(data)));
          dispatch(
            api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
              if (draft?._id === userId) {
                Object.assign(draft, data);
              }
            }),
          );
        } catch { }
      },
    },
    updateVacation: {
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!data) return;
          dispatch(api.util.updateQueryData('getUsers', undefined, updateUserInList(data)));
          dispatch(
            api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
              if (draft?._id === userId) {
                Object.assign(draft, data);
              }
            }),
          );
        } catch { }
      },
    },
    deleteVacation: {
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!data) return;
          dispatch(api.util.updateQueryData('getUsers', undefined, updateUserInList(data)));
          dispatch(
            api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
              if (draft?._id === userId) {
                Object.assign(draft, data);
              }
            }),
          );
        } catch { }
      },
    },
    updateWorkloadMessage: {
      async onQueryStarted({ workloadMessageCreationParams }, { dispatch, queryFulfilled }) {
        let currentUserId: string | null | undefined = null;
        const updateUserDraft = (draft: MaybeDrafted<UserDto | null | undefined>) => {
          if (!draft) return;
          Object.assign(draft, {
            message: {
              message: workloadMessageCreationParams.message,
            },
          });
        };
        const currentUserPatchResult = dispatch(
          api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
            currentUserId = draft?._id;
            updateUserDraft(draft);
          }),
        );
        const usersPatchResult = dispatch(
          api.util.updateQueryData('getUsers', undefined, (draftUsers) => {
            updateUserDraft(draftUsers?.find((u) => u._id === currentUserId));
          }),
        );
        try {
          const { data } = await queryFulfilled;
          if (!currentUserId) {
            dispatch(api.util.updateQueryData('getUsers', undefined, updateUserInList(data)));
            dispatch(
              api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
                Object.assign(draft || {}, data);
              }),
            );
          }
        } catch {
          currentUserPatchResult.undo();
          usersPatchResult.undo();
        }
      },
    },
    refreshUser: {
      invalidatesTags: (result) => [
        { type: 'User', id: result?._id ?? undefined },
        { type: 'User', id: 'ME' },
      ],
    },
    createWorkload: {
      async onQueryStarted({ type, workloadCreationParams }, { dispatch, queryFulfilled }) {
        let currentUserId: string | null | undefined = null;
        const updateUserDraft = (draft: MaybeDrafted<UserDto | null | undefined>) => {
          if (!draft) return;
          if (type === 'current') {
            draft.currentWorkload = workloadCreationParams.workscale;
          } else if (type === 'future') {
            draft.futureWorkload = workloadCreationParams.workscale;
          }
          (window as any)._workloadLastUpdated_ = (new Date()).toISOString()
        };
        const currentUserPatchResult = dispatch(
          api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
            currentUserId = draft?._id;
            updateUserDraft(draft);
          }),
        );
        const usersPatchResult = dispatch(
          api.util.updateQueryData('getUsers', undefined, (draftUsers) => {
            updateUserDraft(draftUsers?.find((u) => u._id === currentUserId));
          }),
        );
        try {
          const { data } = await queryFulfilled;
          if (type !== 'current' && type !== 'future' && !currentUserId) {
            dispatch(api.util.updateQueryData('getUsers', undefined, updateUserInList(data)));
            dispatch(
              api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
                Object.assign(draft || {}, data);
              }),
            );
          }
        } catch {
          currentUserPatchResult.undo();
          usersPatchResult.undo();
        }
      },
    },

    createWorkplaceForecast: {
      async onQueryStarted({ workplaceForecastCreationParams, index }, { dispatch, queryFulfilled }) {
        let currentUserId: string | null | undefined = null;
        const updateUserDraft = (draft: MaybeDrafted<UserDto | null | undefined>) => {
          const { option, future } = workplaceForecastCreationParams;
          if (draft?.workplaceForecast) {
            if (future && draft.workplaceDefault) {
              draft.workplaceDefault[index] = option;
            }
            else {
              draft.workplaceForecast[index] = option;
            }

          }
          const indexNbr = parseInt(index, 10);
          if (!future && draft && indexNbr === 0) {
            draft.onLeave = option === 2;
            draft.atHome = option === 1;
          }
        };
        const currentUserPatchResult = dispatch(
          api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
            currentUserId = draft?._id;
            updateUserDraft(draft);
          }),
        );
        const usersPatchResult = dispatch(
          api.util.updateQueryData('getUsers', undefined, (draftUsers) => {
            updateUserDraft(draftUsers?.find((u) => u._id === currentUserId));
          }),
        );
        try {
          const { data } = await queryFulfilled;
          if (!currentUserId) {
            dispatch(api.util.updateQueryData('getUsers', undefined, updateUserInList(data)));
          }
        } catch {
          currentUserPatchResult.undo();
          usersPatchResult.undo();
        }
      },
    },

    /*getFilteredWorkloadHistory: {
      providesTags: (result) => providesList(result, 'WorkloadHistory'),
    },
    getUserWorkloadHistory: {
      providesTags: (result) => providesList(result, 'WorkloadHistory'),
    },
    getWorkplaceForecast,
    getWorkplaceHistory,
    */
  },
});

export const {
  useRefreshTokenQuery,
  useLoginMutation,
  useRegisterUserMutation,
  useChangePasswordMutation,
  useSetPasswordMutation,
  useResetPasswordMutation,
  useResendUserInviteMutation,
  useUpdateAzureTenantMutation,
  useRemoveAzureTenantMutation,
  useSignInAzureUserWithCodeMutation,
  useSignInAzureUserWithTokenMutation,
  useGetAzureUsersQuery,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  useGetCompetencesQuery,
  useCreateCompetenceMutation,
  useGetCompetenceQuery,
  useUpdateCompetenceMutation,
  useDeleteCompetenceMutation,
  useAddCompetenceToUsersMutation,
  useAddCompetenceToUserMutation,
  useGetGroupsQuery,
  useGetGroupsForUserQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useGetLevelOfSkillsQuery,
  useCreateLevelOfSkillMutation,
  useUpdateLevelOfSkillMutation,
  useDeleteLevelOfSkillMutation,
  useGetProjectStagesQuery,
  useCreateProjectStageMutation,
  useUpdateProjectStageMutation,
  useDeleteProjectStageMutation,
  useGetProjectCategoriesQuery,
  useCreateProjectCategoryMutation,
  useUpdateProjectCategoryMutation,
  useDeleteProjectCategoryMutation,
  useCreateProjectTimeReportMutation,
  useUpdateProjectTimeReportMutation,
  useDeleteProjectTimeReportMutation,
  useCreateProjectInvoiceMutation,
  useUpdateProjectInvoiceMutation,
  useDeleteProjectInvoiceMutation,
  useGetNotificationTokensQuery,
  useCreateNotificationTokenMutation,
  useDeleteNotificationTokenMutation,
  useGetOfficesQuery,
  useGetOfficesForUserQuery,
  useCreateOfficeMutation,
  useUpdateOfficeMutation,
  useDeleteOfficeMutation,
  useGetProjectsQuery,
  useGetFilteredProjectsMutation,
  useCreateProjectMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useArchiveProjectMutation,
  useUnarchiveProjectMutation,
  useAddProjectToUserMutation,
  useDeleteProjectFromUserMutation,
  useAddProjectToUsersMutation,
  useCreateSkillMutation,
  useUpdateSkillMutation,
  useDeleteSkillMutation,
  useGetStatisticsMutation,
  useGetCurrentUserQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useGetFilteredUsersMutation,
  useGetFilteredUserIdsMutation,
  useGetUserWorkloadHistoryQuery,
  useGetUserByIdQuery,
  usePrefetch,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUncompletedUserByIdQuery,
  useCreateMultipleUsersMutation,
  useUpdateUserProfilePictureMutation,
  useChangeUserTeamMutation,
  useUpdateUserAdminStatusMutation,
  useCreateVacationMutation,
  useUpdateVacationMutation,
  useDeleteVacationMutation,
  useGetForecastsQuery,
  useCreateForecastMutation,
  useUpdateForecastMutation,
  useDeleteForecastMutation,
  useGetFilteredWorkloadHistoryQuery,
  useUpdateWorkloadMessageMutation,
  useRefreshUserMutation,
  useCreateWorkloadMutation,
  useCreateWorkplaceForecastMutation,
  useGetWorkplaceHistoryQuery,
  useGetUtilizationReportMutation,
  useGetDetailedReportMutation,
  useGetMatterCommentsForUserQuery,
  useGetMatterCommentsForMeQuery,
  useUpdateMatterCommentMutation,
  useUpdateMatterManagerMutation

} = api;
