import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { UserDto } from 'utils/wemble-api.generated';

const useStyles = makeStyles((theme) => ({
  members: {
    display: 'flex',
  },
  user: {
    borderRadius: '50%',
    backgroundColor: theme.palette.gray.g6,
    overflow: 'hidden',
    border: 'solid 2px white',
    marginRight: -10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    '&:nth-last-child(1)': {
      marginRight: 0,
    },
    '& img': {
      width: '100%',
    },
  },
  user__letter: {
    color: theme.palette.gray.g1,
    fontWeight: 600,
    fontSize: 14,
  },
  user__extra: {
    color: theme.palette.gray.g7,
    fontWeight: 400,
    fontSize: 11,
  },
}));

function handleSizing(size: string, append?: object) {
  let sizes;
  if (size === 'small') {
    sizes = {
      height: 36,
      width: 36,
    };
  } else {
    sizes = {
      height: 48,
      width: 48,
    };
  }
  if (append) {
    return Object.assign(append, sizes);
  } else {
    return sizes;
  }
}
const AvatarRow = ({
  members,
  visible = 5,
  size = 'medium',
}: {
  members: UserDto[];
  visible?: number;
  size?: 'small' | 'medium';
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.members}>
        {members.map((user, index) => (
          <React.Fragment key={`${user.name}-${user._id}-member`}>
            {index < visible && (
              <div className={classes.user} title={user.name ?? ''} style={handleSizing(size)}>
                {user.profilePicture ? (
                  <img
                    alt={`${user.name}-image`}
                    src={(user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture}
                  />
                ) : (
                  <div className={classes.user__letter}>{user.name?.charAt(0)}</div>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
        {members.length > visible && (
          <div
            className={classes.user}
            style={handleSizing(size, { backgroundColor: theme.palette.gray.g2 })}
            title={(members.length - 5).toString()}
          >
            <div className={classes.user__extra}>
              +
              {members.length >= 1000 - visible
                ? (members.length - visible).toString().charAt(0) + 'K'
                : members.length - visible}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AvatarRow;
