import { useState } from 'react';
import '../../../../assets/datePickerCustomStyling.css';
import DatePicker from 'react-datepicker';
import { cleanedDateString, formattedDateRange, generateSuggestions } from '../utils';
import { useGetCurrentUserQuery, useGetForecastsQuery } from 'utils/wemble-api';
import { Moment } from 'moment-timezone';


const RangeSelect = ({ selectRange, nextDate, classes }: { selectRange: any, classes: any, nextDate?: Moment }) => {

  const { data: user } = useGetCurrentUserQuery();
  const { data: allForecasts } = useGetForecastsQuery();
  const forecasts = allForecasts?.filter((forecast) => forecast.user == user?._id).sort((a, b) => new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()) || []

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);



    if (start && end) {
      selectRange({ startDate: cleanedDateString(start), endDate: cleanedDateString(end) })
    }
  };




  return (<>
    {generateSuggestions(nextDate, forecasts).map(suggestion => (<div className={classes.suggestionItem} onClick={() => selectRange(suggestion.range)}>
      <div className={classes.subtitle}>{formattedDateRange(suggestion.range)}</div>
      <div className={classes.title}>{suggestion.title}</div>
    </div>))}

    <DatePicker
      wrapperClassName="datePickerCustomStyling"
      selectsRange
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      calendarStartDay={1}
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
      filterDate={(date) => date >= new Date() && !(forecasts.find(forecast => forecast.status == 'away' && new Date(cleanedDateString(date)) >= new Date(forecast.startDate || '') && new Date(cleanedDateString(date)) <= new Date(forecast.endDate || '')))}
      disabledKeyboardNavigation
      monthsShown={2}
      customInput={
        (<div className={classes.suggestionItem} style={{ position: 'relative', height: 40 }}>
          <div className={classes.title}>Custom</div>
        </div>
        )
      }
    />


  </>)
}

export default RangeSelect