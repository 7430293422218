import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Item from '../shared/settings/Item';
import Input from '../shared/Input';
import { SettingsButtons } from '../shared/Settings';
import { makeStyles } from '@material-ui/core';
import Select from 'react-select';
import settingsStyles from '../../theme/react-select/settings';
import { useGetCompetencesQuery, useCreateCompetenceMutation } from 'utils/wemble-api';
import { useHistory } from 'react-router-dom';
import { CompetenceCreationParams, CompetenceDto } from 'utils/wemble-api.generated';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 16px',
  },
}));

const CreateCompetence = ({ close, isCategory = false }: { close: () => void; isCategory?: boolean }) => {
  const classes = useStyles();
  const history = useHistory();
  const [createCompetence] = useCreateCompetenceMutation();
  const { data: competences } = useGetCompetencesQuery();
  const categories = competences
    ?.filter((c) => c.root && (isCategory ? !c.subCategory : true))
    .map((competence) => ({ value: competence, label: (competence.parent?.name ? (competence.parent?.name + ' - ') : '') + competence.name }));

  return (
    <div>
      <h2 className={classes.title}>Add {isCategory ? ' category' : ' competence'}</h2>
      <Formik
        initialValues={{
          name: '',
          category: '',
          parent: null,
          addUser: false,
          root: isCategory,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          category: Yup.string(),
        })}
        onSubmit={async (values) => {
          if (values.name.split(' ').join('') !== '') {
            const data: CompetenceCreationParams = {
              name: values.name,
              root: values.root,
              subCategory: isCategory && values.parent !== null,
              parentId: values.parent ? (values.parent as CompetenceDto)?._id ?? null : null,
              users: [],
            };
            // Adds a new competence as well as automatically attaches it to the user if that option is checked
            if (isCategory) {
              // Adds a competence parent (category)
              await createCompetence({ addToUser: 'false', competenceCreationParams: data });
              close();
            } else {
              const newCompetence = await createCompetence({
                addToUser: values.addUser.toString(),
                competenceCreationParams: data,
              }).unwrap();
              history.push({
                pathname: `/competences/${newCompetence?._id}`,
                state: { competence: newCompetence },
              });
            }
          }
        }}
      >
        {({ handleSubmit, setFieldValue, resetForm }) => (
          <Form>
            <Item title={'Title'} input={<Input placeholder={'Title'} name={'name'} type="text" />} />
            <Item
              title={isCategory ? 'Parent category' : 'Category'}
              input={
                <div style={{ width: '100%', display: 'inline-block' }}>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={(option) => {
                      setFieldValue('parent', option.value);
                    }}
                    options={categories}
                    styles={settingsStyles}
                  />
                </div>
              }
            />
            <SettingsButtons
              discardCustom={'Close'}
              discard={() => {
                resetForm();
                close();
              }}
              saveCustom={'Create competence'}
              save={() => {
                handleSubmit();
              }}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateCompetence;
