import { ReactNode, useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import FilterSelect from './FilterSelect';
import Grid from '@material-ui/core/Grid';
import {
  useGetCurrentUserQuery,
  useGetOfficesQuery,
  useGetGroupsQuery,
  useGetCompetencesQuery,
  useGetProjectsQuery,
  useGetUsersQuery,
  useGetProjectStagesQuery,
  useGetProjectCategoriesQuery,
} from 'utils/wemble-api';
import { isArray, notEmpty } from 'utils/helpers';
import { ProjectFilterParams, UserDto, UserFilterParams } from 'utils/wemble-api.generated';
import TextField from '@material-ui/core/TextField';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { useDebounce } from 'hooks';
import organization from 'utils/organization';

const useStyles = makeStyles(() => ({
  filter_tooltip: {
    color: 'gray',
    marginLeft: 19,
    marginBottom: 2,
    fontSize: 14,
  },
}));

type FilterGroupType = 'group' | 'office' | 'competence' | 'project' | 'cvpartnerData';

type FilterType = {
  label: string | undefined;
  value: string | undefined;
  ids: string[];
  type: FilterGroupType;
};

type InternalFilterType = {
  groups: FilterType[];
  offices: FilterType[];
  projects: FilterType[];
  competences: FilterType[];
  cvpartnerData: FilterType[];
  projectStages: FilterType[];
  projectCategories: FilterType[];
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const formatFilters = (type: 'users' | 'matters' | 'projects', filters: InternalFilterType, searchQuery: string): UserFilterParams | ProjectFilterParams => {


  const baseFilter = {
    offices: ([] as string[]).concat.apply(
      [],
      filters.offices.map((filter) => filter.ids),
    ),
    groups: ([] as string[]).concat.apply(
      [],
      filters.groups.map((filter) => filter.ids),
    ),
    term: searchQuery.length > 0 ? searchQuery : undefined,
  };

  return (type === 'users' || type === 'matters') ? {
    ...baseFilter,
    competences: filters.competences.map((filter) => filter.value).filter(notEmpty),
    cvpartnerData: filters.cvpartnerData.map((filter) => filter.value).filter(notEmpty),
    projects: filters.projects.map((filter) => filter.value).filter(notEmpty),
  } : { ...baseFilter, categories: filters.projectCategories.map((filter) => filter.value).filter(notEmpty), stages: filters.projectStages.map((filter) => filter.value).filter(notEmpty) }



};
const UniversalFilterSelect = ({
  setFilters,
  type = 'users',
  hideFilters: hideFiltersProp = {},
  hideSearch = false,
  showAllOffices = false,
  showAllGroups = false,
  searchFieldClassName,
  itemsFullWidth = false,
  requireOfficeAndGroup = true,
  hideFilter = false,
  manyChildren = false,
  skip = false,
  saveFiltering = false,
  filteredUsers,
  children,
}: {
  setFilters: (newFilters: UserFilterParams | ProjectFilterParams) => void;
  type: 'users' | 'projects' | 'matters';
  hideFilters?: {
    competences?: boolean;
  };
  hideSearch?: boolean;
  showAllOffices?: boolean;
  showAllGroups?: boolean;
  searchFieldClassName?: string;
  itemsFullWidth?: boolean;
  requireOfficeAndGroup?: boolean;
  hideFilter?: boolean;
  manyChildren?: boolean;
  skip?: boolean;
  saveFiltering?: boolean,
  filteredUsers?: UserDto[];
  children?: ReactNode;
}) => {
  const hideFilters = {
    groups: false,
    offices: false,
    competences: type !== 'users',
    projects: type !== 'users',
    cvpartner: type !== 'users',
    projectStages: type !== 'projects',
    projectCategories: type !== 'projects',

    ...hideFiltersProp,
  };
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchValue, touched] = useDebounce(searchQuery);
  const [internalFilters, setInternalFilters] = useState<InternalFilterType | undefined>();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: offices } = useGetOfficesQuery();
  const { data: groupsData } = useGetGroupsQuery();
  const { data: allUsers } = useGetUsersQuery();
  const users = filteredUsers?.length ? filteredUsers : allUsers
  const groups = groupsData?.groups;

  const skipLoading = !(users && users.length) || skip
  const { data: competences } = useGetCompetencesQuery(undefined, { skip: skipLoading });
  const { data: projectStages } = useGetProjectStagesQuery(undefined, { skip: skipLoading });
  const { data: projectCategories } = useGetProjectCategoriesQuery(undefined, { skip: skipLoading });
  const { data: projects } = useGetProjectsQuery({ all: 'false', office: '' }, { skip: skipLoading });


  let allGroupsDefault = organization(currentUser?.company).allGroupsDefault;

  const competenceInUsers = (competenceId: string | undefined | null) => Boolean(users && users.find(
    (user) =>
    (user.skills &&
      user.skills.find(
        (skill) =>
          typeof skill !== 'string' &&
          skill.competence &&
          typeof skill.competence !== 'string' &&
          skill.competence._id === competenceId,
      ))
  ));


  useEffect(() => {
    if (setFilters && internalFilters) {
      setFilters(formatFilters(type, internalFilters, searchQuery));
    }
  }, []);


  useEffect(() => {
    if (internalFilters && touched) {
      setFilters(formatFilters(type, internalFilters, debouncedSearchValue));
    }
  }, [debouncedSearchValue]);

  const generateDefaultValues = useCallback(() => {
    if (!isArray(offices) || !isArray(groups) || !currentUser) return null;
    const currentUserGroupId = typeof currentUser.group === 'string' ? currentUser.group : currentUser.group?._id;
    const currentUserSecondaryGroupId =
      typeof currentUser.secondaryGroup === 'string' ? currentUser.secondaryGroup : currentUser.secondaryGroup?._id;
    const currentUserOfficeId = typeof currentUser.office === 'string' ? currentUser.office : currentUser.office?._id;
    const currentUserSecondaryOfficeId =
      typeof currentUser.secondaryOffice === 'string' ? currentUser.secondaryOffice : currentUser.secondaryOffice?._id;


    const userGroup = groups.find((group) => group._id === currentUserGroupId);
    const defaultGroups = userGroup && !(allGroupsDefault || hideFilter)
      ? [
        {
          label: userGroup.name,
          value: userGroup.name,
          ids: [typeof currentUser.group === 'string' ? currentUser.group : currentUser.group?._id].filter(notEmpty),
          type: 'group' as FilterGroupType,
        },
      ]
      : [];

    const userSecondaryGroup = currentUser.secondaryGroup
      ? groups.find((group) => group._id === currentUserSecondaryGroupId)
      : null;
    if (userSecondaryGroup && !((allGroupsDefault) || hideFilter)) {
      const groupIndex = defaultGroups.findIndex((group) => group.label === userSecondaryGroup.name);
      if (groupIndex >= 0) {
        defaultGroups[groupIndex].ids = defaultGroups[groupIndex].ids.concat(
          [typeof userSecondaryGroup === 'string' ? userSecondaryGroup : userSecondaryGroup?._id].filter(notEmpty),
        );
      } else {
        defaultGroups.push({
          label: userSecondaryGroup.name,
          value: userSecondaryGroup.name,
          ids: [typeof userSecondaryGroup === 'string' ? userSecondaryGroup : userSecondaryGroup?._id].filter(notEmpty),
          type: 'group' as FilterGroupType,
        });
      }
    }

    const userOffice = (!allGroupsDefault || hideFilter || requireOfficeAndGroup) ? offices.find(
      (office) => office._id === currentUserOfficeId && office.group === currentUserGroupId,
    ) : null;
    const defaultOffices = userOffice
      ? [
        {
          label: userOffice.name,
          value: userOffice.name,
          ids: offices
            .filter((office) => office.name === userOffice.name)
            /*.filter((office) => office._id === currentUserOfficeId && office.group === currentUserGroupId)*/
            .map((office) => office._id)
            .filter(notEmpty),
          type: 'office' as FilterGroupType,
        },
      ]
      : [];

    const userSecondaryOffice =
      currentUser.secondaryOffice &&
      currentUser.secondaryGroup &&
      offices.find(
        (office) => office._id === currentUserSecondaryOfficeId && office.group === currentUserSecondaryGroupId,
      );

    if (userSecondaryOffice) {
      const officeIndex = defaultOffices.findIndex((office) => office.label === userSecondaryOffice.name);
      if (officeIndex >= 0) {
        defaultOffices[officeIndex].ids = defaultOffices[officeIndex].ids.concat(
          offices
            .filter(
              (office) =>
                office.name === userSecondaryOffice.name &&
                office._id &&
                !defaultOffices[officeIndex].ids.includes(office._id),
            )
            /*.filter(
              (office) => office._id === currentUserSecondaryOfficeId && office.group === currentUserSecondaryGroupId,
            )*/
            .map((office) => office._id)
            .filter(notEmpty),
        );
      } else {
        defaultOffices.push({
          label: userSecondaryOffice.name,
          value: userSecondaryOffice.name,
          ids: offices
            .filter((office) => office.name === userSecondaryOffice.name)
            /*.filter(
              (office) => office._id === currentUserSecondaryOfficeId && office.group === currentUserSecondaryGroupId,
            )*/
            .map((office) => office._id)
            .filter(notEmpty),
          type: 'office' as FilterGroupType,
        });
      }
    }

    // Sets default filters to all groups & office for company admins with observer status.
    if (
      defaultGroups.length === 0 &&
      defaultGroups.length === 0 &&
      (currentUser.companyAdmin || allGroupsDefault || hideFilter) &&
      showAllGroups &&
      showAllOffices
    ) {
      defaultGroups.push({
        label: 'All groups',
        value: 'All groups',
        ids: groups.map((group) => group._id).filter(notEmpty) as string[],
        type: 'group',
      });
      defaultOffices.push({
        label: 'All offices',
        value: 'All offices',
        ids: offices.map((office) => office._id).filter(notEmpty) as string[],
        type: 'office',
      });
    }

    return {
      groups: defaultGroups,
      offices: defaultOffices,
      competences: [],
      projects: [],
      cvpartnerData: [],
      projectStages: [],
      projectCategories: []
    };
  }, [offices, groups, currentUser, showAllGroups, showAllOffices]);

  const [initializedFromSavedFilters, setInitializedFromSavedFilters] = useState(false);

  useEffect(() => {
    if (!internalFilters) {
      var savedFilters: InternalFilterType | undefined = undefined;
      try {
        savedFilters = type == "users" && localStorage.getItem('OverviewPageSavedFilter') && (JSON.parse(localStorage.getItem('OverviewPageSavedFilter') || '')) || undefined;
      }
      catch { }
      if (saveFiltering && savedFilters && 'groups' in savedFilters && 'offices' in savedFilters) {
        setInternalFilters(savedFilters);
        setInitializedFromSavedFilters(true);
        if (setFilters) {
          setFilters(formatFilters(type, savedFilters, searchQuery));
        }
        return
      }
      else if (localStorage.getItem('OverviewPageSavedFilter') && saveFiltering) {
        localStorage.removeItem('OverviewPageSavedFilter')
      }

      const defaultFilters = generateDefaultValues();
      if (!defaultFilters) return;
      setInternalFilters(defaultFilters);
      if (setFilters) {
        setFilters(formatFilters(type, defaultFilters, searchQuery));
      }
    }
  }, [internalFilters, generateDefaultValues]);

  useEffect(() => {

    if (!skip && filteredUsers?.length == 0 && allUsers?.length && initializedFromSavedFilters && type == "users") {
      const defaultFilters = generateDefaultValues();
      if (!defaultFilters) return;
      setInternalFilters(defaultFilters);
      setInitializedFromSavedFilters(false);
      saveFiltering && localStorage.removeItem('OverviewPageSavedFilter')
      if (setFilters) {
        setFilters(formatFilters(type, defaultFilters, searchQuery));
      }
    }
    else if (!skip && filteredUsers?.length && type == "users" && saveFiltering) {
      localStorage.setItem('OverviewPageSavedFilter', JSON.stringify(internalFilters));
    }
  }, [filteredUsers, generateDefaultValues])


  if (!internalFilters) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      {!hideSearch && (
        <Grid item xs={12} md={12} lg={itemsFullWidth ? 12 : (manyChildren ? 2 : 3)}>
          <TextField
            className={searchFieldClassName}
            fullWidth
            placeholder={'Search' + (type === 'users' ? ' name or title' : (type === 'matters' ? ' name or client' : ''))}
            variant="filled"
            value={searchQuery}
            onChange={(ev) => setSearchQuery(ev.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: <SearchIcon />,
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={!children ? 12 : (manyChildren ? 6 : 8)} lg={!children ? 12 : itemsFullWidth ? 8 : 6}>
        {!hideFilter && (
          <FilterSelect
            options={[
              !hideFilters.groups
                ? {
                  label: 'Groups',
                  options: [
                    (showAllGroups)
                      ? {
                        label: 'All groups',
                        value: 'All groups',
                        ids: groups?.map((group) => group._id),
                        type: 'group',
                      }
                      : null,
                  ]
                    .filter(notEmpty)
                    .concat(
                      groups
                        ?.filter((g) =>
                          showAllGroups
                            ? true
                            : g._id &&
                            [
                              typeof currentUser?.group === 'string' ? currentUser?.group : currentUser?.group?._id,
                              typeof currentUser?.secondaryGroup === 'string'
                                ? currentUser?.secondaryGroup
                                : currentUser?.secondaryGroup?._id,
                            ].includes(g._id),
                        )
                        .map((group) => ({
                          label: group.name ?? '',
                          value: group.name ?? '',
                          ids: [group._id],
                          type: 'group',
                        })) ?? [],
                    )
                    .filter(notEmpty),
                }
                : null,
              !hideFilters.offices
                ? {
                  label: 'Offices',
                  options: offices
                    ?.filter((office) =>
                      showAllOffices
                        ? true
                        : [
                          typeof currentUser?.office === 'string' ? currentUser.office : currentUser?.office?._id,
                          typeof currentUser?.secondaryOffice === 'string'
                            ? currentUser.secondaryOffice
                            : currentUser?.secondaryOffice?._id,
                        ].includes(office._id ?? undefined) &&
                        (!internalFilters.groups.length ||
                          internalFilters.groups.some((group) =>
                            group.ids.includes(
                              typeof office.group === 'string' ? office.group : office.group?._id ?? '',
                            ),
                          )),
                    )
                    .reduce(
                      (groupedOffices, office) => {
                        let existingGroup = groupedOffices.find((element) => {
                          return element.label === office.name;
                        });
                        if (existingGroup !== undefined) {
                          existingGroup.ids.push(office._id);
                        } else {
                          groupedOffices.push({
                            label: office.name ?? '',
                            value: office.name ?? '',
                            ids: [office._id],
                            type: 'office',
                          });
                        }
                        return groupedOffices;
                      },
                      (showAllOffices)
                        ? [
                          {
                            label: 'All offices',
                            value: 'All offices',
                            ids: offices.map((office) => office._id),
                            type: 'office',
                          },
                        ]
                        : [],
                    ),
                }
                : null,
              ...competences?.filter(competence => !hideFilters.competences && competences.find(c => c.parent?._id === competence._id)).map(c => ({
                label: (c.parent ? (c.parent.name + " - " + c.name) : c.name),
                options: competences?.filter(competence => competence.parent?._id === c._id && !competence.root && competenceInUsers(competence._id))
                  ?.map((competence) => {
                    return {
                      label: competence.name,
                      value: competence._id,
                      type: 'competence',
                    };
                  }),
              })) || []
              ,
              !hideFilters.competences
                ? {
                  label: 'Competences',
                  options: competences?.filter(competence => !competence.root && !competence.parent && competenceInUsers(competence._id))
                    .map((competence) => {
                      return {
                        label: (competence.parent ? (competence.parent.name + " - " + competence.name) : competence.name),
                        value: competence._id,
                        type: 'competence',
                      };
                    }),
                }
                : null,
              !hideFilters.cvpartner
                ? {
                  label: 'CV Partner',
                  options: users
                    ?.flatMap((user) => user.cvpartnerData || [])
                    .filter(onlyUnique)
                    .map((cvpartnerData) => {
                      return {
                        label: cvpartnerData,
                        value: cvpartnerData,
                        type: 'cvpartnerData',
                      };
                    }),
                }
                : null,
              !hideFilters.projects
                ? {
                  label: 'Projects',
                  options: projects
                    ?.filter((project) => users?.find((user) => project._id && user.projects?.includes(project._id)))
                    .map((project) => {
                      return {
                        label: project.name,
                        value: project._id,
                        type: 'project',
                      };
                    }),
                }
                : null,
              !hideFilters.projectStages
                ? {
                  label: 'Stages',
                  options: projectStages?.map((projectStage) => {
                    return {
                      label: projectStage.name,
                      value: projectStage._id,
                      type: 'projectStage',
                      color: projectStage.color
                    };
                  }),
                }
                : null,
              !hideFilters.projectCategories
                ? {
                  label: 'Categories',
                  options: projectCategories?.map((projectCategory) => {
                    return {
                      label: projectCategory.name,
                      value: projectCategory._id,
                      type: 'projectCategory',
                    };
                  }),
                }
                : null,

            ].filter((v) => v !== null)}
            onChange={(value) => {
              const newFilters = {
                groups: value.find((value) => value.type === 'group' && value.label === 'All groups')
                  ? [value.find((value) => value.type === 'group' && value.label === 'All groups')]
                  : value.filter((option) => {
                    return option.type === 'group';
                  }),

                offices: value.find((value) => value.type === 'office' && value.label === 'All offices')
                  ? [value.find((value) => value.type === 'office' && value.label === 'All offices')]
                  : value.filter((option) => {
                    return option.type === 'office';
                  }),

                competences: value.filter((option) => {
                  return option.type === 'competence';
                }),
                cvpartnerData: value.filter((option) => {
                  return option.type === 'cvpartnerData';
                }),
                projects: value.filter((option) => {
                  return option.type === 'project';
                }),
                projectCategories: value.filter((option) => {
                  return option.type === 'projectCategory';
                }),
                projectStages: value.filter((option) => {
                  return option.type === 'projectStage';
                }),

              };
              setInitializedFromSavedFilters(false);
              if (setFilters) {
                setFilters(formatFilters(type, newFilters, searchQuery));
              }
              setInternalFilters(newFilters);

            }}
            defaultValue={Object.values(generateDefaultValues() ?? {}).flatMap((v) => v)}
            value={Object.values(internalFilters).flatMap((v) => v)}
            placeholder={'No filter selected'}
          />)}

        {Object.values(internalFilters).flatMap((v) => v).length > 0 &&
          requireOfficeAndGroup &&
          (!internalFilters.groups.length || !internalFilters.offices.length) && (
            <div className={classes.filter_tooltip}>
              {!internalFilters.groups.length ? 'No group selected' : 'No office selected'}
            </div>
          )}
      </Grid>
      {children && (
        <Grid item xs={12} md={manyChildren ? 6 : 4} lg={manyChildren ? 4 : 3} >
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default UniversalFilterSelect;
