
import { makeStyles } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { getToday, statusBackgroundColor } from './utils';

import DefaultView from './views/DefaultView';
import NewForecast from './views/NewForecast';
import RangeSelect from './views/RangeSelect';
import StatusSelect from './views/StatusSelect';
import { ForecastDto } from 'utils/wemble-api.generated';
import { useCreateForecastMutation, useCreateWorkloadMutation, useGetCurrentUserQuery, useGetForecastsQuery, useUpdateForecastMutation, useUpdateUserMutation } from 'utils/wemble-api';
import { useTeamsAuth } from 'hooks/useTeamsAuth';
import ReactGA from 'react-ga';

import moment, { Moment } from 'moment-timezone';
moment.tz.setDefault("utc");



const useStyles = (hovered: boolean, forecastsLength: number) => makeStyles(() => ({
  forecastItem: {
    padding: 12,
    minWidth: forecastsLength < 3 ? 160 : 140,
    maxWidth: forecastsLength < 3 ? 160 : 140,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  forecastItemNew: {
    padding: hovered ? 0 : 8,
    border: `${hovered ? 0 : 8}px dotted #F2F2F2`,
    '& > *:not(:first-child)': {
      marginTop: '6px',
    }
  },
  addForecast: {
    marginTop: 8,
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '120%',
    color: "#D2D2D2",
    textAlign: 'center'
  },
  suggestionItem: {
    padding: 3,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#F5F5F5',
    cursor: 'pointer',
    flexGrow: 1,
    transition: '0.1s',
    '&:hover': {
      backgroundColor: '#E8E8E8',
      transform: 'scale(1.065)',
    },
  },
  title: {
    fontSize: 12,
    fontWeight: 600
  },
  subtitle: {
    fontSize: 9
  },
  commentInput: {
    fontSize: 11, fontWeight: 600, textAlign: 'center', padding: 0, width: 'calc(100% - 30px)', marginLeft: 2, marginRight: 4, background: 'none', border: 'none',
    '&:focus': {
      outline: 'none',
      background: '#FFFFFF77',
      borderRadius: 8
    }
  }

}));

const ForecastItem = ({
  today,
  nextDate,
  data,
}: {
  today?: true;
  nextDate?: Moment;
  data?: ForecastDto;
}) => {

  const { data: user } = useGetCurrentUserQuery();

  const { data: allForecasts } = useGetForecastsQuery();
  const forecasts = allForecasts?.filter((forecast) => forecast.user == user?._id && moment(forecast.endDate) >= moment(getToday())).sort((a, b) => new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()) || []

  const [createForecast] = useCreateForecastMutation();
  const [updateForecast] = useUpdateForecastMutation();

  const todayStatus = user?.onLeave ? 'away' : user?.currentWorkload?.toString()

  const [hovered, setHovered] = useState(false);
  const [range, setRange] = useState<{ startDate: string, endDate: string } | null>(null);
  const [status, setStatus] = useState<"0" | "1" | "2" | "3" | 'away' | null>(null);
  const [view, setView] = useState<'default' | 'new' | 'range' | 'custom-range' | 'status'>('new')
  const classes = useStyles(hovered, forecasts.length)();

  const views = {
    'default': () => (<DefaultView data={today ? { status: todayStatus } : data!} />),
    'new': () => (<NewForecast classes={classes} />),
    'range': () => (<RangeSelect classes={classes} selectRange={(setRange)} nextDate={nextDate} />),
    'status': () => (<StatusSelect classes={classes} selectStatus={setStatus} range={data ? data : range} data={data} toggle={() => setHovered(false)} />)
  }

  const [createWorkload] = useCreateWorkloadMutation();
  const [updateUser] = useUpdateUserMutation();
  const { isInTeams, clientType } = useTeamsAuth();



  const updateToday = () => {
    if (!user || !user._id || !user.name) return;

    if (status == 'away' || user.onLeave) {
      updateUser({
        id: user._id || '', userUpdateParams: {
          name: user.name,
          email: user.email,
          admin: Boolean(user.admin),
          onLeave: status == 'away'
        }
      })
    }

    const workload = parseInt(status || '')

    if (workload <= 3 && workload >= 0) {
      createWorkload({
        type: 'current',
        workloadCreationParams: {
          workscale: workload,
          source: Boolean(window.navigator?.userAgent?.includes('Wemble(iOS)')) ? "ios_app" : (isInTeams ? `teams_app_${clientType}` : "web_app")
        },
      });
      ReactGA.event({ category: 'Workload', action: `Change current`, value: workload });
    }
  }

  useEffect(() => {
    if (data?.externalSource) setView('default');
    else if (!hovered) {
      setRange(null)
      setStatus(null)
      setView((data || today) ? 'default' : 'new')
    }
    else if (typeof status == 'string') {
      if (data) updateForecast({ userId: user?._id || '', forecastId: data._id || '', forecastUpsertParams: { startDate: data.startDate!, endDate: data.endDate!, status, comment: null } })
      else if (today) updateToday()
      else createForecast({ userId: user?._id || '', forecastUpsertParams: { startDate: range!.startDate, endDate: range!.endDate, status } })
      setHovered(false)
    }
    else if (range) { window.removeEventListener('mousemove', mouseListener.current); setView('status') }
    else if (view == 'new') setView('range')
    else if (view == 'default') setView('status')
  }, [hovered, range, status]);

  const divRef = useRef<HTMLHeadingElement>(null);

  const mouseListener = useRef(() => {
    const h = divRef.current?.matches(':hover');
    if (!h) {
      setHovered(false);
      window.removeEventListener('mousemove', mouseListener.current);
    }
  });

  const mouseEnter = () => {
    setHovered(true);
    window.removeEventListener('mousemove', mouseListener.current);
    window.addEventListener('mousemove', mouseListener.current);
  };

  useEffect(() => {
    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('mousemove', mouseListener.current);
    };
  }, []);

  return (
    <div
      ref={divRef}
      className={`${classes.forecastItem} ${view == 'default' ? '' : classes.forecastItemNew}`}
      style={{ backgroundColor: view == 'default' ? statusBackgroundColor(today ? todayStatus : data!.status) : '' }}
      onMouseEnter={mouseEnter}
      onClick={mouseEnter}
    >
      {views[view]()}
    </div>
  );
};

export default ForecastItem;

