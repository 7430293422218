import { makeStyles } from '@material-ui/core';


const useStyles = (sm: boolean, xs: boolean) => makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.gray.g7,
    height: xs ? '51px' : 65,
    borderRadius: xs ? 8 : 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: xs ? 8 : 14,
    cursor: 'pointer',
    '&:hover': {
      '& img': {
        filter: 'brightness(1) invert(0.4)',
      },
      '& > div': {
        backgroundColor: 'white',
      },
    },
    ...(sm ? (xs ? { marginRight: 8, color: 'gray' } : {
      marginLeft: 8
    }) : {
      height: 100
    })
  },
  box__img: {
    width: sm ? 32 : 42,
    height: sm ? 32 : 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    transition: '0.2s',
    '& img': {
      width: '80%',
      filter: 'brightness(1) invert(0.5)',
      pointerEvents: 'none',
      userSelect: 'none',
      transition: '0.1s',
    },
  },
  box__text: {
    fontSize: sm ? (xs ? 10 : 12) : 13,
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: 1,
    marginTop: 2,
    userSelect: 'none',
  },
}));

const SingleBoxButton = ({ icon, text, sm = false, xs = false, event }: { icon: string; text: string; sm?: boolean; xs?: boolean; event: () => void }) => {
  const classes = useStyles(sm, xs)();

  return (
    <div className={classes.box} onClick={event}>
      <div className={classes.box__img}>
        <img alt={text + '-icon'} src={icon} />
      </div>
      <div className={classes.box__text}>{text}</div>
    </div>
  );
};
export default SingleBoxButton;
