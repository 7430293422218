import { useState } from 'react';
import { Formik } from 'formik';
import Item from '../shared/settings/Item';
import Input from '../shared/Input';
import SettingsContainer, { SettingsButtons } from '../shared/Settings';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { useChangePasswordMutation } from 'utils/wemble-api';
import { ChangePasswordInputErrors } from 'utils/wemble-api.generated';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 16px',
  },
  error: {
    color: theme.palette.red.r1,
    width: '50%',
    position: 'absolute',
    wordWrap: 'break-word',
    top: 'calc(100% - 76px)',
  },
}));

const ChangePassword = ({ handleClose }: { handleClose: () => void }) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [changePassword] = useChangePasswordMutation();

  return (
    <SettingsContainer noPadding>
      <h2 className={classes.title}>Change password</h2>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().required('Required'),
          newPassword: Yup.string().required('Required'),
          newPasswordConfirmation: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
            .required('Required'),
        })}
        onSubmit={async (values, { setFieldError }) => {
          const res = await changePassword({
            changePasswordParams: values,
          });
          if ('error' in res) {
            const error = res.error as any;
            if (error?.data?.entityType === 'ChangePasswordInputErrors') {
              Object.entries(error.data as ChangePasswordInputErrors).forEach(([key, message]) => {
                if (['oldPassword', 'newPassword', 'newPasswordConfirmation'].includes(key)) {
                  setFieldError(key, message);
                }
              });
            } else if (typeof error?.data?.message === 'string') {
              setMessage(error?.data?.message);
            }
            return;
          }
          setMessage('Password changed!');
          setTimeout(() => {
            handleClose();
          }, 2500);
        }}
      >
        {({ handleSubmit, resetForm }) => (
          <form>
            <Item title={'Old Password'} input={<Input placeholder={'*'} name={'oldPassword'} type="password" />} />
            <Item title={'New Password'} input={<Input placeholder={'*'} name={'newPassword'} type="password" />} />
            <Item
              title={'Confirm New Password'}
              input={<Input placeholder={'*'} name={'newPasswordConfirmation'} type="password" />}
            />
            <SettingsButtons
              discardCustom={'Cancel'}
              discard={() => {
                resetForm();
                handleClose();
              }}
              saveCustom={'Change password'}
              save={() => {
                handleSubmit();
              }}
            />
          </form>
        )}
      </Formik>
      {message && <div className={classes.error}>{message}</div>}
    </SettingsContainer>
  );
};

export default ChangePassword;
