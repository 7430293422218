import { makeStyles, IconButton, Hidden } from '@material-ui/core';
import moment from 'moment';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  range_selector: {
    float: 'right',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
}));

const RangeSelector = ({
  startDate,
  length,
  setStartDate,
}: {
  startDate: moment.Moment;
  length: number;
  setStartDate: (value: moment.Moment) => void;
}) => {
  const classes = useStyles();

  return (
    <h4 >
      <span className={classes.title}>Forecasts</span>{' '}
      <span className={classes.range_selector}>
        <IconButton color="primary" onClick={() => setStartDate(moment(startDate).subtract(1, 'day'))}>
          <ChevronLeftIcon />
        </IconButton>
        {''}
        <Hidden smDown>Week</Hidden> {startDate.week()} - <Hidden smDown>Week</Hidden>{' '}
        {moment(startDate).add(length, 'day').week() - 1}
        <IconButton color="primary" onClick={() => setStartDate(moment(startDate).add(1, 'day'))}>
          <ChevronRightIcon />
        </IconButton>
        {''}
      </span>
    </h4>
  );
};

export default RangeSelector;
