import { makeStyles, useTheme } from '@material-ui/core';
import home from './../../assets/icons/home.svg';
import office from './../../assets/icons/office.svg';
import absent from './../../assets/icons/absent.svg';
import elsewhere from './../../assets/icons/elsewhere.svg';
import court from './../../assets/icons/court.svg';
import organization from 'utils/organization';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  icon: {
    width: 50,
    height: 50,
    backgroundColor: theme.palette.blueGray.bg1,
    borderRadius: '50%',
    overflow: 'hidden',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      filter: 'brightness(0) invert(1)',
      width: '60%',
    },
  },
}));

const ForecastIcon = ({ forecast, user }) => {
  const classes = useStyles();
  const theme = useTheme();

  const companyId = typeof user.company === 'string' ? user.company : user.company?._id ?? '';
  const enableElsewhere = Boolean(organization(companyId).elseWhereOption);
  const enableInCourt = companyId === "65f779d34b718376e412163f";

  const getIconFromForecast = (forecast: number) => {
    switch (forecast) {
      case 0:
        return office;
      case 1:
        return home;
      case 2:
        return absent;
      case 3:
        return enableElsewhere ? (enableInCourt ? court : elsewhere) : office;
      default:
        return office;
    }
  }

  return (
    <div className={classes.root}>
      <div
        className={classes.icon}
        style={
          forecast === null
            ? { opacity: 0.1 }
            : forecast === 2
              ? { backgroundColor: theme.palette.state.disabled }
              : undefined
        }
      >
        {forecast !== null && <img alt={'status'} src={getIconFromForecast(forecast)} />}
      </div>
    </div>
  );
};
export default ForecastIcon;
