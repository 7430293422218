import { useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { RegisterParams, UserDto, UserSignUpInputErrors } from 'utils/wemble-api.generated';
import { useAppDispatch } from 'store';
import { addFlashMessage } from 'store/reducers/flashMessages';
import { useRegisterUserMutation } from 'utils/wemble-api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSetAuth } from 'hooks';

import wembleIcon from '../../resources/images/wemble_black.png';
import '../login/css/material-design-iconic-font.min.css';
import '../login/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import '../login/css/util.css';
import '../login/css/main.css';
import OptionsComponent from 'components/users/OptionsComponent';

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: 'url("../background.png")',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logo: {
    pointerEvents: 'none',
    width: '70%',
    marginTop: -25,
    marginBottom: '35px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  disabledInput: {
    color: '#999999',
  },
  disabledInputUnderline: {
    '&:before': {
      background: '#999999',
    },
  },
  options_container: {
    marginTop: '-20px',
    marginBottom: '8px'
  }
}));

const companiesWithSSO = ['5da0c1370b30f993a68d898f', '5d7932251a047744d0b16877', '5e4d42f50fc8ec2139341a39', '650197f48213ce0e6b0e5045'];

const schema = yup.object().shape({
  name: yup.string().required('This field is required.'),
  email: yup.string().email('Needs to be a valid email').required('This field is required.'),
  phone: yup.string(),
});

const SignupForm = ({ user }: { user: UserDto }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const setAuth = useSetAuth();
  const sso = new URLSearchParams(useLocation().search).get('sso');
  const passwordIsOptional =
    Boolean(sso) ||
    companiesWithSSO.includes(typeof user.company === 'string' ? user.company : user.company?._id ?? '');
  const [showPassword, setShowPassword] = useState(false);
  const [registerUser] = useRegisterUserMutation();

  const {
    handleSubmit,
    register,
    watch,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<RegisterParams>({
    defaultValues: {
      name: user.name ?? '',
      title: user.title ?? '',
      email: user.email ?? '',
      phone: user.phone ?? '',
      group: typeof user.group === 'string' ? '' : user.group?._id ?? '',
      office: user.office?.toString() ?? '',
      password: '',
      passwordConfirmation: '',
      sso: Boolean(sso) && passwordIsOptional,
    },
    resolver: yupResolver(schema),
  });
  const fields = watch();
  const passwordConfirmation = watch('passwordConfirmation');

  const setErrors = (error: any) => {
    if (error?.data?.entityType === 'UserSignUpInputErrors') {
      Object.entries(error.data as UserSignUpInputErrors).forEach(([key, message]) => {
        if (
          key === 'name' ||
          key === 'email' ||
          key === 'phone' ||
          key === 'password' ||
          key === 'passwordConfirmation'
        ) {
          setError(key, { message });
        }
      });
    } else if (typeof error?.data?.message === 'string') {
      // @ts-ignore
      setError('server', { message: error?.data?.message });
    }
  };

  const onSubmit = async (data) => {
    clearErrors();
    if (!user._id) return;
    const res = await registerUser({
      id: user._id,
      registerParams: data,
    });

    if ('error' in res) {
      setErrors(res.error);
      return;
    }
    setAuth(res.data.token);
    dispatch(addFlashMessage({ type: 'signup' }));
    history.push('/home');
  };

  return (
    <div className={classes.root}>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form onSubmit={handleSubmit(onSubmit)} className="login100-form validate-form">
              <img src={wembleIcon} alt="" className={classes.logo} />

              <div
                className={'wrap-input100 validate-input' + (errors.name ? ' alert-validate' : '')}
                data-validate={errors.name?.message}
              >
                <input
                  className={'input100' + (fields.name ? ' has-val' : '')}
                  type="text"
                  required
                  {...register('name')}
                  autoComplete="name"
                />
                <span className="focus-input100" data-placeholder="Full name" />
              </div>

              <div
                className={'wrap-input100 validate-input' + (errors.title ? ' alert-validate' : '')}
                data-validate={errors.title}
              >
                <input className={'input100' + (fields.title ? ' has-val' : '')} type="text" {...register('title')} />
                <span className="focus-input100" data-placeholder="Title (e.g Associate)" />
              </div>

              <div
                className={'wrap-input100 validate-input' + (errors.email ? ' alert-validate' : '')}
                data-validate={errors.email?.message}
              >
                <input
                  className={classNames('input100', classes.disabledInput, { ['has-val']: fields.email })}
                  type="email"
                  {...register('email')}
                  autoComplete="email"
                  disabled
                />
                <span
                  className={classNames('focus-input100', classes.disabledInputUnderline)}
                  data-placeholder="Email"
                />
              </div>

              <div
                className={'wrap-input100 validate-input' + (errors.phone ? ' alert-validate' : '')}
                data-validate={errors.phone?.message}
              >
                <input
                  className={'input100' + (fields.phone ? ' has-val' : '')}
                  type="tel"
                  {...register('phone')}
                />
                <span className="focus-input100" data-placeholder="Phone number (optional)" />
              </div>

              <div className="passwords-container">
                <div
                  className={'wrap-input100 validate-input' + (errors.password ? ' alert-validate' : '')}
                  data-validate={errors.password?.message}
                >
                  <span
                    className={'btn-show-pass' + (showPassword ? ' active' : '')}
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    <i className="zmdi zmdi-eye" />
                  </span>
                  <input
                    className={'input100' + (fields.password ? ' has-val' : '')}
                    type={showPassword ? 'text' : 'password'}
                    required={!passwordIsOptional}
                    {...register('password')}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder={'Password' + (passwordIsOptional ? ' (optional)' : '')}
                  />
                </div>

                <div
                  className={'wrap-input100 validate-input' + (errors.passwordConfirmation ? ' alert-validate' : '')}
                  data-validate={errors.passwordConfirmation?.message}
                >
                  <input
                    className={'input100' + (passwordConfirmation ? ' has-val' : '')}
                    type={showPassword ? 'text' : 'password'}
                    required={!passwordIsOptional || fields.password !== ''}
                    {...register('passwordConfirmation')}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder={
                      'Password confirmation' + (passwordIsOptional && fields.password === '' ? ' (optional)' : '')
                    }
                  />
                </div>
              </div>

              {
                user && !user.office && !user.companyAdmin && (
                  <div className={classes.options_container}>
                    <OptionsComponent
                      uncompletedUserId={user._id || undefined}
                      selectedOfficeId={fields.office || ''}
                      selectedGroupId={fields.group || ''}
                      setSelectedOffice={office => setValue("office", office)}
                      setSelectedGroup={group => setValue("group", group)}
                    />
                  </div>
                )
              }

              {errors['server']?.message && <span className="text-danger">{errors['server'].message}</span>}
              <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                  <div className="login100-form-bgbtn" />
                  <button type="submit" value="Submit" className="login100-form-btn">
                    Create account
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
