import { useState } from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import CompetenceAverageSkillLevel from './CompetenceAverageSkillLevel';
import dots from '../../assets/icons/dots.svg';
import CompetenceCategory from './CompetenceCategory';
import { compareNumbers, compareString } from 'helpers/compareFunctions';
import CompetencesSelect from './CompetencesSelect';
import { CompetenceDto } from 'utils/wemble-api.generated';
import { useGetCompetencesQuery, usePrefetch } from 'utils/wemble-api';

const useStyles = makeStyles((theme) => ({
  root: {},
  competencesList: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 14,
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      padding: 15,
    },
  },
  competences__table: {
    '& .MuiTableCell-root': {
      borderBottomColor: theme.palette.gray.g6,
      padding: '12px 0',
    },
    '& .MuiTableCell-head': {
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.gray.g10,
      padding: '0 0 8px',
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
  },
  details: {
    backgroundColor: theme.palette.gray.g3,
    display: 'inline-block',
    height: 40,
    width: 40,
    borderRadius: 8,
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  },
}));

type OrderBy = 'name' | 'users-number' | 'skill';

const orderOptions: { label: string; value: OrderBy }[] = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Number of members',
    value: 'users-number',
  },
  {
    label: 'Skill level',
    value: 'skill',
  },
];

const orderCompetences = (order) => (competenceA, competenceB) => {
  switch (order) {
    default:
    case 'name':
      return compareString(competenceA.name, competenceB.name);
    case 'users-number':
      return competenceB.users.length - competenceA.users.length;
    case 'skill':
      return compareNumbers(competenceB.averageSkillLevel, competenceA.averageSkillLevel);
  }
};
const CompetencesList = ({ searchQuery, hideDetails }: { searchQuery: string, hideDetails: boolean }) => {
  const classes = useStyles();
  const history = useHistory();
  const [order /*, setOrder*/] = useState<OrderBy>(orderOptions[0].value);
  const [visibleCompetences, setVisibleCompetences] = useState<CompetenceDto[]>([]);
  const { data: competences } = useGetCompetencesQuery(undefined, { refetchOnMountOrArgChange: 15, refetchOnReconnect: true });
  const prefetchCompetence = usePrefetch('getCompetence')


  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {/* <Box mt={2}>
                <Grid container justify={'space-between'} spacing={1}>
                    <Grid item />
                    <Grid item md={3} xs={4}>
                        <OrderSelect
                            options={orderOptions}
                            onChange={(order) => {
                                setOrder(order.value);
                            }}
                            defaultValue={orderOptions[0]}
                        />
                    </Grid>
                </Grid>
            </Box> */}

      <CompetencesSelect setVisibleCompetences={setVisibleCompetences} searchQuery={searchQuery} />

      <div className={classes.competencesList}>
        <Table className={classes.competences__table}>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              {!mobile && <TableCell>Category</TableCell>}
              {!mobile && <TableCell># of users</TableCell>}
              <TableCell align={'right'}>Avg. skill level</TableCell>
              {!mobile && !hideDetails && <TableCell align={'right'}>Details</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...visibleCompetences]
              ?.filter((c) => !c.root)
              .sort(orderCompetences(order))
              .map((competence) => (
                <TableRow
                  style={{ cursor: 'pointer' }}
                  key={competence._id}
                  onClick={() => history.push(`/competences/${competence._id}`)}
                  onMouseEnter={() => competence._id && prefetchCompetence({ id: competence._id }, { ifOlderThan: 180 })}

                >
                  <TableCell style={{ maxWidth: 300 }}>{competence.name}</TableCell>
                  {!mobile && (
                    <TableCell>
                      {competence?.parent?.parent && <CompetenceCategory parent name={competences?.find(c => c._id == competence!.parent?._id)?.parent?.name || ""} />}
                      {competence?.parent?.name && <CompetenceCategory name={competence!.parent.name} />}
                    </TableCell>
                  )}
                  {!mobile && <TableCell>{competence.users?.length}</TableCell>}
                  <TableCell align={'right'}>
                    <CompetenceAverageSkillLevel
                      skill={competence?.averageSkillLevel === null ? -1 : competence?.averageSkillLevel}
                    />
                  </TableCell>
                  {!mobile && !hideDetails && (
                    <TableCell align={'right'}>
                      <div className={classes.details}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(`/competences/${competence._id}/settings`);
                          }}
                        >
                          <img alt={'...'} src={dots} />
                        </div>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default CompetencesList;
