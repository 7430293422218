import moment from 'moment';

const isOnVacation = (user) =>
  Boolean(
    (user?.vacations || []).find((vacation) => {


      {
        if (typeof vacation !== 'string' && vacation.startDate && (new Date(new Date(vacation.startDate).setHours(0, 0, 0, 0))) <= (new Date(new Date().setHours(0, 0, 0, 0)))) {
          return !vacation.endDate ? true : (new Date(new Date(vacation.endDate).setHours(0, 0, 0, 0))) >= (new Date(new Date().setHours(0, 0, 0, 0)));
        }
        return false;
      }
    }),
  );

export const compareUsersByName = (a, b) => {
  return compareString(a.name, b.name);
};

export const compareUsersByWorkload = (a, b) => {
  if (a.pinned === b.pinned) {
    const aOnLeave = isOnVacation(a) || a.onLeave,
      bOnLeave = isOnVacation(b) || b.onLeave;
    if (Boolean(aOnLeave) === Boolean(bOnLeave)) return a.currentWorkload - b.currentWorkload;
    return aOnLeave ? 1 : -1;
  }
  return a.pinned ? -1 : 1;
};

export const compareUsersByNegativeWorkload = (a, b) => {
  if (a.pinned === b.pinned) {
    const aOnLeave = isOnVacation(a) || a.onLeave,
      bOnLeave = isOnVacation(b) || b.onLeave;
    if (Boolean(aOnLeave) === Boolean(bOnLeave)) return b.currentWorkload - a.currentWorkload;
    return aOnLeave ? 1 : -1;
  }
  return a.pinned ? -1 : 1;
};

export const compareUsersByExperience = (a, b) => {
  if (a.pinned === b.pinned) {
    const aOnLeave = isOnVacation(a) || a.onLeave,
      bOnLeave = isOnVacation(b) || b.onLeave,
      userAStartYear = a.startYear || new Date().getFullYear(),
      userBStartYear = b.startYear || new Date().getFullYear();
    if (Boolean(aOnLeave) === Boolean(bOnLeave)) {
      return userAStartYear - userBStartYear;
    }
    return aOnLeave ? 1 : -1;
  }
  return a.pinned ? -1 : 1;
};

export const compareUsersByUpdated = (a, b) => {
  if (a.pinned === b.pinned) {
    const aOnLeave = isOnVacation(a) || a.onLeave,
      bOnLeave = isOnVacation(b) || b.onLeave;
    if (Boolean(aOnLeave) === Boolean(bOnLeave)) return moment(b.lastUpdated || 0) - moment(a.lastUpdated || 0);
    return aOnLeave ? 1 : -1;
  }
  return a.pinned ? -1 : 1;
};

export const compareString = (a, b) => {
  let nameA = a.toUpperCase(); // ignore upper and lowercase
  let nameB = b.toUpperCase(); // ignore upper and lowercase

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const compareNumbers = (a, b) => {
  const numA = a || Infinity;
  const numB = b || Infinity;
  return a - b;
};
