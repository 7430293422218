import { FormEvent } from 'react';
import { Form, FormGroup, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';

import { addFlashMessage } from 'store/reducers/flashMessages';
import { SetPasswordInputErrors, SetPasswordParams } from 'utils/wemble-api.generated';
import { useSetPasswordMutation } from 'utils/wemble-api';
import { useAppDispatch } from 'store';
import { useSetAuth } from 'hooks';

const ResetPage = () => {
  const dispatch = useAppDispatch();
  const setAuth = useSetAuth();
  const history = useHistory();
  const { resetHash } = useParams<{ resetHash: string }>();
  const [setPassword] = useSetPasswordMutation();
  const { register, handleSubmit, setError, clearErrors, formState } = useForm<SetPasswordParams>({
    defaultValues: {
      newPassword: '',
      newPasswordConfirmation: '',
    },
  });
  const { errors, isSubmitting } = formState;

  const setErrors = (error: any) => {
    if (error?.data?.entityType === 'SetPasswordInputErrors') {
      Object.entries(error.data as SetPasswordInputErrors).forEach(([key, message]) => {
        if (key === 'newPassword' || key === 'newPasswordConfirmation') {
          setError(key, { message });
        }
      });
    } else if (typeof error?.data?.message === 'string') {
      // @ts-ignore
      setError('server', { message: error?.data?.message });
    }
  };

  const onSubmit = (event: FormEvent) => {
    clearErrors();
    handleSubmit(async (data) => {
      const res = await setPassword({
        resetHash,
        setPasswordParams: data,
      });
      if ('error' in res) {
        setErrors(res.error as any);
        return;
      }
      dispatch(
        addFlashMessage({
          type: 'success',
          text: 'Password changed.',
        }),
      );
      setAuth(res.data.token);
      history.push('/');
    })(event);
  };

  return (
    <Form style={{ padding: 20 }} onSubmit={onSubmit}>
      <h2>Reset Password</h2>
      <br />

      <div className="form-group">
        <label
          style={{ fontWeight: 'bold' }}
          className={classnames('control-label', {
            'text-danger': errors.newPassword,
          })}
        >
          Password
        </label>
        <input
          type="password"
          {...register('newPassword')}
          required
          className={classnames('form-control', { 'is-invalid': errors.newPassword })}
          autoComplete="new-password"
        />
        {errors.newPassword && <span className="text-danger">{errors.newPassword?.message}</span>}
      </div>

      <div className="form-group">
        <label
          style={{ fontWeight: 'bold' }}
          className={classnames('control-label', {
            'text-danger': errors.newPassword,
          })}
        >
          Confirm password
        </label>
        <input
          type="password"
          {...register('newPasswordConfirmation')}
          required
          className={classnames('form-control', { 'is-invalid': errors.newPasswordConfirmation })}
          autoComplete="new-password"
        />
        {errors.newPasswordConfirmation && (
          <span className="text-danger">{errors.newPasswordConfirmation?.message}</span>
        )}
      </div>
      {errors['server']?.message && <span className="text-danger">{errors['server'].message}</span>}
      <FormGroup>
        <Button color="primary" disabled={isSubmitting}>
          Reset
        </Button>
      </FormGroup>
    </Form>
  );
};

export default ResetPage;
