declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    red: {
      r1: string;
      r2: string;
      r3: string;
      r4: string;
      opacity: {
        r16: string;
        r32: string;
      };
    };
    green: {
      g4: string;
      opacity: {
        g16: string;
      };
    };
    blue: {
      b2: string;
      b3: string;
      b4: string;
      b5: string;
      opacity: {
        b16: string;
        b32: string;
      };
    };
    yellow: {
      y1: string;
      y2: string;
      y3: string;
      opacity: {
        y16: string;
      };
    };
    blueGray: {
      bg1: string;
      opacity: {
        bg16: string;
      };
    };
    gray: {
      g1: string;
      g2: string;
      g3: string;
      g6: string;
      g7: string;
      g8: string;
      g9: string;
      g10: string;
    };
    statusCards: {
      gray: string;
      green: string;
      blue: string;
      red: string;
      yellow: string;
    };
    state: {
      disabled: string;
      disabledText: string;
    };
  }
  interface PaletteOptions {
    red: {
      r1: string;
      r2: string;
      r3: string;
      r4: string;
      opacity: {
        r16: string;
        r32: string;
      };
    };
    green: {
      g4: string;
      opacity: {
        g16: string;
      };
    };
    blue: {
      b2: string;
      b3: string;
      b4: string;
      b5: string;
      opacity: {
        b16: string;
        b32: string;
      };
    };
    yellow: {
      y1: string;
      y2: string;
      y3: string;
      opacity: {
        y16: string;
      };
    };
    blueGray: {
      bg1: string;
      opacity: {
        bg16: string;
      };
    };
    gray: {
      g1: string;
      g2: string;
      g3: string;
      g6: string;
      g7: string;
      g8: string;
      g9: string;
      g10: string;
    };
    statusCards: {
      gray: string;
      green: string;
      blue: string;
      red: string;
      yellow: string;
    };
    state: {
      disabled: string;
      disabledText: string;
    };
  }
}

export default {};

export const palette = {
  primary: {
    main: '#007095',
    // light: will be calculated from palette.primary.main,
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    main: 'rgb(238, 138, 138)',
  },
  // grey: {
  //     main: "#EEE"
  // },
  // white: {
  //     main: "#FFF"
  // },
  // black: {
  //     main: "#000"
  // },
  // secondary: {
  //     // main: '#008181',
  //     // boxShadow: `${boxShadowDim} rgba(23, 170, 170, 0.4)`,
  //     // boxShadowHover: `${boxShadowHoverDim} rgba(23, 170, 170, 0.4)`
  //     // light: will be calculated from palette.primary.main,
  //     // dark: will be calculated from palette.primary.main,
  //     // contrastText: will be calculated to contrast with palette.primary.main
  // },
  // error: {
  //     // light: will be calculated from palette.primary.main,
  //     // dark: will be calculated from palette.primary.main,
  //     // contrastText: will be calculated to contrast with palette.primary.main
  // },
  // warning: {
  //     // light: will be calculated from palette.primary.main,
  //     // dark: will be calculated from palette.primary.main,
  //     // contrastText: will be calculated to contrast with palette.primary.main
  // },
  // // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  contrastText: '#FFF',
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,

  red: {
    r1: '#FA6B6B',
    r2: '#FB8989',
    r3: '#E08F8D',
    r4: '#FF5454',
    opacity: {
      r16: 'rgba(251, 137, 137, 0.16)',
      r32: 'rgba(199, 131, 149, 0.32)',
    },
  },
  green: {
    g4: '#65C898',
    opacity: {
      g16: 'rgba(101, 200, 152, 0.16)',
    },
  },
  blue: {
    b2: '#75B7D8',
    b3: '#4CA2CD',
    b4: '#DAD8ED',
    b5: '#6E61ED',
    opacity: {
      b16: 'rgba(117, 183, 216, 0.16)',
      b32: 'rgba(136, 131, 199, 0.32)',
    },
  },
  yellow: {
    y1: '#F2B744',
    y2: '#F1D06C',
    y3: '#EEC444',
    opacity: {
      y16: 'rgba(241, 208, 108, 0.16)',
    },
  },
  blueGray: {
    bg1: '#38566B',
    opacity: {
      bg16: '#dfe4e7',
    },
  },
  gray: {
    g1: '#333333',
    g2: '#4F4F4F',
    g3: '#F5F8FA',
    g6: '#F2F2F2',
    g7: '#F7F7F7',
    g8: '#A1A5B7',
    g9: '#E0E0E0',
    g10: '#7E8299',
  },
  statusCards: {
    gray: '#c9ced1',
    green: '#e7f4ee',
    blue: '#edf6fa',
    red: '#fbecec',
    yellow: '#f9f5e8',
  },
  state: {
    disabled: '#828282',
    disabledText: '#BDBDBD',
  },
};
