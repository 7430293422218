export default {
    red: {
        r1: '#FA6B6B',
        r2: '#FB8989',
        r3: '#E08F8D',
        r4: '#FF5454',
        opacity: {
            r16: 'rgba(251, 137, 137, 0.16)',
            r32: 'rgba(199, 131, 149, 0.32)',
        },
    },
    green: {
        g4: '#65C898',
        opacity: {
            g16: 'rgba(101, 200, 152, 0.16)',
        },
    },
    blue: {
        b2: '#75B7D8',
        b3: '#4CA2CD',
        b4: '#DAD8ED',
        b5: '#6E61ED',
        opacity: {
            b16: 'rgba(117, 183, 216, 0.16)',
            b32: 'rgba(136, 131, 199, 0.32)',
        },
    },
    yellow: {
        y1: '#F2B744',
        y2: '#F1D06C',
        y3: '#EEC444',
        opacity: {
            y16: 'rgba(241, 208, 108, 0.16)',
        },
    },
    blueGray: {
        bg1: '#38566B',
        opacity: {
            bg16: 'rgba(56, 86, 107, 0.16)',
        },
    },
    gray: {
        g1: '#333333',
        g2: '#4F4F4F',
        g3: '#F5F8FA',
        g6: '#F2F2F2',
        g7: '#F7F7F7',
        g8: '#A1A5B7',
        g9: '#E0E0E0',
        g10: '#7E8299',
    },
    statusCards: {
        gray: '#c9ced1',
        green: '#e7f4ee',
        blue: '#edf6fa',
        red: '#fbecec',
        yellow: '#f9f5e8',
    },
    state: {
        disabled: '#828282',
        disabledText: '#BDBDBD',
    },
};
