import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'store';
import { useGetCurrentUserQuery } from 'utils/wemble-api';

export const COMPANY_ADMIN_ROUTES = ['/offices', '/groups'];

const AuthRoute = ({
  render,
  admin,
  companyAdmin,
  ...rest
}: RouteProps & { admin?: boolean; companyAdmin?: boolean }) => {
  const { authenticated } = useAppSelector((state) => state.auth);
  const { data: user, isLoading } = useGetCurrentUserQuery();

  if (isLoading) return null;

  return (
    <Route
      {...rest}
      render={({ location, ...restRenderProps }) =>
        user?.companyAdmin && COMPANY_ADMIN_ROUTES.some((route) => location.pathname.startsWith(route))
          ? render && render({ location, ...restRenderProps })
          : authenticated &&
            (admin ? user?.admin || user?.administratorPriviliges : true) &&
            (companyAdmin ? user?.companyAdmin : true)
          ? render && render({ location, ...restRenderProps })
          : ({ location }) => (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
                from={location.pathname}
              />
            )
      }
    />
  );
};

export default AuthRoute;
