import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme, useTheme } from '@material-ui/core';
import { CompetenceDto, IUsersStatistics } from 'utils/wemble-api.generated';

const useStyles = makeStyles((theme) => ({
  overview: {
    backgroundColor: 'white',
    borderRadius: 16,
    height: '100%',
    padding: 10,
  },
  overview__table: {
    '& .MuiTableCell-root': {
      borderBottomColor: theme.palette.gray.g6,
    },
    '& .MuiTableCell-head': {
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.gray.g10,
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
  },
  competence__amount: {
    display: 'inline-block',
    width: 26,
    height: 26,
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '26px',
    color: 'white',
  },
  overview__title: {
    padding: '10px 10px 0',
    fontSize: 14,
    fontWeight: 900,
    color: theme.palette.gray.g1,
  },
}));

function handleMembersColor(index: number, theme: Theme) {
  switch (index) {
    case 0:
      return theme.palette.yellow.y2;
    case 1:
      return theme.palette.green.g4;
    case 2:
      return theme.palette.blueGray.bg1;
    case 3:
      return theme.palette.blue.b2;
    case 4:
      return theme.palette.red.r2;
    default:
      return theme.palette.state.disabled;
  }
}

function statusColor(status: number, theme: Theme) {
  switch (status) {
    case 0:
      return theme.palette.blue.b2;
    case 1:
      return theme.palette.green.g4;
    case 2:
      return theme.palette.yellow.y3;
    case 3:
      return theme.palette.red.r1;
    default:
      return theme.palette.state.disabled;
  }
}
const CompetenceOverview = ({
  data,
  competences,
}: {
  data: IUsersStatistics['competenceWorkload'];
  competences: CompetenceDto[];
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.overview}>
      <div>
        <Table className={classes.overview__table} size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '20%' }} align={'left'}>
                # of members
              </TableCell>
              <TableCell style={{ width: '60%' }} align={'left'}>
                Competence
              </TableCell>
              <TableCell style={{ width: '20%' }} align={'right'}>
                Avg. workload
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data)
              ?.sort(([, a], [, b]) => (b?.nbrOfUsers ?? 0) - (a?.nbrOfUsers ?? 0))
              .splice(0, 5)
              .map(([competenceId, statistics], index) => (
                <TableRow key={`competence-${competenceId}`} style={{ cursor: 'pointer' }}>
                  <TableCell style={{ width: '20%' }} align={'left'}>
                    <div
                      className={classes.competence__amount}
                      style={{
                        backgroundColor: handleMembersColor(index, theme),
                      }}
                    >
                      {statistics.nbrOfUsers}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: '60%', fontSize: 14, fontWeight: 500 }} align={'left'}>
                    {competences.find((c) => c._id === competenceId)?.name}
                  </TableCell>
                  <TableCell style={{ width: '20%' }} align={'right'}>
                    <span
                      style={{
                        color: statusColor(Math.floor(statistics.avgWorkload ?? 0), theme),
                      }}
                    >
                      {statistics.avgWorkload && !isNaN(statistics.avgWorkload)
                        ? (statistics.avgWorkload + 1).toFixed(1)
                        : ''}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.overview__title}>Competence overview</div>
    </div>
  );
};
export default CompetenceOverview;
