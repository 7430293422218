import { makeStyles, Theme, Tooltip, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import { CalendarItemType } from './Calendar';
import classNames from 'classnames';
import { statusBackgroundColor, statusColor } from 'components/Home/Forecast/utils';

const useStyles = (length) => makeStyles((theme) => ({
  calendar_item: {
    height: '50px',
    marginTop: 7,
    position: 'absolute',
    userSelect: 'none',
    overflow: 'hidden',
    border: '0.5px solid white'
  },
  calendar_item_start_end: {
    borderRadius: '12px',
  },
  calendar_item_start: {
    borderRadius: '12px 0 0 12px',
  },
  calendar_item_end: {
    borderRadius: '0 12px 12px 0',
  },
  calendar_item_editable: {
    cursor: 'pointer',
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.02, 1.02, 1)' },
  },
  placeholder: {
    height: '65px',
    marginTop: '6px',
  },
  title: {
    fontSize: length < 20 ? 10 : 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: length < 20 ? 9 : 10
    },
  },
  dateRange: {
    fontSize: length < 20 ? 8 : 10,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      fontSize: length < 20 ? 7 : 9,
    },
  },
  content: {
    padding: '4px',
    height: '50px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  tooltip: {
    '& > div': {
      height: 50,
      borderRadius: '14px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      margin: 0,
      marginTop: 5,
      padding: 8,
      boxShadow: theme.shadows[4]
    },
  },
}));

const CalendarItem = ({
  properties,
  isEditable,
  edit,
  startDate,
  length,
}: {
  properties: CalendarItemType;
  isEditable: boolean;
  edit: () => void;
  startDate: moment.Moment;
  length: number;
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isSmallMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const absenceEndDate = properties.endDate ?? moment(startDate).add(length + 1, 'days');
  const endDate = moment(startDate).add(length, 'days');

  const startInRange = properties.startDate.isSameOrAfter(startDate);
  const endInRange = absenceEndDate.isSameOrBefore(endDate);
  const inRange = properties.startDate.isBefore(endDate) && absenceEndDate.isAfter(startDate);

  const itemStartDate = startInRange ? properties.startDate : startDate;
  const itemEndDate = endInRange ? absenceEndDate : endDate;

  const isWeekend = (itemStartDate, offset = 0) => [6, 0].includes(moment(itemStartDate).add(offset, 'days').locale('en').weekday())
  const adjustedDayLength = (itemStartDate, offset = 0) => isWeekend(itemStartDate, offset) ? 0.15 : 1;
  const weekendAdjustedLength = (itemStartDate, dayLength) => {
    return [...Array(Math.max(dayLength, 0))].reduce((sum, _, i) => sum + adjustedDayLength(itemStartDate, i), 0)
  }


  console.log(weekendAdjustedLength(startDate, length))

  const offset = weekendAdjustedLength(startDate, itemStartDate.diff(startDate, 'days')) / weekendAdjustedLength(startDate, length) * 100;
  const itemLength = weekendAdjustedLength(itemStartDate, (itemEndDate.diff(itemStartDate, 'days') + 1)) / weekendAdjustedLength(startDate, length) * 100;

  const classes = useStyles(itemLength)();


  const borderStyle =
    startInRange && endInRange
      ? classes.calendar_item_start_end
      : startInRange
        ? classes.calendar_item_start
        : endInRange
          ? classes.calendar_item_end
          : '';

  if (!inRange) return null;

  return (
    <div
      className={classNames(classes.calendar_item, borderStyle, { [classes.calendar_item_editable]: isEditable })}
      style={{
        marginLeft: offset + '%',
        width: itemLength + '%',
        background: "#FFFC",
        color: statusColor(properties.status || 'away')
      }}
      onClick={() => isEditable && edit()}
    >
      <Tooltip
        classes={{
          popper: classes.tooltip,
        }}
        title={
          itemLength < 14 || isMobile ? (
            <>
              <span className={classes.title} >
                {properties.title}
              </span>
              <span className={classes.dateRange} >
                {`${properties.startDate.format('MM.DD')} - ${properties.endDate ? `${absenceEndDate.format('MM.DD')}` : ''
                  }`}
              </span>
            </>
          ) : (
            ''
          )
        }
      >
        <div className={classes.content}

          style={{
            background: statusBackgroundColor(properties.status || 'away'),
          }}
        >
          {(isSmallMobile ? itemLength > 25 : isMobile ? itemLength > 15 : itemLength > 3) && (
            <>
              <span className={classes.title} >
                {properties.title}
              </span>
              <span className={classes.dateRange} >
                {`${properties.startDate.format('MM.DD')} - ${properties.endDate ? `${absenceEndDate.format('MM.DD')}` : ''
                  }`}
              </span>
            </>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default CalendarItem;
