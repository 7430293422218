import { Checkbox, makeStyles } from '@material-ui/core';
import Input from 'components/shared/Input';
import { Formik } from 'formik';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { CompanyDto, useUpdateCompanySsoConfigMutation } from 'utils/wemble-api.generated';

const useStyles = makeStyles((theme) => ({
  description: {
    '&::placeholder': {
      color: '#878787',
    },
    backgroundColor: theme.palette.gray.g3,
    border: 'none',
    borderRadius: 4,
    width: '100%',
    position: 'relative',
    padding: '12px 16px',
    fontSize: '8px',
    fontWeight: 500,
    cursor: 'text',
    '&:focus': {
      outline: 'none',
    }
  }
}));


const SsoModal = ({
  isOpen,
  onClose,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  data?: CompanyDto | undefined;
}) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  const [updateCompanySmtpConfigMutation] = useUpdateCompanySsoConfigMutation();  


  // smtpHost: string;
  // smtpPort: string;
  // smtpSender: string;
  // smtpSecure: boolean;
  // smtpUser: string;
  // smtpPassword: string;


  data?.smtpSecure
  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="lg">
            <Formik initialValues={data ? {ssoLoginUrl: data.ssoLoginUrl,
ssoCertificate: data.ssoCertificate, allowFederation: data.allowFederation, allowGroupSelection: data.allowGroupSelection} : {}} onSubmit={ (values, submitProps)=> updateCompanySmtpConfigMutation({companySsoConfigParams: values}).then((_)=>submitProps.resetForm({values}))}
        
>
        {({ handleSubmit, values, setFieldValue, dirty }) => (
          <div>
        <ModalHeader toggle={handleClose}>{'SSO/SAML2 Config'}</ModalHeader>
        <ModalBody>
          <table className={'table'}>
            <tbody>
              <tr>
                <th scope="row" className={'control-label'}>
                  IdP SSO URL
                </th>
                <td>
                <Input placeholder={'Eg. https://login.microsoftonline.com/abc123/saml2'} name={'ssoLoginUrl'} type="text" /> 
                </td>
              </tr>
              <tr>
                <th scope="row" className={'control-label'}>
                IdP Signature Certificate
                </th>
                <td>
                <textarea
                value={values.ssoCertificate}
                className={classes.description}
                placeholder={"-----BEGIN CERTIFICATE-----\nabc123\n-----END CERTIFICATE-----"}
                onChange={(event) => {
                setFieldValue('ssoCertificate', event.target.value) 
                }}
                />
              </td>
              </tr>
              <tr>
                <th style={{width: 320 + 'px'}}scope="row" className={'control-label'}>
                  Automatic user provisioning
                  <br/>
                  <span style={{fontSize: 10, fontWeight: 400}}>Allow users who do not yet have an account to get one provisioned automatically on first login. If the attribute "title" is provided, the user will automatically get that title in wemble as well.</span>
                </th>
                <td>
                <Checkbox
                color="primary"
                checked={values.allowFederation}
                onChange={(event) => {
                setFieldValue('allowFederation', event.target.checked)          
                }}
                />
              </td>

              </tr>
              <tr>
                <th scope="row" className={'control-label'}>
                  Allow user to select group
                  <br/>
                  <span style={{fontSize: 10, fontWeight: 400, maxWidth: 200}}>If not checked, user accounts can only be provisioned if the attribute "department" matches the name of a group in the system </span>

                </th>
                <td>
                <Checkbox
                color="primary"
                checked={values.allowGroupSelection}
                onChange={(event) => {
                setFieldValue('allowGroupSelection', event.target.checked)          
                }}
                />
              </td>
              </tr>
              





              <tr>
                <th scope="row" className={'control-label'}>
                SP Assert URL
                <br/>
                <span style={{fontSize: 10, fontWeight: 400}}>
                Following attributes supported: title, department, EmailAddress. <br/>
                Attribute EmailAddress or name id set to users email is required.
                </span>

                </th>
                <td>
                {/*@ts-ignore*/}
                <Input disabled value={ ((window._env_ && window._env_.APP_LAUNCH_URL) || "https://www.wemble.app") + "/api/v2/company/" + data?._id + "/sso"} name="sp_url" type="text" /> 

              </td>
              

              </tr>
              <tr>
              </tr>

              </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>

          <Button color="primary" type="submit" disabled={!dirty} onClick={handleSubmit}>
            {'Update'}
          </Button>

        </ModalFooter>


              </div>
        )}
        </Formik>

    </Modal>
  );
};

export default SsoModal;
