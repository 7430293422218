export const typography = {
  fontFamily: 'Roboto, Source Sans Pro, sans-serif',
  h1: {
    fontWeight: 700,
    // fontSize: 50,
  },
  h2: {},
  h3: {},
  h4: {
    fontWeight: 700,
  },
  h5: {
    fontWeight: 700,
  },
  h6: {
    fontWeight: 500,
  },
  body: {},
};
