import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import OptionsComponent from '../users/OptionsComponent';
import { addFlashMessage } from 'store/reducers/flashMessages';
import { UserDto } from 'utils/wemble-api.generated';
import { useChangeUserTeamMutation } from 'utils/wemble-api';
import { useAppDispatch } from 'store';

const MoveUserModal = ({
  isOpen,
  handleClose,
  user,
  onAfterUserModified,
}: {
  isOpen: boolean;
  handleClose: () => void;
  user: UserDto;
  onAfterUserModified?: () => Promise<void> | void;
}) => {
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState({
    group: typeof user.group === 'string' ? user.group : user.group?._id ?? '',
    office: typeof user.office === 'string' ? user.office : user.office?._id ?? '',
    secondaryGroup: typeof user.secondaryGroup === 'string' ? user.secondaryGroup : user.secondaryGroup?._id ?? '',
    secondaryOffice: typeof user.secondaryOffice === 'string' ? user.secondaryOffice : user.secondaryOffice?._id ?? '',
  });
  const [secondaryEnabled, setSecondaryEnabled] = useState(
    fields.secondaryGroup.length > 0 && fields.secondaryOffice.length > 0,
  );

  const [changeUserTeam, { isLoading }] = useChangeUserTeamMutation();

  const handleUpdateField = (field: keyof typeof fields) => (value: string) => {
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleMoveUser = async () => {
    if (!user._id) return;

    try {
      await changeUserTeam({
        id: user._id,
        changeUserTeamParams: {
          office: fields.office.length > 0 ? fields.office : null,
          group: fields.group.length > 0 ? fields.group : null,
          secondaryEnabled,
          secondaryOffice: secondaryEnabled
            ? fields.secondaryOffice.length > 0
              ? fields.secondaryOffice
              : undefined
            : undefined,
          secondaryGroup: secondaryEnabled
            ? fields.secondaryGroup.length > 0
              ? fields.secondaryGroup
              : undefined
            : undefined,
        },
      });
      if (onAfterUserModified) await onAfterUserModified();
      dispatch(
        addFlashMessage({
          type: 'success',
          text: `Group successfully updated.`,
        }),
      );
      handleClose();
    } catch {
      dispatch(addFlashMessage({ type: 'error', text: `Something went wrong` }));
    }
  };
  return (
    <Modal
      size="md"
      isOpen={isOpen}
      toggle={() => {
        handleClose();
      }}
    >
      <ModalHeader
        toggle={() => {
          handleClose();
        }}
      >
        {user.name}
      </ModalHeader>

      <ModalBody>
        <h5>Primary group</h5>
        <div
          style={{
            width: '100%',
            float: 'left',
            marginTop: -15,
          }}
        >
          <OptionsComponent
            selectedOfficeId={fields.office}
            selectedGroupId={fields.group}
            setSelectedOffice={handleUpdateField('office')}
            setSelectedGroup={handleUpdateField('group')}
            allowEmptyValue={Boolean(user.companyAdmin)}
          />
        </div>

        <h5 style={{ marginTop: 60 + 'px', marginBottom: -15 + 'px' }}>Secondary group</h5>

        <div className="form-check" style={{ paddingLeft: 23 + 'px' }}>
          <div className=" position-static">
            <input
              className="form-check-input "
              style={{
                marginRight: '6px',
                marginTop: '26px',
              }}
              type="checkbox"
              name="returnDate"
              checked={secondaryEnabled}
              onChange={() => setSecondaryEnabled((prev) => !prev)}
            />
          </div>

          <div
            style={{
              opacity: secondaryEnabled ? 1.0 : 0.75,
              filter: secondaryEnabled ? '' : 'saturate(0)',
              transition: '0.1s ease-in-out',
              pointerEvents: secondaryEnabled ? 'auto' : 'none',
            }}
          >
            <OptionsComponent
              secondary
              selectedOfficeId={fields.secondaryOffice}
              selectedGroupId={fields.secondaryGroup}
              setSelectedOffice={handleUpdateField('secondaryOffice')}
              setSelectedGroup={handleUpdateField('secondaryGroup')}
              filterGroup={(group) => group._id !== fields.group}
              filterOffice={(office) => office._id !== fields.office}
            />
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            handleClose();
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>

        <Button color="primary" onClick={handleMoveUser} disabled={isLoading}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MoveUserModal;
