import { useEffect, useState } from 'react';
import { useAppSelector } from 'store';
import * as microsoftTeams from '@microsoft/teams-js';
import { useSignInAzureUserWithTokenMutation } from 'utils/wemble-api';
import jwt from 'jsonwebtoken';
import { useSetAuth } from 'hooks/index';
import { useHistory } from 'react-router-dom';
import { SignInAzureUserWithTokenApiResponse } from 'utils/wemble-api.generated';

// Not the ideal solution but should work for now. More info here: https://docs.microsoft.com/en-us/answers/questions/382718/detect-whether-website-is-being-hosted-within-a-te.html
const appIsInTeams = window.name === 'embedded-page-container' || window.name === 'extension-tab-frame';

export const useTeamsAuth = () => {
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const [isInTeams, setIsInTeams] = useState(appIsInTeams);
  const [clientType, setClientType] = useState("");
  const [signInTeamsUser] = useSignInAzureUserWithTokenMutation();
  const setAuth = useSetAuth();
  const [error, setError] = useState(null);
  const authenticated = useAppSelector((state) => state.auth.authenticated);

  useEffect(() => {
    const loadTeamsClient = async () => {

      if (initialized) return;

      if (authenticated) {

        try {
          console.log(await microsoftTeams.app.initialize())
          setInitialized(true);
        }
        catch (error) {
          console.log(error);
          return setInitialized(true);

        }
        setIsInTeams(true);

        try {
          const context = await microsoftTeams.app.getContext();
          setClientType(context.app.host.clientType);
        }
        catch (error) {
          console.log(error);
        }

        return;

      }

      try {
        await microsoftTeams.app.initialize()
        setIsInTeams(true);

        const context = await microsoftTeams.app.getContext();
        console.log(context)
        setClientType(context.app.host.clientType);

        const token = await microsoftTeams.authentication.getAuthToken()
        console.log(token)

        const res = await signInTeamsUser({
          body: {
            adToken: token,
            tenantId: context.user?.tenant?.id ?? '',
            adUserId: context.user?.id ?? '',
          },
        });
        if ('data' in res) {
          const data = res.data as SignInAzureUserWithTokenApiResponse;
          const user = jwt.decode(data.token);
          if (!user) return;
          if (user.completed) {
            setAuth(data.token);
            history.push('/');
          } else {
            history.push(`/signup/${user.id}?sso=true`);
          }
        } else {
          const error = res?.error as any;
          console.log(error)
          setError(error?.data?.message ?? null);
        }
        setInitialized(true);



      }
      catch (error) {
        console.log(error);
        setInitialized(true);
      }
    };
    loadTeamsClient();
  }, [signInTeamsUser, initialized, authenticated, setAuth, history]);

  return { initialized, isInTeams, clientType, error };
};
