import { makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  singleStat: {
    backgroundColor: theme.palette.state.disabled,
    padding: 10,
    borderRadius: 16,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  singleStat__data: {
    fontSize: 54,
    fontWeight: 900,
    color: theme.palette.state.disabledText,
    display: 'flex',
    alignItems: 'center',
  },
  singleStat__text: {
    margin: 0,
    marginTop: 10,
    color: theme.palette.gray.g1,
    fontSize: 14,
    fontWeight: 900,
  },
}));

const SingleStat = ({
  data,
  text,
  backgroundColor,
  color,
  ImageComponent,
}: {
  data: string;
  text: string;
  backgroundColor?: string;
  color?: string;
  ImageComponent?: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.singleStat} style={backgroundColor ? { backgroundColor } : undefined}>
        <div className={classes.singleStat__data} style={color ? { color } : undefined}>
          {ImageComponent ? ImageComponent : null}
          {data}
        </div>
        <p className={classes.singleStat__text}>{text}</p>
      </div>
    </>
  );
};
export default SingleStat;
