import { makeStyles, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useGetCurrentUserQuery, useGetMatterCommentsForMeQuery, useUpdateMatterCommentMutation, useUpdateMatterManagerMutation, useUpdateWorkloadMessageMutation } from "utils/wemble-api";
import { useDebounce, usePrevious } from 'hooks';
import { useEffect, useState, useRef } from "react";
import organization from "utils/organization";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F7F7F7",
    height: 220,
    position: 'relative',
    borderRadius: 24,
    transition: '0.2s',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    padding: '12px',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
  },

  message__input: {
    backgroundColor: '#fcfcfc',
    color: theme.palette.gray.g1,
    padding: '4px',
    width: '100%',
    height: '100%',
    border: '1px solid transparent', // Add a transparent border
    position: 'relative',
    resize: 'none',
    lineHeight: 1,
    fontWeight: 500,
    transition: 'all 0.15s',
    '&:hover': {
      borderRadius: 4,
    },
    '&:focus': {
      outline: 'none',
      boxShadow: '0 0 10px 5px rgba(0, 0, 0, 0.07)',
      backgroundColor: '#ffffff',
      transform: 'scale(1.02, 1.0)', // Scale x by 1.02 and y by 1.06
      zIndex: 1,
      borderRadius: 4,
    },
    '&::-webkit-scrollbar': {
      width: 0,
    },
    display: '-webkit-box',
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 11.8,
    minHeight: '34px', // Set a minimum height
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
    borderRadius: 0, // Reset border-radius for all textareas
    margin: 0, // Remove the negative margin
  },
  message__input_expanded: {
    fontSize: 12,
    lineHeight: 1.15,
    // Remove the fixed height
  },
  message__input_first: {
    borderTopLeftRadius: 8, // Reduced from 12
    borderTopRightRadius: 8, // Reduced from 12
    '&:hover, &:focus': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },
  message__input_last: {
    borderBottomLeftRadius: 8, // Reduced from 12
    borderBottomRightRadius: 8, // Reduced from 12
    '&:hover, &:focus': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  table: {
    '& .MuiTableCell-root': {
      fontSize: 11,
      borderBottomColor: "#CCCCCC",
      padding: 0, // Remove padding from table cells
      [theme.breakpoints.down('sm')]: {
        padding: 0, // Remove padding for small screens as well
      },
      overflow: 'visible', // Change from 'hidden' to 'visible'
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
    borderRadius: 8, // Reduced from 12 to match new input radius
    overflow: 'visible', // Change from 'hidden' to 'visible'
  },
  matter__cell: {
    maxWidth: '90px !important',
  },
  matter__manager: {
    backgroundColor: "#EEEEEE",
    padding: '2px 4px 2px 4px',
    marginLeft: '4px',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: 9
  },
  matter__manager_active: {
    backgroundColor: theme.palette.blueGray.bg1,
    color: 'white'
  },
  comment__cell: {
    backgroundColor: 'transparent', // Change to transparent
    padding: 0, // Ensure no padding in the comment cell
    height: '100%', // Ensure the cell takes full height
    overflow: 'visible', // Change from 'hidden' to 'visible'
  }

}));

const MatterComments = () => {
  const classes = useStyles();

  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: matterComments } = useGetMatterCommentsForMeQuery();
  const [updateMatterComment] = useUpdateMatterCommentMutation();
  const [updateMatterManager] = useUpdateMatterManagerMutation();

  const [value, setValue] = useState<{ comment: string, matterNumber: string }>({ comment: "", matterNumber: "" });
  const [debouncedValue] = useDebounce(value, 150);
  const prevDebouncedValue = usePrevious(debouncedValue);

  const [message, setMessage] = useState<string | undefined>();
  const [updateWorkloadMessage] = useUpdateWorkloadMessageMutation();
  const [debouncedMessage] = useDebounce(message);
  const prevDebouncedMessage = usePrevious(debouncedMessage);


  useEffect(() => {
    if (message === undefined && currentUser) {
      setMessage(currentUser.message?.message ?? '');
    }
  }, [message, currentUser]);

  useEffect(() => {
    if (debouncedMessage !== undefined && prevDebouncedMessage !== undefined) {
      updateWorkloadMessage({
        workloadMessageCreationParams: {
          message: debouncedMessage,
        },
      });
    }
  }, [debouncedMessage]);

  useEffect(() => {
    if (debouncedValue !== undefined && prevDebouncedValue !== undefined) {
      updateMatterComment({
        body: {
          ...value,
          userId: currentUser?._id || ""
        },
      });
    }
  }, [debouncedValue]);

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
          <MatterRow
            title={"General comment"}
            subtitle={currentUser?.name}
            messageLength={message?.length}
            manager={false}
            currentUser={currentUser}
            defaultValue={currentUser?.message?.message}
            setValue={(value) => setMessage(value)}
            updateMatterManager={() => { }}
            isFirst={true}
            isLast={matterComments?.length === 0}
          />
          {matterComments?.length ? matterComments.map((matter, index) => (
            <MatterRow
              key={matter.number}
              title={matter.displayName}
              subtitle={matter.clientDisplayName}
              messageLength={0}
              currentUser={currentUser}
              manager={matter.manager == currentUser?._id}
              defaultValue={matter.comment}
              setValue={(value) => setValue({ matterNumber: matter.number || "", comment: value })}
              updateMatterManager={() => updateMatterManager({ body: { matterNumber: matter.number || '', userId: currentUser?._id || '', value: !matter.manager } })}
              isFirst={false}
              isLast={index === matterComments.length - 1}
            />
          )) : (
            <TableRow>
              <TableCell colSpan={3}>No billable matters in the last 14 days found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

const MatterRow = ({ title, subtitle, manager, defaultValue, setValue, updateMatterManager, currentUser, messageLength, isFirst, isLast }) => {
  const classes = useStyles();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    // Prevent focus when clicking on the PM button
    if (event.target instanceof Element && !event.target.classList.contains(classes.matter__manager)) {
      textareaRef.current?.focus();
    }
  };

  return (
    <TableRow onClick={handleRowClick} >
      <TableCell colSpan={1} className={classes.matter__cell}>{title}
        {title != 'General comment' && organization(currentUser?.company).enableMatterPM && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              updateMatterManager();
            }}
            className={classes.matter__manager + " " + (manager ? classes.matter__manager_active : '')}
          >
            PM
          </span>
        )}
        <div style={{ opacity: 0.6, fontSize: 10, marginTop: -1 }}>{subtitle}</div>
      </TableCell>
      <TableCell colSpan={2} className={`${classes.comment__cell} ${isFirst ? classes.message__input_first : ''} ${isLast ? classes.message__input_last : ''}`}>
        <textarea
          ref={textareaRef}
          maxLength={messageLength ? 110 : 85}
          defaultValue={defaultValue}
          className={`${classes.message__input} 
                      ${messageLength > 80 ? classes.message__input_expanded : ""}
                      ${isFirst ? classes.message__input_first : ""}
                      ${isLast ? classes.message__input_last : ""}`}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              //Prevent line breaks
              event.preventDefault();
            }
          }}
          onClick={(e) => e.stopPropagation()} // Prevent row click event when clicking directly on textarea
        />
      </TableCell>
    </TableRow>
  );
};

export default MatterComments;
