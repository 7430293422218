import { Grid, makeStyles, Theme, useTheme } from '@material-ui/core';
import Profile from './Profile';
import moment from 'moment';
import { useGetForecastsQuery, useGetUsersQuery, usePrefetch } from 'utils/wemble-api';
import { StatusCardListOrderBy } from 'components/StatusCardList';
import { formattedDateRange, getToday, statusBackgroundColor, statusColor, statusTitle, useGetStatusForUser } from 'components/Home/Forecast/utils';

const useStyles = (commentLength: number, nameLength: number, forecastCommentLength: number, infoCard: boolean) => makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: 'Roboto',
    userSelect: 'none',
  },
  statusCard: {
    position: 'relative',
    padding: '10px',
    borderRadius: '20px',
    height: '242px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: '0.1s',
    '&:hover': {
      boxShadow: '4px 12px 20px rgba(0,0,0,0.04), -4px -4px 20px rgba(0,0,0,0.01)',
      cursor: 'pointer',
      transform: 'scale(1.02)'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      height: 'unset',
      padding: '6px 18px 6px 6px',
      '&:hover': {
        boxShadow: '2px 6px 10px rgba(0,0,0,0.04)',
      },
      borderRadius: '40px 20px 20px 40px',


      // padding: '8px 20px 12px 12px',

    },
  },
  statusCard__profile: {
    [theme.breakpoints.down('xs')]: {

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  statusCard__status: {
    textAlign: 'center',
    '& p': {
      fontWeight: 500,
      lineHeight: 1.1,
      marginBottom: 8,
    },
    [theme.breakpoints.up('sm')]: {
      '& p': {
        fontSize: commentLength > 50 ? 13.2 : 14,
      }
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      '& p': {
        fontSize: commentLength > 50 ? 11 : 13,
        marginBottom: 6
      }
    },

  },
  statusCard__status__expanded: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
    },
    '& p': {
      margin: 0,
    },
  },
  statusCard__avatar: {
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      marginTop: (commentLength > 38) ? -2 : (infoCard ? 4 : 8),
    },

  },
  profile__lastSeen: {
    position: 'absolute',
    marginTop: (commentLength > 38) ? 0 : (infoCard ? 2 : 4),
    marginRight: (commentLength > 38) ? 0 : (infoCard ? 2 : 4),
    right: 14,
    color: '#828282',
    fontSize: 11,
    fontWeight: 600,
    opacity: 0.8,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }

  },
  statusCard__container: {
    '& h3': {
      fontWeight: 600,
      margin: 0,
    },
    '& h2': {
      fontSize: 13,
      fontWeight: 400,
      color: '#828282',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      paddingTop: commentLength > 38 ? 4 : 8,
      '& h3': {
        fontSize: ((nameLength > 30 && commentLength)) ? 15 : 18,
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 8,
      '& h3': {
        fontSize: 14
      },
      '& h2': {
        marginBottom: 0,
        fontSize: 12
      }

    },
  },
  status__active: {
    fontSize: infoCard ? 18 : 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: infoCard ? 16 : 18,
    },
    fontWeight: 600,
  },
  status__message: {},
  statusCard__infoCard: {
    backgroundColor: 'white',
    borderRadius: '14px',
    fontSize: '12px',
    color: '#828282',
    padding: commentLength > 38 ? '3px' : '6px',
    [theme.breakpoints.down('xs')]: {
      padding: '2px 8px',
      fontSize: '7px',
      marginBottom: -8
    }

  },
  statusCard__infoCard__status: {
    fontSize: forecastCommentLength > 20 ? 12 : 13,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: forecastCommentLength > 15 ? 10.5 : 12,

    }
  }
  ,
  date__icon: {
    display: 'inline-block',
    position: 'relative',
    top: 2,
    marginLeft: 6,
    width: 16,
    height: 16,
    backgroundColor: 'red',
    borderRadius: '50%',
  },
}));


const StatusCard = ({
  handleClick,
  orderOption,
  id,
}: {
  id: string;
  handleClick: () => void;
  orderOption: StatusCardListOrderBy;
}) => {
  const prefetchUser = usePrefetch('getUserById');
  const prefetchSkills = usePrefetch('getLevelOfSkills');

  var { post: user } = useGetUsersQuery(
    undefined,
    {
      selectFromResult: ({ data }) => ({
        post: data?.find((u) => u._id === id),
      }),
    },
  );
  if (!user) return null;
  if (user.message?.message && !user.message?.message?.replaceAll(" ", "").replaceAll(".", "").length) user = { ...user, message: { ...user.message, message: "" } };

  const today = getToday()
  const status = useGetStatusForUser()(user._id)
  var comment = user.message?.message

  const { data: allForecasts } = useGetForecastsQuery(undefined, { refetchOnReconnect: true });
  const forecasts = allForecasts?.filter((forecast) => forecast.user == user?._id && moment(forecast.endDate) >= moment(today)).sort((a, b) => new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()) || []

  const currentForecast = forecasts.find(forecast => moment(forecast.startDate) <= moment(today))
  const nextForecast = forecasts.find(forecast => forecast.status != status)
  const forecast = (nextForecast || currentForecast)

  const classes = useStyles(forecast && comment?.length || 0, user.name?.length || 0, forecast?.comment?.length || (forecast?.status && statusTitle(forecast?.status).length) || 0, Boolean(forecast))();

  const diff = moment().diff(moment(user.lastUpdated), 'minutes')
  const timeAgo = diff <= 6.5 * 24 * 60 ? (diff >= 10 ? moment(user.lastUpdated).locale('en').fromNow().replace('minutes', 'min') : 'now') : '7+ days ago'

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} className={classes.root}>
      <div
        className={classes.statusCard}
        style={{
          backgroundColor: statusBackgroundColor(status),
        }}
        onClick={handleClick}
        onMouseEnter={() => { prefetchUser({ id }, { ifOlderThan: 180 }); prefetchSkills(undefined, { ifOlderThan: 1000 }) }}
      >
        <div className={classes.profile__lastSeen}>
          {orderOption === 'experience'
            ? user.startYear
              ? new Date().getFullYear() - user.startYear > 0
                ? new Date().getFullYear() - user.startYear + ' years'
                : 'first year'
              : ''
            : user.lastUpdated && !user.onLeave && timeAgo}
        </div>

        <div className={classes.statusCard__profile}>
          <div className={classes.statusCard__avatar}>
            <Profile onLeave={Boolean(user.onLeave)} data={user} />
          </div>
          <div className={classes.statusCard__container}>
            <h3>{user.name}</h3>
            <h2>{user.title}</h2>
          </div>

        </div>


        <div className={`${classes.statusCard__status} ${!forecast && classes.statusCard__status__expanded}`} >
          <p>
            {comment && (<span className={classes.status__message}>{comment}</span>)}
            {!comment && (<span className={classes.status__active} style={{ color: statusColor(status) }}>{currentForecast?.comment || statusTitle(status)}</span>)}
          </p>
          {forecast && (<div className={classes.statusCard__infoCard}>
            <span className={classes.statusCard__infoCard__status} style={{ color: statusColor(forecast.status) }}>{forecast?.comment || statusTitle(forecast.status)}</span>
            {` ${formattedDateRange(forecast)}`}
          </div>)}

        </div>
      </div>
    </Grid >
  );
};

export default StatusCard;