import { ReactNode, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from 'theme/theme';
import { useAppDispatch, useAppSelector } from 'store';
import ReactGA from 'react-ga';
import { setCredentials } from 'store/reducers/auth';
import { useGetCurrentUserQuery, useRefreshTokenQuery, api } from 'utils/wemble-api';

import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { UserDto } from 'utils/wemble-api.generated';

import { usePrevious } from 'hooks';
import io from "socket.io-client";
import moment from 'moment';


const App = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const initialized = useAppSelector((state) => state.auth.initialized);

  const authenticated = useAppSelector((state) => state.auth.authenticated);
  const { refetch: refetchUser } = useGetCurrentUserQuery();
  const { refetch: refetchToken } = useRefreshTokenQuery();
  const prevAuthenticated = usePrevious(authenticated);

  const connectSocket = () => {
    var socket = io({ query: { token: localStorage.getItem('token') } });

    socket.on('error', function (error) {
      console.log(error);
    });

    socket.on("connect_error", (error) => {
      console.log(error);
    });

    socket.on("UPDATE_USER", function (user) {

      let skip = false;

      const updateUserDraft = (draft: MaybeDrafted<UserDto | null | undefined>) => {
        if (!draft || skip) return;
        draft.currentWorkload = user.currentWorkload;
        draft.futureWorkload = user.futureWorkload;
        draft.message = user.message;
        draft.lastUpdated = user.lastUpdated;
        draft.workplaceForecast = user.workplaceForecast;
        draft.atHome = user.atHome;
        draft.onLeave = user.onLeave;
      };


      dispatch(
        // @ts-ignore
        api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
          console.log("IDD")
          console.log(draft?._id)

          if (draft?._id == user._id) {
            skip = (window as any)._workloadLastUpdated_ && moment().diff(moment((window as any)._workloadLastUpdated_), 's') < 5
            updateUserDraft(draft);
          }
        }),
      );

      dispatch(
        // @ts-ignore
        api.util.updateQueryData('getUsers', undefined, (draftUsers) => {
          updateUserDraft(draftUsers?.find((u) => u._id === user._id));
        }),
      );

    });
  }

  useEffect(() => {
    window['_IosAppEnterForeground'] = () => {
      // @ts-ignore
      dispatch(api.util.updateQueryData('getCurrentUser', undefined, (draft) => {
        if (draft) {
          // @ts-ignore
          dispatch(api.util.invalidateTags([{ type: 'User', id: "ME" }, { type: 'User', id: draft._id }]))
          // @ts-ignore
          setTimeout(() => dispatch(api.util.invalidateTags([{ type: "Competence" }, { type: "Vacation" }, { type: "Project" }, { type: "Matter" }])), 300)


        }
      }))


    }
      ;
  }, []);

  useEffect(() => {
    if (!prevAuthenticated && authenticated) {
      refetchUser();
      connectSocket()

    }
  }, [authenticated, prevAuthenticated, refetchUser]);

  useEffect(() => {
    if (initialized) {
      refetchToken();
    }
  }, [initialized]);

  useEffect(() => {
    ReactGA.pageview(pathname);
    try {
      (window as any).webkit?.messageHandlers?.setLocation?.postMessage(window.location.pathname);
    } catch { }
  }, [pathname]);

  // Scrolls to top if navigating forward.
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (!initialized) {
      dispatch(setCredentials(localStorage.getItem('token') ?? false));
    }
  }, [initialized]);


  if (!initialized) return null;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default App;
