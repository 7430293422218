import { Grid, makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  overview: {
    backgroundColor: 'white',
    padding: '10px 10px 0',
    borderRadius: 16,
    height: '100%',
  },
  overview__toolbar: {
    marginTop: 4,
    paddingBlock: 10,
    alignItems: 'center',
  },
  overview__title: {
    fontSize: 14,
    fontWeight: 900,
  },
  overview__showMore: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  showMore__button: {
    display: 'flex',
    backgroundColor: theme.palette.gray.g6,
    padding: '8px 16px',
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    '& > img': {
      transform: 'rotate(90deg)',
      marginRight: 10,
    },
    '& > div': {
      fontWeight: 500,
      fontSize: 14,
    },
  },
}));

const StatisticOverview = ({
  title,
  children,
  showMore,
}: {
  title: string;
  showMore: () => void;
  children: ReactNode;
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container direction="column" justify="space-between" className={classes.overview}>
        <Grid container spacing={2}>
          {children}
        </Grid>
        <Grid container className={classes.overview__toolbar}>
          <Grid item xs={6} className={classes.overview__title}>
            {title}
          </Grid>
          <Grid item xs={6} className={classes.overview__showMore}>
            <div className={classes.showMore__button} onClick={showMore}>
              <div>View all</div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default StatisticOverview;
