import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, useTheme } from '@material-ui/core';
import Avatar from 'components/shared/members/Avatar';
import { compareNumbers, compareString } from 'helpers/compareFunctions';
import { useEffect, useState } from 'react';
import organization from 'utils/organization';
import { useGetCurrentUserQuery, useGetMatterCommentsForMeQuery, useGetUsersQuery, useUpdateMatterManagerMutation } from 'utils/wemble-api';
import { DetailedReport, UserDetailed, UserDto } from 'utils/wemble-api.generated';
import { formattedDateRange, statusColor, statusTitle, useGetCurrentForecastForUser, useGetNextForecastForUser, useGetStatusForUser } from './Forecast/utils';
import VacationPlanner from 'components/vacationPlanner/VacationPlanner';


export const titleRank = (companyId, title) => {
    switch (companyId) {
        case '650197f48213ce0e6b0e5045':
            return {
                'CEO & COO': 7,
                'Team Leader': 6,
                'Lawyer/Advokat': 5,
                'Lawyer': 4,
                'Student': 3,
                '': 0
            }[title] || 0

        case '5da0c1370b30f993a68d898f':
            return {
                'Senior Adviser': 16,
                'Specialist Partner': 15,
                'Counsel': 14,
                'Counsel, Head of Public Procurement': 13, // Unsure if needed, added just in case
                'Managing Associate': 12,
                'Senior Associate': 11,
                'Associate': 10,
                'Junior Associate': 9,
                'Associate Trainee': 8,
                'Trainee': 7,
                'Senior Legal Assistant': 6,
                'Executive Assistant': 5,
                'Legal Assistant': 4,
                'Legal Assistant Trainee': 3,
                'Legal AI Analyst': 2,
                'Senior Partner': 1,
                'Managing Partner': 0,
                '': 0 // Assuming a missing or empty title should have the lowest rank
            }[title] || 0
        default:
            return 0

    }
};



const getSortFunction = () => {

    const getNextForecastForUser = useGetNextForecastForUser();
    const getStatusForUser = useGetStatusForUser()
    const { data: currentUser } = useGetCurrentUserQuery();


    return (sortMethod) => (tkA: UserDetailed, tkB: UserDetailed) => {

        const getStatus = (userId) => {
            const status = getStatusForUser(userId)

            if (!status) return 5;
            if (status == 'away') return 4;
            return parseInt(status);
        };

        const getForecastedWorkload = (userId) => {
            const forecast = getNextForecastForUser(userId)
            // If no forecast date is set, return 5 to ensure it's sorted last
            if (!forecast?.status) return 5;
            if (forecast?.status == 'away') return 4;
            return parseInt(forecast?.status);
        };

        switch (sortMethod) {
            case 'NAME':
                return compareString(currentUser?.name == tkA.displayName ? "AAA" : tkA.displayName, currentUser?.name == tkB.displayName ? "AAA" : tkB.displayName);
            case 'TITLE':
                const rankA = titleRank(currentUser?.company, tkA.title)
                const rankB = titleRank(currentUser?.company, tkB.title)
                return compareNumbers(rankB, rankA);
            case 'STATUS':
                return compareNumbers(getStatus(tkA.userId), getStatus(tkB.userId));
            case 'FORECAST':
                return compareNumbers(getForecastedWorkload(tkA.userId), getForecastedWorkload(tkB.userId));
            case 'LEAD PARTNER':
                return compareString(
                    tkA.matters && tkA.matters.length > 0 ? tkA.matters[0]?.resposibleTimekeeperName : 'zzz',
                    tkB.matters && tkB.matters.length > 0 ? tkB.matters[0]?.resposibleTimekeeperName : 'zzz'
                );
            default:
                return 0;
        }
    }
};

const matterSortFunction = (sortMethod) => (matterA: any, matterB: any) => {
    switch (sortMethod) {
        case 'NAME':
            return compareString(matterA.displayName, matterB.displayName);
        case 'CLIENT':
            return compareString(matterA.clientDisplayName, matterB.clientDisplayName);
        case 'HOURS':
            return compareNumbers(matterB.totalHours, matterA.totalHours);
        case 'LEAD PARTNER':
            return compareString(
                matterA.resposibleTimekeeperName,
                matterB.resposibleTimekeeperName
            );
        default:
            return 0;
    }
};


const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Roboto',
    },
    utilization__content: {
        backgroundColor: 'white',
        padding: '20px 16px',
        [theme.breakpoints.down('sm')]: {
            padding: 12
        },
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    filled__cell: {
        backgroundColor: '#fafafa'
    },
    all__toggle: {
        backgroundColor: "#EEEEEE", padding: "8px 0px", textAlign: 'center', borderRadius: 10, cursor: 'pointer'
    },
    no__border: {
        borderBottomColor: 'white !important',
    },
    table: {
        '& .MuiTableCell-root': {
            fontSize: 12,
            borderBottomColor: theme.palette.gray.g9,
            padding: '4.5px 10px',
            maxWidth: 320,
            [theme.breakpoints.down('sm')]: {
                padding: '4px 8px',
            },
        },
        '& .MuiTableCell-head': {
            fontSize: 12,
            textTransform: 'uppercase',
            color: theme.palette.gray.g10,
            padding: '10px 10px',
        },
        '& .MuiTableCell-body': {
            fontWeight: 500,
        },
        '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
            border: 'none',
        },
    },
    matter__manager: {
        backgroundColor: "#EEEEEE",
        padding: '2px 4px 2px 4px',
        marginLeft: '4px',
        borderRadius: '6px',
        fontSize: 9,
        cursor: 'pointer'
    },
    matter__manager_active: {
        backgroundColor: theme.palette.blueGray.bg1,
        color: 'white'
    },
}));

const DetailedTable = ({ detailedReport, filterTerm, filteredUsers, setLayout }: { detailedReport: DetailedReport, filterTerm: string, filteredUsers?: UserDto[], setLayout: (layout: string) => void; }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { data: matterComments } = useGetMatterCommentsForMeQuery();
    const { data: currentUser } = useGetCurrentUserQuery();
    const { data: users } = useGetUsersQuery();
    const [timekeeperSortMethod, setTimekeeperSortMethod] = useState<string>(localStorage.getItem("DetailedPageTimekeeperSortMethod") || 'NAME');
    const [matterSortMethod, setMatterSortMethod] = useState<string>(localStorage.getItem("DetailedPageMatterSortMethod") || 'HOURS');

    const enableHours = organization(currentUser?.company).enableDetailedViewHours && (currentUser?.detailedViewHoursPriviliges || ((currentUser?.admin || currentUser?.administratorPriviliges || currentUser?.companyAdmin) && organization(currentUser?.company).name == "Synch"))
    const [matterLayout, setMatterLayout] = useState<boolean>(localStorage.getItem("DetailedPageLayout") === 'matter');

    const [vacationPlanner, setVacationPlanner] = useState<string | undefined>(undefined);

    useEffect(() => {
        localStorage.setItem("DetailedPageTimekeeperSortMethod", timekeeperSortMethod)
        localStorage.setItem("DetailedPageMatterSortMethod", matterSortMethod)
    }, [timekeeperSortMethod, matterSortMethod])

    useEffect(() => {
        localStorage.setItem("DetailedPageLayout", matterLayout ? 'matter' : 'timekeeper')
        setLayout(matterLayout ? 'matter' : 'timekeeper')
    }, [matterLayout, detailedReport])

    const sortFunction = getSortFunction();

    const uniqueMatters = detailedReport && detailedReport.reduce((acc, tk) => {
        [...tk.matters || []].forEach(matter => {
            if (matter.number && (matter.displayName?.toLowerCase().includes(filterTerm.toLowerCase()) || matter.clientDisplayName?.toLowerCase().includes(filterTerm.toLowerCase()))) {
                if (!acc[matter.number]) {
                    acc[matter.number] = { ...matter, timekeepers: [], totalHours: 0 };

                }
                acc[matter.number].timekeepers = [...acc[matter.number].timekeepers, { ...tk, comment: matter.comment, hours: matter.totalHours }].sort((a, b) => compareNumbers(b.hours + (b.comment ? 1000 : 0), a.hours + (a.comment ? 1000 : 0)))
                acc[matter.number].totalHours += matter.totalHours
            }
        });
        return acc;
    }, {}) as any;

    const today = new Date(new Date().setHours(0, 0, 0, 0));


    return (
        <div className={classes.utilization__content}>
            <h1 style={{ fontSize: 26, fontWeight: 600, marginBottom: '16px' }}>
                <span onClick={() => setMatterLayout(false)} style={{ color: !matterLayout ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>Timekeepers</span>
                &nbsp;|&nbsp;
                <span onClick={() => setMatterLayout(true)} style={{ color: matterLayout ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>Matters</span>
            </h1>

            <TableContainer>
                <Table className={classes.table}>
                    {!matterLayout ? (<>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <span onClick={() => setTimekeeperSortMethod('NAME')} style={{ color: timekeeperSortMethod === 'NAME' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>NAME</span>
                                    &nbsp;/&nbsp;
                                    <span onClick={() => setTimekeeperSortMethod('TITLE')} style={{ color: timekeeperSortMethod === 'TITLE' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>TITLE</span>
                                </TableCell>

                                <TableCell onClick={() => setTimekeeperSortMethod('STATUS')} style={{ color: timekeeperSortMethod == 'STATUS' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>STATUS</TableCell>
                                <TableCell onClick={() => setTimekeeperSortMethod('FORECAST')} style={{ color: timekeeperSortMethod == 'FORECAST' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>FORECAST</TableCell>
                                <TableCell className={classes.filled__cell} onClick={() => setTimekeeperSortMethod('LEAD PARTNER')} style={{ color: timekeeperSortMethod == 'LEAD PARTNER' ? '#4e505d' : '#7E8299', cursor: 'pointer', borderTopLeftRadius: '12px' }}>LEAD</TableCell>
                                <TableCell className={classes.filled__cell} >MATTERS&nbsp;(LAST&nbsp;{organization(currentUser?.company).mattersTimeperiod}&nbsp;DAYS)</TableCell>
                                {enableHours && (<TableCell className={classes.filled__cell} style={{ borderTopRightRadius: organization(currentUser?.company).enableDetailedViewComments ? 0 : '12px' }}>HOURS</TableCell>)}
                                {organization(currentUser?.company).enableDetailedViewComments && (<TableCell className={classes.filled__cell} style={{ borderTopRightRadius: '12px' }}>COMMENT</TableCell>)}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {detailedReport && [...detailedReport].filter(user => user.displayName?.toLocaleLowerCase().includes(filterTerm.toLocaleLowerCase()) || user.title?.toLocaleLowerCase().includes(filterTerm.toLocaleLowerCase())).sort(sortFunction(timekeeperSortMethod)).map((timekeeper) => {
                                const matters = timekeeper.matters?.slice().sort((a, b) => compareNumbers(b.totalHours, a.totalHours))
                                return (
                                    <>
                                        <TableRow>
                                            <TableCell className={(((matters?.length || 0) > 1) || users?.find(user => user._id == timekeeper.userId)?.message?.message) ? classes.no__border : ''}>
                                                <Avatar size={42} image={timekeeper.profilePicture} name={timekeeper.displayName} email={timekeeper.title} />
                                            </TableCell>
                                            <UserStatus className={(((matters?.length || 0) > 1) || users?.find(user => user._id == timekeeper.userId)?.message?.message) ? classes.no__border : ''} userId={timekeeper.userId} setVacationPlanner={setVacationPlanner} />

                                            {(matters?.length && matters[0]) ? (
                                                <>
                                                    <TableCell className={classes.filled__cell}>{matters[0].resposibleTimekeeperName}</TableCell>
                                                    <TableCell className={classes.filled__cell}>{matters[0].displayName}<MatterPM matter={matters[0]} userId={timekeeper.userId} /> <br /><span style={{ opacity: 0.5, fontSize: 10.5 }}>{matters[0].clientDisplayName}</span></TableCell>
                                                    {enableHours && (<TableCell className={classes.filled__cell}>{matters[0].totalHours?.toFixed(1)}</TableCell>)}
                                                    {organization(currentUser?.company).enableDetailedViewComments && (<TableCell className={classes.filled__cell}>{timekeeper.userId == currentUser?._id ? matterComments?.find(m => m.number == matters![0].number)?.comment : matters[0].comment}</TableCell>)}
                                                </>
                                            ) : (<>{Array((enableHours && organization(currentUser?.company).enableDetailedViewComments) ? 4 : 3).fill(1).map(() => (<TableCell className={classes.filled__cell} />))}</>)}

                                        </TableRow>
                                        {matters?.slice(1)?.map((matter, i) => (
                                            <TableRow>
                                                <TableCell className={(i + 2 !== matters?.length || users?.find(user => user._id == timekeeper.userId)?.message?.message) ? classes.no__border : ''} />

                                                <TableCell className={(i + 2 !== matters?.length || users?.find(user => user._id == timekeeper.userId)?.message?.message) ? classes.no__border : ''} />
                                                <TableCell className={(i + 2 !== matters?.length || users?.find(user => user._id == timekeeper.userId)?.message?.message) ? classes.no__border : ''} />
                                                <TableCell className={classes.filled__cell}>{matter.resposibleTimekeeperName}</TableCell>
                                                <TableCell className={classes.filled__cell}>
                                                    {matter.displayName}

                                                    <MatterPM matter={matter} userId={timekeeper.userId} /><br /><span style={{ opacity: 0.5, fontSize: 10.5, marginTop: -2 }}>{matter.clientDisplayName}</span></TableCell>
                                                {enableHours && (<TableCell className={classes.filled__cell}>{matter.totalHours?.toFixed(1)}</TableCell>)}
                                                {organization(currentUser?.company).enableDetailedViewComments && (<TableCell className={classes.filled__cell}>{timekeeper.userId == currentUser?._id ? matterComments?.find(m => m.number == matter.number)?.comment : matter.comment}</TableCell>)}

                                            </TableRow>
                                        ))}
                                        {users?.find(user => user._id == timekeeper.userId)?.message?.message && (
                                            <TableRow>
                                                <TableCell />
                                                <TableCell />
                                                <TableCell />

                                                <TableCell className={classes.filled__cell} />
                                                <TableCell className={classes.filled__cell} style={{ color: theme.palette.gray.g10 }}>General comment</TableCell>

                                                <TableCell colSpan={(enableHours && organization(currentUser?.company).enableDetailedViewComments) ? 3 : 2} className={classes.filled__cell}>{users?.find(user => user._id == timekeeper.userId)?.message?.message}</TableCell>


                                            </TableRow>
                                        )}



                                    </>

                                )
                            })}

                        </TableBody>
                    </>) : (<>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <span onClick={() => setMatterSortMethod('NAME')} style={{ color: matterSortMethod === 'NAME' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>MATTER</span>
                                    &nbsp;/&nbsp;
                                    <span onClick={() => setMatterSortMethod('CLIENT')} style={{ color: matterSortMethod === 'CLIENT' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>CLIENT</span>
                                </TableCell>
                                <TableCell onClick={() => setMatterSortMethod('LEAD PARTNER')} style={{ color: matterSortMethod == 'LEAD PARTNER' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>LEAD PARTNER</TableCell>
                                {enableHours && (<TableCell onClick={() => setMatterSortMethod('HOURS')} style={{ color: matterSortMethod == 'HOURS' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>HOURS&nbsp;(LAST&nbsp;{organization(currentUser?.company).mattersTimeperiod}&nbsp;DAYS)</TableCell>)}
                                <TableCell className={classes.filled__cell} style={{ borderTopLeftRadius: '12px' }}>TIMEKEEPER</TableCell>
                                {enableHours && (<TableCell className={classes.filled__cell}>HOURS</TableCell>)}
                                <TableCell className={classes.filled__cell}>COMMENT</TableCell>
                                <TableCell className={classes.filled__cell}>STATUS</TableCell>
                                <TableCell className={classes.filled__cell} style={{ borderTopRightRadius: '12px' }}  >FORECAST</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(uniqueMatters).sort(matterSortFunction(matterSortMethod)).map((matter: any) => (
                                <>
                                    <TableRow>
                                        <TableCell className={(matter.timekeepers?.length > 1) ? classes.no__border : ''}>{matter.displayName}<br /><span style={{ opacity: 0.5 }}>{matter.clientDisplayName}</span></TableCell>
                                        <TableCell className={(matter.timekeepers?.length > 1) ? classes.no__border : ''}>{matter.resposibleTimekeeperName}</TableCell>
                                        {enableHours && (<TableCell className={(matter.timekeepers?.length > 1) ? classes.no__border : ''}>{matter.totalHours.toFixed(1)}</TableCell>)}


                                        {(matter.timekeepers?.length && matter.timekeepers[0]) ? (
                                            <>
                                                <TableCell className={classes.filled__cell} ><Avatar size={40} image={matter.timekeepers[0].profilePicture} name={matter.timekeepers[0].displayName} email={matter.timekeepers[0].title} item={<MatterPM matter={matter} userId={matter.timekeepers[0].userId} />} /></TableCell>
                                                {enableHours && (<TableCell className={classes.filled__cell}>{matter.timekeepers[0].hours.toFixed(1)}</TableCell>)}

                                                <TableCell className={classes.filled__cell}>{matter.timekeepers[0].userId == currentUser?._id ? matterComments?.find(m => m.number == matter.number)?.comment : matter.timekeepers[0].comment}</TableCell>
                                                <UserStatus className={classes.filled__cell} userId={matter.timekeepers[0].userId} setVacationPlanner={setVacationPlanner} />
                                            </>
                                        ) : (<><TableCell className={classes.filled__cell} /><TableCell className={classes.filled__cell} /><TableCell className={classes.filled__cell} /></>)}


                                    </TableRow>
                                    {matter.timekeepers?.slice(1)?.map((timekeeper, i) => (
                                        <TableRow>
                                            <TableCell className={i + 2 !== matter.timekeepers?.length ? classes.no__border : ''} />
                                            <TableCell className={i + 2 !== matter.timekeepers?.length ? classes.no__border : ''} />
                                            {enableHours && (<TableCell className={i + 2 !== matter.timekeepers?.length ? classes.no__border : ''} />)}
                                            <TableCell className={classes.filled__cell}><Avatar size={40} image={timekeeper.profilePicture} name={timekeeper.displayName} email={timekeeper.title} item={<MatterPM matter={matter} userId={timekeeper.userId} />} /></TableCell>
                                            {enableHours && (<TableCell className={classes.filled__cell}>{timekeeper.hours.toFixed(1)}</TableCell>)}
                                            <TableCell className={classes.filled__cell}>{timekeeper.userId == currentUser?._id ? matterComments?.find(m => m.number == matter.number)?.comment : timekeeper.comment}</TableCell>
                                            <UserStatus className={classes.filled__cell} userId={timekeeper.userId} setVacationPlanner={setVacationPlanner} />

                                        </TableRow>
                                    ))}

                                </>

                            ))}

                        </TableBody>


                    </>)}


                </Table>
            </TableContainer>

            {users && (<VacationPlanner
                isOpen={Boolean(vacationPlanner)}
                close={() => setVacationPlanner(undefined)}
                selectedUser={vacationPlanner}
                users={filteredUsers} />)}

        </div>
    );
};



const UserStatus = ({ userId, className, setVacationPlanner }) => {
    const status = useGetStatusForUser()(userId)
    const nextForecast = useGetNextForecastForUser()(userId)
    const currentForecast = useGetCurrentForecastForUser()(userId)


    return (<>
        <TableCell className={className}>
            <><span style={{ color: statusColor(status), fontWeight: 800 }}>{statusTitle(status)}</span>
                {currentForecast && (<div style={{ opacity: 0.5, fontSize: 10, whiteSpace: 'nowrap' }}>{formattedDateRange(currentForecast!)} </div>)}
            </></TableCell>
        <TableCell className={className} onClick={() => setVacationPlanner(userId)} style={{ cursor: nextForecast ? 'pointer' : 'default' }}>
            {nextForecast && (<>
                <span style={{ color: statusColor(nextForecast!.status), fontWeight: 800 }}>{nextForecast!.comment ? nextForecast!.comment : statusTitle(nextForecast!.status)}</span>
                <div style={{ opacity: 0.5, fontSize: 10, whiteSpace: 'nowrap' }}>{formattedDateRange(nextForecast!)} </div></>)}

        </TableCell>


    </>
    )
}

const MatterPM = ({ matter, userId }) => {
    const classes = useStyles();
    const [updateMatterManager] = useUpdateMatterManagerMutation();
    const isManager = matter.manager == userId
    const { data: currentUser } = useGetCurrentUserQuery();

    if (!organization(currentUser?.company).enableMatterPM) return <></>
    return (
        <span
            className={classes.matter__manager + ' ' + (isManager && classes.matter__manager_active)}
            onClick={() => updateMatterManager({
                body: {
                    matterNumber: matter.number || '', userId: userId || '',
                    value: !isManager
                }
            })}>
            PM
        </span>

    )
}
export default DetailedTable;
export { MatterPM, UserStatus };
