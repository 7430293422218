import { Link } from 'react-router-dom';
import AvatarRow from 'components/AvatarRow';
import ProjectLeader from 'components/projects/ProjectLeader';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { GroupDto, OfficeDto, ProjectWithUsersDto, UserDto } from 'utils/wemble-api.generated';
import ProjectInfoBox from './ProjectInfoBox';
import organization from 'utils/organization';
import { usePrefetch } from 'utils/wemble-api';

const useStyles = makeStyles((theme) => ({
  project: {
    backgroundColor: 'white',
    padding: '10px 14px',
    margin: '14px 0',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: '0.2s',
    '&:hover': {
      boxShadow: '4px 4px 20px rgba(0,0,0,0.05), -4px -4px 20px rgba(0,0,0,0.01)',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  project__left: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  project__title: {
    fontSize: 22,
    fontWeight: 500,
  },
  project__tags: {
    display: 'flex',
    marginTop: 6,
    '& > div': {
      backgroundColor: theme.palette.gray.g3,
      color: theme.palette.blueGray.bg1,
      borderRadius: 4,
      margin: '0 10px 0 0',
      padding: '0 6px',
      fontWeight: 500,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      '&:nth-last-child(1)': {
        marginRight: 0,
      },
    },
  },
  project__right: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
}));

const nullCheckManager = (manager: UserDto, users: UserDto[] | null | undefined) => {
  if (manager.name === undefined && users) {
    return users.find((u) => u._id === manager._id);
  }
  return manager;
};

const formatDate = (date) => {
  return date.toString().substr(0, 10).replaceAll('-', '.');
};

const getName = (
  group: GroupDto | null | undefined,
  office: OfficeDto | null | undefined,
  groups: GroupDto[] | null | undefined,
  offices: OfficeDto[] | null | undefined,
) => {
  if (group?.name === undefined || office?.name === undefined) {
    return groups?.find((grp) => grp._id === group)?.name + ' - ' + offices?.find((off) => off._id === office)?.name;
  }
  return group.name + ' - ' + office.name;
};

const ProjectLink = ({
  project,
  user,
  users,
  groups,
  offices,
  disableProjectManager
}: {
  project: ProjectWithUsersDto;
  user: UserDto | null | undefined;
  users: UserDto[] | null | undefined;
  groups: GroupDto[] | null | undefined;
  offices: OfficeDto[] | null | undefined;
  disableProjectManager?: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const prefetchProject = usePrefetch('getProject')
  const manager = project.manager && nullCheckManager(project.manager, users);

  return (
    <Link to={`/projects/${project._id}`}
      style={{ textDecoration: 'none', color: theme.palette.gray.g1 }}
      onMouseEnter={() => project._id && prefetchProject({ id: project._id }, { ifOlderThan: 180 })}
    >
      <div className={classes.project}>
        <div className={classes.project__left}>
          <div className={classes.project__title}>{project.name}</div>
          <div className={classes.project__tags}>
            {!organization(user?.company).projectsAllGroups && (
              <div
                style={{
                  backgroundColor: theme.palette.blue.opacity.b16,
                  color: theme.palette.blue.b3,
                }}
              >
                {getName(project.group, project.office, groups, offices)}
              </div>)}
            {project.startDate && <div>Starts {formatDate(project.startDate)}</div>}
            {project.endDate && <div>Ends {formatDate(project.endDate)}</div>}
          </div>
        </div>
        <div className={classes.project__right}>
          {manager && !disableProjectManager && (
            <div>
              <ProjectLeader leader={manager} size={'small'} reverse={mobile} />
            </div>
          )}

          {project.users && (
            <div>
              <AvatarRow members={project.users} size={'small'} />
            </div>
          )}

          {typeof project.averageWorkload === 'number' && (
            <div style={mobile ? { marginLeft: 0 } : { marginLeft: 10 }}>
              <ProjectInfoBox score={project.averageWorkload + 1} />
            </div>
          )}

          {project.projectCategories?.map(projectCategory => (
            <div style={mobile ? { marginLeft: 0 } : { marginLeft: 10 }}>
              <ProjectInfoBox text={projectCategory.name} />
            </div>)
          )}


          {project.projectStage && (
            <div style={mobile ? { marginLeft: 0 } : { marginLeft: 10 }}>
              <ProjectInfoBox color={project.projectStage.color} text={project.projectStage.name} />
            </div>
          )}



        </div>
      </div>
    </Link>
  );
};

export default ProjectLink;
