import { ReactNode, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import Sidebar from 'components/navigation/Sidebar';
import MobileNavigation from 'components/navigation/MobileNavigation';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useAppSelector } from 'store';
import ReactLoading from 'react-loading';

const useStyles = makeStyles(() => ({
  smallDevice: {
    marginBottom: 25,
  },
  largeDevice: {
    marginBottom: 0,
    marginLeft: 75,
    marginTop: 25,
  },
  loader: {
    fill: '#59ABCB !important',
    margin: 'auto',
    marginTop: 50,
    width: '12%',
  },
}));

const UserLayout = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();
  const history = useHistory();
  const smallDevice = useMediaQuery('(max-width:467px)');
  const { initialized, authenticated } = useAppSelector((state) => state.auth);

  useEffect(() => {
    window['pushLocation'] = (location) => history.push(location);
  }, []);


  useEffect(() => {
    if (initialized && !authenticated) {
      history.push('/login');
    }
  }, [initialized, authenticated]);

  if (!initialized || !authenticated) return <ReactLoading type="spinningBubbles" className={classes.loader} />;

  return (
    <div>
      {!smallDevice ? <Sidebar /> : <MobileNavigation />}
      <div
        className={classNames('saContainer', 'notDesktop', {
          [classes.smallDevice]: smallDevice,
          "isSmallDevice": smallDevice,
          [classes.largeDevice]: !smallDevice,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default UserLayout;
