import { useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import Modal from 'components/shared/Modal';
import { AzureUser } from 'utils/wemble-api.generated';
import { SettingsButtons } from 'components/shared/Settings';
import { useCreateMultipleUsersMutation, useGetAzureUsersQuery } from 'utils/wemble-api';
import Avatar from 'components/shared/members/Avatar';
import OptionsComponent from 'components/users/OptionsComponent';
import { notEmpty } from 'utils/helpers';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.gray.g7,
    marginBlock: 0,
    userSelect: 'none',
  },
  label: {
    width: '100%',
  },
  checkBox: {
    color: theme.palette.gray.g1,
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.gray.g1,
    },
  },
  list__user: {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: 8,
    margin: 0,
  },
}));

const InviteADUsersModal = ({
  open,
  onClose,
  azureADTenantId,
}: {
  open: boolean;
  onClose: () => void;
  azureADTenantId: string | undefined;
}) => {
  const classes = useStyles();
  const [selectedUsers, setSelectedUsers] = useState<AzureUser[]>([]);
  const { data: users, refetch } = useGetAzureUsersQuery(undefined, {
    skip: typeof azureADTenantId !== 'string',
  });
  const [createMultipleUsers, { isLoading }] = useCreateMultipleUsersMutation();
  const [addAsAdmin, setAddAsAdmin] = useState(false);

  const [fields, setFields] = useState({
    group: '',
    office: '',
    secondaryGroup: '',
    secondaryOffice: '',
  });
  const [secondaryEnabled, setSecondaryEnabled] = useState(
    fields.secondaryGroup.length > 0 && fields.secondaryOffice.length > 0,
  );

  const handleUserSelect = (selectedUser: AzureUser, checked: boolean) => {
    const users = selectedUsers.filter((user) => user.mail !== selectedUser.mail);
    if (checked) {
      users.push(selectedUser);
    }
    setSelectedUsers(users);
  };

  const handleUpdateField = (field: keyof typeof fields) => (value: string) => {
    setFields((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onSubmit = async () => {
    if (isLoading) return;
    await createMultipleUsers({
      multipleUserCreationParams: {
        users: selectedUsers
          .map((user) =>
            user.mail
              ? {
                  email: user.mail,
                  name: user.displayName ?? '',
                  azureADId: user.id ?? undefined,
                }
              : null,
          )
          .filter(notEmpty),
        admin: addAsAdmin,
        group: fields.group ?? undefined,
        office: fields.office ?? undefined,
        secondaryEnabled,
        secondaryOffice: secondaryEnabled
          ? fields.secondaryOffice.length > 0
            ? fields.secondaryOffice
            : undefined
          : undefined,
        secondaryGroup: secondaryEnabled
          ? fields.secondaryGroup.length > 0
            ? fields.secondaryGroup
            : undefined
          : undefined,
      },
    });
    refetch();
    onClose();
    setSelectedUsers([]);
    setFields({
      group: '',
      office: '',
      secondaryGroup: '',
      secondaryOffice: '',
    });
  };

  return (
    <Modal visible={open} close={onClose} customWidth={600}>
      <h5>Primary group</h5>
      <div
        style={{
          width: '100%',
          float: 'left',
          marginTop: -15,
        }}
      >
        <OptionsComponent
          selectedOfficeId={fields.office}
          selectedGroupId={fields.group}
          setSelectedOffice={handleUpdateField('office')}
          setSelectedGroup={handleUpdateField('group')}
        />
      </div>

      <h5 style={{ marginTop: 60 + 'px', marginBottom: -15 + 'px' }}>Secondary group</h5>

      <div className="form-check" style={{ paddingLeft: 23 + 'px' }}>
        <div className=" position-static">
          <input
            className="form-check-input "
            style={{
              marginRight: '6px',
              marginTop: '26px',
            }}
            type="checkbox"
            checked={secondaryEnabled}
            onChange={() => setSecondaryEnabled((prev) => !prev)}
          />
        </div>

        <div
          style={{
            opacity: secondaryEnabled ? 1.0 : 0.75,
            filter: secondaryEnabled ? '' : 'saturate(0)',
            transition: '0.1s ease-in-out',
            pointerEvents: secondaryEnabled ? 'auto' : 'none',
            marginBottom: 20,
          }}
        >
          <OptionsComponent
            secondary
            selectedOfficeId={fields.secondaryOffice}
            selectedGroupId={fields.secondaryGroup}
            setSelectedOffice={handleUpdateField('secondaryOffice')}
            setSelectedGroup={handleUpdateField('secondaryGroup')}
            filterGroup={(group) => group._id !== fields.group}
            filterOffice={(office) => office._id !== fields.office}
          />
        </div>
      </div>
      <FormControlLabel
        className={classes.listItem}
        value="end"
        classes={{ label: classes.label }}
        control={
          <Checkbox
            color="primary"
            className={classes.checkBox}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            checked={addAsAdmin}
            onChange={() => setAddAsAdmin((prev) => !prev)}
          />
        }
        style={{
          paddingBottom: 20,
        }}
        label="Add as administrator"
        labelPlacement="end"
      />
      <FormControl component="fieldset" fullWidth>
        <FormGroup aria-label="position">
          {users?.map((user, key) => (
            <FormControlLabel
              className={classes.listItem}
              key={key}
              value="end"
              classes={{ label: classes.label }}
              control={
                <Checkbox
                  color="primary"
                  className={classes.checkBox}
                  disabled={!Boolean(user.mail)}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onChange={(event) => {
                    handleUserSelect(user, event.target.checked);
                  }}
                />
              }
              label={
                <div className={classes.list__user}>
                  <Avatar
                    name={user.displayName}
                    email={
                      user.mail ??
                      `No email has been set for this Azure AD user and it can't be added until it's has been set in the Azure portal.`
                    }
                  />
                </div>
              }
              labelPlacement="end"
            />
          ))}
        </FormGroup>
      </FormControl>
      <SettingsButtons
        discardCustom={'Cancel'}
        discard={onClose}
        saveCustom={'Add Selected Users'}
        save={onSubmit}
        customPadding={15}
      />
    </Modal>
  );
};

export default InviteADUsersModal;
