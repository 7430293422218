import { useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Avatar from './../Avatar';
import Workload from './Workload';
import Modal from '../../Modal';
import UserCard from '../../UserCard';
import { ProjectWithUsersDto, UserDto } from 'utils/wemble-api.generated';
import { useDeleteProjectFromUserMutation } from 'utils/wemble-api';
import ProjectInfoBox from 'components/projects/ProjectInfoBox';
import ProjectHoursModal from './ProjectHoursModal';
import organization from 'utils/organization';
import { compareNumbers, compareString } from 'helpers/compareFunctions';
import { useGetNextForecastForUser } from 'components/Home/Forecast/utils';

const useStyles = makeStyles((theme) => ({
  members__title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 16px',
    marginBottom: 16,
  },
  members__content: {
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  members__table: {
    '& .MuiTableCell-root': {
      borderBottomColor: theme.palette.gray.g6,
      padding: '16px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 8px',
      },
    },
    '& .MuiTableCell-head': {
      fontSize: 12,
      textTransform: 'uppercase',
      color: theme.palette.gray.g10,
      padding: '0 0 8px',
    },
    '& .MuiTableCell-body': {
      fontWeight: 500,
    },
    '& .MuiTableBody-root .MuiTableRow-root:nth-last-child(1) > td': {
      border: 'none',
    },
  },
  details: {
    backgroundColor: theme.palette.gray.g3,
    display: 'inline-block',
    height: 40,
    width: 40,
    borderRadius: 8,
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
  },
  hours: {
    cursor: 'pointer'
  }
}));

const getSortFunction = () => {

  const getNextForecastForUser = useGetNextForecastForUser();

  return (sortMethod) => (memberA: UserDto, memberB: UserDto) => {

    const getForecastedWorkload = (userId) => {
      const forecast = getNextForecastForUser(userId)
      // If no forecast date is set, return 5 to ensure it's sorted last
      if (!forecast?.status) return 5;
      if (forecast?.status == 'away') return 4;
      return parseInt(forecast?.status);
    };

    switch (sortMethod) {
      default:
      case 'name':
        return compareString(memberA.name, memberB.name);
      case 'projects-number':
        return (memberB.projects?.length || 0) - (memberA.projects?.length || 0);
      case 'current-workload':
        return compareNumbers(memberA.currentWorkload, memberB.currentWorkload);
      case 'workload-forecast':
        return compareNumbers(getForecastedWorkload(memberA._id), getForecastedWorkload(memberB._id));
    }
  }
};


const ProjectMembers = ({ project }: { project: ProjectWithUsersDto | null | undefined; }) => {
  const members = project?.users;
  const classes = useStyles();
  const [clickedUserId, setClickedUserId] = useState<string | null | undefined>();
  const [projectHoursUserId, setProjectHoursUserId] = useState<string | null | undefined>();
  const [sortMethod, setSortMethod] = useState<string>('name');

  const [removeUser] = useDeleteProjectFromUserMutation();
  const organizationConfig = organization(project?.company);
  const sortFunction = getSortFunction()

  async function remove(user: UserDto) {
    if (!project?._id || !user?._id) return;
    await removeUser({
      id: project._id,
      userId: user._id,
    });
  }

  return (
    <article>
      <h2 className={classes.members__title}>Members ({members?.length})</h2>
      <div className={classes.members__content}>
        <TableContainer>
          <Table className={classes.members__table}>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => setSortMethod('name')} style={{ color: sortMethod == 'name' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}><span >Name</span></TableCell>
                <TableCell onClick={() => setSortMethod('projects-number')} style={{ color: sortMethod == 'projects-number' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}># of projects</TableCell>
                <TableCell onClick={() => setSortMethod('current-workload')} style={{ color: sortMethod == 'current-workload' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>Current status</TableCell>
                <TableCell onClick={() => setSortMethod('workload-forecast')} style={{ color: sortMethod == 'workload-forecast' ? '#4e505d' : '#7E8299', cursor: 'pointer' }}>Forecast</TableCell>
                {organizationConfig.enableProjectStages && organizationConfig.projectInvoicing && (<TableCell>Hours</TableCell>)}
                {/* <TableCell align={'right'}>Details</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>

              {members && [...members]?.sort(sortFunction(sortMethod)).map((user) => (
                <TableRow key={user._id}>
                  <TableCell>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setClickedUserId(user._id);
                      }}
                    >
                      <Avatar
                        image={user.profilePicture === undefined ? '' : user.profilePicture}
                        name={user.name}
                        email={user.title}
                      />
                    </div>
                  </TableCell>
                  <TableCell>{user.projects?.length}</TableCell>
                  <TableCell>
                    <Workload userId={user._id} />
                  </TableCell>
                  <TableCell><Workload userId={user._id} forecast /></TableCell>
                  {organizationConfig.enableProjectStages && organizationConfig.projectInvoicing && (

                    <TableCell>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setProjectHoursUserId(user._id);
                        }}
                        className={classes.hours}
                      >
                        <ProjectInfoBox size='mini' text={(user.projectHours || "Report").toString()} /></div></TableCell>
                  )}
                  <TableCell align={'right'}>
                    <div className={classes.details}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          remove(user);
                        }}
                      >
                        <span
                          style={{
                            fontSize: 25,
                          }}
                        >
                          &times;
                        </span>

                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal visible={typeof clickedUserId == 'string'} close={() => setClickedUserId(undefined)} customWidth={900}>
        {clickedUserId && <UserCard userId={clickedUserId} />}
      </Modal>
      <ProjectHoursModal visible={typeof projectHoursUserId == 'string'} onClose={() => setProjectHoursUserId(undefined)} userId={projectHoursUserId} project={project} />
    </article>
  );
};

export default ProjectMembers;
