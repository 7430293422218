import { makeStyles } from '@material-ui/core';
import { UserDto } from 'utils/wemble-api.generated';

const useStyles = makeStyles((theme) => ({
  leader: {
    display: 'flex',
    alignItems: 'center',
  },
  leader__text: {
    padding: '2px 10px',
    height: 36,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  text__name: {
    fontWeight: 500,
    lineHeight: '16px',
  },
  text__title: {
    fontWeight: 400,
    fontSize: 11,
    textTransform: 'uppercase',
    textAlign: 'right',
    lineHeight: '11px',
  },
  leader__img: {
    borderRadius: '50%',
    backgroundColor: theme.palette.gray.g6,
    overflow: 'hidden',
    border: 'solid 2px white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    '& img': {
      width: '100%',
    },
  },
  user__letter: {
    color: theme.palette.gray.g1,
    fontWeight: 600,
    fontSize: 14,
  },
  user__extra: {
    color: theme.palette.gray.g7,
    fontWeight: 400,
    fontSize: 11,
  },
}));

function handleSizing(size: string) {
  if (size === 'small') {
    return {
      height: 36,
      width: 36,
    };
  } else {
    return {
      height: 48,
      width: 48,
    };
  }
}
const ProjectLeader = ({
  leader,
  size = 'small',
  reverse = false,
}: {
  leader: UserDto;
  size?: 'small' | 'medium';
  reverse?: boolean;
}) => {
  const classes = useStyles();
  size
  handleSizing

  return (
    <>
      <div className={classes.leader} style={reverse ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' }}>
        <div className={classes.leader__text}>
          <div className={classes.text__name} style={reverse ? { textAlign: 'left' } : undefined}>
            {leader.name}
          </div>
          <div className={classes.text__title} style={reverse ? { textAlign: 'left' } : undefined}>
            {'Project manager'}
          </div>
        </div>
        {/* <div className={classes.leader__img} style={handleSizing(size)}>
          {(leader.profilePicture) ? (
            <img
              alt={`${leader.name}-image`}
              src={(leader?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + leader?.profilePicture}
            />
          ) : (
            <div className={classes.user__letter}>{leader?.name?.charAt(0)}</div>
          )}
        </div> */}
      </div>
    </>
  );
};
export default ProjectLeader;
