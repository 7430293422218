import { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import PageTitle from '../../PageTitle';
import Card from './../../shared/Card';
import Settings from './../../shared/Settings';
import ProjectSettings from '../../shared/settings/ProjectSettings';
import ProjectMembers from '../../shared/members/project/ProjectMembers';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import UserPickerModal from '../../user/UserPickerModal';
import { useGetProjectQuery, useAddProjectToUsersMutation } from 'utils/wemble-api';
import { notEmpty } from 'utils/helpers';
import ProjectInvoices from 'components/shared/members/project/ProjectInvoices';
import organization from 'utils/organization';

const useStyles = makeStyles((theme) => ({
  project: {
    marginBottom: 200,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));


const Project = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id, view } = useParams<{ id: string; view?: string }>();
  const [userPickerVisible, setUserPickerVisible] = useState(false);
  const project = useGetProjectQuery({id}).data;
  const projectInvoicing = organization(project?.company).projectInvoicing;
  const views = projectInvoicing ? ['members', 'invoices', 'settings'] : ['members', 'settings']

  const [activeNavigationTab, setActiveNavigationTab] = useState(view ? views.indexOf(view) : 0);
  const [addUsersToProject] = useAddProjectToUsersMutation();

  useEffect(() => {
    if (!view) {
      history.replace(`/projects/${id}/members`);
    }
  });

  return (
    <>
      <Container className={classes.project}>
        <PageTitle title={'Project overview'} breadcrumbs={['Projects', project?.name]} link={'projects'} />
        <Card
          type={'project'}
          setTab={(newTabVal) => {
            setActiveNavigationTab(newTabVal);
            history.push(`/projects/${id}/${views[newTabVal]}`);
          }}
          currentTab={activeNavigationTab}
          tabs={views.map(view => view.charAt(0).toUpperCase() + view.slice(1))}
          data={project}
          onAddMember={() => {
            setUserPickerVisible(!userPickerVisible);
          }}
        />
        <Switch>
          <Route exact path="/projects/:id/members" render={() => <ProjectMembers project={project} />} />
          <Route exact path="/projects/:id/invoices" render={() => <ProjectInvoices project={project} />} />
          <Route
            exact
            path="/projects/:id/settings"
            render={() => (
              <Settings title={'Project settings'}>
                <ProjectSettings project={project} />
              </Settings>
            )}
          />
        </Switch>
        <UserPickerModal
          type={'project'}
          visible={userPickerVisible}
          onClose={() => {
            setUserPickerVisible(false);
          }}
          userFilter={(user) => !Boolean(project?.users?.find((u) => u._id === user._id))}
          onSelect={(selectedUsers) => {
            if (!project?._id) return;
            addUsersToProject({
              id: project?._id,
              body: {
                ids: selectedUsers.map((user) => user._id).filter(notEmpty),
              },
            });
          }}
        />
      </Container>
    </>
  );
};

export default Project;
