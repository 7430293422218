import { useCallback, useState } from 'react';
import { Formik } from 'formik';
import Item from './Item';
import Input from './../Input';
import { SettingsButtons } from '../Settings';
import { Chip, makeStyles, MenuItem, Select, TextField, useTheme } from '@material-ui/core';
import DatePicker from '../DatePicker';
import * as Yup from 'yup';
import Button from '../Button';
import Modal from '../Modal';
import { useHistory } from 'react-router-dom';
import UserPickerModal from '../../user/UserPickerModal';
import {
  useArchiveProjectMutation,
  useUnarchiveProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useGetUsersQuery,
  useGetProjectStagesQuery,
  useGetProjectCategoriesQuery,
} from 'utils/wemble-api';
import { ProjectWithUsersDto } from 'utils/wemble-api.generated';
import organization from 'utils/organization';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { convertLocalToUTCDate, convertUTCToLocalDate } from 'components/Home/Forecast/utils';

const useStyles = makeStyles((theme) => ({
  archive: {
    backgroundColor: theme.palette.blueGray.opacity.bg16,
    color: theme.palette.blueGray.bg1,
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-block',
  },
  delete: {
    backgroundColor: theme.palette.red.opacity.r16,
    color: theme.palette.red.r4,
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-block',
  },
  modal__title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 24px',
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.gray.g6,
  },
  modal__buttons: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  description: {
    '&::placeholder': {
      color: '#878787',
    },
    backgroundColor: theme.palette.gray.g3,
    border: 'none',
    borderRadius: 4,
    width: '100%',
    position: 'relative',
    padding: '12px 16px',
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'text',
    '&:focus': {
      outline: 'none',
    }
  }
}));

const ProjectSettings = ({ project }: { project: ProjectWithUsersDto | null | undefined }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [userPickerVisible, setUserPickerVisible] = useState(false);

  const { data: users } = useGetUsersQuery();
  const { data: projectStages } = useGetProjectStagesQuery();
  const { data: projectCategories } = useGetProjectCategoriesQuery();

  const [deleteProject] = useDeleteProjectMutation();
  const [unarchiveProject] = useUnarchiveProjectMutation();
  const [archiveProject] = useArchiveProjectMutation();
  const [updateProject] = useUpdateProjectMutation();

  const handleDelete = () => {
    if (!project?._id) return;
    deleteProject({
      id: project._id,
    });
    history.replace('/projects/');
  };

  const toggleProjectArchivedStatus = useCallback(() => {
    const id = project?._id;
    if (!id) return;
    if (project?.archived) {
      return unarchiveProject({
        id,
      });
    }
    return archiveProject({ id });
  }, [project, archiveProject, unarchiveProject]);

  return (
    <>
      {project && (<Formik
        initialValues={{
          name: project?.name ? project.name : '',
          description: project?.description ? project.description : '',
          startDate: project?.startDate ? new Date(project.startDate) : '',
          endDate: project?.endDate ? new Date(project.endDate) : '',
          manager: project?.manager?._id ? project.manager._id : '',
          projectStage: project?.projectStage?._id ?? '',
          projectCategories: project?.projectCategories?.map(projectCategory => projectCategory.name || '') ?? []
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          description: Yup.string(),
          startDate: Yup.date(),
          endDate: Yup.date(),
          manager: Yup.string().nullable(),
          projectStage: Yup.string().nullable(),
          projectCategory: Yup.string().nullable(),

        })}
        onSubmit={(values, submitProps) => {
          if (!project?._id) return;
          updateProject({
            id: project?._id,
            projectUpdateParams: {
              ...values,
              manager: values.manager ?? '',
              projectStage: values.projectStage ?? '',
              projectCategories: values.projectCategories.map(category => projectCategories?.find(c => c.name === category)?._id || '') ?? [],
              startDate: typeof values.startDate !== 'string' ? values.startDate.toISOString() : undefined,
              endDate: typeof values.endDate !== 'string' ? values.endDate.toISOString() : undefined,
            },
          }).then((_) => submitProps.resetForm({ values }));

        }}
      >
        {({ values, dirty, handleSubmit, setFieldValue, resetForm }) => (
          <form>
            <Item title={'Project Name'} input={<Input placeholder={'Project name'} name={'name'} type="text" />} />
            <Item
              title={'Project comments'}
              input={<textarea value={values.description} className={classes.description} placeholder={'Project comments'} name={'description'} onChange={(e) => setFieldValue('description', e.target.value)} />}
            />
            <Item
              title={'Start'}
              input={<DatePicker selected={values.startDate} onChange={(date) => setFieldValue('startDate', date)} />}
            />
            <Item
              title={'End'}
              input={<DatePicker selected={values.endDate} onChange={(date) => setFieldValue('endDate', date)} />}
            />
            {!organization(project?.company || '').disableProjectManager && (
              <Item
                title={'Manager'}
                input={
                  <Input
                    placeholder={'Select...'}
                    name={'manager'}
                    value={values.manager ? users?.find((user) => user._id === values.manager)?.name ?? '' : ''}
                    onClick={() => setUserPickerVisible(true)}
                    clearable={() => {
                      setFieldValue('manager', null);
                    }}
                  />
                }
              />)}
            {organization(project?.company || '').enableProjectStages && (<>
              <Item title={'Project Stage'} input={(projectStages && projectStages.length > 0) && (
                <Select
                  value={values ? values.projectStage : ''}
                  onChange={(e) => setFieldValue('projectStage', e.target.value)}
                  displayEmpty
                  variant="filled"
                  disableUnderline
                >

                  {projectStages.map(projectStage => <MenuItem value={projectStage._id || ''} key={projectStage._id || ''}>{projectStage.name}</MenuItem>)}
                  <MenuItem value={''} key={''}>None</MenuItem>
                </Select>)}
              />
              {organization(project?.company || '').projectInvoicing && (
                <Item title={'Project Categories'} input={(projectCategories && projectCategories.length > 0) && (
                  <Autocomplete
                    multiple
                    options={(values && values.projectCategories.length > 2) ? [] : projectCategories.map(category => category.name)}
                    value={values ? values.projectCategories : []}
                    onChange={(_, value) => setFieldValue('projectCategories', value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="default"
                          label={option}
                          {...getTagProps({ index })}
                        />))}
                    renderInput={(params) => (
                      <TextField {...params} variant='standard'
                        placeholder={(values && values.projectCategories.length > 2) ? '' : 'Add category...'} disabled={values.projectCategories.length > 3} />
                    )}
                  />)}
                />)}

            </>)}
            <Item
              title={'Archive Project'}
              input={
                <div onClick={toggleProjectArchivedStatus} className={classes.archive}>
                  {project?.archived ? 'Unarchive' : 'Archive'}
                </div>
              }
              alignRight
            />
            <Item
              title={'Delete Project'}
              input={
                <div
                  onClick={() => {
                    setVisible(true);
                  }}
                  className={classes.delete}
                >
                  Delete
                </div>
              }
              alignRight
            />
            <SettingsButtons modified={dirty} discard={resetForm} save={handleSubmit} created={project.created || undefined} />

            <UserPickerModal
              type={'manager'}
              description={'Select a manager'}
              visible={userPickerVisible}
              onClose={() => {
                setUserPickerVisible(false);
              }}
              onSelect={(selectedUsers) => {
                setFieldValue('manager', selectedUsers[0]._id);
              }}
            />
          </form>
        )}
      </Formik>
      )}
      <Modal visible={visible} close={() => setVisible(false)}>
        <div>
          <h2 className={classes.modal__title}>Are you sure you want to delete project?</h2>
          <div className={classes.modal__buttons}>
            <Button text={'Cancel'} onClick={() => setVisible(false)} />
            <Button
              text={'Delete'}
              background={theme.palette.red.opacity.r16}
              color={theme.palette.red.r4}
              onClick={() => handleDelete()}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProjectSettings;
