import { Overrides } from '@material-ui/core/styles/overrides';
import { darken } from '@material-ui/core';

const overrides: Overrides = {
  MuiButton: {
    root: {
      textTransform: 'none',
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 10,
    },
  },
  MuiBottomNavigationAction: {
    label: {
      fontSize: 11,
    },
    root: {
      minWidth: 60,
    },
  },
  MuiInputBase: {
    input: {
      '&::placeholder': {
        fontWeight: 500,
        color: '#8E93A2',
        opacity: 1,
      },
    },
  },
  MuiFilledInput: {
    root: {
      height: 44,
      borderRadius: '8px !important',
      overflow: 'hidden',
      backgroundColor: '#F5F8FA',
      '&:hover': {
        backgroundColor: darken('#F5F8FA', 0.1),
      },
      '&.Mui-focused': {
        backgroundColor: darken('#F5F8FA', 0.05),
      },
    },
    input: {
      fontSize: 16,
      fontWeight: 500,
      padding: '12px 16px',
    },
    inputAdornedStart: {
      paddingLeft: 8,
    },
  },
};

export default overrides;
