import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { OptionsType } from 'react-select';

const useStyles = makeStyles((theme) => ({
  option: {
    fontSize: 12,
    cursor: 'pointer',
    flex: 1,
    height: '100%',
    textAlign: 'center',
    lineHeight: '44px',

  },
  option__selected: {
    fontWeight: 'bold'
  },
  option__container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: 'white',

  },
  selected__option: {
    backgroundColor: theme.palette.blueGray.bg1
  }
}));

const ViewSelect = ({
  options,
  onChange,
  defaultValue = null,
}: {
  options: OptionsType;
  onChange: (...args: any[]) => void;
  defaultValue: any;
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);


  return (

    <div className={classes.option__container}>

      {options.map(option => (<div className={classes.option + ' ' + (value.value === option.value ? classes.option__selected : '')} onClick={() => { onChange(option); setValue(option) }}>{option.label}</div>))}
    </div>
  );
};

export default ViewSelect;
