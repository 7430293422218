import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import BusinessIcon from '@material-ui/icons/BusinessRounded';

import { useGetCurrentUserQuery } from 'utils/wemble-api';
import { useSetAuth } from 'hooks';

import wIcon from '../../assets/companyLogos/wemble.svg';
import dashboardIcon from '../../assets/icons/home.svg';
//import statisticsIcon from '../../assets/icons/statistics.svg';
import projectsIcon from '../../assets/icons/projects.svg';
import competenceIcon from '../../assets/icons/competences.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import usersIcon from '../../assets/icons/users.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import organization from '../../utils/organization';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: `${theme.palette.blue.b2} !important`,
  },
  header__nav: {
    '& img': {
      width: 44,
      height: 44,
      filter: 'brightness(0) invert(1) !important',
    },
    '& .active': {
      filter: 'brightness(1) !important',
      opacity: 1,
    },
    '& svg': {
      width: 44,
      height: 44,
      filter: 'brightness(0) invert(1) !important',
    },
  },
}));

const MobileNavigation = ({ darkMode = true }: { darkMode?: boolean }) => {
  const classes = useStyles();
  const [visible, isVisible] = useState(false);
  const setAuth = useSetAuth();
  const { pathname } = useLocation();
  const { data: user } = useGetCurrentUserQuery();

  const close = () => {
    isVisible(false);
  };
  const toggle = () => {
    isVisible(!visible);
  };

  const organizationConfig = organization(user?.company);

  return (
    <>
      <Navbar
        fixed="top"
        color={darkMode ? 'dark' : 'light'}
        dark={darkMode}
        light={!darkMode}
        expand="sm"
        className={classes.header}
      >
        <Link to="/home" onClick={close}>
          <img
            src={wIcon}
            style={{
              height: '50px',
              filter: 'sepia(1) brightness(10)',
              padding: '5px',
            }}
            alt="status"
          />

        </Link>
        <NavbarToggler onClick={toggle} />


        <Collapse isOpen={visible} navbar={true}>
          <Nav className={classNames(classes.header__nav, 'ml-auto')} navbar={true}>
            {user && (
              <NavItem>
                <NavLink className="nav-link navbar-link" to="/home" activeClassName="active" onClick={close}>
                  <img src={dashboardIcon} className="navbarImg" alt="Home" />
                  <br />
                  Dashboard
                </NavLink>
              </NavItem>
            )}

            {user && !organizationConfig.disableProjects && (
              <NavItem>
                <NavLink
                  className={
                    'nav-link navbar-link' + (window.location.pathname.split('/')[1] === 'projects' ? ' active' : '')
                  }
                  to="/projects"
                  activeClassName="active"
                  onClick={close}
                >
                  <img src={projectsIcon} className="navbarImg" alt="projects" />
                  <br />
                  Projects
                </NavLink>
              </NavItem>
            )}

            {user && !organizationConfig.disableCompetences && (
              <NavItem>
                <NavLink
                  className={
                    'nav-link navbar-link' + (window.location.pathname.split('/')[1] === 'competences' ? ' active' : '')
                  }
                  to="/competences"
                  activeClassName="active"
                  onClick={close}
                >
                  <img src={competenceIcon} className="navbarImg" alt="competences" />
                  <br />
                  Competences
                </NavLink>
              </NavItem>
            )}

            {user && (user.admin || user.administratorPriviliges) && !user.companyAdmin && (
              <NavItem>
                <NavLink
                  className="nav-link navbar-link"
                  to="/users"
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  activeClassName="active"
                  onClick={close}
                >
                  <img src={usersIcon} className="navbarImg" alt="users" />
                  <br />
                  Manage users
                </NavLink>
              </NavItem>
            )}
            {user && (
              <NavItem>
                <NavLink className="nav-link navbar-link" to="/settings" activeClassName="active" onClick={close}>
                  <img src={settingsIcon} className="navbarImg" alt="settings" />
                  <br />
                  Settings
                </NavLink>
              </NavItem>
            )}
            {/*user && !user.companyAdmin && (
              <NavItem>
                <NavLink className="nav-link navbar-link" to="/statistics" activeClassName="active" onClick={close}>
                  <img src={statisticsIcon} className="navbarImg" alt="Statistics" />
                  <br />
                  Statistics
                </NavLink>
              </NavItem>
            )*/}
            {user?.companyAdmin && (
              <NavItem>
                <NavLink
                  className="nav-link navbar-link"
                  to="/groups"
                  activeClassName="active"
                  onClick={close}
                  isActive={() => ['/groups', '/offices'].some((route) => pathname.startsWith(route))}
                >
                  <BusinessIcon />
                  <br />
                  Admin
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <Link className="nav-link navbar-link logout" to="/" onClick={() => setAuth(false)}>
                <img src={logoutIcon} className="navbarImg" alt="logout" style={{ marginLeft: 5 }} />
                <br />
                Log out
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default MobileNavigation;
