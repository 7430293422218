import { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Hidden, makeStyles } from '@material-ui/core';

import Profile from '../components/Home/Profile';
import ProfileV2 from '../components/Home/ProfileV2';

import Statuses from '../components/Home/Statuses';
import { useGetCurrentUserQuery, useGetFilteredUserIdsMutation, useGetForecastsQuery, useGetGroupsQuery, useGetUsersQuery } from 'utils/wemble-api';
import classNames from 'classnames';
import { UserFilterParams } from 'utils/wemble-api.generated';
import organization from 'utils/organization';
import Forecasting from '../components/Home/Forecast/Forecasting';
import VacationPlanner from 'components/vacationPlanner/VacationPlanner';
import MatterComments from 'components/Home/MatterComments';
import moment from 'moment';
import { getToday } from 'components/Home/Forecast/utils';
import DaySelect from 'components/Home/DaySelect';
import HalfGrid from 'components/Home/HalfGrid';
import SingleBoxButton from 'components/Home/SingleBoxButton';

import home from './../assets/icons/home.svg';
import absent from './../assets/icons/absent.svg';
import UserMessageInput from 'components/Home/UserMessageInput';
import Modal from 'components/shared/Modal';
import WorkplaceOverview from 'components/workplaceOverview/WorkplaceOverview';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  halfGridItem: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 8
    },

  },
  root: {
    width: '100%',
    margin: '70px auto 20px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-20px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '25px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '42px',
    },

  },
  welcome: {
    fontSize: 22,
    fontWeight: 600,
    position: 'relative',
    left: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  grid: {
    margin: '14px 0 20px',
    backgroundColor: 'white',
    width: '100%',
    padding: 16,
    borderRadius: '26px 0 26px 26px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 12,
      borderRadius: '24px 0 24px 24px',
    },
  },
  gridHidden: {
    minHeight: 'unset',
    padding: 0,
  },
  messageInput: {
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingBottom: 20,
    }
  },
}));

const Home = () => {
  const classes = useStyles();
  const [workplaceOverview, setWorkplaceOverviewOpen] = useState(false);
  const [vacationPlannerOpen, setVacationPlannerOpen] = useState(false);
  const { data: user } = useGetCurrentUserQuery(undefined, { refetchOnMountOrArgChange: 5, refetchOnReconnect: true });

  const [isLoading, setIsLoading] = useState(true);
  const { data: allUsers } = useGetUsersQuery(undefined, { refetchOnMountOrArgChange: 3, refetchOnReconnect: true, skip: isLoading });
  const { data: groupsRes } = useGetGroupsQuery(undefined, { skip: user?.name !== 'David Schrag' && isLoading });
  const [getFilteredUserIds] = useGetFilteredUserIdsMutation();
  const [filteredUserIds, setFilteredUserIds] = useState<string[] | undefined>();
  const [filters, setFilters] = useState<UserFilterParams | undefined>();

  const somethingSelected =
    filters && ((filters.groups?.length || 0) + (filters.offices?.length || 0) + (filters.competences?.length || 0) + (filters.projects?.length || 0) + (filters.cvpartnerData?.length || 0) + (filters.term?.length || 0)) > 0

  const fetchUsers = useCallback(
    async (showLoader = true) => {
      if (!filters) return;
      if (somethingSelected) {
        if (showLoader) setIsLoading(true);
        const res = await getFilteredUserIds({
          body: {
            filters,
          },
        });
        if ('data' in res) {
          setFilteredUserIds(res.data ?? []);
        }
        if (showLoader) setIsLoading(false);
      } else {
        setFilteredUserIds([]);
      }
    },
    [filters, somethingSelected],
  );

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const matterLayout = organization(user?.company).enableUtilizationView && (organization(user?.company).name != "Synch") && user?.detailedViewPriviliges

  const { data: allForecasts } = useGetForecastsQuery(undefined, { refetchOnReconnect: true });
  const today = getToday()
  const forecasts = allForecasts?.filter(forecast => forecast.user === user?._id && moment(forecast.endDate).isAfter(today)).sort((a, b) => moment(a.startDate).diff(moment(b.startDate))) || [];
  const currentForecast = forecasts.find(forecast => moment(forecast.startDate) <= moment(getToday()))

  const users = allUsers?.filter(({ _id, admin, completed }) => _id && completed && !admin && filteredUserIds?.includes(_id))
  const showWFH = !organization(user?.company).disableWFH

  return (
    <>
      <Container className={classes.container}>
        <div className={classes.root}>
          {(groupsRes?.customHtml && user && organization(user?.company).enableCustomHtml) ? <div
            dangerouslySetInnerHTML={{ __html: groupsRes?.customHtml.replaceAll('USER_NAME', user?.name || '').replaceAll('USER_CUSTOM_ID', user?.name?.toLowerCase().replaceAll(' ', '-') || '').replaceAll('USER_EMAIL', user?.email || '').replaceAll('USER_WEMBLE_ID', user?._id || '').replaceAll("USER_PHOTO_URL", user.profilePicture ? ((user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture) : '') }}
          />
            : <h1 className={classes.welcome}>Welcome back, {user?.name}</h1>}

          <div className={classNames(classes.grid, { [classes.gridHidden]: user?.admin })}>
            {matterLayout ? (
              <Profile
                user={user}
                openVacationPlanner={(() => setVacationPlannerOpen(true))}
                openWorkplaceOverview={showWFH ? (() => setWorkplaceOverviewOpen(true)) : undefined}
                fetchUsersWFH={((matterLayout || organization(user?.company).expandComment) && showWFH && !Boolean(user?.admin)) ? (() => fetchUsers(false)) : undefined}
              />
            ) : (
              showWFH ? (
                <Profile
                  user={user}
                  openVacationPlanner={false}
                  fetchUsersWFH={((matterLayout || organization(user?.company).expandComment) && showWFH && !Boolean(user?.admin)) ? (() => fetchUsers(false)) : undefined}
                />
              ) : (
                <ProfileV2
                  user={user}
                  openVacationPlanner={(() => setVacationPlannerOpen(true))}
                />
              )
            )}

            {!Boolean(user?.admin) && matterLayout && (<Grid spacing={3} container>
              <Grid item xs={12} md={7} >
                <Forecasting />
              </Grid>

              <Grid item xs={12} md={5}>
                <MatterComments />
                <Hidden smUp>
                  {showWFH && (<div style={{ height: 100, marginTop: 14 }}>
                    <DaySelect user={user} disabled={Boolean(user?.admin)} fetchUsers={() => fetchUsers(false)} />
                  </div>)}
                </Hidden>

              </Grid>


            </Grid>)}

            {(!Boolean(user?.admin) && !matterLayout) && (showWFH ? (
              <Grid spacing={3} container>
                <Grid item xs={12} md={8} >
                  <Forecasting />
                </Grid>

                <Grid item xs={12} md={4}>
                  <HalfGrid>
                    <Grid container>
                      <Grid item xs={12} md={organization(user?.company).expandComment ? 12 : 6} style={{ paddingRight: organization(user?.company).expandComment ? 0 : 10 }} className={classes.messageInput}>
                        <UserMessageInput disabled={Boolean(user?.admin)} />
                      </Grid>


                      <Grid container style={{ marginTop: 12 }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{ paddingRight: 6 }}
                          className={classes.halfGridItem}
                        >
                          <SingleBoxButton
                            icon={home}
                            text={'WFH Overview'}
                            event={() => setWorkplaceOverviewOpen(true)}
                          />
                        </Grid>
                        {!Boolean(organization(user?.company)?.disableAbsencePlanner) && (
                          <Grid
                            xs={12}
                            md={6}
                            style={{ paddingLeft: 6 }}
                            className={classes.halfGridItem}>

                            <SingleBoxButton
                              icon={absent}
                              text={'Absence planning'}
                              event={() => setVacationPlannerOpen(true)}
                            />
                          </Grid>
                        )}

                      </Grid>

                    </Grid>

                  </HalfGrid>




                </Grid>
              </Grid>



            ) : (<Forecasting />))}
          </div>
        </div>

        <Statuses
          users={users}
          isFetching={isLoading}
          setFilters={setFilters}
          officeOrGroupSelected={somethingSelected ?? false}
        />


      </Container>

      <VacationPlanner
        isOpen={vacationPlannerOpen}
        close={() => setVacationPlannerOpen(false)}
        selectedUser={user?._id}
        users={users}
      />

      <Modal visible={workplaceOverview} close={() => setWorkplaceOverviewOpen(false)} customWidth={900}>
        <WorkplaceOverview users={users} currentUser={user} />
      </Modal>


    </>
  );
};

export default Home;
