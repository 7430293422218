import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = (size: number) => makeStyles((theme) => ({
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar__img: {
    borderRadius: '50%',
    overflow: 'hidden',
    width: size,
    height: size,
    flex: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.gray.g6,
    '& img': {
      width: '100%',
    },
    '& div': {
      color: theme.palette.gray.g1,
      fontWeight: 600,
      fontSize: 14,
    },
  },
  avatar__text: {
    padding: '2px 0 2px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: theme.palette.gray.g1,
    fontWeight: 500,
    fontSize: size <= 40 ? 13 : 14,
    lineHeight: '115%',
  },
  text__email: {
    fontSize: size <= 40 ? 9 : 10,
    marginTop: -1,
    color: '#828282',
  },
}));

const Avatar = ({
  image,
  name = '',
  email,
  item,
  size = 44
}: {
  image?: string | null;
  name?: string | null;
  email?: string | null;
  item?: React.ReactElement;
  size?: number;
}) => {
  const classes = useStyles(size)();

  return (
    <div className={classes.avatar}>
      <div className={classes.avatar__img}>
        {image ? (
          <img alt={`${name}-image`}
            src={(image.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + image} />
        ) : (
          <div>{name?.charAt(0)}</div>
        )}
      </div>
      <div className={classes.avatar__text}>
        <div>{name}{item}</div>
        <div className={classes.text__email}>{email}</div>
      </div>
    </div>
  );
};

export default Avatar;
