import { Link } from 'react-router-dom';

const PrivacyPage = () => (
  <div style={{ padding: '20px' }}>
    <Link className="nav-link back-link" to="/login" style={{ paddingLeft: 0, color: 'grey' }}>
      <svg height="25" width="15" viewBox="0 7 30 20">
        <polyline points="15,0 5,15 15,30" strokeWidth="8" strokeLinecap="round" fill="none" stroke="grey" />
      </svg>
      Tillbaka
    </Link>

    <h2 className="MsoNormal">
      <b>
        <span>Privacy policy / Personuppgiftspolicy </span>
      </b>
    </h2>

    <p>
      <br />
      Enhanced Work Nordic AB
      <br />
      Org. nr. 559215-4081
      <br />
      Silvergatan 12, 426 71 Västra Frölunda{' '}
    </p>

    <h4>
      <br />
      Information om vår personuppgiftsbehandling{' '}
    </h4>

    <p className="MsoNormal">
      Att värna den personliga integriteten och skydda de personuppgifter som vi behandlar är en central del i vårt
      informationssäkerhetsarbete. All behandling av personuppgifter sker i enlighet med gällande
      personuppgiftslagstiftning.{' '}
    </p>

    <p className="MsoNormal">
      Besök på vår webbplats (www.enhancedwork.com) kan ske utan att besökaren behöver lämna några personuppgifter eller
      acceptera cookies. Webbplatsen är rent informativ och innehåller inga kontaktformulär eller liknande, men den som
      väljer att kontakta oss per e-post kommer oftast att lämna personuppgifter till oss.{' '}
    </p>

    <p className="MsoNormal">
      När kunder eller dess företrädare, samarbetspartner och/eller konsulter har kontakt med oss eller förekommer i
      samband med den kontakt vi har med kunden, innebär det att personuppgifter lämnas till oss eller inhämtas av oss.
      När personer besöker våra kontor registrerar vi normalt information om dem i ett besökshanteringssystem. I det
      följande beskrivs hur vi samlar in, behandlar och delar personuppgifter i samtliga dessa fall. Nedan finns också
      information om de rättigheter som registrerade personer har i relation till oss som personuppgiftsansvarig samt
      våra kontaktuppgifter.{' '}
    </p>

    <br />
    <h4>
      <br />
      Vilka personuppgifter behandlar vi?{' '}
    </h4>

    <p className="MsoNormal">
      En betydande del av vår kommunikation med kund sker med hjälp av telefon och e-post, vilket i princip alltid
      innebär en personuppgiftsbehandling. Att ringa eller skicka e-postmeddelanden till oss innebär i regel att
      personuppgifter lämnas som går att hänföra till personer.{' '}
    </p>

    <p className="MsoNormal">
      Den centrala delen i insamlingen av personuppgifter sker dock i samband med att vi installerar kundens specifika
      miljö i den mjukvara vi säljer till kunden. Det går vanligtvis till så att kunden skickar oss en förteckning över
      samtliga användare, vilket inbegriper deras namn, e-postadress och grupptillhörighet, som vi sedan lägger till i
      våra databaser och bjuder in som användare till vår mjukvara. Personuppgifterna samlas oftast in från en
      administrativt ansvarig person hos kunden, vilket innebär att vi i regel samlar in personuppgifter om berörda
      personer utan att informationen kommer direkt från dem. Vi kan även komma att komplettera personuppgifterna genom
      inhämtning av information från privata och offentliga register och källor. Utlämningen av dessa personuppgifter är
      nödvändiga för att vi ska kunna erbjuda vår tjänst.{' '}
    </p>

    <p className="MsoNormal">
      De personuppgifter vi behandlar gäller företrädesvis kontaktuppgifter (t.ex. namn, titel, arbetsadress,
      telefonnummer, e-postadress, statusuppdateringar samt arbetsgrupptillhörighet). Vid besök på våra kontor
      registreras normalt besökarens namn och arbetsgivare (eller motsvarande).{' '}
    </p>

    <br />
    <h4>
      <br />
      Vad är ändamålen med vår behandling av personuppgifter?{' '}
    </h4>

    <p className="MsoNormal">
      Vi behandlar personuppgifter som lämnas eller inhämtas i samband med att vi initierar kontakt med kund samt i
      samband med att vi installerar kundens specifika miljö i vår mjukvara. Ändamålet med vår behandling är således att
      kunna sköta och upprätthålla en normal kontakt med kunden samt kunna erbjuda den tjänst vi säljer.{' '}
    </p>

    <p className="MsoNormal">
      Vi kan även komma att använda personuppgifter som underlag för marknads- och kundanalyser, affärs- och
      metodutveckling, statistikändamål samt för marknadsföringsändamål.{' '}
    </p>

    <br />
    <h4>
      <br />
      Vad är den lagliga grunden för vår behandling?{' '}
    </h4>

    <p className="MsoNormal">
      Den lagliga grunden för behandlingen av personuppgifter är att vi ska kunna fullgöra det avtal som är grunden för
      det vi åtagit oss i relation till vår kund, dvs. att kunna leverera den tjänst vi erbjuder. I relation till våra
      kunders anställda, samarbetspartners och/eller konsulter grundar sig vår personuppgiftsbehandling normalt på en
      intresseavvägning. Det innebär att vi bedömer att behandlingen av personuppgifterna är nödvändig för de ändamål
      som rör vår kunds eller, i förekommande fall, våra berättigade intressen och att dessa väger tyngre än eventuella
      motstående intressen elelr grundläggande rättigheter och friheter.{' '}
    </p>

    <p className="MsoNormal">
      Behandling av personuppgifter hänförliga till leverantörer eller dess företrädare och andra externa parter grundas
      på vårt berättigade intresse av att administrera relationen och fullgöra våra avtalsförpliktelser.{' '}
    </p>

    <p className="MsoNormal">
      I relation till information som registreras i samband med besök på våra kontor är den legala grunden för
      behandlingen – utöver att behandlingen också kan täckas av de föregående styckena – vårt berättigade intresse av
      att hantera besöket och/eller vår skyldighet att fullgöra våra rättsliga förpliktelser.{' '}
    </p>

    <p className="MsoNormal">
      När vi behandlar personuppgifter i syfte att analysera och utveckla vår verksamhet och för marknadskommunikation,
      sker behandlingen på grundval av vårt berättigade intresse av att förbättra och att marknadsföra vår verksamhet.{' '}
    </p>

    <br />
    <h4>
      <br />
      Vem har tillgång till de personuppgifter vi behandlar?{' '}
    </h4>

    <p className="MsoNormal">
      Vi har vidtagit lämpliga tekniska och organisatoriska säkerhetsåtgärder för att skydda de personuppgifter vi
      behandlar mot bl.a. förlust och obehörig åtkomst.{' '}
    </p>

    <p className="MsoNormal">
      Överföringar av uppgifter utanför EU/EES sker i regel inte, men i de fall det sker görs sker det i enlighet med
      tillämplig personuppgiftslagstiftning och endast för de ändamål som anges ovan. Denna typ av överföringar grundas
      normalt på EU-kommissionens standardavtalsklausuler.{' '}
    </p>

    <p className="MsoNormal">
      Enhanced Work kommer inte att lämna ut personuppgifter till någon utanför företaget annat än i de fall då (i) det
      överenskommits mellan oss och den vars personuppgifter vi behandlar, (ii) det är nödvändigt för att vi ska
      fullgöra en lagstadgad skyldighet, (iii) vi anlitar en utomstående tjänsteleverantör eller samarbetspartner som
      utför tjänster för vår räkning (sådana tjänsteleverantörer och samarbetspartners får endast behandla
      personuppgifter i enlighet med våra instruktioner och får inte använda personuppgifter för egna ändamål); eller
      (iv) det annars tillåts enligt lag.{' '}
    </p>

    <br />
    <h4>
      <br />
      Hur länge sparas personuppgifter?{' '}
    </h4>

    <p className="MsoNormal">
      Vi sparar inte personuppgifterna längre än nödvändigt med hänsyn till ändamålet för behandlingen, om inte
      uppgifterna måste eller får sparas längre enligt lag.{' '}
    </p>

    <p className="MsoNormal">
      De personuppgifter som kan komma att behandlas inför, under och i samband med leverans av och användning av vår
      tjänst sparas under hela den tid tjänsten levereras till och används av kund, därefter raderas dem i enlighet med
      vad som överenskommits med kund.{' '}
    </p>

    <br />
    <h4>
      <br />
      Vilka rättigheter har den vars personuppgifter behandlas av oss?{' '}
    </h4>

    <p className="MsoNormal">
      Enhanced Work Nordic AB, org. nr. 559215-4081, med adress Silvergatan 12, 426 71 Västra Frölunda (besöksadress
      Norrlandsgatan 21, 111 43 Stockholm), är personuppgiftsansvarig för den behandling av personuppgifter som är
      hänförliga till vår generella affärsmässiga kontakt med kunden och kundens representanter. Det innebär att vi
      ansvarar för att personuppgifterna behandlas korrekt och i enlighet med gällande personuppgiftslagstiftning såvitt
      avser denna behandling.{' '}
    </p>

    <p className="MsoNormal">
      För den behandling av personuppgifter som gäller kundens anställda och som skickas till oss i form av en
      förteckning (och som är nödvändig för att vi ska kunna installera och leverera tjänsten och kunden ska kunna
      använda den) är vi personuppgiftsbiträde och kunden{' '}
    </p>

    <p className="MsoNormal">
      personuppgiftsansvarig. Detta gäller all behandling av personuppgifter som krävs för och sker inom ramen för
      kundens användning av vår mjukvara. {' '}
    </p>

    <p className="MsoNormal">
      Registrerade personer har rätt att få veta vilka personuppgifter vi behandlar om dem. En registrerad person har
      också rätt att begära att felaktiga eller ofullständiga personuppgifter om denne rättas eller att vi raderar dessa
      personuppgifter (t.ex. om personuppgifterna inte längre är nödvändiga för ändamålet eller om ett lämnat samtycke
      återkallas). Registrerade personer har vidare rätt att invända mot viss behandling av personuppgifter och begära
      att behandlingen av personuppgifter begränsas. Slutligen har registrerade personer rätt att få ut sådana
      personuppgifter som de tillhandahållit i ett maskinläsbart format och att överföra dessa till en annan
      personuppgiftsansvarig.{' '}
    </p>

    <p className="MsoNormal">
      Den som har invändningar eller synpunkter på hur vi behandlar personuppgifter har rätt att kontakta eller lämna in
      klagomål hos Datainspektionen, som är tillsynsmyndighet för vår personuppgiftsbehandling.{' '}
    </p>

    <p className="MsoNormal">
      Vid frågor om eller klagomål på hur vi behandlar personuppgifter eller begäran om utövande av rättigheter enligt
      ovan, vänligen kontakta oss via e-post på info@enhancedwork.com eller per brev till adressen ovan.{' '}
    </p>

    <br />
    <p>
      Stockholm
      <br />
      21 januari 2020
    </p>
  </div>
);

export default PrivacyPage;
