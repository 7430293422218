import { useState } from 'react';
import List from '../common/List';
import GroupModal from '../groups/GroupModal';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import OfficeModal from './OfficeModal';
import { useGetGroupsQuery, useDeleteGroupMutation, useGetOfficesQuery } from 'utils/wemble-api';
import { GroupDto } from 'utils/wemble-api.generated';
import Modal from '../shared/Modal';

const GroupPage = () => {
  const history = useHistory();
  const [showOfficeModal, setShowOfficeModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editGroup, setEditGroup] = useState<GroupDto | undefined>();
  const { data: groupsRes } = useGetGroupsQuery();
  const { data: offices } = useGetOfficesQuery();
  const [deleteGroup, { isLoading }] = useDeleteGroupMutation();
  const { id } = useParams<{ id: string }>();

  const group = groupsRes?.groups?.find((group) => group._id === id);

  if (!group) return null;

  const filteredOffices = offices?.filter(
    (office) => (typeof office.group === 'string' ? office.group : office.group?._id) === group._id,
  );

  if (!filteredOffices) return null;

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    if (!group._id) return;
    await deleteGroup({
      id: group._id,
    });
    history.push('/groups');
  };

  const disabled = isLoading;

  return (
    <Row>
      <Col sm="12" md={{ size: 8, offset: 2 }}>
        <Link className="nav-link back-link" to="/groups" style={{ paddingLeft: 0 + 'px', color: 'grey' }}>
          <svg height="25" width="15" viewBox="0 7 30 20">
            <polyline points="15,0 5,15 15,30" strokeWidth="8" strokeLinecap="round" fill="none" stroke="grey" />
          </svg>
          Groups
        </Link>

        <OfficeModal
          title="New Office"
          groupId={id}
          isOpen={showOfficeModal}
          onClose={() => setShowOfficeModal(false)}
        />
        <h2
          style={{
            marginBottom: 32,
          }}
        >
          {group.name || <br />}
        </h2>
        <List
          dataType="office"
          data={filteredOffices}
          title={'Offices'}
          handleAddItem={() => setShowOfficeModal(true)}
          handleItemClick={(office) => history.push(`/offices/${office._id}`)}
          isGroup
        />
        <Button color="danger" onClick={handleDelete} disabled={disabled}>
          Delete
        </Button>
        <Button color="primary" style={{ marginLeft: 8 }} onClick={() => setEditGroup(group)} disabled={disabled}>
          Edit group
        </Button>

        <GroupModal
          title="Edit Group"
          isOpen={Boolean(editGroup)}
          onClose={() => setEditGroup(undefined)}
          data={editGroup}
        />

        {/* Delete confirmation modal */}
        <Modal visible={showDeleteConfirmation} close={() => setShowDeleteConfirmation(false)} customWidth={400}>
          <h3>Confirm Delete</h3>
          <p>Are you sure you want to delete the group {group.name}?</p>
          {filteredOffices.length > 0 && (
            <div>
              <p><strong>Warning:</strong> Deleting this group will also delete the following offices and their users:</p>
              <ul>
                {filteredOffices.map((office) => (
                  <li key={office._id}>{office.name}</li>
                ))}
              </ul>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button color="secondary" onClick={() => setShowDeleteConfirmation(false)} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
            <Button color="danger" onClick={confirmDelete}>
              Delete
            </Button>
          </div>
        </Modal>
      </Col>
    </Row>
  );
};

export default GroupPage;
