import { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import Grid from '@material-ui/core/Grid';
import { ChartConfiguration } from 'chart.js';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: 'white',
    padding: '20px 20px 10px',
    borderRadius: 16,
    minHeight: 300,
  },
  barChart: {
    position: 'relative',
  },
  barChart__chart: {
    width: '80%',
    paddingBottom: 10,
  },
  barChart__text: {
    color: theme.palette.gray.g1,
    fontSize: 14,
    fontWeight: 900,
  },
  labels: {
    position: 'absolute',
    top: 0,
    left: 6,
    height: '100%',
    padding: '14px 0 27px',
    '& > div': {
      fontWeight: 900,
      fontSize: 14,
    },
  },
  values: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    padding: '14px 0 27px',
    '& > div': {
      fontWeight: 900,
      fontSize: 14,
    },
  },
}));

const BarChart = ({
  data,
  title,
  labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
}: {
  data: number[];
  title: string;
  labels?: string[];
}) => {
  const classes = useStyles();
  const [chart, setChart] = useState<Chart | null>(null);
  const barChart = useRef<HTMLCanvasElement | null>(null);

  const config: ChartConfiguration = useMemo(
    () => ({
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            backgroundColor: [
              'rgba(76, 162, 205, 0.8',
              '#4CA2CD',
              'rgba(76, 162, 205, 0.7)',
              'rgba(76, 162, 205, 0.43)',
              'rgba(76, 162, 205, 0.2)',
            ],
            borderColor: 'transparent',
            borderSkipped: false,
            borderRadius: { topRight: 7, bottomRight: 7, topLeft: 2, bottomLeft: 2 },
            data: [],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              drawBorder: false,
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              drawBorder: false,
              display: false,
            },
          },
        },
      },
    }),
    [],
  );

  useEffect(() => {
    if (barChart && barChart.current && !chart) {
      setChart(new Chart(barChart.current, config));
    }
  }, [barChart, chart]);

  useEffect(() => {
    if (chart && data.length > 0) {
      chart.data.datasets[0].data = data;
      chart.update();
    }
  }, [data, chart]);

  return (
    <div className={classes.root}>
      <div className={classes.barChart}>
        <Grid container direction="column" alignItems="flex-start" justify="space-between" className={classes.labels}>
          {labels.map((d) => (
            <div key={d}>{d}</div>
          ))}
        </Grid>
        <div className={classes.barChart__chart}>
          <canvas ref={barChart} height={260} />
        </div>
        <Grid container direction="column" alignItems="flex-end" justify="space-between" className={classes.values}>
          {data.map((d, i) => (
            <div key={d + labels[i]}>{d?.toFixed(1)}%</div>
          ))}
        </Grid>
      </div>
      <div className={classes.barChart__text}>{title}</div>
    </div>
  );
};
export default BarChart;
