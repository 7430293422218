import { useEffect, useState } from 'react';
import '../../assets/datePickerCustomStyling.css';
import { makeStyles } from '@material-ui/core';
import calendar from '../../assets/icons/calendar.svg';
import DatePicker from 'react-datepicker';
import { useCreateForecastMutation, useDeleteForecastMutation, useGetForecastsQuery, useUpdateForecastMutation, useUpdateUserMutation } from 'utils/wemble-api';
import { UserDto } from 'utils/wemble-api.generated';
import moment from 'moment-timezone';

import { cleanedDateString, convertLocalToUTCDate, convertUTCToLocalDate, getToday } from './Forecast/utils';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: 12,
  },
  datePicker: {
    transition: '0.2s',
    height: 40,

  },
  icon: {
    position: 'absolute',
    left: 11,
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },
  input: {
    height: 40,
    lineHeight: '40px',
    width: '100%',
    opacity: 0,
    paddingRight: 28,
    fontSize: 14,
    textAlign: 'right',
    transition: '0.2s',
    overflow: 'hidden',
  },
}));

const Calendar = ({ user }: { user: UserDto }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [selected, setSelected] = useState(false);
  const [updateUser] = useUpdateUserMutation();
  const [createForecast] = useCreateForecastMutation();
  const [deleteForecast] = useDeleteForecastMutation();
  const [updateForecast] = useUpdateForecastMutation();

  const { data: allForecasts } = useGetForecastsQuery(undefined, { refetchOnReconnect: true });

  const status = user.onLeave ? 'away' : user.currentWorkload?.toString()
  const today = getToday()
  const forecasts = allForecasts?.filter(forecast => forecast.user === user._id && moment(forecast.endDate).isAfter(today)).sort((a, b) => moment(a.startDate).diff(moment(b.startDate))) || [];
  const currentForecast = forecasts.find(forecast => moment(forecast.startDate) <= moment(moment(today)))
  const nextWorkloadForecast = forecasts.find(forecast => forecast !== currentForecast && forecast.status !== "away")


  useEffect(() => {
    if (user.workloadForecastDate) {
      setSelected(true);
      setStartDate(new Date(user.workloadForecastDate));
    }
  }, [user]);

  function handleChange(selectedDate: Date | null) {
    if (!user._id || !user.name) return;
    const currentDate = new Date();

    if (!selectedDate || selectedDate < currentDate) {
      setSelected(false);
      setStartDate(null);

      updateUser({
        id: user._id,
        userUpdateParams: {
          name: user.name,
          email: user.email,
          admin: Boolean(user.admin),
          workloadForecastDate: null,
        },
      });

      nextWorkloadForecast && deleteForecast({ forecastId: nextWorkloadForecast._id || '', userId: user?._id || '' })
    } else {
      setSelected(true);
      setStartDate(selectedDate);

      if (user.workloadForecastDate !== cleanedDateString(selectedDate)) {
        updateUser({
          id: user._id,
          userUpdateParams: {
            name: user.name,
            email: user.email,
            admin: Boolean(user.admin),
            workloadForecastDate: cleanedDateString(selectedDate),
          },
        });

        nextWorkloadForecast ? updateForecast({
          forecastId: nextWorkloadForecast._id || '', userId: user?._id || '', forecastUpsertParams: {
            startDate: cleanedDateString(selectedDate),
            endDate: cleanedDateString(selectedDate),
            status: (user.futureWorkload || '0').toString() as "0" | "1" | "2" | "3" | "away"
          }
        }) : createForecast({
          userId: user?._id || '', forecastUpsertParams: {
            startDate: cleanedDateString(selectedDate),
            endDate: cleanedDateString(selectedDate),
            status: (user.futureWorkload || '0').toString() as "0" | "1" | "2" | "3" | "away"
          }
        })




      }
    }
  }

  const dateStr = moment(startDate).locale('en').format('ll').split(',')[0]

  return (
    <>
      <div className={classes.root}>
        <div className={classes.datePicker} style={selected ? { width: 112, opacity: 1 } : { width: 40, opacity: 1 }}>
          <DatePicker
            wrapperClassName="datePickerCustomStyling"
            selected={convertUTCToLocalDate(startDate)}
            onChange={(selectedDate: Date) => handleChange(convertLocalToUTCDate(selectedDate))}
            calendarStartDay={1}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            filterDate={(d) => ((date) => date && date >= new Date() && !(forecasts.find(forecast => new Date(cleanedDateString(date)) >= new Date(forecast.startDate || '') && new Date(cleanedDateString(date)) <= new Date(forecast.endDate || ''))))(convertLocalToUTCDate(d))}
            customInput={
              <button className={classes.input} style={selected ? { opacity: 1 } : undefined}>
                {dateStr}
              </button>
            }
            isClearable
          />
        </div>
        <img alt={'calendar-img'} className={classes.icon} src={calendar} />
      </div>
    </>
  );
};

export default Calendar;
