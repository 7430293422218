import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  category: {
    backgroundColor: theme.palette.red.opacity.r16,
    display: 'inline-block',
    fontSize: 12,
    padding: '6px 16px',
    borderRadius: 24,
  },
  category__parent: {
    marginRight: '8px',
    backgroundColor: theme.palette.blue.opacity.b32,
  },
  category__small: {
    padding: '6px 10px',
    fontSize: 12,
    backgroundColor: theme.palette.blue.opacity.b32,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      padding: '4px 8px',
      borderRadius: 20,
    }


  }
}));

const CompetenceCategory = ({ name, parent = false, small = false }: { name: string, parent?: boolean, small?: boolean }) => {
  const classes = useStyles();

  return <span className={classes.category + " " + (parent ? classes.category__parent : "") + " " + (small ? classes.category__small : "")}>{name}</span>;
};
export default CompetenceCategory;
