import { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Item from '../shared/settings/Item';
import Input from '../shared/Input';
import { SettingsButtons } from '../shared/Settings';
import { Checkbox, makeStyles } from '@material-ui/core';
import DatePicker from '../shared/DatePicker';
import UserPickerModal from '../user/UserPickerModal';
import { useGetUsersQuery, useCreateProjectMutation, useRefreshUserMutation } from 'utils/wemble-api';
import { UserDto } from 'utils/wemble-api.generated';
import organization from 'utils/organization';
import { useHistory } from 'react-router-dom';
import OptionsComponent from 'components/users/OptionsComponent';

const useStyles = makeStyles((theme) => ({
  archive: {
    backgroundColor: theme.palette.blueGray.opacity.bg16,
    color: theme.palette.blueGray.bg1,
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-block',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 16px',
  },
  errorMsg: {
    fontSize: 12,
    position: 'absolute',
    left: 16,
    transform: 'translateY(2px)',
    color: theme.palette.red.r4,
  },
}));

const CreateProject = ({ user, close }: { user: UserDto | null | undefined; close: () => void }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const history = useHistory();
  const [userPickerVisible, setUserPickerVisible] = useState(false);
  const [createProject] = useCreateProjectMutation();
  const [refreshUser] = useRefreshUserMutation()
  const { data: users } = useGetUsersQuery();


  if (!user) return null;

  return (
    <div>
      <h2 className={classes.title}>Create project</h2>
      <Formik
        initialValues={{
          name: '',
          company: user.company,
          office: typeof user.office === 'string' ? user.office : user.office?._id ?? '',
          group: typeof user.group === 'string' ? user.group : user.group?._id ?? '',
          description: '',
          startDate: '',
          endDate: '',
          manager: null,
          addUser: false
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          setError(false);
          const res = await createProject({
            projectCreationParams: {
              name: values.name,
              description: values.description,
              office: values.office,
              group: values.group,
              startDate: values.startDate === '' ? undefined : values.startDate,
              endDate: values.endDate === '' ? undefined : values.endDate,
              manager: values.manager ?? undefined,
              addUser: values.addUser ?? false
            },
          });
          if ('error' in res) {
            return setError(true);
          }
          if (res) {
            close();
            history.push('/projects/' + res.data._id);
            if (values.addUser) {
              await refreshUser();
            }

          }
        }}
      >
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <Form>
            <Item title={'Project Name'} input={<Input placeholder={'Project name'} name={'name'} type="text" />} />
            {(!user.group || user.admin || user.administratorPriviliges || user.secondaryGroup) && (<div style={{ display: organization(user?.company).projectsAllGroups ? 'none' : 'block' }}><Item
              title={'Location'}
              input={<OptionsComponent
                selectedOfficeId={values.office}
                selectedGroupId={values.group}
                setSelectedOffice={(officeId: string) => setFieldValue("office", officeId)}
                setSelectedGroup={(groupId: string) => setFieldValue("group", groupId)}
              />}
            /></div>)
            }

            <Item
              title={'Project Description'}
              input={<Input placeholder={'Project description'} name={'description'} type="text" />}
            />
            <Item
              title={'Start'}
              input={<DatePicker selected={values.startDate} onChange={(date) => setFieldValue('startDate', date)} />}
            />
            <Item
              title={'End'}
              input={<DatePicker selected={values.endDate} onChange={(date) => setFieldValue('endDate', date)} />}
            />
            {!organization(user.company || '').disableProjectManager && (
              <Item
                title={'Manager'}
                input={
                  <Input
                    placeholder={'Select...'}
                    name={'manager'}
                    value={values.manager ? users?.find((user) => user._id === values.manager)?.name ?? '' : ''}
                    onClick={() => setUserPickerVisible(true)}
                    clearable={() => {
                      setFieldValue('manager', null);
                    }}
                  />
                }
              />)}
            <Item
              title={'Add me'}
              input={<Checkbox
                color="primary"
                checked={values.addUser}
                onChange={(event) => {
                  setFieldValue('addUser', event.target.checked)
                }}
              />}
            />
            <SettingsButtons
              discardCustom={'Close'}
              discard={() => {
                resetForm();
                close();
              }}
              saveCustom={'Create project'}
              save={() => {
                handleSubmit();
              }}
            />
            <UserPickerModal
              type={'manager'}
              description={'Select a manager'}
              visible={userPickerVisible}
              onClose={() => {
                setUserPickerVisible(false);
              }}
              onSelect={(selectedUsers) => {
                setFieldValue('manager', selectedUsers[0]._id);
              }}
            />
            {error ? 'Make sure you entered all required fields' : ''}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateProject;
