import { useEffect } from 'react';
import SignupForm from './SignupForm';
import { addFlashMessage } from 'store/reducers/flashMessages';
import { useHistory, useParams } from 'react-router-dom';
import { useGetUncompletedUserByIdQuery } from 'utils/wemble-api';
import { useAppDispatch, useAppSelector } from 'store';
import Redirect from 'components/Redirect';

const SignupPage = () => {

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const authenticated = useAppSelector((state) => state.auth.authenticated);

  const { data: user, isError, isLoading } = useGetUncompletedUserByIdQuery({ id });

  useEffect(() => {
    if (isError || (!isLoading && !user)) {
      dispatch(
        addFlashMessage({
          type: 'error',
          text: 'Something went wrong',
        }),
      );
      history.push('/login');
    }
  }, [isError, isLoading, dispatch]);

  if (authenticated) {
    return <Redirect />;
  }

  if (!user) return null;

  return <SignupForm user={user}  />;
};

export default SignupPage;
