import { makeStyles } from '@material-ui/core';
import minus from '../../assets/icons/minus.svg';
import plus from '../../assets/icons/plus.svg';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { useGetCurrentUserQuery, useCreateWorkloadMutation, useCreateForecastMutation } from 'utils/wemble-api';
import { useTeamsAuth } from 'hooks/useTeamsAuth';

const useStyles = makeStyles(() => ({
  root: {
    width: 105,
    height: 40,
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: 12,
    '& div': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        '& > img': {
          filter: 'invert(72%) sepia(0%) saturate(0%) hue-rotate(136deg) brightness(103%) contrast(98%)',
        },
      },
    },
    '& img': {
      userSelect: 'none',
    },
  },
  minus: {
    borderRadius: '12px 0 0 12px',
  },
  plus: {
    borderRadius: '0 12px 12px 0',
  },
  divider: {
    width: '4px !important',
    borderRadius: '4px',
    marginTop: '6px',
    height: 'calc(100% - 12px) !important',
    backgroundColor: '#E2E2E2',
    cursor: 'default !important',

  },
  disabled: {
    cursor: 'default !important',
    '& > img': {
      filter: 'invert(72%) sepia(0%) saturate(0%) hue-rotate(136deg) brightness(103%) contrast(98%)',
    },
  },
}));

const Select = ({ future, disabled, forecastDate }: { future: boolean; disabled?: boolean, forecastDate?: string }) => {
  const classes = useStyles();
  const { data: user } = useGetCurrentUserQuery();
  const [createWorkload] = useCreateWorkloadMutation();
  const [createForecast] = useCreateForecastMutation();
  const { isInTeams, clientType } = useTeamsAuth();

  if (!user) return null;

  function update(change: number) {
    if (typeof workload() !== 'number') return;
    const newWorkloadValue = (workload() || 0) + change;

    if (newWorkloadValue <= 3 && newWorkloadValue >= 0) {

      const isIosApp = Boolean(window.navigator?.userAgent?.includes('Wemble(iOS)'));

      createWorkload({
        type: future ? 'future' : 'current',
        workloadCreationParams: {
          workscale: newWorkloadValue,
          source: isIosApp ? "ios_app" : (isInTeams ? `teams_app_${clientType}` : "web_app")
        },
      });

      if (future && forecastDate) {
        createForecast({
          userId: user?._id || '', forecastUpsertParams: {
            startDate: forecastDate,
            endDate: forecastDate,
            status: newWorkloadValue.toString() as "0" | "1" | "2" | "3" | "away"
          }
        })
      }
      ReactGA.event({
        category: 'Workload',
        action: `Change ${future ? 'future' : 'current'}`,
        value: newWorkloadValue,
      });
    }
  }

  function workload() {
    return future ? user?.futureWorkload : user?.currentWorkload
  }

  return (
    <div className={classes.root}>
      <div
        className={classNames(classes.minus, (workload() === 0 || disabled) && classes.disabled)}
        onClick={(e) => {
          if (disabled) return;
          e.stopPropagation();
          update(-1);
        }}
      >
        <img alt={'minus'} src={minus} draggable={false} />
      </div>
      <div className={classes.divider} />
      <div
        className={classNames(classes.plus, (workload() === 3 || disabled) && classes.disabled)}
        onClick={(e) => {
          if (disabled) return;
          e.stopPropagation();
          update(1);
        }}
      >
        <img alt={'plus'} src={plus} draggable={false} />
      </div>
    </div>
  );
};

export default Select;
