import UsersPage from 'components/users/UsersPage';
import { useParams, useHistory } from 'react-router-dom';
import {
  useGetFilteredUsersMutation,
  useGetOfficesQuery,
  useGetGroupsQuery,
  useDeleteOfficeMutation,
  useUpdateOfficeMutation,
} from 'utils/wemble-api';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useCallback, useEffect, useState } from 'react';
import { OfficeDto, UserDto } from 'utils/wemble-api.generated';
import { notEmpty } from 'utils/helpers';

const OfficeShow = () => {
  const [users, setUsers] = useState<UserDto[] | undefined | null>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data: offices } = useGetOfficesQuery();
  const { data: groupsRes } = useGetGroupsQuery();
  const group = groupsRes?.groups?.find((g) => g._id === offices?.find((o) => o._id === id)?.group);
  const [getFilteredUsers, { isLoading }] = useGetFilteredUsersMutation();
  const office = offices?.find((o) => o._id === id);

  const [deleteOffice, { isLoading: deletingOffice }] = useDeleteOfficeMutation();
  const [updateOffice, { isLoading: updatingOffice }] = useUpdateOfficeMutation();

  const fetchAndSetUsers = useCallback(() => {
    if (!office) return;
    getFilteredUsers({
      body: {
        filters: {
          offices: [id],
          groups: [typeof office.group == 'string' ? office.group : office.group?._id].filter(notEmpty),
          completed: false,
        },
      },
    })
      .unwrap()
      .then((users) => setUsers(users.filter((user) => !user.companyAdmin)));
  }, [office, id]);

  const handleDelete = async () => {
    if (!office?._id) return;
    await deleteOffice({
      id: office._id,
    });
    history.replace(`/groups/${group?._id}`);
  };

  useEffect(() => {
    if (users) return;
    fetchAndSetUsers();
  }, [fetchAndSetUsers, users]);

  const [editOffice, setEditOffice] = useState<OfficeDto | undefined>();
  const [newName, setNewName] = useState('');
  const disabled = deletingOffice || updatingOffice;

  const toggleRenameModal = (office?: OfficeDto) => {
    if (office) {
      setEditOffice(office);
      setNewName(office.name ?? '');
    } else if (!disabled) {
      setEditOffice(undefined);
      setNewName('');
    }
  };

  const handleUpdateOfficeName = async () => {
    if (office?._id && newName.length > 0) {
      await updateOffice({
        id: office._id,
        officeUpdateParams: {
          name: newName,
        },
      });
      toggleRenameModal();
    }
  };

  return (
    <>
      <UsersPage
        users={users}
        hideFilters
        loading={isLoading && !users}
        handleBack={
          group?.name && group._id
            ? {
                text: group.name,
                path: `/groups/${group._id}`,
              }
            : undefined
        }
        office={id}
        group={group?._id}
        onAfterUserModified={fetchAndSetUsers}
      >
        <Button color="danger" onClick={() => setShowConfirmModal(true)} disabled={deletingOffice}>
          Delete
        </Button>
        <Button color="primary" style={{ marginLeft: 8 }} onClick={() => toggleRenameModal(office)}>
          Rename
        </Button>

        <Modal isOpen={showConfirmModal} toggle={() => !disabled && setShowConfirmModal(false)}>
          <ModalHeader toggle={() => !disabled && setShowConfirmModal(false)}>Delete</ModalHeader>
          <ModalBody>
            <b>Warning!</b> <br />
            Are you sure you want to delete {office?.name}?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setShowConfirmModal(false)} disabled={disabled}>
              Cancel
            </Button>{' '}
            <Button color="danger" onClick={handleDelete} disabled={disabled}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={editOffice !== undefined} toggle={() => toggleRenameModal()}>
          <ModalHeader toggle={() => toggleRenameModal()}>Rename</ModalHeader>
          <ModalBody>
            <table className={'table'}>
              <tbody>
                <tr>
                  <th scope="row" className={'control-label'}>
                    New name
                  </th>
                  <td>
                    <input
                      value={newName}
                      type="text"
                      name="name"
                      className={'form-control'}
                      onChange={(ev) => setNewName(ev.target.value)}
                      disabled={disabled}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => toggleRenameModal()} disabled={disabled}>
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={handleUpdateOfficeName} disabled={disabled || newName.length === 0}>
              Rename
            </Button>
          </ModalFooter>
        </Modal>
      </UsersPage>
    </>
  );
};

export default OfficeShow;
