import { makeStyles } from '@material-ui/core';
import ProjectLink from 'components/projects/ProjectLink';
import { ProjectDto, ProjectWithUsersDto, UserDto } from 'utils/wemble-api.generated';
import { useGetUsersQuery, useGetGroupsQuery, useGetOfficesQuery } from 'utils/wemble-api';
import organization from 'utils/organization';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10px',
  },
}));

type GroupedProjects = {
  my: ProjectDto[];
  others: ProjectDto[];
  archived: ProjectDto[];
};

const ProjectsList = ({
  currentUser,
  projects
}: {
  currentUser: UserDto | null | undefined;
  projects: ProjectWithUsersDto[] | null | undefined;
}) => {
  const classes = useStyles();
  const { data: groups } = useGetGroupsQuery();
  const { data: offices } = useGetOfficesQuery();
  const { data: users } = useGetUsersQuery();
  const { disableProjectManager } = organization(currentUser?.company);

  const filteredProjects: GroupedProjects =
    projects?.reduce(
      (result, currentProject) => {
        if (currentProject.archived) {
          result.archived.push(currentProject);
          return result;
        }
        if (
          currentUser?.projects?.find(
            (project) => currentProject._id === (typeof project === 'string' ? project : project?._id),
          ) !== undefined ||
          currentProject.manager?._id === currentUser?._id
        ) {
          result.my.push(currentProject);
          return result;
        }
        result.others.push(currentProject);
        return result;
      },
      { my: [], others: [], archived: [] } as GroupedProjects,
    ) ?? ({ my: [], others: [], archived: [] } as GroupedProjects);

  return (
    <div className={classes.root}>
      {filteredProjects && (
        <>
          {filteredProjects.my.length > 0 && (
            <>
              <h5>My projects</h5>
              {filteredProjects.my.map((project) => (
                <ProjectLink
                  key={`my-projects-${project._id}`}
                  project={project}
                  users={users}
                  groups={groups?.groups}
                  offices={offices}
                  disableProjectManager={disableProjectManager}
                  user={currentUser}
                />
              ))}
              <br />
            </>
          )}
          {filteredProjects.others.length > 0 && (
            <>
              {filteredProjects.my.length > 0 && <h5>Others</h5>}
              {filteredProjects.others.map((project) => (
                <ProjectLink
                  key={`others-projects-${project._id}`}
                  project={project}
                  users={users}
                  groups={groups?.groups}
                  offices={offices}
                  disableProjectManager={disableProjectManager}
                  user={currentUser}
                />
              ))}
              <br />
            </>
          )}
          {filteredProjects.archived.length > 0 && (
            <>
              <h5>Archived</h5>
              {filteredProjects.archived.map((project) => (
                <ProjectLink
                  key={`archived-projects-${project._id}`}
                  project={project}
                  users={users}
                  groups={groups?.groups}
                  offices={offices}
                  disableProjectManager={disableProjectManager}
                  user={currentUser}

                />
              ))}
              <br />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ProjectsList;
