import { useCallback, useEffect, useState } from "react";
import { useGetUtilizationReportMutation } from "utils/wemble-api";
import { UserDto, UserFilterParams, UtilizationReport } from "utils/wemble-api.generated";
import UtilizationTable from "./UtilizationTable";
import ReactLoading from 'react-loading';
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles(() => ({
  loader: {
    fill: '#59abcb',
    margin: 'auto',
    marginTop: 50,
    marginBottom: 50,
    width: '10%',
  }
}));

const UtilizationPage = ({
  filters,
  filteredUsers
}: {
  filters: UserFilterParams | undefined,
  filteredUsers?: UserDto[]
}) => {
  const [getUtilizationReport] = useGetUtilizationReportMutation();
  const [utilizationReport, setUtilizationReport] = useState<UtilizationReport | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const somethingSelected =
    filters && ((filters.groups?.length || 0) + (filters.offices?.length || 0) + (filters.competences?.length || 0) + (filters.projects?.length || 0) + (filters.cvpartnerData?.length || 0) + (filters.term?.length || 0)) > 0


  const fetchUtilizationReport = useCallback(
    async (showLoader = true) => {
      if (!filters) return;
      if (somethingSelected) {
        if (showLoader) setIsLoading(true);
        const res = await getUtilizationReport({
          body: {
            filters,
          },
        });
        if ('data' in res) {
          setUtilizationReport(res.data)
        }
        if (showLoader) setIsLoading(false);
      } else {
        setUtilizationReport(undefined);
      }
    },
    [filters, somethingSelected],
  );

  useEffect(() => {
    fetchUtilizationReport();
  }, [fetchUtilizationReport]);



  return (
    <>
      {utilizationReport && utilizationReport.length ? <UtilizationTable filteredUsers={filteredUsers} utilizationReport={utilizationReport} /> : ""}
      {isLoading && <ReactLoading type={'spinningBubbles'} className={classes.loader} />}

    </>
  );
};

export default UtilizationPage;
