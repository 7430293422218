import { Link, NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/BusinessRounded';

import { useSetAuth } from 'hooks';
import { useGetCurrentUserQuery, usePrefetch } from 'utils/wemble-api';

import dashboardIcon from '../../assets/icons/home.svg';
import statisticsIcon from '../../assets/icons/statistics.svg';
import projectsIcon from '../../assets/icons/projects.svg';
import competenceIcon from '../../assets/icons/competences.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import usersIcon from '../../assets/icons/users.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import wemble from '../../assets/companyLogos/wemble.svg';
import organization from 'utils/organization';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: 'white',
    height: '100%',
    width: 75,
    position: 'fixed',
    zIndex: 1,
    top: 0,
    left: 0,
    padding: '20px 10px 0',
  },
  sidebar__logo: {
    width: '100%',
    padding: '3px',
    marginBottom: '-3px',
  },
  sidebar__links: {
    marginTop: 30,
    '& > a:nth-child(1).active~.with-indicator': {
      top: '89px !important',
    },
    '& > a:nth-child(2).active~.with-indicator': {
      top: 'calc(89px + 65px) !important',
    },
    '& > a:nth-child(3).active~.with-indicator': {
      top: 'calc(89px + (65px * 2)) !important',
    },
    '& > a:nth-child(4).active~.with-indicator': {
      top: 'calc(89px + (65px * 3)) !important',
    },
    '& > a:nth-child(5).active~.with-indicator': {
      top: 'calc(89px + (65px * 4)) !important',
    },
    '& > a:nth-child(6).active~.with-indicator': {
      top: 'calc(89px + (65px * 5)) !important',
    },
    '& > a:nth-child(7).active~.with-indicator': {
      top: 'calc(89px + (65px * 6)) !important',
    },
  },
  links__link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 55,
    marginBottom: 10,
    '& > img': {
      filter: 'brightness(50%)',
      width: '50%',
    },
    '& > svg': {
      color: 'black',
      filter: 'brightness(50%)',
      width: '50%',
      height: '50%',
    },
  },
  links__link_active: {
    '& > img': {
      filter: 'invert(70%) sepia(20%) saturate(717%) hue-rotate(155deg) brightness(94%) contrast(94%); !important',
    },
    '& > svg': {
      filter: 'invert(70%) sepia(20%) saturate(717%) hue-rotate(155deg) brightness(94%) contrast(94%); !important',
    },
  },
  indicator: {
    transition: '0.2s ease-in-out',
    borderRadius: '0 8px 8px 0',
    position: 'absolute',
    width: '6px',
    height: '55px',
    background: theme.palette.blue.b2,
    left: 0,
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const setAuth = useSetAuth();
  const { pathname } = useLocation();
  const smallDevice = useMediaQuery('(max-width:1117px)');
  const prefetchLevelOfSkills = usePrefetch('getLevelOfSkills');
  const prefetchUsers = usePrefetch('getUsers');
  const prefetchCompetences = usePrefetch('getCompetences');


  const { data: user } = useGetCurrentUserQuery();

  if (!user) return null;

  const organizationConfig = organization(user.company);

  return (
    <div className={classes.sidebar}>
      <Link to="/home">
        <img alt="logo" src={wemble} className={classes.sidebar__logo} />
      </Link>
      <div className={classes.sidebar__links}>
        {
          <NavLink
            className={classes.links__link}
            to="/home"
            activeClassName={classNames(classes.links__link_active, 'active')}
            onMouseEnter={() => prefetchUsers(undefined, { ifOlderThan: 3 })}

          >
            <img src={dashboardIcon} alt="dashboard" />
          </NavLink>
        }

        {!organizationConfig.disableProjects && (
          <NavLink
            className={classes.links__link}
            to="/projects"
            activeClassName={classNames(classes.links__link_active, 'active')}
          >
            <img src={projectsIcon} alt="projects" />
          </NavLink>
        )}

        {!organizationConfig.disableCompetences && (
          <NavLink
            className={classes.links__link}
            to="/competences"
            activeClassName={classNames(classes.links__link_active, 'active')}
            onMouseEnter={() => { prefetchLevelOfSkills(undefined, { ifOlderThan: 500 }); prefetchCompetences(undefined, { ifOlderThan: 10 }); }}
          >
            <img src={competenceIcon} alt="competences" />
          </NavLink>
        )}

        {(user.admin || user.administratorPriviliges) && !user.companyAdmin && (
          <NavLink
            className={classes.links__link}
            to="/users"
            activeClassName={classNames(classes.links__link_active, 'active')}
          >
            <img src={usersIcon} alt="users" />
          </NavLink>
        )}
        {
          <NavLink
            className={classes.links__link}
            to="/settings"
            activeClassName={classNames(classes.links__link_active, 'active')}
          >
            <img src={settingsIcon} alt="settings" />
          </NavLink>
        }
        {!smallDevice && (
          <NavLink
            className={classes.links__link}
            to="/statistics"
            activeClassName={classNames(classes.links__link_active, 'active')}
          >
            <img src={statisticsIcon} alt="statistics" />
          </NavLink>
        )}
        {user.companyAdmin && (
          <NavLink
            className={classes.links__link}
            to="/groups"
            isActive={() => ['/groups', '/offices'].some((route) => pathname.startsWith(route))}
            activeClassName={classNames(classes.links__link_active, 'active')}
          >
            <BusinessIcon />
          </NavLink>
        )}
        <Link
          className={classes.links__link}
          style={{
            position: 'absolute',
            bottom: 0,
            width: 55,
          }}
          to="/"
          onClick={() => setAuth(false)}
        >
          <img
            src={logoutIcon}
            alt="logout"
            style={{
              transform: 'rotate(90deg)',
            }}
          />
        </Link>
        {<div className={classNames(classes.indicator, 'with-indicator')} />}
      </div>
    </div>
  );
};

export default SideBar;
