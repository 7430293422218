import { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import PageTitle from '../components/PageTitle';
import SettingsContainer, { SettingsButtons } from '../components/shared/Settings';
import { Formik } from 'formik';
import Input from '../components/shared/Input';
import Item from '../components/shared/settings/Item';
import Select from 'react-select';
import Modal from '../components/shared/Modal';
import ChangePassword from '../components/settings/ChangePassword';
import settingsStyles from './../theme/react-select/settings';
import FormData from 'form-data';
import { useGetCurrentUserQuery, useRefreshTokenQuery, useUpdateUserMutation, useUpdateUserProfilePictureMutation } from 'utils/wemble-api';
import organization from 'utils/organization';

const useStyles = makeStyles((theme) => ({
  settings: {
    marginBottom: 200,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  settings__profilePicture: {
    display: 'flex',
    justifyContent: 'center',
  },
  profilePicture__container: {
    height: 110,
    width: 110,
    borderRadius: '50%',
    overflow: 'hidden',
    marginBottom: 30,
    position: 'relative',
  },
  profilePicture__img: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.gray.g6,
    textIndent: '110px',
    height: '100%',
    width: '100%',
  },
  profilePicture__icon: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#eee',
    transition: '0.3s ease-out',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      opacity: '1 !important',
    },
  },
  fileInput: {
    width: '110px',
    height: '110px',
    position: 'absolute',
    left: 0,
    opacity: 0,
    cursor: 'pointer',
  },
}));

const employedOptions: { value: number; label: string }[] = [];
for (let i = new Date().getFullYear(); i + 30 > new Date().getFullYear(); i--) {
  employedOptions.push({ value: i, label: i.toString() });
}
employedOptions.push({
  value: 0,
  label: 'Before ' + (new Date().getFullYear() - 30).toString(),
});

const updateRemindersOptions: { value: number; label: string }[] = [
  {
    value: 0,
    label: 'Disabled',
  },
  {
    value: 7,
    label: 'After three days',
  },
];

const Settings = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const [updateUser] = useUpdateUserMutation();
  const [updateUserProfilePicture] = useUpdateUserProfilePictureMutation();
  const { data: user } = useGetCurrentUserQuery();
  const { refetch: refetchToken } = useRefreshTokenQuery();

  const [selectedNotifications, setSelectedNotifications] = useState<number | undefined>();

  useEffect(() => {
    if (user && selectedNotifications === undefined) {
      setSelectedNotifications(user.updateReminders ?? updateRemindersOptions[0].value);
    }
  }, [user]);

  if (!user) return null;

  const toggleModal = () => {
    setVisible(!visible);
  };

  const handleProfilePicture = async (event) => {
    if (event.target.files && event.target.files.length > 0 && user._id) {
      const formData = new FormData();
      formData.append('image', event.target.files[0]);
      updateUserProfilePicture({
        id: user._id,
        // @ts-ignore
        body: formData,
      });
    }
  };

  return (
    <>
      <Container className={classes.settings}>
        <PageTitle title={'Settings'} />
        <SettingsContainer>
          <div className={classes.settings__profilePicture}>
            <div className={classes.profilePicture__container}>
              <img
                alt="Profile picture"
                src={(user.profilePicture && ((user.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user.profilePicture)) || ""}
                className={classes.profilePicture__img}
                style={{ opacity: user.profilePicture ? 1 : 0 }}
              />
              <span
                className={classes.profilePicture__icon}
                style={{
                  opacity: user.profilePicture ? 0 : 1,
                }}
              >
                <svg
                  x="0px"
                  y="0px"
                  viewBox="0 0 307.308 307.308"
                  style={{
                    width: '50px',
                    height: '50px',
                  }}
                >
                  <g id="camera">
                    <path d="M284.909,66.146h-81.345l-16.426-27.595c-1.607-2.698-4.514-4.351-7.654-4.351h-51.662c-3.14,0-6.048,1.653-7.654,4.351   l-16.426,27.595H77.049v-6.082c0-4.919-3.988-8.907-8.907-8.907H35.185c-4.92,0-8.907,3.988-8.907,8.907v6.082h-3.88   C10.027,66.146,0,76.174,0,88.543v162.166c0,12.37,10.027,22.398,22.397,22.398h262.512c12.37,0,22.398-10.028,22.398-22.398   V88.543C307.308,76.174,297.279,66.146,284.909,66.146z M153.653,233.379c-35.21,0-63.753-28.543-63.753-63.754   c0-35.209,28.543-63.753,63.753-63.753c35.21,0,63.753,28.544,63.753,63.753C217.406,204.836,188.863,233.379,153.653,233.379z    M270.935,112.322h-27.91c-4.919,0-8.907-3.988-8.907-8.908c0-4.92,3.988-8.908,8.907-8.908h27.91c4.921,0,8.908,3.988,8.908,8.908   C279.843,108.334,275.855,112.322,270.935,112.322z" />
                    <circle cx="153.653" cy="169.625" r="44.538" />
                  </g>
                </svg>

                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={(event) => handleProfilePicture(event)}
                  className={classes.fileInput}
                />
              </span>
            </div>
          </div>
          <Formik
            enableReinitialize
            initialValues={{
              name: user.name ?? '',
              email: user.email ?? '',
              title: user.title ?? '',
              phone: user.phone ?? '',
              startYear: user.startYear ?? new Date().getFullYear(),
            }}
            onSubmit={async (values) => {
              if (!user._id) return;
              await updateUser({
                id: user._id,
                userUpdateParams: {
                  ...values,
                  admin: Boolean(user.admin),
                  updateReminders: selectedNotifications ?? 0,
                },
              });
              refetchToken();

            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <form>
                <Item title={'Name'} input={<Input placeholder={'name'} name={'name'} type="text" />} />
                <Item
                  title={'Email'}
                  input={<Input placeholder={'email@getwemble.com'} name={'email'} type="text" />}
                />
                {!user.companyAdmin && !organization(user.company).hideUpdateRemindersSetting && (
                  <Item
                    title={'Update Notifications'}
                    input={
                      <Select
                        defaultValue={updateRemindersOptions.find((obj) => obj.value === selectedNotifications)}
                        value={updateRemindersOptions.find((obj) => obj.value === selectedNotifications)}
                        onChange={(option) => setSelectedNotifications(option.value)}
                        options={updateRemindersOptions}
                        styles={settingsStyles}
                      />
                    }
                  />
                )}
                <Item title={'Phone'} input={<Input placeholder={'xxxx xx xx xx'} name={'phone'} type="text" />} />
                <Item
                  title={'Employed'}
                  input={
                    <Select
                      value={{
                        value: values.startYear,
                        label: employedOptions.find((user) => user.value === values.startYear)?.label,
                      }}
                      onChange={(option) => {
                        setFieldValue('startYear', option.value);
                      }}
                      options={employedOptions}
                      styles={settingsStyles}
                    />
                  }
                />
                <Item title={'Title'} input={<Input placeholder={'title'} name={'title'} type="text" />} />
                <SettingsButtons
                  changePassword={toggleModal}
                  discard={() => {
                    resetForm();
                    if (user.updateReminders !== null) {
                      setSelectedNotifications(user.updateReminders);
                    }
                  }}
                  save={handleSubmit}
                />
              </form>
            )}
          </Formik>
        </SettingsContainer>
        <Modal visible={visible} close={toggleModal}>
          <ChangePassword handleClose={toggleModal} />
        </Modal>
      </Container>
    </>
  );
};

export default Settings;
