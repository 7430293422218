import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import { GroupDto, LevelOfSkillDto, OfficeDto, UserDto, ProjectStageDto, ProjectCategoryDto } from 'utils/wemble-api.generated';

const HighlightedText = ({ text, highlight }: { text: string; highlight: string }) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span key={part + i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
          {part}
        </span>
      ))}{' '}
    </span>
  );
};

type BaseProps = {
  title: string;
  handleAddItem?: () => void;
  isGroup?: boolean;
  isSkillView?: boolean;
  search?: string;
};

type UserProps = {
  dataType: 'user';
  data: UserDto[] | undefined;
  handleItemClick?: (value: UserDto) => void;
};

type GroupProps = {
  dataType: 'group';
  data: GroupDto[] | undefined;
  handleItemClick?: (value: GroupDto) => void;
};

type OfficeProps = {
  dataType: 'office';
  data: OfficeDto[] | undefined;
  handleItemClick?: (value: OfficeDto) => void;
};

type LevelOfSkillProps = {
  dataType: 'levelOfSkill';
  data: LevelOfSkillDto[] | undefined;
  handleItemClick?: (value: LevelOfSkillDto) => void;
};

type ProjectStageProps = {
  dataType: 'projectStage';
  data: ProjectStageDto[] | undefined;
  handleItemClick?: (value: ProjectStageDto) => void;
};

type ProjectCategoryProps = {
  dataType: 'projectCategory';
  data: ProjectCategoryDto[] | undefined;
  handleItemClick?: (value: ProjectCategoryDto) => void;
};



type DataProps = UserProps | GroupProps | OfficeProps | LevelOfSkillProps |  ProjectStageProps | ProjectCategoryProps;

const List = ({
  title,
  data,
  handleAddItem,
  handleItemClick,
  isGroup = false,
  isSkillView = false,
  search = '',
}: BaseProps & DataProps) => {
  return (
    <div>
      <h4>
        <span>{title}</span>
        {handleAddItem && (
          <Button
            style={{
              filter: 'brightness(115%)',
              color: 'white',
            }}
            color={'primary'}
            size="sm"
            className={'float-right flexible-font'}
            onClick={() => handleAddItem()}
          >
            {isGroup ? 'Add' : 'Invite'}
          </Button>
        )}
      </h4>

      <ListGroup
        style={{
          marginBottom: 20,
        }}
      >
        {data?.map((item) => (
          <ListGroupItem
            style={{ pointerEvents: 'auto', cursor: 'pointer' }}
            key={item._id}
            data_id={item._id}
            tag="a"
            onClick={() => handleItemClick && handleItemClick(item)}
          >
            {isSkillView ? item.name : <HighlightedText text={item.name} highlight={search} />}

            {isSkillView && (
              <span style={{ float: 'right', margin: 0, pointerEvents: 'none' }} className="text-secondary">
                Level: {item.level}
              </span>
            )}
            {!isGroup && !item.completed && (
              <span
                style={{
                  float: 'right',
                  margin: 0,
                  pointerEvents: 'none',
                }}
                className="text-secondary"
              >
                Not completed
              </span>
            )}

            {item.external && (
              <span
                style={{
                  float: 'right',
                  margin: 0,
                  pointerEvents: 'none',
                }}
                className="text-secondary"
              >
                From CV Partner
              </span>
            )}
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export default List;
