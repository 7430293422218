import colors from '../colors';

function filterBackgroundColor(data) {
    switch (data.type) {
        case 'competence':
            return colors.blue.opacity.b32;
        case 'project':
            return colors.yellow.opacity.y16;
        case 'cvpartnerData':
            return '#8abc6629';
        default:
            return data.color ? (data.color + '20') : colors.blue.opacity.b16;
    }
}

function filterColor(data) {
    switch (data.type) {
        case 'competence':
            return '#5a5895';
        case 'project':
            return colors.yellow.y3;
        case 'cvpartnerData':
            return '#8abc66';
        default:
            return data.color ?? colors.blue.b2;
    }
}

const sortStyle = (small = false) => ({
    container: (provided) => ({
        ...provided,
        fontSize: 14,
        fontWeight: 500,
        fontFamily: 'Roboto'

    }),
    control: (provided) => ({
        ...provided,
        border: 'none',
        borderRadius: 8,
        boxShadow: 'none',
        padding: '4px 4px',
        ...(small ? { width: '160px', backgroundColor: '#F7F7F7', paddingLeft: '8px', paddingBottom: '2px', paddingTop: '2px' } : undefined)

    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'black',
    }),
    singleValue: (provided) => ({
        ...provided,
        position: 'relative',
        transform: 'none',
        maxWidth: 'none',
        paddingLeft: 8
    }),
    multiValue: (provided, { data }) => ({
        ...provided,
        padding: 2,
        borderRadius: 6,
        marginRight: 4,
        backgroundColor: filterBackgroundColor(data),
        color: filterColor(data),
    }),
    multiValueLabel: (provided, { data }) => ({
        ...provided,
        color: filterColor(data),
        fontSize: 14,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        transition: '0.1s',
        transform: 'scale(1.1) translateY(-0.5px)',
        '&:hover': {
            backgroundColor: 'transparent',
            color: colors.gray.g1,
            opacity: 0.5,
        },
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
    }),
    indicatorSeparator: () => ({}),
    indicatorContainer: (provided) => ({
        ...provided,
        paddingRight: 0,
    }),
    menu: (provided) => ({
        ...provided,
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
    }),
    groupHeading: (provided) => ({
        ...provided,
        paddingBottom: 6,
        marginBottom: 0,
        borderBottom: 'solid 1px',
        borderBottomColor: colors.gray.g6,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected && colors.blueGray.bg1,
        '&:hover': {
            backgroundColor: !state.isSelected && colors.gray.g3,
            //opacity: 0.7,
        },
        '&:nth-child(1)': {
            borderRadius: '4px 4px 0 0',
        },
        '&:nth-last-child(1)': {
            borderRadius: '0 0 4px 4px',
        },
    }),
});
export default sortStyle;

/*
clearIndicator
dropdownIndicator
group
input
loadingIndicator
loadingMessage
menuPortal
noOptionsMessage
placeholder
 */
