import CardItem from '../CardItem';
import AvatarRow from '../../../AvatarRow';
import { useTheme } from '@material-ui/core';
import { CompetenceDto, UserDto } from 'utils/wemble-api.generated';
import { useGetCompetencesQuery } from 'utils/wemble-api';

const CompetenceCards = ({ data }: { data: CompetenceDto }) => {
  const theme = useTheme();
  const unskilledCount = (data.users && data.users.filter(user => user.skills.find(skill => skill.competence === data._id)?.level?.level == 0).length) || 0

  const { data: competences } = useGetCompetencesQuery(undefined, { refetchOnMountOrArgChange: 15, refetchOnReconnect: true });
  const parentOfParent = data.parent?.parent && (competences?.find(c => c._id == data.parent?.parent)?.name)


  return (
    <>
      {data.users && <CardItem data={data.users.length} description={'Users'} />}
      {data.averageSkillLevel && (<CardItem
        data={Math.round((data.averageSkillLevel + Number.EPSILON) * 100) / 100}
        description={'Avg. skill level'}
      />)}
      {unskilledCount > 0 && (<CardItem
        data={unskilledCount}
        description={data.users && data.users.find(user => user.skills.find(skill => skill.competence === data._id)?.level?.level == 0)?.skills.find(skill => skill?.level?.level == 0)?.level.name || undefined}
      />)}



      {data.parent && (
        <CardItem
          data={data.parent.name ?? ''}
          description={parentOfParent || 'Category'}
          backgroundColor={theme.palette.blue.b4}
          dataColor={theme.palette.blue.b5}
          descColor={theme.palette.gray.g1}
        />
      )}

      {data.users && <AvatarRow members={data.users as UserDto[]} size={'medium'} />}
    </>
  );
};
export default CompetenceCards;
