import { Route, Switch } from 'react-router-dom';

import SignupPage from './components/signup/SignupPage';
import ResetPage from './components/reset/ResetPage';
import LoginPage from './components/login/LoginPage';
import Redirect from './components/Redirect';
import PrivacyPage from './components/PrivacyPage';
import GroupsPage from './components/groups/GroupsPage';
import GroupPage from './components/group/GroupPage';

import Home from './views/Home';
import HomeV2 from './views/HomeV2';

import Projects from './views/Projects';
import Project from './components/projects/project/Project';

import Competences from './views/Competences';
import Competence from './components/competences/competence/Competence';

import Statistics from './views/Statistics';

import Settings from './views/Settings';

import AuthRoute from 'components/AuthRoute';
import OfficeShow from 'views/OfficeShow';
import Users from 'views/Users';
import UserLayout from 'components/UserLayout';
import { useGetCurrentUserQuery } from 'utils/wemble-api';
import organization from 'utils/organization';

export default () => {

  const { data: currentUser } = useGetCurrentUserQuery();
  // const V2 = (
  //   ["Maria Antrea", "Mike Specter"].includes(currentUser?.name || '')
  //   || organization(currentUser?.company).name == "Falk Steadman LLP"
  //   || ["66309b91113dc300150542f6", "5e425ccb6d4ea9001678e002", "5daeb8637e1c4600165d587f", "5da2f6c75438640016f8da9f"].includes(typeof currentUser?.group == 'string' ? currentUser?.group : (currentUser?.group?._id || ''))
  // )
  const V2 = organization(currentUser?.company).enableNewForecasting

  return (
    <Switch>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/signup/:id" exact component={SignupPage} />
      <Route path="/privacy" exact component={PrivacyPage} />
      <Route path="/reset/:resetHash" exact component={ResetPage} />

      <UserLayout>
        <Switch>
          <AuthRoute path="/home" exact render={() => V2 ? <HomeV2 /> : <Home />} />
          <AuthRoute path="/statistics" exact render={() => <Statistics />} />
          <AuthRoute path="/users" exact admin render={() => <Users />} />
          <AuthRoute path="/settings" exact render={() => <Settings />} />

          <AuthRoute path="/groups" exact admin companyAdmin render={() => <GroupsPage />} />
          <AuthRoute path="/groups/:id" exact admin companyAdmin render={() => <GroupPage />} />

          <AuthRoute path="/offices/:id" exact admin companyAdmin render={() => <OfficeShow />} />

          <AuthRoute path="/projects" exact render={() => <Projects />} />
          <AuthRoute path="/projects/:id/:view?" exact render={() => <Project />} />

          <AuthRoute path="/competences" exact render={() => <Competences />} />
          <AuthRoute path="/competences/:id/:view?" exact render={() => <Competence />} />

          <Route path="*" component={Redirect} />
        </Switch>
      </UserLayout>
    </Switch>
  )
};
