import { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import 'chartjs-adapter-moment';

const useStyles = makeStyles((theme) => ({
  bubbleChart: {
    backgroundColor: 'white',
    borderRadius: 16,
    minHeight: 300,
    height: '100%',
    padding: '20px 20px 0 0',
  },
  bubbleChart__chart: {
    position: 'relative',
    width: '100%',
  },
  title: {
    paddingLeft: 20,
    color: theme.palette.gray.g1,
    fontWeight: 900,
    fontSize: 14,
  },
  chartText: {
    position: 'absolute',
    color: '#000',
    fontWeight: 900,
    fontSize: 14,
    bottom: 18,
    left: 10,
  },
}));

const config: ChartConfiguration = {
  type: 'bubble',
  data: {
    datasets: [
      {
        data: [],
        hoverRadius: 0,
        borderColor: 'transparent',
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        grid: {
          borderColor: 'transparent',
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 5,
        ticks: {
          padding: 20,
          maxTicksLimit: 6,
          color: 'black',
          precision: 0,
          stepSize: 1,
          font: {
            weight: '900',
          },
        },
      },
      y: {
        grid: {
          borderColor: 'transparent',
          display: false,
        },
        suggestedMin: 1,
        suggestedMax: 4,
        ticks: {
          padding: 20,
          maxTicksLimit: 4,
          color: 'black',
          // beginAtZero: true,
          precision: 0,
          stepSize: 1,
          font: {
            weight: '900',
          },
        },
      },
    },
  },
};

const BubbleChart = ({
  data,
  title,
  chartText,
}: {
  data: { x: number; y: number; r: number }[];
  title: string;
  chartText: string;
}) => {
  const classes = useStyles();
  const [chart, setChart] = useState<Chart | null>(null);
  const bubbleChart = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (bubbleChart && bubbleChart.current && !chart) {
      setChart(new Chart(bubbleChart.current, config));
    }
  }, [bubbleChart, chart]);

  useEffect(() => {
    if (chart && data.length > 0) {
      const totalRadius = data.reduce((acc, curr) => acc + curr.r, 0);
      chart.data.datasets[0].data = data;
      chart.data.datasets[0].backgroundColor = data.map((d) => `rgba(76, 162, 205, ${d.r / (totalRadius * 0.25)})`);
      chart.update();
    }
  }, [data, chart]);

  return (
    <div className={classes.bubbleChart}>
      <div className={classes.title}>{title}</div>
      <div className={classes.bubbleChart__chart}>
        <canvas ref={bubbleChart} height={280} />
        <div className={classes.chartText}>{chartText}</div>
      </div>
    </div>
  );
};
export default BubbleChart;
