import { useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Modal from '../components/shared/Modal';
import CreateCompetence from '../components/competences/CreateCompetence';
import Button from '../components/shared/Button';
import plus from '../assets/icons/plus.svg';
import CompetencesList from '../components/competences/CompetencesList';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import classNames from 'classnames';
import { useGetCurrentUserQuery } from 'utils/wemble-api';
import organization from 'utils/organization';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  competences: {
    width: '100%',
    margin: '70px auto 65px',
  },
  competences__title: {
    fontSize: 26,
    fontWeight: 600,
  },
  competences__toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbar__tools: {
    display: 'flex',
    '& > div': {
      marginLeft: 16,
    },
  },
  topBar: {
    height: '75px',
    width: 'calc(100% - 75px)',
    marginLeft: '75px',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    left: 0,
    backgroundColor: 'white',
    overflowX: 'hidden',
    padding: '8px 25px 0 23px',
    borderBottom: '1px solid #FAFBFD',
    textAlign: 'left',
    borderTop: '1px solid #EAEBF1',
  },
  searchInput: {
    marginLeft: 14,
    border: 'none',
    color: '#8E93A2',
    width: 'calc(100% - 44px)',
    height: '60px',
  },
}));

const Competences = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data: user } = useGetCurrentUserQuery(undefined, { refetchOnMountOrArgChange: 20, refetchOnReconnect: true });
  const organizationConfig = organization(user?.company);

  const hideDetails = organization(user?.company).disableCompetencesPriviligies && !(user?.admin || user?.administratorPriviliges)

  const toggleModal = () => {
    setVisible(!visible);
  };

  return (
    <Container className={classes.container}>
      <div className={classes.topBar}>
        <SearchIcon />
        <input
          className={classNames(classes.searchInput, 'search')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          placeholder={'Search'}
        />
      </div>
      <div className={classes.competences}>
        <h1 className={classes.competences__title}>Competences</h1>
        <div className={classes.competences__toolbar}>
          <div />
          <div className={classes.toolbar__tools}>
            {(!organizationConfig.disableCompetencesPriviligies || user?.admin || user?.administratorPriviliges) && (<Button onClick={toggleModal} text={'Add competence'} icon={<img alt={'+'} src={plus} />} />)}
          </div>
        </div>
        <CompetencesList searchQuery={searchQuery} hideDetails={hideDetails || false} />
        <Modal visible={visible} close={toggleModal}>
          <CreateCompetence close={toggleModal} />
        </Modal>
      </div>
    </Container>
  );
};

export default Competences;
