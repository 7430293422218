import { useCallback } from 'react';
import axios from 'axios';
import { useAppDispatch } from 'store';
import { setCredentials, clearCredentials } from 'store/reducers/auth';

function useSetAuth() {
  const dispatch = useAppDispatch();
  return useCallback(
    (token: string | false) => {
      if (token === false) {
        delete axios.defaults.headers.common['Authorization'];
        if (
          (window as any).webkit?.messageHandlers?.deleteAuthorizationToken?.postMessage &&
          (window as any).webkit?.messageHandlers?.deleteAuthorizationToken?.postMessage instanceof Function
        ) {
          (window as any).webkit?.messageHandlers?.deleteAuthorizationToken?.postMessage('Token removed');
        }
        localStorage.removeItem('token');
        localStorage.removeItem('OverviewPageSavedFilter');
        dispatch({ type: 'RESET' });
        dispatch(clearCredentials());
      } else {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        if (
          (window as any).webkit?.messageHandlers?.setAuthorizationToken?.postMessage &&
          (window as any).webkit?.messageHandlers?.setAuthorizationToken?.postMessage instanceof Function
        ) {
          (window as any).webkit?.messageHandlers?.setAuthorizationToken?.postMessage(token);
        }
        localStorage.setItem('token', token);
        dispatch(setCredentials(token));
      }
    },
    [dispatch],
  );
}

export default useSetAuth;
