import { useEffect, useState } from "react";
import { cleanedDateString, convertUTCToLocalDate, formattedDateRange, statusBackgroundColor, statusColor, statusTitle } from "../utils";
import DatePicker from 'react-datepicker';
import '../../../../assets/datePickerCustomStyling.css';
import { useDeleteForecastMutation, useGetCurrentUserQuery, useUpdateForecastMutation } from 'utils/wemble-api';
import { useDebounce, usePrevious } from "hooks";
import { ForecastDto } from "utils/wemble-api.generated";


const StatusSelect = ({ selectStatus, data, toggle, range, classes }:
  {
    selectStatus: any;
    toggle: any;
    data?: ForecastDto;
    range?: { startDate: string, endDate: string } | any;
    classes: any
  }) => {
  const [updateForecast] = useUpdateForecastMutation();
  const [deleteForecast] = useDeleteForecastMutation();

  const [selected, setSelected] = useState<string | null>(null);
  const [comment, setComment] = useState<string | null>(data?.comment || null);
  const [debouncedComment] = useDebounce(comment, 150);
  const prevComment = usePrevious(debouncedComment);


  const [editing, setEditing] = useState<boolean>(Boolean(data?.comment));


  const { data: user } = useGetCurrentUserQuery();

  const [startDate, setStartDate] = useState<Date | null>(range?.startDate ? convertUTCToLocalDate(new Date(range.startDate)) : null);
  const [endDate, setEndDate] = useState<Date | null>(range?.endDate ? convertUTCToLocalDate(new Date(range.endDate)) : null);

  const removeComment = () => {
    setComment(null)
    setEditing(false)
    data && updateForecast({ userId: user?._id || '', forecastId: data._id || '', forecastUpsertParams: { startDate: data.startDate!, endDate: data.endDate!, status: data.status!, comment: comment } })
  }
  useEffect(() => {
    if (debouncedComment !== undefined && prevComment !== undefined) {
      data && updateForecast({ userId: user?._id || '', forecastId: data._id || '', forecastUpsertParams: { startDate: data.startDate!, endDate: data.endDate!, status: 'away', comment: comment } })
    }
  }, [comment]);


  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {

      data && updateForecast({ userId: user?._id || '', forecastId: data._id || '', forecastUpsertParams: { startDate: cleanedDateString(start), endDate: cleanedDateString(end), status: data.status! } })
      toggle()
    }
  };


  return (
    <>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}>

        {(data &&
          <DatePicker
            wrapperClassName="datePickerCustomStyling noOverlap"
            selectsRange
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            calendarStartDay={1}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            // filterDate={/*(date) => date >= new Date() && !(forecasts.find(forecast => date >= new Date(forecast.startDate || '') && forecast.startDate !== range?.startDate && date <= new Date(forecast.endDate || '')))*/}
            disabledKeyboardNavigation
            monthsShown={2}
            customInput={
              (<div className={classes.suggestionItem} style={{ position: 'relative', height: 24, maxWidth: 100 }}>
                <div className={classes.subtitle}>{formattedDateRange(range)}</div>
              </div>
              )
            }
          />)}

        {!data && range && (<div style={{ width: '100%', textAlign: 'center' }} className={classes.subtitle}>{formattedDateRange(range)}</div>)}

        {data && (<svg width="28" height="28" viewBox="0 0 48 48" onClick={() => data && deleteForecast({ userId: data.user || '', forecastId: data._id || '' })}
          style={{ cursor: 'pointer', marginTop: 1, marginBottom: -3 }}
          fill="none" xmlns="http://www.w3.org/2000/svg"
          transform="rotate(45)">
          <circle cx="24" cy="24" r="24" fill="#F5F5F5" />
          <rect x="8" y="21" width="33" height="6" rx="3" fill="white" />
          <rect x="27" y="7" width="33" height="6" rx="3" transform="rotate(90 27 7)" fill="white" />
        </svg>)}

      </div>

      {['3', '2', '1', '0', 'away'].map(status => (<div
        className={classes.suggestionItem}
        style={{ backgroundColor: (selected == status) ? `${statusColor(status)}40` : statusBackgroundColor(status), cursor: ((status == 'away' && editing) || status == data?.status) ? 'default' : 'pointer' }}
        onClick={() => !(status == 'away' && editing) && selectStatus(status)}
        onMouseOverCapture={() => setSelected(status)}
        onMouseLeave={() => setSelected(null)}
      >

        {(status == 'away' && editing) ? (
          <div>
            <input autoFocus className={classes.commentInput} value={comment || undefined} onChange={(ev => setComment(ev.target.value))} style={{ color: statusColor(status) }} />

            <svg width="18" height="18" viewBox="0 0 48 48" onClick={(e) => { e.stopPropagation(); removeComment() }}
              style={{ cursor: 'pointer' }}
              fill="none" xmlns="http://www.w3.org/2000/svg"
              transform="rotate(45)">
              <circle cx="24" cy="24" r="24" fill="#F5F5F5" />
              <rect x="8" y="21" width="33" height="6" rx="3" fill="white" />
              <rect x="27" y="7" width="33" height="6" rx="3" transform="rotate(90 27 7)" fill="white" />
            </svg>
          </div>) :

          (
            <div className={classes.title} style={{ color: statusColor(status) }}>
              {statusTitle(status)}
              {status == 'away' && data && (

                <svg xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '2px', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); setEditing(true) }} width="15" height="15" viewBox="0 0 512 512"><path fill="white" d="m144 208c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-112-176c-141.4 0-256 93.1-256 208 0 47.6 19.9 91.2 52.9 126.3-14.9 39.4-45.9 72.8-46.4 73.2-6.6 7-8.4 17.2-4.6 26s12.5 14.5 22.1 14.5c61.5 0 110-25.7 139.1-46.3 28.9 9.1 60.1 14.3 92.9 14.3 141.4 0 256-93.1 256-208s-114.6-208-256-208zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8c-18.1-19.3-39.8-51.2-39.8-93.4 0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z" /></svg>

              )}</div>
          )}
      </div>))}
    </>)
}
export default StatusSelect;