import React, { forwardRef, PropsWithoutRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { useField } from 'formik';
import classNames from 'classnames';
import close from './../../assets/icons/close.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    '& input::placeholder': {
      color: '#878787',
    },
    minHeight: 48,
    maxHeight: 48,
  },
  input: {
    backgroundColor: theme.palette.gray.g3,
    border: 'none',
    borderRadius: 4,
    width: '100%',
    position: 'relative',
    padding: '12px 16px',
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'text',
    '&:focus': {
      outline: 'none',
    },
    '&[type="button"]': {
      textAlign: 'left',
    },
  },
  input_icon: {
    paddingLeft: 48,
  },
  placeholderIcon: {
    position: 'absolute',
    top: '50%',
    left: 16,
    transform: 'translateY(-50%)',
    filter: 'invert(66%) sepia(90%) saturate(0%) hue-rotate(137deg) brightness(86%) contrast(89%)',
  },
  clearable: {
    position: 'absolute',
    top: '50%',
    right: 16,
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.gray.g3,
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.1s',
    cursor: 'pointer',
    '& > img': {
      width: '50%',
      filter: 'invert(66%) sepia(90%) saturate(0%) hue-rotate(137deg) brightness(86%) contrast(89%)',
    },
    '&:hover': {
      backgroundColor: theme.palette.gray.g9,
    },
  },
  error: {
    fontSize: 12,
    position: 'absolute',
    left: 16,
    transform: 'translateY(2px)',
    color: theme.palette.red.r4,
  },
}));

interface InputProps extends PropsWithoutRef<JSX.IntrinsicElements['input']> {
  placeholderIcon?: string;
  clearable?: React.MouseEventHandler<HTMLDivElement>;
  name: string;
  label?: string;
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: 'text' | 'password' | 'email' | 'number' | 'date';
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ placeholderIcon, clearable, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props.name);

  return (
    <div className={classes.root}>
      <input
        className={classNames(classes.input, !props.value && placeholderIcon && classes.input_icon)}
        {...field}
        {...props}
        min="0"
        step={props.name === "hours" ? '0.5' : '1'}
      />
      {placeholderIcon && !props.value && (
        <div className={classes.placeholderIcon}>
          <img alt={'icon'} src={placeholderIcon} />
        </div>
      )}
      {clearable && props.value && (
        <div className={classes.clearable} onClick={clearable}>
          <img alt={'x'} src={close} />
        </div>
      )}
      <div className={classes.error}>{meta.touched && meta.error && <div>{meta.error}</div>}</div>
    </div>
  );
});

export default Input;
