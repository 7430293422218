import { makeStyles } from '@material-ui/core';
import DatePicker from 'components/shared/DatePicker';
import { Formik } from 'formik';
import moment from 'moment';
import {ProjectInvoiceDto} from 'utils/wemble-api.generated';
import Modal from '../../Modal';
import * as Yup from 'yup';
import Input from 'components/shared/Input';
import { useCreateProjectInvoiceMutation, useDeleteProjectInvoiceMutation, useUpdateProjectInvoiceMutation } from 'utils/wemble-api';


const useStyles = makeStyles((theme) => ({
  select: {
    fontFamily: 'Roboto',
  },
  select__title: {
    color: theme.palette.gray.g1,
    '& h1': {
      fontSize: 28,
      fontWeight: 600,
    },
    '& p': {
      color: theme.palette.gray.g8,
    },
  },
  hours__buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 32,
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.gray.g6,
    fontFamily: 'Roboto',
  },
  buttons__delete: {
    backgroundColor: theme.palette.red.r2,
    color: 'white',
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    marginRight: 10
  },
  buttons__save: {
    backgroundColor: theme.palette.blue.b3,
    color: 'white',
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
  },
  vatItem: {
    backgroundColor: '#EEE',
    padding: '1px 5px',
    cursor: 'pointer',
    borderRadius: '8px',
    float: 'left',
    margin: '10px 12px 12px 0px',
    fontSize: 14
  }

}));

const ProjectInvoiceModal = ({
  data,
  visible,
  onClose,
}: {
  data?: string | ProjectInvoiceDto | undefined
  visible: boolean;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const [updateInvoice] = useUpdateProjectInvoiceMutation();
  const [createInvoice] = useCreateProjectInvoiceMutation();
  const [deleteInvoice] = useDeleteProjectInvoiceMutation();


  const handleDelete = ()=> {
    if (typeof data === 'string' || !data?._id) return;
    deleteInvoice({
      id: data._id,
      projectId: data.project || '',
    }).then(()=>onClose())
    
  }
  return (
    <Modal visible={visible} close={onClose} customWidth={300}>
      
      <div className={classes.select}>
        <div className={classes.select__title}>
          <h3>{ typeof data === 'string' ? 'New invoice' : ('Invoice ' + data?.identification)}</h3>
        </div>
        <Formik initialValues={(typeof data === 'string' || !data) ? {
          date: new Date(),
          amount: undefined,
          vat: undefined,
          identification: '',
          currency: 'USD'

        } : data} onSubmit={(values)=>{
              if (typeof data === 'string'){
                createInvoice({projectId: data, projectInvoiceInsertParams: {amount: values.amount || 0, vat: values.vat || 0, identification: values.identification || '', currency: values.currency || '', date: moment(values.date).toISOString() }}).then(()=>onClose())

              }
              else if (!data?._id || !data) {return}
              else{
              updateInvoice({id: data._id, projectId: data.project || '', projectInvoiceUpdateParams: {amount: values.amount || 0, vat: values.vat || 0, identification: values.identification || '', currency: values.currency || '' }}).then(()=>onClose())
             
            }
        }}
        validationSchema={Yup.object().shape({
          date: Yup.date().required('Required'),
          amount: Yup.number().required('Required'),
          vat: Yup.number(),
          identification: Yup.string(),
          currency: Yup.string().required('Required')
        
        } )}
>
        {({ handleSubmit, setFieldValue, values }) => (
          <div>
                    <br/>
                    <DatePicker selected={moment(values.date).toDate()} onChange={(date) => setFieldValue('date', date)} />
                    <br/>
                    <Input placeholder={'Amount'} name={'amount'} type="number" /> 
                    <br/>
                    <Input placeholder={'VAT'} name={'vat'} type="number" /> 
                    {[0, 8, 16].map(vatRate => (<div className={classes.vatItem} onClick={()=>setFieldValue('vat', (values.amount || 0) * vatRate / 100)}>{vatRate}%</div>))}
                    <br/>
                    <br/>

                    <Input placeholder={'Identification number'} name={'identification'} type="text" /> 
                    <br/>
                    <Input placeholder={'Currency'} name={'currency'} type="text" /> 

                    <div className={classes.hours__buttons}>
                      {typeof data !== 'string' && ( <div className={classes.buttons__delete} onClick={()=>handleDelete()}>Delete</div>)}
                      <div className={classes.buttons__save} onClick={()=>handleSubmit()}>Save</div>
                    </div>
          </div>
        )}
        </Formik>
        </div>
    </Modal>
  );
};

export default ProjectInvoiceModal;
