import { makeStyles } from '@material-ui/core';
import DatePicker from 'components/shared/DatePicker';
import Input from 'components/shared/Input';
import { Formik } from 'formik';
import moment from 'moment';
import {ProjectTimeReportDto} from 'utils/wemble-api.generated';
import { useCreateProjectTimeReportMutation, useDeleteProjectTimeReportMutation, useUpdateProjectTimeReportMutation } from 'utils/wemble-api';
import Modal from '../../Modal';
import * as Yup from 'yup';


const useStyles = makeStyles((theme) => ({
  select: {
    fontFamily: 'Roboto',
  },
  select__title: {
    color: theme.palette.gray.g1,
    '& h1': {
      fontSize: 28,
      fontWeight: 600,
    },
    '& p': {
      color: theme.palette.gray.g8,
    },
  },
  hours__buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 32,
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.gray.g6,
    fontFamily: 'Roboto',
  },
  buttons__delete: {
    backgroundColor: theme.palette.red.r2,
    color: 'white',
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    marginRight: 10
  },
  buttons__save: {
    backgroundColor: theme.palette.blue.b3,
    color: 'white',
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
  },

}));

const HoursReportModal = ({
  report,
  projectId,
  userId,
  visible,
  onClose,
}: {
  report?: ProjectTimeReportDto | null
  projectId?: string | null | undefined;
  userId?: string | null | undefined;
  visible: boolean;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const [updateReport] = useUpdateProjectTimeReportMutation();
  const [createReport] = useCreateProjectTimeReportMutation();
  const [deleteReport] = useDeleteProjectTimeReportMutation();

  const handleDelete = ()=> {
    if (!report?._id) return;
    deleteReport({
      id: report._id,
      projectId: projectId || '',
    }).then(()=>onClose())
    
  }
  return (
    <Modal visible={visible} close={onClose} customWidth={300}>
      <div className={classes.select}>
        <div className={classes.select__title}>
          <h3>Report</h3>
        </div>
        <Formik initialValues={{hours: report?.hours || 0, date: report?.date || new Date()}} onSubmit={(values)=>{
              if (report == null && userId !== null){
                createReport({projectId: projectId || '', projectTimeReportInsertParams: {user: userId, hours: values.hours, date: moment(values.date).toISOString() }}).then(()=>onClose())
              }
              if (!report?._id) return;
              updateReport({id: report._id, projectId: projectId || '', projectTimeReportUpdateParams: {hours: values.hours || 0}}).then(()=>onClose())

        }}
        validationSchema={Yup.object().shape(report == null ? {
          hours: Yup.number().required('Required'),
          date: Yup.date().required('Required'),
        } : {})}
>
        {({ handleSubmit, setFieldValue, values }) => (
          <div>
                    <Input placeholder={'Hours for ' + moment(values.date).format("DD/MM/YY")} name={'hours'} type="number" /> 
                    <br/>
                    <DatePicker selected={moment(values.date).toDate()} onChange={(date) => setFieldValue('date', date)} />
            

                    <div className={classes.hours__buttons}>
                      {report !== null && ( <div className={classes.buttons__delete} onClick={()=>handleDelete()}>Delete</div>)}
                      <div className={classes.buttons__save} onClick={()=>handleSubmit()}>Save</div>
                    </div>
          </div>
        )}
        </Formik>
        </div>
    </Modal>
  );
};

export default HoursReportModal;
