import { makeStyles } from '@material-ui/core';
import { UserDto } from 'utils/wemble-api.generated';
import classNames from 'classnames';
import SingleBoxButton from 'components/Home/SingleBoxButton';
import home from 'assets/icons/home.svg';
import absent from 'assets/icons/absent.svg';
import DaySelect from './DaySelectBeta';

const useStyles = (openVacationPlanner) => makeStyles((theme) => ({
  root: {
    transition: '0.2s',
    position: 'absolute',
    right: 0,
    top: -70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
      minHeight: 80,
      top: -60,
    },
  },
  rootWFH: {
    top: -68,
  },
  WFH_container: {
    width: '290px', marginTop: '-90px', marginRight: '-19px',
    [theme.breakpoints.down('sm')]: {
      width: openVacationPlanner ? '220px' : '250px',
      marginRight: '-4px',
      marginLeft: '4px',
      marginTop: '-85px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  rootAdmin: {
    minHeight: 72,
    '& > div:first-child': {
      borderRadius: 24,
      paddingBottom: 6,
      minHeight: 72,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 62,
      '& > div:first-child': {
        minHeight: 62,
      },
    },
  },
  profileContainer: {
    minHeight: 70,
    padding: '8px 20px 2px 8px',
    backgroundColor: 'white',
    borderRadius: '24px 24px 0 0',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: '6px 15px 2px 10px',
    },
  },
  profile__img: {
    borderRadius: '50%',
    backgroundColor: theme.palette.gray.g6,
    overflow: 'hidden',
    border: 'solid 2px white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    height: 50,
    width: 50,
    [theme.breakpoints.down('sm')]: {
      height: 40,
      width: 40,
    },

    '& img': {
      width: '100%',
    },
  },
  profile__letter: {
    color: theme.palette.gray.g1,
    fontWeight: 600,
    fontSize: 14,
  },
  text: {
    padding: '0 0 0 10px',
    lineHeight: 1.1,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },

  },
  text__sub: {
    fontSize: 11,
    color: '#828282',
    marginTop: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
      marginTop: 1,
    },
  },
  adminAction: {
    marginLeft: 20,
    '& > div': {
      backgroundColor: 'white',
      height: 72,
      '& > div': {
        fontSize: 12,
      },
      [theme.breakpoints.down('sm')]: {
        height: 62,
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
    },
  },
}));

const Profile = ({
  user,
  openVacationPlanner,
  openWorkplaceOverview,
  fetchUsersWFH,
}: {
  user: UserDto | null | undefined;
  openVacationPlanner?: (() => void) | false;
  openWorkplaceOverview?: (() => void) | false;
  fetchUsersWFH?: () => Promise<void>;
}) => {
  const classes = useStyles(openVacationPlanner)();

  if (!user) return null;


  const group = typeof user.group !== 'string' ? user.group?.name : '';
  const office = typeof user.office !== 'string' ? user.office?.name : '';



  return (
    <div className={classNames(classes.root, { [classes.rootAdmin]: user.admin, [classes.rootWFH]: Boolean(fetchUsersWFH) })}>

      <div className={classes.profileContainer}>
        <div>
          <div className={classes.profile__img}>

            {user.profilePicture ? (
              <img
                alt={`${user.name}-image`}
                src={(user?.profilePicture.includes('minio') ? '/api/profilePicture/' : 'https://d378wr66tg5qg7.cloudfront.net/') + user?.profilePicture}
              />
            ) : (
              <div className={classes.profile__letter}>{user.name?.charAt(0)}</div>
            )}
          </div>
        </div>
        <div className={classes.text}>
          <div>{user.name}</div>
          <div className={classes.text__sub}>
            {group} - {office}
          </div>
        </div>
        {fetchUsersWFH && (
          <div className={classes.WFH_container}>
            <DaySelect user={user} disabled={Boolean(user?.admin)} fetchUsers={fetchUsersWFH} />
          </div>
        )}
        {openWorkplaceOverview && (
          <SingleBoxButton sm={true} icon={home} text={'WFH Overview'} event={openWorkplaceOverview} />
        )}
        {openVacationPlanner && (
          <SingleBoxButton sm={true} icon={absent} text={'Forecasts'} event={openVacationPlanner} />
        )}


      </div>


    </div>
  );
};

export default Profile;
