import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardItem: {
    padding: '10px 14px',
    border: 'dashed 1px',
    borderColor: theme.palette.gray.g6,
    borderRadius: 8,
    height: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > div': {
      lineHeight: '20px',
      margin: 0,
    },
  },
  cardItem__data: {
    fontWeight: 600,
    fontSize: 16,
  },
  cardItem__description: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.gray.g8,
  },
}));

const CardItem = ({
  data = 0,
  description,
  backgroundColor,
  dataColor,
  descColor,
}: {
  data: number | string;
  description: string;
  backgroundColor?: string;
  dataColor?: string;
  descColor?: string;
}) => {
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.cardItem}
        style={backgroundColor ? { backgroundColor: backgroundColor, border: 'none' } : undefined}
      >
        <div className={classes.cardItem__data} style={dataColor ? { color: dataColor } : undefined}>
          {data}
        </div>
        <div className={classes.cardItem__description} style={descColor ? { color: descColor } : undefined}>
          {description}
        </div>
      </div>
    </>
  );
};

CardItem.defaultProps = {
  data: 0,
  description: 'NaN',
};

CardItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.string.isRequired,
};

export default CardItem;
