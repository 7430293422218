import { FormEvent, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useCreateGroupMutation, useUpdateGroupMutation } from 'utils/wemble-api';
import { GroupDto } from 'utils/wemble-api.generated';

const GroupModal = ({
  title,
  isOpen,
  onClose,
  data,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  data?: GroupDto | undefined;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [name, setName] = useState(data?.name ?? '');
  const [ssoIdentifiers, setIdentifiers] = useState(data?.ssoIdentifiers.join(',') ?? '');

  const [createGroup] = useCreateGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();

  useEffect(() => {
    if (isOpen && data) {
      setName(data.name ?? '');
      setIdentifiers(data?.ssoIdentifiers.join(',') ?? '');
    }
  }, [isOpen, data]);

  const handleClose = () => {
    setIsSubmitting(false);
    setName('');
    onClose();
  };

  const handleSubmit = async (ev: FormEvent) => {
    setErrorMsg(undefined);
    setIsSubmitting(true);
    ev.preventDefault();
    let res;
    if (data) {
      if (!data._id) return;
      res = await updateGroup({
        id: data._id,
        groupUpsertParams: {
          name,
          ssoIdentifiers: ssoIdentifiers.split(',')

        },
      });
    } else {
      res = await createGroup({
        groupUpsertParams: {
          name,
          ssoIdentifiers: ssoIdentifiers.split(',')
        },
      });
    }
    if ('error' in res) {
      const error = res.error as any;
      if (typeof error?.data?.message === 'string') {
        setErrorMsg(error?.data?.message);
      }
      setIsSubmitting(false);
      return;
    }
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={handleClose}>{title}</ModalHeader>
        <ModalBody>
          <table className={'table'}>
            <tbody>
              <tr>
                <th scope="row" className={'control-label'}>
                  Name
                </th>
                <td>
                  <input
                    value={name}
                    type="text"
                    name="name"
                    required
                    className={'form-control'}
                    onChange={(ev) => setName(ev.target.value)}
                    disabled={isSubmitting}
                  />
                </td>
              </tr>
              <tr>
                <th scope="row" className={'control-label'}>
                  SSO identifiers
                </th>
                <td>
                  <input
                    value={ssoIdentifiers}
                    type="text"
                    name="ssoIdentifiers"
                    className={'form-control'}
                    onChange={(ev) => setIdentifiers(ev.target.value)}
                    disabled={isSubmitting}
                  />
                  <span style={{fontSize: 12}}>Comma separated list of other user.department names to be mapped to this group when using SSO provisioning</span>
                </td>
              </tr>

            </tbody>
          </table>
          {errorMsg && <span className="text-danger">{errorMsg}</span>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>

          <Button color="primary" type="submit" disabled={isSubmitting}>
            {data ? 'Edit' : 'Add'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default GroupModal;
