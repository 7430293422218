import { useCallback, useState } from 'react';
import { Formik } from 'formik';
import Item from './Item';
import Input from './../Input';
import { SettingsButtons } from '../Settings';
import { makeStyles, Theme, useTheme } from '@material-ui/core';
import Modal from '../Modal';
import Button from '../Button';
import settingsStyles from '../../../theme/react-select/settings';
import Select from 'react-select';
import {
  useGetCompetencesQuery,
  useUpdateCompetenceMutation,
  useDeleteCompetenceMutation,
  useGetLevelOfSkillsQuery,
  useGetCurrentUserQuery,
} from 'utils/wemble-api';

import CompetenceAverageSkillLevel from '../../competences/CompetenceAverageSkillLevel';
import { useHistory } from 'react-router-dom';
import { CompetenceDto } from 'utils/wemble-api.generated';

function onlyUnique(value, index, self) {
  return value && self.indexOf(value) === index;
}
const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    backgroundColor: theme.palette.red.opacity.r16,
    color: theme.palette.red.r4,
    padding: '10px 18px',
    fontWeight: 500,
    borderRadius: 8,
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-block',
  },
  modal__title: {
    fontSize: 24,
    fontWeight: 600,
    padding: '0 0 24px',
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.gray.g6,
  },
  modal__buttons: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  skill_level: {
    marginLeft: 20 + 'px',
    marginBottom: 8 + 'px',
    float: 'right',
    color: 'gray',
  },
  groupStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  groupBadgeStyles: {
    backgroundColor: '#EBECF0',
    borderRadius: '4em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.1666em 0.5em',
    textAlign: 'center',
  }
}));




const CompetenceSettings = ({ competence }: { competence: CompetenceDto | undefined | null }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: competences } = useGetCompetencesQuery();
  const { data: levelOfSkills } = useGetLevelOfSkillsQuery();
  const [updateCompetence] = useUpdateCompetenceMutation();
  const [removeCompetence] = useDeleteCompetenceMutation();

  const getSkillID = () => {
    return competence?.users?.find((user) => user._id === currentUser?._id)?.skills[0]._id;
  };
  const handleDelete = () => {
    if (!competence?._id) return;
    removeCompetence({ id: competence._id });
    history.replace('/competences/');
  };

  const categories = competence
    ? competences
        ?.filter((c) => c.root && (competence.root ? !c.subCategory : true))
        .map((c) => ({ value: c, label:  (c.parent ? ( c.parent.name + " - " + c.name) : c.name), parent: c.parent?.name || "Categories" }))
    : [];

  const parentCategories = categories?.map(category => category.parent).filter(onlyUnique)

    const formatGroupLabel = (data) => (
      <div className={classes.groupStyles}>
        <span>{data.label}</span>
        <span className={classes.groupBadgeStyles}>{data.options.length}</span>
      </div>
    );
    

    const generateDefaultValue = useCallback(() => {
      if (competence && competence!.parent && competences?.length ){
       return ({
        value: competence!.parent,
        label:  (competence!.parent.parent ? ( competences?.find(c => c._id == competence!.parent?._id)?.parent?.name + " - " + competence!.parent.name) : competence!.parent.name)
      })
      }
      return null
    }, [competences])

  

  return (
    <>
      {competence &&  (
        <>
          <Formik
            initialValues={{
              name: competence.name,
              // description: competence.description,
              parent: competence.parent,
              root: typeof competence.root === 'boolean' ? competence.root : null,
            }}
            onSubmit={(values) => {
              //TODO Fix description not being set due to putting to a value that doesn't exist
              if (!values.name) return;

              updateCompetence({
                id: competence._id ?? '',
                competenceUpdateParams: {
                  name: values.name,
                  root: values.root,
                  subCategory: values.root && values.parent !== null,
                  parentId: values?.parent?._id ?? null,
                },
              });
            }}
          >
            {({ handleSubmit, setFieldValue, resetForm }) => (
              <form>
                <Item
                  title={'Competence Name'}
                  input={<Input placeholder={'Competence name'} name={'name'} type="text" />}
                />
                {/* <Item
                                    title={'Competence Description'}
                                    input={
                                        <Input
                                            placeholder={'Competence description'}
                                            name={'description'}
                                            type="text"
                                        />
                                    }
                                /> */}
                {competence?.users?.find((user) => user._id === currentUser?._id) && (
                  <>
                    <Item
                      title={'My skill level'}
                      input={
                        <>
                          <CompetenceAverageSkillLevel
                            skill={
                              competence.users.find((user) => user._id === currentUser?._id)?.skills[0]?.level?.level
                            }
                            changeable
                            userID={currentUser?._id}
                            skillID={getSkillID()}
                          />
                          <div className={classes.skill_level}>
                            {levelOfSkills &&
                              (
                                levelOfSkills.find(
                                  (level) =>
                                    level.level ==
                                    competence?.users?.find((user) => user._id === currentUser?._id)?.skills[0]?.level
                                      ?.level,
                                ) || { name: '' }
                              ).name}
                          </div>
                        </>
                      }
                    />
                  </>
                )}
                {
                  // Temporarily removed because of scope
                  // <Item title={'Created by'} input={<Avatar />} />
                }
                <Item
                  title={competence?.root ? 'Parent category' : 'Category'}
                  input={
                    <div style={{ width: '100%', display: 'inline-block' }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        formatGroupLabel={formatGroupLabel}
                        isClearable
                        onChange={(option) => {
                          setFieldValue('parent', option?.value);
                        }}
                        options=
                        {
                          parentCategories?.map(parentCategory => {return ({label: parentCategory, options: categories?.filter(category=>category.parent === parentCategory)}) as { value?: CompetenceDto | null; label: string | null | undefined; options: ({value: CompetenceDto | null; label: string | null | undefined})[] } }) ?? []
                        }
                        defaultValue={generateDefaultValue() || (competence.parent && {
                          value: competence.parent,
                          label: competence.parent.name,
                        })}
                        styles={settingsStyles}
                      />
                    </div>
                  }
                />

                <Item
                  title={'Delete Competence'}
                  input={
                    <div
                      onClick={() => {
                        setVisible(true);
                      }}
                      className={classes.delete}
                    >
                      Delete
                    </div>
                  }
                  alignRight
                />
                <SettingsButtons discard={resetForm} save={handleSubmit} />
              </form>
            )}
          </Formik>
          <Modal visible={visible} close={() => setVisible(false)}>
            <div>
              <h2 className={classes.modal__title}>Are you sure you want to delete {competence.name}?</h2>
              <div className={classes.modal__buttons}>
                <Button text={'Cancel'} onClick={() => setVisible(false)} />
                <Button
                  text={'Delete'}
                  background={theme.palette.red.opacity.r16}
                  color={theme.palette.red.r4}
                  onClick={() => handleDelete()}
                />
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default CompetenceSettings;
