import { useEffect, useRef, useState } from 'react';
import { notEmpty } from 'utils/helpers';

export default function useDebounce<T>(value: T, delay = 500): [T, boolean] {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const touched = useRef<boolean>(false);

  useEffect(() => {
    if (!touched.current && notEmpty(value)) touched.current = true;
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, touched.current];
}
