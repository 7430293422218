import React from 'react';
import { makeStyles } from '@material-ui/core';
import chevronRight from './../assets/icons/chevronRight.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    width: '100%',
    margin: '70px auto 25px',
    fontFamily: 'Roboto',
  },
  pageTitle__text: {
    fontSize: 26,
    fontWeight: 600,
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbs__text: {
    color: theme.colors.state.disabled,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.state.disabled,
    },
  },
  breadcrumbs__chevron: {
    margin: '0 8px',
    filter: 'invert(50%) sepia(1%) saturate(0%) hue-rotate(152deg) brightness(101%) contrast(96%)',
    width: 7,
  },
  breadcrumbs__active: {
    color: theme.colors.blue.b3,
    fontWeight: 500,
  },
}));

const PageTitle = ({ title, breadcrumbs, link }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageTitle}>
        <h1 className={classes.pageTitle__text}>{title}</h1>
        <div className={classes.breadcrumbs}>
          {breadcrumbs?.map((crumb, index) => (
            <>
              {breadcrumbs.length - 1 === index ? (
                <span className={classes.breadcrumbs__active}>{breadcrumbs[breadcrumbs.length - 1]}</span>
              ) : (
                <>
                  <Link
                    onlyActiveOnIndex={false}
                    to={`/${link}`}
                    style={{ textDecoration: 'none' }}
                    key={crumb + '-' + index}
                    className={classes.breadcrumbs__text}
                  >
                    <span>{crumb}</span>
                  </Link>
                  <img alt={'>'} className={classes.breadcrumbs__chevron} src={chevronRight} />
                </>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};
PageTitle.defaultProps = {
  title: 'NaN',
  breadcrumbs: [],
  link: null,
};
PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array,
  link: PropTypes.string,
};
export default PageTitle;
