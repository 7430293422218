import Select, { OptionsType } from 'react-select';
import sortStyles from 'theme/react-select/sort';

const OrderSelect = ({
  options,
  onChange,
  defaultValue = null,
}: {
  options: OptionsType;
  onChange: (...args: any[]) => void;
  defaultValue: any;
}) => {
  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      onChange={(value) => {
        onChange(value);
      }}
      styles={sortStyles()}
    />
  );
};

export default OrderSelect;
