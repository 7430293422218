import { FormEvent, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Form, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { makeStyles, Button as MuiButton } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import ReactLoading from 'react-loading';

import { addFlashMessage } from 'store/reducers/flashMessages';
import { useLoginMutation, useResetPasswordMutation, useSignInAzureUserWithCodeMutation } from 'utils/wemble-api';
import { useAppDispatch, useAppSelector } from 'store';
import { useSetAuth } from 'hooks';
import Redirect from 'components/Redirect';

import wembleIcon from '../../resources/images/wemble.png';
import './css/material-design-iconic-font.min.css';
import './fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import './css/util.css';
import './css/main.css';
import { ReactComponent as MSLogo } from 'assets/icons/ms-logo.svg';
import { useTeamsAuth } from 'hooks/useTeamsAuth';

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: 'url("background.png")',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logo: {
    pointerEvents: 'none',
    width: '65%',
    marginTop: -30,
    marginBottom: 30,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loader: {
    fill: '#59abcb !important',
    margin: 'auto',
    marginTop: 50,
    marginBottom: 50,
    width: '10%',
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { search, pathname } = useLocation();

  const dispatch = useAppDispatch();
  const setAuth = useSetAuth();
  const { initialized: teamsInitialized, error: teamsAuthError } = useTeamsAuth();
  const token = new URLSearchParams(useLocation().search).get('token');
  const authenticated = useAppSelector((state) => state.auth.authenticated);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [loginUser] = useLoginMutation();
  const [resetPassword] = useResetPasswordMutation();

  const [loginAzureUser, { isLoading: isLoggingInAzureUser }] = useSignInAzureUserWithCodeMutation();
  const azureRedirectUri = `${window.location.origin}/login`;

  useEffect(() => {
    if (teamsAuthError) {
      // @ts-ignore
      setError('server-login', { message: teamsAuthError });
    }
  }, [teamsAuthError]);

  useEffect(() => {
    if (token) {
      const user = jwt.decode(token);
      if (!user) return;
      if (user.completed) {
        setAuth(token);
        history.push('/');
      } else {
        history.push(`/signup/${user.id}?sso=true`);
      }
    }
  }, [token, history, setAuth]);

  const { register, watch, handleSubmit, setError, clearErrors, reset, setValue, formState } = useForm({
    defaultValues: {
      email: '',
      password: '',
      resetEmail: '',
    },
  });
  const { errors, isSubmitting } = formState;
  const fields = watch();

  const disabled = isSubmitting;

  useEffect(() => {
    const searchObject = Object.fromEntries(
      search
        .substring(1)
        .split('&')
        .map((v) => v.split('=')),
    );
    if (searchObject['code']) {
      loginAzureUser({
        body: {
          code: searchObject['code'],
          redirectUri: searchObject['azureRedirectUri'] ? decodeURIComponent(searchObject['azureRedirectUri']) : azureRedirectUri,
        },
      })
        .unwrap()
        .then((res) => {
          const user = jwt.decode(res.token);
          if (!user) return;
          if (user.completed) {
            setAuth(res.token);
            history.push('/');
          } else {
            history.push(`/signup/${user.id}?sso=true`);
          }
        })
        .catch((error) => {
          if (error?.data?.redirect) {
            window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_AAD_APP_CLIENT_ID}&scope=https://graph.microsoft.com/.default&response_type=code&redirect_uri=${error?.data?.redirect}&prompt=${azureRedirectUri.includes("faber") ? "select_account" : ("none&login_hint=" + error?.data?.email)}`
          }
          else {
            // @ts-ignore
            setError('server-login', { message: error?.data?.message });
            history.replace(pathname);
          }
        });
    }
    if (searchObject["email"]) {
      setValue("email", searchObject["email"])
      history.replace(pathname);
    }
  }, [search, pathname, history]);

  if (authenticated) {
    return <Redirect />;
  }

  const onSubmit = (event: FormEvent) => {
    clearErrors();
    handleSubmit(async (data) => {
      clearErrors();
      const res = await loginUser({
        loginParams: {
          email: data.email,
          password: data.password,
        },
      });
      if ('error' in res) {
        const error = res.error as any;
        if (typeof error?.data?.message === 'string') {
          // @ts-ignore
          setError('server-login', { message: error?.data?.message });
        }
        return;
      }
      const user = jwt.decode(res.data.token);
      if (user.completed) {
        setAuth(res.data.token);
        history.push('/');
      } else {
        history.push(`/signup/${user.id}?sso=true`);
      }
    })(event);
  };

  const onForgotPasswordSubmit = (event: FormEvent) => {
    clearErrors();
    handleSubmit(async (data) => {
      clearErrors();
      const res = await resetPassword({
        resetPasswordParams: {
          resetEmail: data.resetEmail,
        },
      });
      if ('error' in res) {
        const error = res.error as any;
        if (typeof error?.data?.message === 'string') {
          // @ts-ignore
          setError('server-forgot-password', { message: error?.data?.message });
        }
        return;
      }
      dispatch(addFlashMessage({ type: 'success', text: 'Reset link sent' }));
      reset();
      setShowForgotPasswordModal(false);
    })(event);
  };

  const handleToggleForgotPasswordModal = () => {
    setShowForgotPasswordModal((prev) => {
      if (prev) {
        setValue('resetEmail', '');
        // @ts-ignore
        setError('server-forgot-password', '');
      } else {
        clearErrors();
      }
      return !prev;
    });
  };

  let redirectMessage: string | null = null;
  let url: string | null = null;
  if (fields.email.includes('@hannessnellman')) {
    redirectMessage = 'Hannes Snellman';
    url = 'https://www.wemble.app/sso/5da0c1370b30f993a68d898f/login';
  } else if (fields.email.includes('@selmer')) {
    redirectMessage = 'Selmer';
    url = 'https://www.wemble.app/sso/5e4d42f50fc8ec2139341a39/login';
  }
  else if (!window.location.hostname.includes("faber.wemble.app") && fields.email.includes('@faberlaw')) {
    redirectMessage = 'Faber installation';
    url = `https://faber.wemble.app/login?email=${encodeURI(fields.email)}`
  }
  else if (window.location.hostname.includes("faber.wemble.app") && (new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')).test(fields.email) && !fields.email.includes('@faberlaw')) {
    redirectMessage = 'Wemble EU installation';
    url = `https://www.wemble.app/login?email=${encodeURI(fields.email)}`

  }


  return (
    <div className={classes.root}>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            {!teamsInitialized || isLoggingInAzureUser ? (
              <ReactLoading type={'spinningBubbles'} className={classes.loader} />
            ) : (
              <form onSubmit={onSubmit} className="login100-form validate-form">
                <img src={wembleIcon} alt="" className={classes.logo} />

                <div
                  className={'wrap-input100 validate-input' + (errors.email ? ' alert-validate' : '')}
                  data-validate={errors.email?.message}
                >
                  <input
                    className={'input input100' + (fields.email ? ' has-val' : '')}
                    {...register('email')}
                    type="email"
                    required
                    autoComplete="email"
                  />
                  <span className="focus-input100" data-placeholder="Email" />
                </div>

                <div
                  className="text-center"
                  style={{
                    marginTop: -32,
                    marginBottom: 8,
                    opacity: redirectMessage ? 1.0 : 0,
                    transition: '0.5s ease-in-out',
                  }}
                >
                  <a
                    className="txt2"
                    style={{
                      fontSize: 13 + 'px',
                      color: '#0084c4',
                    }}
                    href={url ?? '#'}
                  >
                    Login through {redirectMessage}
                  </a>
                </div>

                {!redirectMessage && (<div>
                  <div
                    className={'wrap-input100 validate-input' + (errors.password ? ' alert-validate' : '')}
                    data-validate={errors.password?.message}
                  >
                    <span
                      className={'btn-show-pass' + (showPassword ? ' active' : '')}
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <i className="zmdi zmdi-eye" />
                    </span>
                    <input
                      className={'input input100' + (fields.password ? ' has-val' : '')}
                      type={showPassword ? 'text' : 'password'}
                      {...register('password')}
                      required
                      autoComplete="current-password"
                    />
                    <span className="focus-input100" data-placeholder="Password" />
                  </div>

                  {errors['server-login']?.message && (
                    <span className="text-danger" style={{ fontSize: 14 }}>
                      {errors['server-login'].message}
                    </span>
                  )}

                  <div className="container-login100-form-btn">
                    <div className="wrap-login100-form-btn">
                      <div className="login100-form-bgbtn" />
                      <button type="submit" value="Submit" className="login100-form-btn">
                        Login
                      </button>
                    </div>
                    {/*@ts-ignore*/}
                    {(!(window._env_ && window._env_.APP_ON_RC) && process.env.REACT_APP_AAD_APP_CLIENT_ID) ? (
                      <MuiButton
                        startIcon={<MSLogo />}
                        href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_AAD_APP_CLIENT_ID}&scope=https://graph.microsoft.com/.default&response_type=code&redirect_uri=${azureRedirectUri}&prompt=select_account`}
                      >
                        Sign in
                      </MuiButton>
                    ) : null}
                    {/*@ts-ignore*/}
                    {(window._env_ && window._env_.APP_ON_RC) ? (
                      <MuiButton
                        /*@ts-ignore*/
                        href={window._env_.APP_LAUNCH_URL + "/api/v2/company/ON_RC/sso/login"}
                      >
                        Sign in with SSO
                      </MuiButton>
                    ) : null}

                  </div>

                  <div className="text-center p-t-20">
                    <span
                      className="txt2"
                      style={{
                        pointerEvents: 'auto',
                        cursor: 'pointer',
                      }}
                      onClick={handleToggleForgotPasswordModal}
                    >
                      Forgot password
                    </span>
                  </div>
                </div>)}
              </form>
            )}
          </div>
        </div>
      </div>

      <Link
        to="privacy"
        style={{
          position: 'absolute',
          color: 'white',
          left: 0,
          right: 0,
          bottom: 10 + 'px',
          textAlign: 'center',
          opacity: 0.5,
          textDecoration: 'none',
          fontSize: 12,
        }}
      >
        Privacy&nbsp;policy
      </Link>

      <Modal isOpen={showForgotPasswordModal} toggle={handleToggleForgotPasswordModal}>
        <ModalHeader toggle={handleToggleForgotPasswordModal}>Forgot password</ModalHeader>
        <Form onSubmit={onForgotPasswordSubmit}>
          <ModalBody>
            <div className="form-group">
              <label
                style={{ fontWeight: 'bold' }}
                className={classnames('control-label', {
                  'text-danger': errors.resetEmail,
                })}
              >
                Email
              </label>
              <input
                type="email"
                {...register('resetEmail')}
                className={classnames('form-control', { 'is-invalid': errors.resetEmail })}
                autoComplete="email"
              />
              {errors.resetEmail && <span className="text-danger">{errors.resetEmail?.message}</span>}
            </div>
            {errors['server-forgot-password']?.message && (
              <span className="text-danger">{errors['server-forgot-password'].message}</span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button disabled={disabled} color="secondary" onClick={handleToggleForgotPasswordModal}>
              Cancel
            </Button>{' '}
            <Button disabled={disabled} color="primary">
              Send reset link
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default LoginPage;
