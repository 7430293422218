import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'utils/wemble-api';
import { useAppSelector } from 'store';
import { COMPANY_ADMIN_ROUTES } from 'components/AuthRoute';

const Redirect = () => {
  const history = useHistory();
  const authenticated = useAppSelector((state) => state.auth.authenticated);
  const { data: user, isLoading } = useGetCurrentUserQuery();

  useEffect(() => {
    if (isLoading) return;
    if (authenticated && user) {
      if (user.companyAdmin && user.admin) {
        if (!COMPANY_ADMIN_ROUTES.some((route) => window.location.pathname.startsWith(route))) {
          history.replace('/groups');
        }
      } else {
        history.replace('/home');
      }
    } else {
      history.replace('/login');
    }
  }, [isLoading, authenticated]);

  return null;
};

export default Redirect;
