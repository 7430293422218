import { FormEvent, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { ProjectCategoryDto } from 'utils/wemble-api.generated';
import {
  useDeleteProjectCategoryMutation,
  useCreateProjectCategoryMutation,
  useUpdateProjectCategoryMutation,
} from 'utils/wemble-api';

const ProjectCategoryModal = ({
  isOpen,
  onClose,
  title,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  data?: ProjectCategoryDto | undefined;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [name, setName] = useState(data?.name ?? '');
  const [removeProjectCategory] = useDeleteProjectCategoryMutation();
  const [createProjectCategory] = useCreateProjectCategoryMutation();
  const [updateProjectCategory] = useUpdateProjectCategoryMutation();

  useEffect(() => {
    if (isOpen && data) {
      setName(data.name ?? '');
    }
  }, [isOpen]);

  const resetState = () => {
    setName('');
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleSubmit = async (ev: FormEvent) => {
    setIsSubmitting(true);
    setErrorMsg(undefined);
    ev.preventDefault();
    let res;
    if (data && data._id) {
      res = await updateProjectCategory({
        id: data._id,
        projectCategoryUpsertParams: {
          name,
        },
      });
    } else {
      res = await createProjectCategory({
        projectCategoryUpsertParams: {
          name,
        },
      });
    }
    if ('error' in res) {
      const error = res.error as any;
      if (typeof error?.data?.message === 'string') {
        setErrorMsg(error?.data?.message);
      }
      setIsSubmitting(false);
      return;
    }
    setIsSubmitting(false);
    handleClose();
  };

  const handleDelete = () => {
    setIsSubmitting(true);
    if (!data?._id) return;
    removeProjectCategory({
      id: data._id,
    });
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={handleClose}>{title}</ModalHeader>
        <ModalBody>
          <table className={'table'}>
            <tbody>
              <tr>
                <th scope="row" className={'control-label'}>
                  Name
                </th>
                <td>
                  <input
                    id="skill-name"
                    placeholder='Example: "Pro bono"'
                    value={name}
                    type="text"
                    name="name"
                    required
                    className={'form-control'}
                    onChange={(ev) => setName(ev.target.value)}
                    disabled={isSubmitting}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {errorMsg && <span className="text-danger">{errorMsg}</span>}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose} disabled={isSubmitting}>
            Cancel
          </Button>
          {data && (
            <Button color="danger" onClick={handleDelete} disabled={isSubmitting}>
              Delete
            </Button>
          )}
          <Button color="primary" type="submit" disabled={isSubmitting}>
            {data ? 'Save' : 'Add'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ProjectCategoryModal;
