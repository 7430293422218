import { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Chart, { ChartConfiguration } from 'chart.js/auto';
import 'chartjs-adapter-moment';

const useStyles = makeStyles((theme) => ({
  pieChart: {
    backgroundColor: 'white',
    borderRadius: 16,
    minHeight: 300,
    height: '100%',
    padding: '20px 20px 10px',
  },
  pieChart__chart: {
    width: '100%',
    paddingBottom: 10,
  },
  pieChart__text: {
    textAlign: 'center',
    color: theme.palette.gray.g1,
    fontSize: 14,
    fontWeight: 900,
  },
}));

const PieChart = ({
  data,
  title,
  showDataLabels = false,
  dataLabelColors = 'black',
  backgroundColor = ['#75B7D8', '#83C7C0', '#F7DA80', '#E08F8D'],
}: {
  data: number[];
  title: string;
  showDataLabels?: boolean;
  dataLabelColors?: string | string[];
  backgroundColor: string | string[];
}) => {
  const classes = useStyles();
  const [chart, setChart] = useState<Chart | null>(null);
  const pieChart = useRef<HTMLCanvasElement | null>(null);

  const config: ChartConfiguration = useMemo(
    () => ({
      type: 'pie',
      data: {
        datasets: [
          {
            backgroundColor,
            data: [],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if (value === 0) return '';
              return ctx.dataIndex;
            },
            display: showDataLabels,
            font: {
              weight: 'bold',
            },
            color: dataLabelColors,
          },
        },
      },
    }),
    [showDataLabels, dataLabelColors, backgroundColor],
  );

  useEffect(() => {
    if (pieChart && pieChart.current && !chart) {
      setChart(new Chart(pieChart.current, config));
    }
  }, [pieChart, chart]);

  useEffect(() => {
    if (chart && data.length > 0) {
      chart.data.datasets[0].data = data;
      chart.update();
    }
  }, [data, chart]);

  return (
    <div className={classes.pieChart}>
      <div className={classes.pieChart__chart}>
        <canvas ref={pieChart} height={260} />
      </div>
      <div className={classes.pieChart__text}>{title}</div>
    </div>
  );
};
export default PieChart;
