import axios from 'axios';

export default class Axios {
    //TODO rename to maybe createAxiosApi and make it a function instead of class
    constructor(baseURL) {
        // Add a request interceptor
        axios.interceptors.request.use(
            async function (config) {
                // Do something before request is sent
                //config.baseURL = baseURL;
                config.maxRedirects = 1;
                config.headers.accept = '*/*';
                if (!config.headers.Authorization) {
                    //Auto add access token if non exist
                    config.headers.Authorization = 'Bearer ' + localStorage.token;
                }
                return config;
            },
            function (error) {
                // Do something with request error
                console.log('Do something with request error');
                return Promise.reject(error);
            },
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                // Do something with response data
                return response;
            },
            function (error) {
                // Do something with response error
                // console.log('response error');
                // console.log(error);
                // console.log(error.response);
                // console.log(error.config);
                // console.log(error.data);

                // Not an AccessToken invalid error
                if (error.response.status !== 401) {
                    return new Promise((resolve, reject) => {
                        reject(error);
                    });
                }
                return new Promise((resolve, reject) => {
                    reject(error);
                });
                // Try request again with new token
                /*return axios
                    .get('/api/users/token/new')
                    .then((res) => {
                        localStorage.setItem('token', res.data.token);
                        console.log('new token gotten lets go', res.data.token);

                        // Same request with new token
                        const config = error.config;
                        config.headers.Authorization = 'Bearer ' + res.data.token;

                        return new Promise((resolve, reject) => {
                            axios
                                .request(config)
                                .then((response) => {
                                    resolve(response);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        });
                    })
                    .catch((error) => {
                        //Getting new token failed
                        localStorage.setItem('user', null);
                        Promise.reject(error);
                    });*/
            },
        );
    }
}
