import React from 'react';
import Select from 'react-select';
import sortStyles from './../../theme/react-select/sort';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '4em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.1666em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const FilterSelect = ({ options, onChange, value = [], placeholder = 'Select ...', small = false, defaultValue }) => {
  return (
    <>
      <Select
        options={options}
        formatGroupLabel={formatGroupLabel}
        isMulti
        placeholder={placeholder}
        onChange={(newValue, meta) => {
          if (meta.action === 'clear') {
            return onChange(defaultValue);
          }
          if (meta.action === 'select-option' && value.find((v) => v.type === meta.option.type && v.label === 'All ' + meta.option.type + 's')) {
            return onChange(newValue.filter(option => option.label !== 'All ' + meta.option.type + 's'));
          }
          onChange(newValue);
        }}
        styles={sortStyles(small)}
        closeMenuOnSelect={false}
        value={value}
        defaultValue={defaultValue}
        isClearable={JSON.stringify(defaultValue) !== JSON.stringify(value)}
      />
    </>
  );
};

export default FilterSelect;
