import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import plus from './../../assets/icons/plus.svg';
import close from './../../assets/icons/close.svg';
import settings from './../../assets/icons/settings.svg';
import Modal from '../shared/Modal';
import CreateCompetence from './CreateCompetence';
import { useGetCompetencesQuery, useGetCurrentUserQuery } from 'utils/wemble-api';
import { CompetenceDto } from 'utils/wemble-api.generated';
import { Link } from 'react-router-dom';
import organization from 'utils/organization';

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: 'white',
    padding: 6,
    borderRadius: 12,
    display: 'flex',
    marginTop: 12,
    overflow: 'scroll',
    scrollbarWidth: 'none'
  },
  select__add: {
    backgroundColor: theme.palette.gray.g7,
    borderRadius: 8,
    minWidth: 32,
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& > img': {
      width: '40%',
    },
  },
  select__category: {
    backgroundColor: theme.palette.blue.opacity.b32,
    borderRadius: 16,
    height: 32,
    padding: '0 10px',
    fontSize: 12,
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 6,
    whiteSpace: 'nowrap'
  },
  select__close: {
    backgroundColor: theme.palette.red.opacity.r16,
    borderRadius: '50%',
    height: 32,
    width: 32,
    minWidth: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 18,
    '& > img': {
      width: '30%',
    },
  },
  settingsBtn: {
    backgroundColor: theme.palette.red.opacity.r16,
    borderRadius: '50%',
    height: 32,
    width: 32,
    minWidth: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 18,
    '& > img': {
      width: '50%',
    },
  },
}));

const CompetencesSelect = ({
  setVisibleCompetences,
  searchQuery,
}: {
  setVisibleCompetences: (competences: CompetenceDto[]) => void;
  searchQuery: string;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [categories, setCategories] = useState<CompetenceDto[]>([]);
  const [subCategories, setSubcategories] = useState<CompetenceDto[]>([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const { data: allCompetences } = useGetCompetencesQuery();
  const { data: user } = useGetCurrentUserQuery();
  const organizationConfig = organization(user?.company);

  const competences = allCompetences?.filter(
    (competence) =>
      competence.name?.toLowerCase().includes(searchQuery.toLowerCase()) &&
      !(competence.root && competence.parent === null),
  );
  const rootCategories = allCompetences?.filter(
    (competence) =>
      competence.name?.toLowerCase().includes(searchQuery.toLowerCase()) && competence.root && !competence.subCategory,
  );

  const clearCategories = () => {
    setCategories(rootCategories ?? []);
    setSubcategories([]);
    setVisibleCompetences(competences ?? []);
    setSelectedCategoryIds([]);
  };

  useEffect(() => {
    clearCategories();
  }, [allCompetences, searchQuery]);

  const toggleModal = () => {
    setVisible(!visible);
  };

  const handleCategoryClick = (category: CompetenceDto) => {
    setCategories([category]);
    setSubcategories(
      allCompetences?.filter(
        (c) => c.root && c.parent && (c.parent._id === category._id || c.parent === category._id),
      ) ?? [],
    );
    setCompetences(category._id, true);
  };

  const handleSubCategoryClick = (category: CompetenceDto) => {
    setCompetences(category._id, false);
  };

  const setCompetences = (categoryId: string | null | undefined, isRootCategory: boolean) => {
    if (!categoryId) return;
    setSelectedCategoryIds((prevSelected) => {
      let newSelectedCategories: string[] = [];

      if (isRootCategory) {
        if (prevSelected.length === 1 && prevSelected.includes(categoryId)) {
          newSelectedCategories = [];
        } else {
          newSelectedCategories = [categoryId];
        }
      } else {
        if (prevSelected.length === 1 && rootCategories?.find((c) => c._id === prevSelected[0])) {
          newSelectedCategories = [categoryId];
        } else if (prevSelected.includes(categoryId)) {
          const indexOfCategory = prevSelected.findIndex((s) => s === categoryId);
          newSelectedCategories = prevSelected;
          newSelectedCategories.splice(indexOfCategory, 1);
        } else {
          newSelectedCategories = [categoryId];
        }
      }

      if (newSelectedCategories.length === 1 || (newSelectedCategories.length === 0 && !isRootCategory)) {
        if (newSelectedCategories.length === 0 && !isRootCategory) {
          const rootCategory = rootCategories?.find(
            (r) => r._id === competences?.find((c) => c._id === categoryId)?.parent?._id,
          );
          if (rootCategory?._id) {
            newSelectedCategories = [rootCategory._id];
            setCategories([rootCategory]);
            setSubcategories(
              allCompetences?.filter((c) => c.root && c.parent && c.parent._id === rootCategory._id) ?? [],
            );
          } else {
            setCategories(rootCategories ?? []);
            setSubcategories([]);
            setVisibleCompetences(competences ?? []);
            return newSelectedCategories;
          }
        }
        const selectedRootCompetences =
          competences?.filter(
            (competence) => competence?.parent?._id && newSelectedCategories.includes(competence?.parent?._id),
          ) ?? [];
        const childCompetences =
          competences?.filter((competence) => selectedRootCompetences.find((r) => r._id === competence?.parent?._id)) ??
          [];
        setVisibleCompetences([...selectedRootCompetences, ...childCompetences]);
      } else if (newSelectedCategories.length > 0) {
        setVisibleCompetences(
          competences?.filter(
            (competence) => competence?.parent?._id && newSelectedCategories.includes(competence?.parent?._id),
          ) ?? [],
        );
      } else if (newSelectedCategories.length === 0) {
        setCategories(rootCategories ?? []);
        setSubcategories([]);
        setVisibleCompetences(competences ?? []);
      }

      return newSelectedCategories;
    });
  };

  return (
    <>
      <div className={classes.select}>
        {(!organizationConfig.disableCompetencesPriviligies || user?.admin || user?.administratorPriviliges) && (<div className={classes.select__add} onClick={toggleModal}>
          <img alt={'+'} src={plus} />
        </div>
        )}

        {categories?.map((category) => (
          <React.Fragment key={category._id}>
            <div
              className={classes.select__category}
              style={
                category._id && selectedCategoryIds.includes(category._id)
                  ? { backgroundColor: theme.palette.red.opacity.r32 }
                  : undefined
              }
              onClick={() => {
                handleCategoryClick(category);
              }}
            >
              {category.name}
            </div>
          </React.Fragment>
        ))}
        {
          //TODO Fix for 'Secondment', currently not visible
          selectedCategoryIds.length > 0 && (
            <div className={classes.select__close} onClick={clearCategories}>
              <img alt={'x'} src={close} />
            </div>
          )
        }
        {categories.length === 1 && selectedCategoryIds.length === 1 && (!organizationConfig.disableCompetencesPriviligies || user?.admin || user?.administratorPriviliges) && (
          <Link to={`/competences/${selectedCategoryIds[0]}`} className={classes.settingsBtn} onClick={clearCategories}>
            <img alt={'settings'} src={settings} />
          </Link>
        )}

        {subCategories?.map((category) => (
          <React.Fragment key={category._id}>
            <div
              className={classes.select__category}
              style={
                category._id && selectedCategoryIds.includes(category._id)
                  ? { backgroundColor: theme.palette.red.opacity.r32 }
                  : undefined
              }
              onClick={() => {
                handleSubCategoryClick(category);
              }}
            >
              {category.name}
            </div>
          </React.Fragment>
        ))}
      </div>
      <Modal visible={visible} close={toggleModal}>
        <CreateCompetence isCategory close={() => setVisible(!visible)} />
      </Modal>
    </>
  );
};

export default CompetencesSelect;
